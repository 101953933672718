import {
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    FormLabelProps,
    HTMLChakraProps,
    Input,
    InputGroup,
    InputLeftElement,
    InputProps,
    InputRightElement,
} from "@chakra-ui/react";
import {ReactNode} from "react";

export type FInputGroupProps = InputProps & {
  formControlProps?: FormControlProps;
  label?: ReactNode;
  helperText?: string;
  errorMessage?: string;
  formLabelProps?: FormLabelProps;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  leftElementProps?: HTMLChakraProps<"div">;
  rightElementProps?: HTMLChakraProps<"div">;
} & Pick<FormControlProps, "width">;

function FInputGroup({
  formControlProps,
  label,
  helperText,
  errorMessage,
  formLabelProps,
  leftElement,
  rightElement,
  leftElementProps,
  rightElementProps,
  width = 400,
  ...props
}: FInputGroupProps) {
  return (
    <FormControl {...formControlProps} width={width}>
      <FormLabel variant="custom" {...formLabelProps}>
        {label}
      </FormLabel>
      <InputGroup>
        {leftElement && <InputLeftElement {...leftElementProps}>{leftElement}</InputLeftElement>}
        <Input variant="custom" {...props} />
        {rightElement && <InputRightElement {...rightElementProps}>{rightElement}</InputRightElement>}
      </InputGroup>
      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : <></>}
      {!errorMessage && helperText ? <FormHelperText>{helperText}</FormHelperText> : <></>}
    </FormControl>
  );
}

export default FInputGroup;
