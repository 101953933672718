import React from "react";
import {
    mdiAccountBox,
    mdiCalculatorVariant,
    mdiCalendarToday,
    mdiClipboardTextMultiple,
    mdiCog,
    mdiInbox,
} from "@mdi/js";
import Icon from "@mdi/react";
import {IconProps} from "@mdi/react/dist/IconProps";

export type MenuIconName = "student" | "exam" | "activity" | "training" | "settings" | "calculator";

export const menuIconMapping: Record<MenuIconName, string> = {
  student: mdiAccountBox,
  exam: mdiInbox,
  activity: mdiCalendarToday,
  training: mdiClipboardTextMultiple,
  settings: mdiCog,
  calculator: mdiCalculatorVariant,
};

export type MenuIconProps = Omit<IconProps, "path"> & {
  name: MenuIconName;
};

function MenuIcon({ name, ...props }: MenuIconProps) {
  return <Icon path={menuIconMapping[name]} {...props} />;
}

export default MenuIcon;
