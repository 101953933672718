import {Link, LinkProps} from "@chakra-ui/react";
import React from "react";
import {To, useNavigate} from "react-router-dom";

export type NavLinkProps = Omit<LinkProps, "onClick"> & {
  to: To;
};

function NavLink({ to, ...props }: NavLinkProps) {
  const navigate = useNavigate();
  return (
    <Link
      onClick={() => {
        navigate(to);
      }}
      {...props}
    />
  );
}

export default NavLink;
