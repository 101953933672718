import {GridItem, GridItemProps} from "@chakra-ui/react";
import React from "react";

export type DtlGridItemProps = Pick<GridItemProps, "children" | "className"> & {
  title: string;
};

function DtlGridItem({ title, ...props }: DtlGridItemProps) {
  return (
    <>
      <GridItem className="dg-header">{title}</GridItem>
      <GridItem {...props} />
    </>
  );
}

export default DtlGridItem;
