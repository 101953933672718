import {Box, Button, Flex, Text, VStack} from "@chakra-ui/react";
import {mdiFile} from "@mdi/js";
import Icon from "@mdi/react";
import {ApiDefinitionData} from "api/ApiDefinition";
import {AxiosRequestConfig} from "axios";
import {useAPIRequest, useCustomToast, useLoading} from "hooks";
import React, {ChangeEvent, HTMLProps, MouseEventHandler, ReactNode, useRef, useState} from "react";
import CommonModal, {CommonModalProps} from "./CommonModal";
import {getErrorMessage} from "./errMsgHelper";
import {appendFiles, download} from "./fileHelper";

export type ImportModalProps = CommonModalProps &
  Pick<HTMLProps<HTMLInputElement>, "accept"> & {
    api: ApiDefinitionData;
    onFinish: () => void;
    exampleFilePath?: string;
    exampleFileName?: string;
    uploadDescription: string;
    additionView?: ReactNode;
    onImport?: () => FormData;
  } & Pick<AxiosRequestConfig, "params">;

function ImportModal({
  api,
  accept = ".xlsx",
  additionView,
  exampleFilePath,
  exampleFileName = "excel_sample.xlsx",
  onClose,
  onFinish,
  uploadDescription,
  onImport,
  params,
  ...props
}: ImportModalProps) {
  const toast = useCustomToast();
  const { showLoading } = useLoading();
  const { processRequest } = useAPIRequest();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleExampleFileDownload = () => {
    if (exampleFilePath) {
      download(exampleFilePath, exampleFileName);
    } else {
      toast("warning", "目前暫時沒有範本檔案");
    }
  };
  const handleChooseFileButton = () => {
    inputRef.current?.click();
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const mFiles = Array.from<File>(e.target.files || []);
    if (mFiles && mFiles.length > 0) {
      const file = mFiles[0];
      setSelectedFile(file);
    }
  };
  const handleImport = () => {
    if (selectedFile) {
      const mFormData = onImport ? onImport() : new FormData();
      appendFiles(mFormData, [selectedFile]);
      showLoading();
      processRequest(api, mFormData, params)
        .then((res) => {
          toast("success", "上傳檔案成功");
          onClose();
          onFinish && onFinish();
        })
        .catch((err) => {
          const message = getErrorMessage(err, "上傳檔案失敗");
          toast("error", message);
          console.error(err);
        })
        .finally(() => {
          showLoading(false);
        });
    } else {
      toast("error", "請選擇上傳檔案");
    }
  };
  return (
    <CommonModal
      size="xl"
      onClose={onClose}
      footer={
        <Button variant="customSolid" onClick={handleImport}>
          上傳資料
        </Button>
      }
      {...props}
    >
      <VStack justifyContent="start" spacing={10} pb={10}>
        <StepUnitView
          title="Step1. 下載excel範本"
          buttonTitle="檔案下載"
          description={exampleFileName}
          onButtonClick={handleExampleFileDownload}
        />
        <StepUnitView
          title={`Step2. ${uploadDescription}`}
          buttonTitle="選擇檔案"
          description={selectedFile ? selectedFile.name : "未選擇任何檔案"}
          descriptionColor={selectedFile ? "black" : "secondary.600"}
          onButtonClick={handleChooseFileButton}
        />
        {additionView}
      </VStack>
      <Box />
      <Box display="none">
        <input type="file" onChange={handleChange} accept={accept} ref={inputRef} />
      </Box>
    </CommonModal>
  );
}

interface StepUnitViewProps {
  title: string;
  buttonTitle: string;

  onButtonClick?: MouseEventHandler;
  description: string;
  descriptionColor?: string | undefined;
}

function StepUnitView({
  title,
  buttonTitle,
  onButtonClick,
  description,
  descriptionColor = "black",
}: StepUnitViewProps) {
  return (
    <Box w="100%">
      <Text fontSize="18px" fontWeight="bold">
        {title}
      </Text>
      <Flex alignItems="center" justifyContent="space-between" w="325px" pt={4}>
        <Flex alignItems="center" color={descriptionColor}>
          <Icon path={mdiFile} size={1} />
          <Text ml={2} maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {description}
          </Text>
        </Flex>
        <Button variant="customOutline" onClick={onButtonClick} size="sm" fontSize="15px">
          {buttonTitle}
        </Button>
      </Flex>
    </Box>
  );
}

export default ImportModal;
