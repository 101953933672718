import {forwardRef, ReactNode} from "react";
import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import TimeInput, {TimeInputProps} from "./datePicker/TimeInput";
import {
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    FormLabelProps,
} from "@chakra-ui/react";
import CustomCalendarContainer from "./datePicker/CustomCalendarContainer";

export type FTimePickerProps = Omit<ReactDatePickerProps, "customInput"> & {
  formControlProps?: FormControlProps;
  label?: ReactNode;
  helperText?: string;
  errorMessage?: string;
  formLabelProps?: FormLabelProps;
  inputProps?: TimeInputProps;
} & Pick<FormControlProps, "width">;

function FTimePicker({
  formControlProps,
  label,
  helperText,
  errorMessage,
  formLabelProps,
  inputProps,
  width = 400,
  ...props
}: FTimePickerProps) {
  const CustomInput = forwardRef<HTMLInputElement>((props, ref) => {
    return <TimeInput {...inputProps} {...props} />;
  });

  return (
    <FormControl {...formControlProps} width={width}>
      <FormLabel variant="custom" {...formLabelProps}>
        {label}
      </FormLabel>
      <DatePicker
        {...props}
        customInput={<CustomInput />}
        calendarContainer={(props) => <CustomCalendarContainer {...props} />}
        //locale="zh-TW"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        calendarStartDay={0}
        timeCaption="時間"
        showTimeSelect
        showTimeSelectOnly
        autoComplete="off"
      />
      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : <></>}
      {!errorMessage && helperText ? <FormHelperText>{helperText}</FormHelperText> : <></>}
    </FormControl>
  );
}

export default FTimePicker;
