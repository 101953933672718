import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    ModalProps,
    Text,
    VStack,
} from "@chakra-ui/react";
import {mdiAlertCircle} from "@mdi/js";
import Icon from "@mdi/react";
import React, {ReactNode} from "react";

export type WarningDialogProps = Omit<ModalProps, "children"> & {
  title: string;
  message?: ReactNode;
  onConfirm: () => void;
};

function WarningDialog({ title, message, onConfirm, onClose, ...props }: WarningDialogProps) {
  return (
    <Modal onClose={onClose} isCentered closeOnOverlayClick={false} closeOnEsc={false} {...props}>
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalBody>
          <VStack spacing={3} textAlign="center">
            <Icon color="red" path={mdiAlertCircle} size={5} />
            <Text fontWeight="bold" fontSize="22.5px">
              {title}
            </Text>
            {message && <Text fontSize="18px">{message}</Text>}
            <HStack w="100%" spacing={3} pt={5}>
              <Button
                w="100%"
                variant="customOutline"
                onClick={() => {
                  onClose();
                }}
              >
                取消
              </Button>
              <Button
                type="submit"
                w="100%"
                variant="customSolid"
                onClick={() => {
                  onConfirm();
                }}
              >
                確定
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default WarningDialog;
