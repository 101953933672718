import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import api from '../../api/Api'
import style from '../css/student_detail_panel.module.css'

import radio_true from '../../svg-files/radio-true.svg'
import radio_false from '../../svg-files/radio-false.svg'
import dropdown_calendar from '../../svg-files/calendar-black.svg'

// import { useFetchData, useCustomToast, useLoading } from "hooks";
// import PathDefinition from "routes/RoutePathDefinition";
// import ApiDefinition from 'api/ApiDefinition';

let StudentTestPanelComponent = () => {
  
  const loading = useState(true)
  const { id } = useParams()
  const memberdata = useState({})
  let company_type = {}
  
  useEffect(() => {
    if (!Number.isNaN(Number(id))) {
      loading[1](true)
      api.get_company_types().then((res) => {
        for (let i = 0; i < res.length; ++i) {
          company_type[res[i].id] = res[i].label
        }

        return api.get_student_exam_list(id)
      }).then((res) => {
        console.log('get_student_exam_list',res)
        if (res['companyType'] !== null && company_type.hasOwnProperty(res['companyType']) === true) {
          res['companyType'] = company_type[res['companyType']]
        }
        if (res.total > 0){
          memberdata[1](res.rows[0])
        }else{
          memberdata[1]([])
        }

      }).catch((e) => {

      }).finally(() => {
        loading[1](false)
      })
    }

  }, [id])

  const layout_model=[
    { type: 'label', label: '批次', dataid:'batch'},
    {type: 'empty',},
    { type: 'label', label: '結業證號', dataid: 'classNum'},
    {type: 'label', label: '結業訓練機構', dataid: ''},
    {type: 'empty',},
    {type: 'label', label: '中文姓名', dataid: 'chName'},
    {type: 'label', label: '英文姓', dataid: 'SurName'},
    {type: 'label', label: '英文名', dataid: 'GivenName'},
    {type: 'empty',},
    {type: 'radio', label: '是否持有CFP/AFP', radio_opts:[{'是':true}, {'否':false}], dataid: 'certNum'},
    {type: 'label', label: '編號', dataid: 'certNum'},
    {type: 'empty',},
    {type: 'label', label: '身份證字號', dataid: 'idNum'},
    {type: 'label', label: '出生年月日', dataid: 'birthday'},
    {type: 'empty',},
    {type: 'label', label: '現職服務單位類別', dataid: 'companyType'},
    {type: 'label', label: '現職服務單位', dataid: 'company'},
    {type: 'label', label: '本工作到職日', dataid: 'workDate'},
    {type: 'label', label: '每周工作時數', dataid: 'workHoursPerWeek'},
    {type: 'label', label: '總計年資', dataid: 'totalYears'},
    {type: 'label', label: '簡述實質工作內容', dataid: 'description'},
    {type: 'empty',},
    {type: 'label', label: '公司', dataid: 'companyArea'},
    {type: 'label', label: '公司地址', dataid: 'companyAddress'},
    {type: 'label', label: '住家', dataid: 'homeArea'},
    {type: 'label', label: '住家地址', dataid: 'homeAddress'},
    {type: 'empty',},
    {type: 'label', label: '收據抬頭', dataid: 'vatTitle'},
    {type: 'label', label: '統一編號', dataid: 'vatNumber'},
    {type: 'empty',},
    {type: 'label', label: '最高學歷', dataid: 'eduLevel'},
    {type: 'label', label: '學位取得年度', dataid: 'graduateYear'},
    {type: 'label', label: '畢業學校', dataid: 'school'},
    {type: 'label', label: '科系', dataid: 'eduClass'},
    {type: 'empty',},
    {type: 'label', label: '公司電話', dataid: 'workPhone'},
    {type: 'label', label: '住家電話', dataid: 'homePhone'},
    {type: 'label', label: '行動電話', dataid: 'mobile'},
    {type: 'label', label: '電子郵件信箱', dataid: 'email'},
    {type: 'empty',},
    {type: 'label', label: '考試延期狀態', data: ''},
  ]
  return (
    <div className={['colume', style['container']].join(' ')} style={{minWidth: '915px', marginTop: '18px', marginBottom: '26px', paddingTop: '30px', paddingLeft: '60px', paddingBottom: '40px', fontSize: '18px'}}>
      {
        layout_model.map((v,idx)=>{
          if (v.type==='label'){
            return(
              <div key={idx} className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
                <div className={['col-auto'].join(' ')} style={{width: '159px', textAlign: 'right', color: '#616161'}}>
                  {v.label}
                </div>
                <div className={['col'].join(' ')} style={{color: 'black', marginLeft: '32px'}}>
                  {(v.dataid === '' || memberdata[0][v.dataid] === null || memberdata[0][v.dataid] === undefined || memberdata[0][v.dataid] === '') ? '-' : memberdata[0][v.dataid]}
                </div>
              </div>
            )
          }else
          if (v.type==='empty'){
            return(
              <div key={idx} className={['col-auto', 'row'].join(' ')}>&nbsp;</div>
            )
          }else
          if (v.type==='head'){
            return(
              <div key={idx} className={['col-auto', ].join(' ')} style={{width: '159px', color: '#212121', textAlign: 'right', fontSize: '18px'}}>{v.label}</div>
            )
          }else
          if (v.type==='radio'){
            return (
              <div key={idx} className={['col-auto', 'row'].join(' ')} style={{ marginBottom: '14px' }}>
                <div className={['col-auto'].join(' ')} style={{ width: '159px', textAlign: 'right', color: '#616161' }}>
                  {v.label}
                </div>
                <div className={['col-auto', 'row'].join(' ')} style={{ color: '#616161', marginLeft: '12px' }}>
                  {
                    v.radio_opts.map((subv, subidx) => {
                      return (
                        <div key={subidx} className={['col-auto', 'row', 'items-center'].join(' ')} style={{ marginLeft: '20px' }}>
                          <img alt="" className={['col-auto'].join(' ')} src={((memberdata[0][v.dataid] !== undefined && memberdata[0][v.dataid] !== null && memberdata[0][v.dataid].length > 0) === subv[Object.keys(subv)[0]]) ? radio_true : radio_false} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                          <div className={['col-auto'].join(' ')} >{Object.keys(subv)[0]}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          }else
          if (v.type==='radio_ext_arr'){
            return(
              <div key={idx} className={['col-auto', 'row', 'no-wrap'].join(' ')} style={{marginBottom: '14px'}}>
                <div className={['col-auto'].join(' ')} style={{width: '159px', textAlign: 'right', color: '#616161'}}>
                  {v.label}
                </div>
                <div className={['col-auto', 'column'].join(' ')} style={{color: 'black', marginLeft: '32px'}}>
                { 
                  v.ext_arr.map((subv, subidx)=>{
                    return (
                      <div key={subidx} className={['col-auto', 'row'].join(' ')} style={{color: 'black', marginBottom: '14px'}}>
                      {
                        subv.map((rowv, rowidx)=>{
                          return (
                            <div key={rowidx} className={['col-auto', 'row'].join(' ')} style={{marginLeft: rowv.marginLeft}}>
                              <div className={['col-auto'].join(' ')} style={{width: rowv.width}}>
                                {rowv.ext}
                              </div>
                              <div className={['col-auto', 'row'].join(' ')} style={{color: '#616161', marginLeft: '12px'}}>
                              {
                                rowv.radio_opts.map((rowsubv, rowsubidx)=>{
                                  return (
                                    <div key={rowsubidx} className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginLeft: '20px'}}>
                                      <img alt="" className={['col-auto'].join(' ')} src={radio_false} style={{width: '20px', height: '20px', marginRight: '8px'}}/>
                                      <div className={['col-auto'].join(' ')} >{Object.keys(rowsubv)[0]}</div>
                                    </div>
                                  )
                                })
                              }
                              </div>
                            </div>

                          )
                            
                        })
                      }
                      </div>
                    )
                  })
                }
                </div>
              </div>
            )
          }else
          if (v.type ==='date'){
            return (
              <div key={idx} className={['col-auto', 'row', 'items-center'].join(' ')} style={{ marginBottom: '14px' }}>
                <div className={['col-auto'].join(' ')} style={{ width: '159px', textAlign: 'right', color: '#616161' }}>
                  {v.label}
                </div>
                <div className={['col-auto', 'row', 'justify-between', 'items-center', style['custom-outline-button']].join(' ')} style={{ marginLeft: '32px' }}>
                  <label className={['col-auto'].join(' ')} style={{ pointerEvents: 'none' }}>{memberdata[0][v.dataid] === null ? '-' : memberdata[0][v.dataid]}</label>
                  <img alt="" src={dropdown_calendar} style={{ width: '16.67px', height: '18.33px', marginLeft: '9.67px' }} />
                </div>

              </div>
            )
          }
        })
      }
      <div className={['col-auto', 'row', 'no-wrap'].join(' ')} style={{marginTop: '64px', marginLeft: '580px'}}>
        <div className={['col-auto'].join(' ')} style={{marginRight: '24px', color: '#616161'}}>更新時間</div>
        <div className={['col-auto'].join(' ')}>2022-05-16 12:12</div>
      </div>
    </div>
  )
}

export default StudentTestPanelComponent
