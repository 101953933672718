import {Text} from "@chakra-ui/react";
import PageContainer from "common/PageContainer";
import React from "react";
import HistoryExamStatBlock from "./HistoryExamStatBlock";
import SingleExamStatBlock from "./SingleExamStatBlock";
import CalculatorStatBlock from "./CalculatorStatBlock";

function Dashboard() {
  return (
    <PageContainer>
      <Text fontSize="28.5px" fontWeight="bold" mb={5}>
        RFA 後台管理系統
      </Text>
      <HistoryExamStatBlock />
      <SingleExamStatBlock />
      <CalculatorStatBlock />
    </PageContainer>
  );
}

export default Dashboard;
