export const importTypeMapping = {
  "1": "個人報名",
  "2": "團體報名",
};

export const transactionStatusMapping = {
  "0": "-",
  "1": "已付款",
  "2": "待付款",
  "10100073": "待繳費",
  "10100058": "付款失敗",
  "10100248": "拒絕交易",
  "10100249": "交易失敗",
  "10100251": "卡片過期",
  "10100282": "3D授權未完成",
};
