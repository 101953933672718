import {Grid} from "@chakra-ui/react";
import CommonModal, {CommonModalProps} from "common/CommonModal";
import DtlGridBlank from "common/DtlGridBlank";
import DtlGridItem from "common/DtlGridItem";
import NumericText from "common/NumericText";
import React from "react";
import {signUpTypeMapping, transactionStatusMapping} from "../commonValues";
import {ActivitySignUpData} from "../type";

export type SignUpDetailModalProps = Omit<CommonModalProps, "isOpen" | "title"> & {
  data: ActivitySignUpData | null;
};

function SignUpDetailModal({ data, ...props }: SignUpDetailModalProps) {
  const title = data ? signUpTypeMapping[data.signupType.toString() as keyof typeof signUpTypeMapping] : "報名";
  const transactionStatus = data?.userPayment
    ? transactionStatusMapping[data.userPayment.transactionStatus as keyof typeof transactionStatusMapping]
    : "-";
  return (
    <CommonModal isOpen={!!data} title={title} size="xl" {...props}>
      {data && (
        <Grid fontSize={18} templateColumns="150px 1fr" gap="2.5" w="100%">
          <DtlGridItem title="姓名">{data.user?.chName || "-"}</DtlGridItem>
          <DtlGridBlank />
          <DtlGridItem title="付款狀態">{transactionStatus}</DtlGridItem>
          <DtlGridBlank />
          <DtlGridItem title="金額">{<NumericText value={data.amount} />}</DtlGridItem>
          <DtlGridItem title="收據編號">{data.userReceipt?.serialnumber || "-"}</DtlGridItem>
          <DtlGridBlank />
          <DtlGridItem title="公司抬頭">{data.vatTitle || "-"}</DtlGridItem>
          <DtlGridItem title="統一編號">{data.vatNumber || "-"}</DtlGridItem>
          <DtlGridBlank />
          <DtlGridItem title="廠商訂單編號">{data.userPayment?.transactionId || "-"}</DtlGridItem>
        </Grid>
      )}
    </CommonModal>
  );
}

export default SignUpDetailModal;
