import ApiDefinition from "api/ApiDefinition";
import ExamImportModal from "../pagesV2/exam/ExamImportModal";
import style from './css/student_detail.module.css'
import people_dark_svg from '../svg-files/people-dark.svg'
import breadcrumb_arrow_svg from '../svg-files/breadcrumb-arrow.svg'
import red_cross from '../svg-files/red-cross.svg'

import {
    Button,
    Spinner,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useDisclosure,
    useMultiStyleConfig,
    useTab,
    useToast
} from '@chakra-ui/react'
import {forwardRef, useEffect, useRef, useState} from 'react'

import PathDefinition from '../routes/RoutePathDefinition'
import {generatePath, matchRoutes, useLocation, useNavigate, useParams} from 'react-router-dom'

import ExamDetailPanelComponent from './components/ExamDetailPanelComponent'
import ExamDetailEditPanelComponent from './components/ExamDetailEditPanelComponent'
import ExamCandidatePanelComponent from './components/ExamCandidatePanelComponent'
import api from '../api/Api'
import WarningDialogComponent from './components/WarningDialogComponent'
import LoadingComponent from './components/LoadingComponent'
import ExamScorecheckPanelComponent from './components/ExamScorecheckPanelComponent'
import ExamCertPanelComponent from './components/ExamCertPanelComponent'

let ExamDetail =()=>{
  const disclosureExam = useDisclosure();
  const delModaldisclosure =useDisclosure()
  const memberModalDisclosure = useDisclosure()
  const importType =useRef(0) //按鈕動作代碼
  const groupType =useState(0)

  const toast =useToast()
  const location =useLocation()
  const params =useParams()
  const [currTabIdx, setCurrTabIdx] =useState(0)
  const pathIdxMap=[
    PathDefinition.EXAM_DETAIL.path,
    PathDefinition.EXAM_CANDIDATE.path,
    PathDefinition.EXAM_SCORECHECK.path,
    PathDefinition.EXAM_CERTIFICATE.path,
  ]
  const editablePathIdxMap=[
    PathDefinition.EXAM_DETAIL_EDIT.path,
  ]
  const pagedPathIdxMap=[
    '',
    PathDefinition.EXAM_CANDIDATE_PAGE.path,
    PathDefinition.EXAM_SCORECHECK_PAGE.path,
    PathDefinition.EXAM_CERTIFICATE_PAGE.path,
  ]
  const [editMode, setEditmode] =useState(false)
  const navigate =useNavigate()

  const memberdata =useState({})
  const loading =useState(true)

  const delete_processing =useState(false)

  useEffect(()=>{
    if (params.hasOwnProperty('id')===true){
      loading[1](true)
      api.get_exam_detail(params.id).then((res)=>{
        //console.log(res)
        memberdata[1](res)
      }).catch((e)=>{
        
      }).finally(()=>{
        loading[1](false)
      })
    }
    
  },[location])

  const tab_name=[
    '考試資訊',
    '考生資訊',
    '複查資訊',
    '認證資訊',
  ]

  const modal_type=[
    '',//0
    '',//1
    '考生',//2
    '淮考證',//3
    '應考證明',//4
    '成績',//5
    '複查名單',//6
    '複查成績',//7
    '證書',//8
  ]

  const modal_api=[
    '',
    '',
    ApiDefinition.IMPORT_EXAM_SIGNUP,//2
    ApiDefinition.IMPORT_EXAM_TICKET,//3
    ApiDefinition.IMPORT_EXAM_SHOWUP,//4
    ApiDefinition.IMPORT_EXAM_SCORE,//5
    ApiDefinition.IMPORT_EXAM_CHECK,//6
    ApiDefinition.IMPORT_EXAM_RESULT,//7
    ApiDefinition.IMPORT_CERTIFICATE,//8
  ]

  const modal_sample = [
    '',
    '',
    '考生匯入.xlsx',//2
    '准考證匯入.xlsx',//3
    '應考證明匯入.xlsx',//4
    '考試成績匯入.xlsx',//5
    '複查名單匯入.xlsx',//6
    '複查成績匯入.xlsx',//7
    '證書匯入.xlsx',//8
  ]

  useEffect(()=>{
    
    const find_match =matchRoutes(pathIdxMap.map(v=>{return {path: v}}), location)
    if (find_match!==null && find_match.length>0){
      console.warn('39 - update tab state...')
      const tab_idx =pathIdxMap.indexOf(find_match[0].route.path)
      setCurrTabIdx(tab_idx)
      setEditmode(false)
      console.warn('58 - set tabidx to '+tab_idx+', set editmode to false')
      return
    }

    const find_match2 =matchRoutes(editablePathIdxMap.map(v=>{return {path: v}}), location)
    if (find_match2!==null && find_match2.length>0){
      console.warn('39 - update edit mode tab state...')
      const tab_idx =editablePathIdxMap.indexOf(find_match2[0].route.path)
      setCurrTabIdx(tab_idx)
      setEditmode(true)
      console.warn('71 - set tabidx to '+tab_idx+', set editmode to true')
      return
    }

    const find_match3 =matchRoutes(pagedPathIdxMap.map(v=>{return {path: v}}), location)
    if (find_match3!==null && find_match3.length>0){
      console.warn('83 - update paging mode tab state...')
      const tab_idx =pagedPathIdxMap.indexOf(find_match3[0].route.path)
      setCurrTabIdx(tab_idx)
      console.warn('86 - set tabidx to '+(tab_idx))
      return
    }

    //unknown tab mode
    //...

  },[location.pathname])

  const CustomTab =forwardRef((props, ref)=>{
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref })
    const isSelected = !!tabProps['aria-selected']

    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps)

    return (
      <button __css={styles.tab} {...tabProps} className={[style['custom-tab'], style[isSelected?'selected':'']].join(' ')}>
        {tabProps.children}
      </button>
    )
  })

  return (
    <>
      <div className={['column', style['page-container']].join(' ')} >
        <div className={['col-auto', 'row', 'no-wrap', 'justify-between', 'items-center', ].join(' ')}>
          <div className={['col-auto', 'row', 'no-wrap', 'items-center', ].join(' ')}>
            <img alt="" className={'col-auto'} src={people_dark_svg} style={{width: '27px', height: '27px'}}/>
            <div className={'col-auto'} style={{fontSize: '28.13px', marginLeft: '20.5px'}}>
              考試列表
            </div>
            <img alt="" src={breadcrumb_arrow_svg} style={{marginLeft: '26px', marginRight: '25px'}}/>
            <div className={'col-auto'} style={{fontSize: '22.5px', color: '#959ca4'}}>
              {tab_name[currTabIdx]}
            </div>
            {editMode&&(
              <>
                <img alt="" src={breadcrumb_arrow_svg} style={{marginLeft: '26px', marginRight: '25px'}}/>
                <div className={'col-auto'} style={{fontSize: '22.5px', color: '#959ca4'}}>
                  編輯
                </div>
              </>
            )}


          </div>
          {currTabIdx===0&&(
            <div className={['col-auto', 'row', 'no-wrap', 'items-center'].join(' ')}>
              
              <button className={['col-auto', style['custom-button']].join(' ')} onClick={()=>{
                console.warn('142 - toggle edit mode from '+editMode+' ---> '+!editMode)
                setEditmode(!editMode)
                editMode?
                navigate(generatePath(pathIdxMap[currTabIdx], {id: params.id,})):
                navigate(generatePath(editablePathIdxMap[currTabIdx], {id: params.id,}))

              }}>{editMode?'取消編輯':'編輯'}</button>
              <button className={['col-auto', 'row', 'items-center', 'justify-center', style['custom-outline-button']].join(' ')} onClick={()=>{
                if (delete_processing[0]===true){
                  return
                }

                delete_processing[1](true)
                delModaldisclosure.onOpen()
              }} style={{marginLeft: '16px'}}>
                {delete_processing[0]?(<Spinner className={'col-auto'} style={{marginRight: '10px', width: 'var(--spinner-size)'}}/>):('')}
                <label className={'col-auto'} style={{pointerEvents: 'none'}}>刪除</label>
              </button>
            </div>
          )}
          {currTabIdx===1&&(
            <div className={['col-auto', 'row', 'no-wrap', 'items-center'].join(' ')}>
              
              <Button variant="customSolid" onClick={() => {
                  importType.current = 2
                  disclosureExam.onOpen();
              }} className={['col-auto', style['custom-button']].join(' ')} >考生匯入</Button>
              <Button variant="customSolid" onClick={() => {
                importType.current = 3
                disclosureExam.onOpen();
              }} className={['col-auto', style['custom-button']].join(' ')} >淮考證匯入</Button>
              <Button variant="customSolid" onClick={() => {
                importType.current = 4
                disclosureExam.onOpen();
              }} className={['col-auto', style['custom-button']].join(' ')} >應考證明匯入</Button>
              <Button variant="customSolid" onClick={() => {
                importType.current = 5
                disclosureExam.onOpen();
              }} className={['col-auto', style['custom-button']].join(' ')} >成績匯入</Button>
              <button className={['col-auto', style['custom-button']].join(' ')} onClick={() => {
                loading[1](true);
                console.log(`export signup list, examId:${params.id}`);
                api.get_report_exam_signup({ exam: params.id }).then((res) => {
                  console.log(`get_report_exam_signup:`,res);
                  //download(res, `報名清單.xls` );
                  window.location.href = res;
                }).catch((e) => {
                  console.log(e);
                  let message = "錯誤:";
                  if ("response" in e && e.response.data) {
                    message = e.response.data;
                  }
                  if ("message" in e) {
                    message += ` (${e.message})`;
                  }
                  toast("error", e.message);
                }).finally(() => {
                  loading[1](false)
                })
                
              }}>報名清單匯出</button>
            </div>
          )}
          {currTabIdx === 2 && (
            <div className={['col-auto', 'row', 'no-wrap', 'items-center'].join(' ')}>
              <Button variant="customSolid" onClick={() => {
                importType.current = 6
                disclosureExam.onOpen();
              }} className={['col-auto', style['custom-button']].join(' ')} >匯入複查名單</Button>
              <Button variant="customSolid" onClick={() => {
                importType.current = 7
                disclosureExam.onOpen();
              }} className={['col-auto', style['custom-button']].join(' ')} >複查成績匯入</Button>
              <button className={['col-auto', style['custom-button']].join(' ')} onClick={() => {
                loading[1](true);
                console.log(`export scorecheck list, examId:${params.id}`);
                api.get_report_exam_scorecheck({ exam: params.id }).then((res) => {
                  console.log(`get_report_exam_scorecheck:`, res);
                  //download(res, `報名清單.xls` );
                  window.location.href = res;
                }).catch((e) => {
                  console.log(e);
                  let message = "錯誤:";
                  if ("response" in e && e.response.data) {
                    message = e.response.data;
                  }
                  if ("message" in e) {
                    message += ` (${e.message})`;
                  }
                  toast("error", e.message);
                }).finally(() => {
                  loading[1](false)
                })
              }}>複查成績匯出</button>
            </div>
          )}
          {currTabIdx === 3 && (
            <div className={['col-auto', 'row', 'no-wrap', 'items-center'].join(' ')}>
              <Button variant="customSolid" onClick={() => {
                importType.current = 8
                disclosureExam.onOpen();
              }} className={['col-auto', style['custom-button']].join(' ')} >證書匯入</Button>
              <button className={['col-auto', style['custom-button']].join(' ')} onClick={() => {
                //console.log(`export cert request list`);
                loading[1](true);
                console.log(`export newcert list, examId:${params.id}`);
                api.get_report_exam_newcert({ exam: params.id }).then((res) => {
                  console.log(`get_report_exam_newcert:`, res);
                  //download(res, `報名清單.xls` );
                  window.location.href = res;
                }).catch((e) => {
                  console.log(e);
                  let message = "錯誤:";
                  if ("response" in e && e.response.data) {
                    message = e.response.data;
                  }
                  if ("message" in e) {
                    message += ` (${e.message})`;
                  }
                  toast("error", e.message);
                }).finally(() => {
                  loading[1](false)
                })
              }}>認證申請匯出</button>
            </div>
          )}
        </div>

        <div className={['col-auto']} style={{fontSize: '22.5px', marginTop: '25px'}}>{memberdata[0]['name']}</div>

        <Tabs index={currTabIdx} className={['col-auto'].join(' ')} style={{marginTop: '10px', width: '100%'}} onChange={v=>{
          
          setCurrTabIdx(v)
          setEditmode(false)
          let urlparams = generatePath(pathIdxMap[v], { id: params.id, });
          console.warn('311 - tab idx changed to ' + v + `, pathIdxMap[${v}]: ${pathIdxMap[v]}`)
          window.history.pushState(null, null, '#'+urlparams);
        }}>
          <TabList style={{borderColor: '#b7cae0'}}>
            <CustomTab>{tab_name[0]}</CustomTab>
            <CustomTab>{tab_name[1]}</CustomTab>
            <CustomTab>{tab_name[2]}</CustomTab>
            <CustomTab>{tab_name[3]}</CustomTab>
          </TabList>

          <TabPanels>
            <TabPanel style={{padding: '0px'}}>
              {editMode?(
                <ExamDetailEditPanelComponent 
                  memberdata={memberdata}
                  cancel={()=>{ navigate(generatePath(pathIdxMap[currTabIdx], {id: params.id,})) }} 
                  save={(data, completed_callback)=>{

                    // //strip null/undefined data
                    // let keys =Object.keys(data)
                    // for(let i=0;i<keys.length;++i){
                    //   if (data[keys[i]]===null || data[keys[i]]===undefined){
                    //     delete data[keys[i]]
                    //   }
                    // }

                    console.log(`api before update_exam_detail:`,data)

                    api.update_exam_detail(data).then((res)=>{
                      //console.log(res)
                      completed_callback(true)
                    }).catch((e)=>{
                      completed_callback(false, e)
                    }).finally(()=>{
                      navigate(generatePath(pathIdxMap[currTabIdx], {id: params.id,}))
                    })

                  }}/>
              ):(
                <ExamDetailPanelComponent memberdata={memberdata}/>
              )}
            </TabPanel>
            <TabPanel style={{padding: '0px'}}>
              <ExamCandidatePanelComponent />
            </TabPanel>
            <TabPanel style={{ padding: '0px' }}>
              <ExamScorecheckPanelComponent />
            </TabPanel>
            <TabPanel style={{ padding: '0px' }}>
              <ExamCertPanelComponent />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <WarningDialogComponent title={''} message={'確定要刪除這筆考試嗎?'} disclosure={delModaldisclosure} handler={(cb)=>{
          if (cb===true){
            //console.log('246 - delete exam id : '+params.id)
            api.delete_exam(params.id).then((res)=>{
              console.log(res);
              if(res.status === 200){
                navigate(generatePath(PathDefinition.EXAM.path));
              }else{
                console.warn('delete_exam - error')
                toast({
                  position: 'top-center',
                  containerStyle: {
                    maxWidth: 'unset',
                    minWidth: 'unset',
                    marginBottom: '20px',
                  },
                  render: () => (
                    <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{ paddingLeft: '16px', paddingRight: '16px', backgroundColor: 'white', height: '49px', borderRadius: '12px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)' }}>
                      <img alt="" className={['col-auto'].join(' ')} src={red_cross} style={{ width: '20px', height: '20px', marginRight: '7px' }} />
                      <label className={['col-auto'].join(' ')} style={{ fontSize: '16px', color: 'black' }}>{res.data}</label>
                    </div>
                  ),
                })
              }
            }).catch((ex)=>{
              console.log(ex);              
            }).finally(()=>{
              delete_processing[1](false)
            })
          }else{
            delete_processing[1](false)
          }
        }}/>
      </div>
      <ExamImportModal
        exampleFilePath={process.env.REACT_APP_API_URL + "/files/" + importType.current + ".xlsx"}
        exampleFileName={modal_sample[importType.current]}
        api={modal_api[importType.current]}
        params={{ examId: params.id, importType: importType.current }}
        onFinish={() => {
          //TODO reload table
        }}
        title={`${modal_type[importType.current]}匯入`}
        uploadDescription={`上傳${modal_type[importType.current]}`}
        onImport={() => {
          const formData = new FormData();
          formData.append("postId", params.id || "");
          return formData;
        }}
        {...disclosureExam}
      />
      
      {loading[0]&&(
        <LoadingComponent />
      )}
    </>
  )
}

export default ExamDetail