export interface ApiDefinitionData {
  path: string;
  method: "get" | "post" | "put" | "delete";
}
const ApiDefinition: Record<string, ApiDefinitionData> = {
  REPORT_PROFILE: { method: "get", path: "/admin/report/profile" },
  REPORT_ACTIVITY: { method: "get", path: "/admin/report/activity" },
  // /admin/report/exam/signup?exam=4
  REPORT_SIGNUP: { method: "get", path: "/admin/report/exam/signup" },
  REPORT_SCORECHECK: { method: "get", path: "/admin/report/exam/scorecheck" },
  REPORT_NEWCERT: { method: "get", path: "/admin/report/exam/newcert" },
  REPORT_RENEW: { method: "get", path: "/admin/report/renew" },
  REPORT_RENEWCERT: { method: "get", path: "/admin/report/renewcert" },
  REPORT_STATISTICS_EVERY_EXAM: { method: "get", path: "/admin/report/statistics/every_exam" },
  REPORT_STATISTICS_OWN_CERTIFICATE: { method: "get", path: "/admin/report/statistics/own_certificate" },
  REPORT_STATISTICS_ONE_EXAM_PASS: { method: "get", path: "/admin/report/statistics/one_exam_pass" },

  LOGIN: { method: "post", path: "/admin/login" }, //2022-07-13
  LOGOUT: { method: "get", path: "/admin/logout" }, //2022-07-13

  LOGIN_STATUS: { method: "get", path: "/admin/login" }, //2022-07-13

  GET_COMPANY_TYPES: { method: "get", path: "/admin/company_types" },
  GET_COMPANY: { method: "get", path: "/admin/company/" },

  GET_MEMBER_LIST: { method: "post", path: "/admin/profile" }, //2022-07-13
  GET_MEMBER_DETAIL: { method: "get", path: "/admin/profile/" }, //2022-07-13
  UPDATE_MEMBER_DETAIL: { method: "put", path: "/admin/profile" },

  GET_SUBSCRIPTION_STATUS: { method: "get", path: "/admin/subscription/" },
  GET_AGREEMENT_STATUS: { method: "get", path: "/admin/agreement/" },

  GET_EXAM_ALL_SIMPLE: { method:"get", path:"/admin/exam_all_simple"},
  GET_EXAM_LIST: { method: "get", path: "/admin/exam" }, //2022-07-29
  GET_EXAM_DETAIL: { method: "get", path: "/admin/exam/" }, //2022-07-29

  UPDATE_EXAM_DETAIL: { method: "put", path: "/admin/exam" }, //2022-08-01
  CREATE_EXAM: { method: "post", path: "/admin/exam" }, //2022-08-01
  DELETE_EXAM: { method: "delete", path: "/admin/exam/" }, //2022-08-01

  UPLOAD_IMAGE: { method: "post", path: "/admin/file/banner" }, //2022-08-11

  GET_SIGNUP_LIST: { method: "get", path: "/admin/signup" }, //2022-09-18
  GET_SIGNUP_DETAIL: { method: "get", path: "/admin/signup/" }, //2022-09-18
  UPDATE_SIGNUP_DETAIL: { method: "put", path: "/admin/signup" }, //2022-09-18
  CREATE_SIGNUP: { method: "post", path: "/admin/signup" }, //2022-09-18

  GET_SCORECHECK_LIST: { method: "get", path: "/admin/scorecheck" },

  GET_CERT_LIST: { method: "get", path: "/admin/certificate" },

  UPLOAD_SIGNUP_LIST: { method: "post", path: "/admin/file/signup" }, //2022-09-18

  GET_ACTIVITY_LIST: { method: "get", path: "/admin/activity" }, //2022-12-20
  GET_ACTIVITY_DETAIL: { method: "get", path: "/admin/activity/" }, //2022-12-20
  GET_ACTIVITY_SIGNUP_LIST: { method: "get", path: "/admin/activitysignup" }, //2022-12-26

  UPDATE_ACTIVITY_DETAIL: { method: "put", path: "/admin/activity" }, //2022-12-20
  CREATE_ACTIVITY: { method: "post", path: "/admin/activity" }, //2022-12-20
  DELETE_ACTIVITY: { method: "delete", path: "/admin/activity/" }, //2022-12-20

  IMPORT_ACTIVITY_LIST: { method: "post", path: "/admin/activity/import" }, //2022-12-23
  IMPORT_ACTIVITY_SIGNUP: { method: "post", path: "/admin/activity/signupimport" }, //2022-12-23
  IMPORT_ACTIVITY_MINS: { method: "post", path: "/admin/activity/minsimport" }, //2022-12-23
  IMPORT_PROFILE: { method: "post", path: "/admin/import/profile" }, //1
  IMPORT_EXAM_SIGNUP: { method: "post", path: "/admin/import/exam/signup" }, //2
  IMPORT_EXAM_TICKET: { method: "post", path: "/admin/import/exam/ticket" }, //3
  IMPORT_EXAM_SHOWUP: { method: "post", path: "/admin/import/exam/showup" }, //4
  IMPORT_EXAM_SCORE: { method: "post", path: "/admin/import/exam/score" }, //5
  IMPORT_EXAM_CHECK: { method: "post", path: "/admin/import/exam/scorecheck" }, //6
  IMPORT_EXAM_RESULT: { method: "post", path: "/admin/import/exam/scorecheck_result" }, //7
  IMPORT_CERTIFICATE: { method: "post", path: "/admin/import/exam/certificate" }, //8

  GET_SUBSCRIPTION_LIST: { method: "get", path: "/admin/subscription" }, //2023-02-14
  GET_PAYMENT_DETAIL: { method: "get", path: "/admin/payment/" }, //2023-02-14

  GET_BANNER_DATA: { method: "get", path: "/admin/page/rfa" }, //2023-02-14
  UPDATE_BANNER_DATA: { method: "post", path: "/admin/page/rfa" }, //2023-02-14

  GET_EXAM_LIST_ALTER: { method: "get", path: "/admin/list/exam" }, //2023-02-14
  GET_EXAM_STAT: { method: "get", path: "/admin/statistics/exam" }, //2023-02-14
  GET_SINGLE_EXAM_STAT: { method: "get", path: "/admin/statistics/exam/" }, //2023-02-14
  GET_SUBSCRIPTION_STAT: { method: "get", path: "/admin/statistics/subscription" }, //2023-02-14

  // 第三期

  GET_SUBSCRIBEFEE: { method: "get", path: "/admin/config/subscribefee" }, // 查計算機訂閱方案
  UPDATE_SUBSCRIBEFEE: { method: "post", path: "/admin/config/subscribefee" }, // 修改計算機訂閱方案的金額

  GET_RENEWFEE: { method: "get", path: "/admin/config/renewfee" }, // 查換證方案
  UPDATE_RENEWFEE: { method: "post", path: "/admin/config/renewfee" }, //修改換證方案的金額

  GET_NAV: { method: "get", path: "/admin/page/nav" }, // 查前台 navbar
  UPDATE_NAV: { method: "post", path: "/admin/page/nav" }, // 修改前台 navbar

  GET_ADVERTISEMENT: { method: "get", path: "/admin/page/advertisement" }, // 查廣告
  UPDATE_ADVERTISEMENT: { method: "post", path: "/admin/page/advertisement" }, // 修改廣告

  GET_RFA: { method: "get", path: "/admin/page/rfa" }, // 查前台 RFA 專區，測驗訊息/簡章(連結設定)
  UPDATE_RFA: { method: "post", path: "/admin/page/rfa" }, // 設定前台 RFA 專區
  UPDATE_RFA_CARD: { method: "post", path: "/admin/page/rfa/card" }, // 設定前台 RFA 專區，測驗訊息/簡章(連結設定)

  // 查編碼規則
  GET_FORMAT: { method: "get", path: "/admin/config/doc/serialnumber" }, // 編碼規則

  // EMAIL
  GET_EMAIL: { method: "get", path: "/admin/email/template" }, // 查 EMAIL 範本
  GET_EMAIL_TEMPLATE_ID: { method: "get", path: "/admin/email/template" }, // 查 EMAIL_TEMPLATE_ID
  UPDATE_EMAIL: { method: "put", path: "/admin/email/template/" }, // 修改 EMAIL 範本

  // 寄送 EMAIL
  GET_EMAIL_SENDLIST: { method: "get", path: "/admin/email/sendlist" }, //發送郵件項目清單
  POST_EMAIL_SEND: { method: "post", path: "/admin/email/send" }, //發送測試信(全)
  POST_EMAIL_SEND_TEST: { method: "post", path: "/admin/email/sendtest" }, //發送測試信(單人)

  // 轉跳簽到系統後台 verification
  POST_VERIFICATION_ADMIN: { method: "post", path: "/admin/token" }, // 轉跳系統後台
};

export default ApiDefinition;
