import {HStack, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text, VStack} from "@chakra-ui/react";
import {useAppSelector} from "hooks";
import React from "react";

function Loading() {
  const loadingCount = useAppSelector((state) => state.loading);
  const isOpen = loadingCount.length > 0;
  return (
    <Modal isOpen={isOpen} onClose={() => {}} size="xs" isCentered closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent p={5} color="white" bg="blackAlpha.700">
        <ModalBody>
          <VStack>
            <HStack>
              <Spinner h="40px" w="40px" mr={5} />
              <Text fontSize="20px">載入中...</Text>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default Loading;
