import Cookies from "universal-cookie";

let cookies =undefined

export function getCookies(key){
  if (cookies===undefined){
    cookies =new Cookies()
  }

  let ret =cookies.get(key)
  //console.log('12 - cookies.get('+key+') return '+JSON.stringify(ret))
  return ret
}

export function setCookies(key, val){
  if (cookies===undefined){
    cookies =new Cookies()
  }

  //console.log('21 - cookies.set('+key+', '+val+')...')
  cookies.set(key, val, {path:'/'})
}

export function delCookies(key){
  if (cookies===undefined){
    cookies =new Cookies()
  }

  //console.log('30 - cookies.remove('+key+')...')
  cookies.remove(key)
}

export default{
  getCookies,
  setCookies,
  delCookies,
}