import baseURL from "api/baseURL";
import axios from "axios";
import {useCallback, useState} from "react";
import useLoading from "./useLoading";

export interface FetchDataConfig {
  url: string;
  initialParams?: any;
  onError?: (err: any) => void;
}

function useFetchData<DataRow = any>({ url, initialParams, onError }: FetchDataConfig) {
  const { showLoading } = useLoading();
  const [data, setData] = useState<DataRow | null>(null);
  const [loaded, setLoaded] = useState(false);
  const fetchData = useCallback(
    (params: any = initialParams) => {
      showLoading();
      axios
        .get(url, { params, withCredentials: true, baseURL, headers: { "Content-Type": "application/json" } })
        .then((resp) => {
          const data = resp.data;
          if (data) {
            setLoaded(true);
            setData(data);
          }
        })
        .catch((err) => {
          console.log(err);
          onError && onError(err);
        })
        .finally(() => {
          showLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );
  return { fetchData, loaded, data };
}

export default useFetchData;
