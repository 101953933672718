// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.rootframe_container__w4ZCj{
  width: 100%;
  min-height: 100%;
  /* background-color: red; */
}

.rootframe_drawer__xJI0b{
  width: 277px;
  min-height: 100%;
  background-color: #2a3949;
}

.col-auto.rootframe_topbar__btUHx{
  background-color: white;
  height: 48px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  z-index: 1;
}

.rootframe_topbar-title__WntAc{
  font-weight: 400;
  font-size: 22.5px;
  margin-left: 80px;
}

.rootframe_drawer-btn__PVFPs{
  background-color: #2a3949;
}
.rootframe_drawer-btn__PVFPs:hover{
  filter: brightness(1.5);
}
.rootframe_drawer-btn__PVFPs.rootframe_active__Betjz{
  background-color: #00A3FF;
  pointer-events: none;
}

.rootframe_drawer-sub-btn__njJC\\+{
  background-color: #3F4D5B;
}
.rootframe_drawer-sub-btn__njJC\\+:hover{
  filter: brightness(1.5);
}
.rootframe_drawer-sub-btn__njJC\\+.rootframe_active__Betjz{
  background-color: #00A3FF;
  /* pointer-events: none; */
}
.rootframe_drawer-sub-btn__njJC\\+.rootframe_active__Betjz:hover{
  filter: unset;
}`, "",{"version":3,"sources":["webpack://./src/pages/css/rootframe.module.css"],"names":[],"mappings":";;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,4CAA4C;EAC5C,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B;AACA;EACE,aAAa;AACf","sourcesContent":["\n\n.container{\n  width: 100%;\n  min-height: 100%;\n  /* background-color: red; */\n}\n\n.drawer{\n  width: 277px;\n  min-height: 100%;\n  background-color: #2a3949;\n}\n\n:global(.col-auto).topbar{\n  background-color: white;\n  height: 48px;\n  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);\n  z-index: 1;\n}\n\n.topbar-title{\n  font-weight: 400;\n  font-size: 22.5px;\n  margin-left: 80px;\n}\n\n.drawer-btn{\n  background-color: #2a3949;\n}\n.drawer-btn:hover{\n  filter: brightness(1.5);\n}\n.drawer-btn.active{\n  background-color: #00A3FF;\n  pointer-events: none;\n}\n\n.drawer-sub-btn{\n  background-color: #3F4D5B;\n}\n.drawer-sub-btn:hover{\n  filter: brightness(1.5);\n}\n.drawer-sub-btn.active{\n  background-color: #00A3FF;\n  /* pointer-events: none; */\n}\n.drawer-sub-btn.active:hover{\n  filter: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `rootframe_container__w4ZCj`,
	"drawer": `rootframe_drawer__xJI0b`,
	"topbar": `rootframe_topbar__btUHx`,
	"topbar-title": `rootframe_topbar-title__WntAc`,
	"drawer-btn": `rootframe_drawer-btn__PVFPs`,
	"active": `rootframe_active__Betjz`,
	"drawer-sub-btn": `rootframe_drawer-sub-btn__njJC+`
};
export default ___CSS_LOADER_EXPORT___;
