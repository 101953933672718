import style from '../css/student_detail_panel.module.css'
import radio_false from '../../svg-files/radio-false.svg'
import dropdown_calendar from '../../svg-files/calendar-black.svg'
import dropdown_arrow from '../../svg-files/dropdown_arrow.svg'

import MenuListDatePicker from './MenuListDatePickerV2'

import {Menu, MenuButton} from '@chakra-ui/react'

let StudentDetailEditPanelComponent =()=>{
  const layout_model =[
    [
      {type: 'input', label: '姓名', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '結業證號', dataid:''}, {type: 'spacer'}, {type: 'input', label: '結業訓練機構', dataid:''},
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '身份', dataid:''}, {type: 'spacer'}, {type: 'menu', label: '考區', dataid:''},
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '中文姓名', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input', label: '英文姓', dataid:''}, {type: 'spacer'}, {type: 'input', label: '英文名', dataid:''},
    ],
    [{type: 'empty'},],
    [
      {type: 'radio', label: '是否持CFP/AFP', radio_opts:[{'是':true}, {'否':false}], dataid: ''}, {type: 'spacer'}, {type: 'input', label: '編號', dataid:''},
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '身分證字號', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'date', label: '出生年月日', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [{type: 'empty'},],
    [
      {type: 'menu', label: '現職服務單位類別', menu_opts:[], dataid: ''}, {type: 'spacer'}, {type: 'menu', label: '現職服務單位', menu_opts:[], dataid: ''},
    ],
    [{type: 'empty'},],
    [
      {type: 'radio', label: '主要聯絡地址', radio_opts:[{'公司':'office'}, {'住家':'home'}], dataid: ''}, {type: 'spacer'}, {type: 'empty',},
    ],
    [
      {type: 'input', label: '公司', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input', label: '住家', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'addr-input', label: '公司地址', dataid:''},
    ],
    [
      {type: 'addr-input', label: '住家地址', dataid:''},
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '收據抬頭', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input', label: '統一編號', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '最高學歷', menu_opts:[], dataid: ''}, {type: 'spacer'}, {type: 'input', label: '學位取得年度', menu_opts:[], dataid: ''},
    ],
    [
      {type: 'input', label: '畢業學校', menu_opts:[], dataid: ''}, {type: 'spacer'}, {type: 'input', label: '科系', menu_opts:[], dataid: ''},
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '公司電話', menu_opts:[], dataid: ''}, {type: 'spacer'}, {type: 'input', label: '住家電話', menu_opts:[], dataid: ''},
    ],
    [
      {type: 'input', label: '行動電話', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input', label: '電子郵件信箱', dataid:''}, {type: 'spacer'}, {type: 'empty'}
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '考試延期狀態', menu_opts:[], dataid: ''}, {type: 'spacer'}, {type: 'empty',},
    ],
    [{type: 'empty'},],
  ]
  return (
    <div className={['colume', style['container']].join(' ')} style={{
        marginTop: '18px', marginBottom: '26px', 
        paddingTop: '30px', paddingLeft: '60px', paddingRight: '60px', paddingBottom: '40px', 
        fontSize: '18px',
      }}>
        <div className={['col-auto', 'column'].join(' ')} style={{maxWidth: '866px'}}>
          {
            layout_model.map((v,idx)=>{
              return (
                <div key={idx} className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
                  <div className={['col', 'row'].join(' ')}>
                    {
                      v.map((subv, subidx)=>{

                        if (subv.type==='input'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <input className={['col-auto']} type='text' style={{marginTop: '8px', height: '50px', borderRadius: '5px', border: '1px solid #757575', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                            </div>                        
                          )
                        }else
                        if (subv.type==='textarea'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <textarea className={['col-auto']} rows={2} style={{marginTop: '8px', borderRadius: '5px', border: '1px solid #757575', padding: '12px', fontSize: '18px'}}/>
                            </div>                        
                          )
                        }else
                        if (subv.type==='addr-input'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <div className={['row', 'items-center'].join(' ')} style={{marginTop: '8px'}}>
                                <input placeholder='郵遞區號' className={['col-auto']} type='number' style={{width: '120px', height: '50px', borderRadius: '5px', border: '1px solid #757575', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                                <button className={['col']} style={{marginLeft: '24px'}}>
                                  <div className={['col-auto', 'row', 'justify-between', 'items-center', style['custom-outline-button']].join(' ')} style={{height: '50px'}}>
                                    <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>請選擇縣市</label>
                                    <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                                  </div>
                                </button>
                                <button className={['col']} style={{marginLeft: '24px'}}>
                                  <div className={['col-auto', 'row', 'justify-between', 'items-center', style['custom-outline-button']].join(' ')} style={{height: '50px'}}>
                                    <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>請選擇區域</label>
                                    <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                                  </div>
                                </button>
                              </div>
                              <input placeholder='請輸入地址' className={['col-auto']} type='number' style={{height: '50px', borderRadius: '5px', border: '1px solid #757575', marginTop: '14px', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                            </div>
                          )
                        }else
                        if (subv.type==='spacer'){
                          return(
                            <div key={subidx} className={'col-auto'} style={{width: '40px'}}></div>
                          )
                        }else
                        if (subv.type==='narrow-empty'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '0px'}}></div>
                          )
                        }else
                        if (subv.type==='empty'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '26px'}}></div>
                          )
                        }else
                        if (subv.type==='radio'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{color: '#616161', marginTop: '8px', marginLeft: '-20px', height: '50px'}}>
                              {
                                subv.radio_opts.map((radiov, radioidx)=>{
                                  return (
                                    <div key={radioidx} className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginLeft: '20px'}}>
                                      <img alt="" className={['col-auto'].join(' ')} src={radio_false} style={{width: '20px', height: '20px', marginRight: '8px'}}/>
                                      <div className={['col-auto'].join(' ')} >{Object.keys(radiov)[0]}</div>
                                    </div>
                                  )
                                })
                              }
                              </div>
                            </div>                        
                          )
                        }else
                        if (subv.type==='date'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <Menu>
                                <MenuButton className={style['custom-outline-button']} style={{height: '50px', marginTop: '8px'}}>
                                  <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')} >
                                    <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>0000-00-00</label>
                                    <img alt="" src={dropdown_calendar} style={{width: '16.67px', height: '18.33px', marginLeft: '9.67px'}}/>
                                  </div>
                                </MenuButton>
                                <MenuListDatePicker />
                              </Menu>
                              
                            </div>
                          )
                        }else
                        if (subv.type==='menu'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <button className={['col-auto']} style={{marginTop: '8px',}}>
                                <div className={['col-auto', 'row', 'justify-between', 'items-center', style['custom-outline-button']].join(' ')} style={{height: '50px'}}>
                                  <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>---</label>
                                  <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                                </div>
                              </button>
                            </div>
                          )
                        }else
                        if (subv.type==='head'){
                          return(
                            <div key={subidx} className={['col-auto', ].join(' ')} style={{color: '#212121', fontSize: '22.5px'}}>{subv.label}</div>
                          )
                        }else
                        if (subv.type==='indent-label'){
                          return(
                            <div key={subidx} className={['col-auto', 'row', 'no-wrap'].join(' ')} >
                              <div className={['col-auto'].join(' ')} style={{width: '159px', textAlign: 'right', }}>
                                {subv.label}
                              </div>
                              <label className={['col-auto']} style={{color: '#616161', marginLeft: '32px'}}>ABCDEF</label>
                            </div>                        
                          )
                        }else
                        if (subv.type==='radio_ext_arr'){
                          return(
                            <div key={subidx} className={['col-auto', 'row', 'no-wrap'].join(' ')} >
                              <div className={['col-auto'].join(' ')} style={{width: '159px', textAlign: 'right', }}>
                                {subv.label}
                              </div>
                              <div className={['col-auto', 'column'].join(' ')} style={{color: 'black', marginLeft: '32px'}}>
                              { 
                                subv.ext_arr.map((subsubv, subsubidx)=>{
                                  return (
                                    <div key={subsubidx} className={['col-auto', 'row'].join(' ')} style={{color: 'black', marginBottom: '14px'}}>
                                    {
                                      subsubv.map((rowv, rowidx)=>{
                                        return (
                                          <div key={rowidx} className={['col-auto', 'row'].join(' ')} style={{marginLeft: rowv.marginLeft}}>
                                            {rowv.ext!==''&&(
                                              <div className={['col-auto'].join(' ')} style={{width: rowv.width, color: '#616161', marginRight: '32px'}}>
                                                {rowv.ext}
                                              </div>
                                            )}
                                            <div className={['col-auto', 'row', 'no-wrap'].join(' ')} style={{color: '#616161', marginLeft: '-20px'}}>
                                            {
                                              rowv.radio_opts.map((rowsubv, rowsubidx)=>{
                                                return (
                                                  <div key={rowsubidx} className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginLeft: '20px'}}>
                                                    <img alt="" className={['col-auto'].join(' ')} src={radio_false} style={{width: '20px', height: '20px', marginRight: '8px'}}/>
                                                    <div className={['col-auto'].join(' ')} >{Object.keys(rowsubv)[0]}</div>
                                                  </div>
                                                )
                                              })
                                            }
                                            </div>
                                          </div>

                                        )
                                          
                                      })
                                    }
                                    </div>
                                  )
                                })
                              }
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                </div>
              )
            })
          }

          <div className={['col-auto', 'row'].join(' ')} style={{marginTop: '26px', marginBottom: '14px'}}>
            <button className={['col', style['custom-outline-button']].join(' ')} style={{padding: '0', border: '1px solid #0e4e96', borderRadius: '4px', height: '40px', color: '#0e4e96', fontSize: '18px'}}>取消編輯</button>
            <div className={'col-auto'} style={{width: '20px'}}/>
            <button className={['col', style['custom-button']].join(' ')} style={{backgroundColor: '#0e4e96', borderRadius: '4px', heidht: '40px', fontSize: '18px', color: 'white'}}>儲存變更</button>
          </div>
        </div>

    </div>
  )
}

export default StudentDetailEditPanelComponent