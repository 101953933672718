import {mdiCalendarBlank} from "@mdi/js";
import Icon from "@mdi/react";
import BaseFormatInput, {BaseFormatInputProps} from "./BaseFormatInput";

export type DateInputProps = Omit<BaseFormatInputProps, "icon" | "regExp" | "maxLength">;

function DateInput(props: DateInputProps) {
  return (
    <BaseFormatInput
      {...props}
      icon={<Icon path={mdiCalendarBlank} size={1} />}
      regExp={/^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/g}
      maxLength={10}
    />
  );
}

export default DateInput;
