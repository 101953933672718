import {
    Box,
    Modal,
    ModalBody,
    ModalBodyProps,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
} from "@chakra-ui/react";
import React, {ReactNode} from "react";

export type CommonModalProps = Omit<ModalProps, "children" | "isCentered"> &
  Pick<ModalBodyProps, "children"> & {
    title: string;
    overlay?: boolean;
    closeButton?: boolean;
    footer?: ReactNode;
  };

export function CommonModal({
  title,
  children,
  overlay = true,
  closeButton = true,
  footer,
  ...props
}: CommonModalProps) {
  return (
    <Modal {...props} isCentered>
      {overlay && <ModalOverlay />}
      <ModalContent>
        <ModalHeader pb={1} fontSize="28px">{title}</ModalHeader>
        <Box mx={5} borderTop="1px solid" borderColor="secondary.400" />
        {closeButton && <ModalCloseButton size="lg" mt={2} />}
        <ModalBody mt={4}>{children}</ModalBody>
        <ModalFooter justifyContent="start">{footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CommonModal;
