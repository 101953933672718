import {Card} from "@chakra-ui/react";
import ApiDefinition from "api/ApiDefinition";
import {getErrorMessage} from "common/errMsgHelper";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import WarningDialog from "common/WarningDialog";
import {useAPIRequest, useConfirmDisclosure, useCustomToast} from "hooks";
import React from "react";
import {generatePath, useNavigate} from "react-router-dom";
import ActivityForm from "./ActivityForm";
import {ActivityFormValue} from "./type";
import PathDefinition from "routes/RoutePathDefinition";

function ActivityCreate() {
  const toast = useCustomToast();
  const navigate = useNavigate();
  const { processRequest } = useAPIRequest();
  const handleConfirm = (formData: ActivityFormValue) => {
    processRequest(ApiDefinition.CREATE_ACTIVITY, formData)
      .then((res) => {
        toast("success", "活動資訊建立成功");
        navigate(generatePath(PathDefinition.ACTIVITY.path));
      })
      .catch((err) => {
        const message = getErrorMessage(err, "活動資訊建立失敗");
        toast("error", message);
        console.error(err);
      });
  };
  const { handleSubmit, dialogProps } = useConfirmDisclosure<ActivityFormValue>({
    onConfirm: handleConfirm,
  });

  const handleCancel = () => {
    navigate(PathDefinition.ACTIVITY.path);
  };
  return (
    <PageContainer>
      <PageHeader menuIconName="activity" title="建立活動" />
      <Card variant="custom" px={20} py={10}>
        <ActivityForm onSubmit={handleSubmit} onCancel={handleCancel} />
      </Card>
      <WarningDialog
        title="請注意！"
        message={
          <>
            按下確定後，將建立新的活動，
            <br />
            請確認資訊是否正確無誤。
          </>
        }
        {...dialogProps}
      />
    </PageContainer>
  );
}

export default ActivityCreate;
