import {MenuList} from '@chakra-ui/react'
import {useEffect, useState} from 'react'

import style from './../css/student_detail_panel.module.css'
import twzipcode from 'twzipcode-data'

let MenuListCountyPicker =({isOpen, onClose, map_zipcode_from_code, map_zipcode_from_id, selected, update})=>{

  const options =useState([])
  const zipdata =twzipcode()

  useEffect(()=>{
    if (isOpen===true){
      //compose options list
      options[1](zipdata.counties.map(v=>{
        return v.name
      }))

    }

  },[isOpen])

  return (
    <MenuList style={{overflowY: 'auto', maxHeight: '400px', minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px',}} className={['column', 'no-wrap'].join(' ')}>
      {
        options[0].map((v,idx)=>{
          return(
            <button key={idx} className={['col-auto', 'row', 'items-center', style['custom-menu-item'], style[map_zipcode_from_id[selected]?.county===v?'active':'']].join(' ')} onClick={()=>{
              update(v)
              onClose()

            }}><label className={['col-auto']} style={{lineHeight: '100%'}}>{v}</label></button>
          )
        })

      }
    </MenuList>
  )
}

export default MenuListCountyPicker