const customVariant = {
  table: {
    fontSize: 18,
    borderCollapse: "separate",
    borderRadius: 4,
    borderSpacing: 0,
    w: 0,
    minW: "100%",
    overflowX: "scroll",
    //border: "1px solid",
    //borderColor: "blueGrey.500",
  },
  thead: {
    bg: "primary.500",
    color: "white",
    tr: {
      height: "50px",
    },
    th: {
      fontSize: 18,
    },
  },
  tbody: {
    tr: {
      _even: {
        bg: "primary.50",
      },
      td: {
        borderBottom: "1px solid black",
      },
      _last: {
        td: {
          borderBottom: "0px",
        },
      },
    },
  },
};

const Table = {
  variants: {
    custom: customVariant,
  },
};

export default Table;
