import ApiDefinition from "api/ApiDefinition";
import { MenuSelectValue } from "common/MenuSelect";
import { useFetchData } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import DashBoardUnit, { DashBoardUnitColumn } from "./DashBoardUnit";
import CompanySelect from "./select/CompanySelect";
import CompanyTypeSelect from "./select/CompanyTypeSelect";
import SummaryTable from "./select/SummaryTable";

export interface HistoryExamStatRow {
  field1?: number;
  field2?: number;
  field3?: number;
  field4?: number;
  field5?: number;
}

function HistoryExamStatBlock() {
  const [currentCompanyId, setCurrentCompanyId] = useState<MenuSelectValue>(undefined);
  const [currentCompanyName, setCurrentCompanyName] = useState<string | undefined>(undefined);
  const [currentCompanyTypeId, setCurrentCompanyTypeId] = useState<MenuSelectValue>(undefined);
  const handleCompanyChange = (value: MenuSelectValue, option: Record<number | string, string>) => {
    setCurrentCompanyId(value);
    setCurrentCompanyName(value ? option[value] : undefined);
  };
  const handleCompanyTypeChange = (value: MenuSelectValue) => {
    setCurrentCompanyTypeId(value);
    handleCompanyChange(undefined, {});
  };
  //資料載入後會更新這邊的data，將下面DashBoardUnit中的mockData取代成data即可
  const { fetchData, data } = useFetchData<number[]>({
    url: `${ApiDefinition.GET_EXAM_STAT.path}`,
  });
  //載入資料
  useEffect(() => {
    fetchData({ type: currentCompanyTypeId, name: currentCompanyName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyTypeId, currentCompanyName]);

  const mData = useMemo(
    () =>
      data
        ? data.reduce(
          (previousValue, currentValue, currentIndex) => ({
            ...previousValue,
            [`field${currentIndex + 1}`]: currentValue,
          }),
          {}
        )
        : {},
    [data]
  );

  const columns: DashBoardUnitColumn<HistoryExamStatRow>[] = [
    {
      accessKey: "field1",
      title: "測驗報名人數",
      unit: "人",
    },
    {
      accessKey: "field2",
      title: "考試通過人數",
      unit: "人",
    },
    {
      accessKey: "field3",
      title: "通過率",
      unit: "%",
    },
    {
      accessKey: "field4",
      title: "持證人人數",
      unit: "人",
    },
    {
      accessKey: "field5",
      title: "認證率",
      unit: "%",
    },
  ];
  return (
    <DashBoardUnit
      title="歷次 RFA考試統計"
      columns={columns}
      data={mData as HistoryExamStatRow}
      toolbar={
        //右上角選單
        <>
          {/* <SummaryTable /> */}
          <CompanyTypeSelect onChange={handleCompanyTypeChange} value={currentCompanyTypeId} />
          <CompanySelect companyTypeId={currentCompanyTypeId} onChange={handleCompanyChange} value={currentCompanyId} />
        </>
      }
    />
  );
}

export default HistoryExamStatBlock;
