import {useDisclosure} from "@chakra-ui/react";
import {FormikHelpers} from "formik";
import {useState} from "react";

export interface ConfirmDisclosureConfig<FormValues> {
  onConfirm: (values: FormValues) => void;
}

function useConfirmDisclosure<FormValues>({ onConfirm }: ConfirmDisclosureConfig<FormValues>) {
  const disclosure = useDisclosure();
  const [formData, setFormValues] = useState<FormValues | null>(null);
  const handleSubmit = (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    setFormValues(values);
    disclosure.onOpen();
  };
  const handleConfirm = () => {
    if (formData) {
      onConfirm(formData);
    }
  };

  return { handleSubmit, dialogProps: { ...disclosure, onConfirm: handleConfirm } };
}

export default useConfirmDisclosure;
