import {Box, Flex, HStack, Text} from "@chakra-ui/react";
import {mdiMenuRight} from "@mdi/js";
import Icon from "@mdi/react";
import React, {ReactNode, useMemo} from "react";
import MenuIcon, {MenuIconName} from "./MenuIcon";

export interface PageHeaderProps {
  menuIconName: MenuIconName;
  title: string | string[];
  toolbar?: ReactNode;
}

function PageHeader({ menuIconName, title, toolbar }: PageHeaderProps) {
  const { mainPageTitle, subPageTitles } = useMemo<{ mainPageTitle: string; subPageTitles: string[] }>(() => {
    let mainPageTitle = "";
    let subPageTitles: string[] = [];
    if (Array.isArray(title) && title.length > 0) {
      mainPageTitle = title[0];
      title.forEach((value, index) => {
        if (index > 0) {
          subPageTitles.push(value);
        }
      });
    } else if (typeof title === "string") {
      mainPageTitle = title;
    }
    return { mainPageTitle, subPageTitles };
  }, [title]);
  return (
    <Flex alignItems="center" justifyContent="space-between" mb={8} w="100%">
      <HStack spacing={5} alignItems="center">
        <Box>
          <MenuIcon size={1.5} name={menuIconName} />
        </Box>
        <Text fontWeight="bold" fontSize="28.5px">
          {mainPageTitle}
        </Text>
        {subPageTitles.map((value, index) => (
          <HStack spacing={5} key={index} color="#959CA4" fontSize="22.5px">
            <Icon path={mdiMenuRight} size={1} />
            <Text>{value}</Text>
          </HStack>
        ))}
      </HStack>
      <HStack spacing={3} alignItems="center">
        {toolbar}
      </HStack>
    </Flex>
  );
}

export default PageHeader;
