import style from './../css/menu_list_date_picker_v2.module.css'
import {MenuList,} from '@chakra-ui/react'
import {useEffect, useState} from 'react'

import calendar_arrow_svg from './../../svg-files/calendar-arrow.svg'

let MenuListDatePickerV2 =({isOpen, onClose, dateState, handleDayChange})=>{

  let date =new Date()
  const [year, setYear] =useState(date.getFullYear())
  const [month, setMonth] =useState(date.getMonth()+1)
  
  const [currYear, setCurrYear] =useState(date.getFullYear())
  const [currMonth, setCurrMonth] =useState(date.getMonth()+1)
  const [currdate, setCurrDate] =useState(date.getDate())
  


  useEffect(()=>{
    if (isOpen===true){
      //console.log('17 - update dateStr : '+dateState)

      setYear(date.getFullYear())
      setMonth(date.getMonth()+1)
      setCurrDate(date.getDate())

      setCurrYear(date.getFullYear())
      setCurrMonth(date.getMonth()+1)
      
      let expire_regex1 =/^(\d{4})\/([1-9]|0[1-9]|1[012])\/([1-9]|0[1-9]|[12][0-9]|3[01])$/
      let expire_regex2 =/^(\d{4})-([1-9]|0[1-9]|1[012])-([1-9]|0[1-9]|[12][0-9]|3[01])$/
      if (dateState!==undefined && dateState!==null){
        if (expire_regex1.test(dateState)===true){
          let date_arr =expire_regex1.exec(dateState)
          setYear(parseInt(date_arr[1]))
          setMonth(parseInt(date_arr[2]))
          setCurrDate(parseInt(date_arr[3]))

          setCurrYear(parseInt(date_arr[1]))
          setCurrMonth(parseInt(date_arr[2]))
        }else
        if (expire_regex2.test(dateState)===true){
          let date_arr =expire_regex2.exec(dateState)
          setYear(parseInt(date_arr[1]))
          setMonth(parseInt(date_arr[2]))
          setCurrDate(parseInt(date_arr[3]))

          setCurrYear(parseInt(date_arr[1]))
          setCurrMonth(parseInt(date_arr[2]))
        }
      }

    }
  },[isOpen])

  const week_label=[
    'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
  ]

  let firstDay =()=>{
    return new Date(year, month-1, 1).getDay()
  }
  let totalDays =()=>{
    return new Date(year, month, 0).getDate()
  }
  let estWeeks =()=>{
    let days =totalDays()
    let first_week =firstDay()

    let tt =first_week+days
    return [...Array(Math.ceil(tt/7)).keys()]
  }
  let prevMonthBtn =()=>{
    let tmp_month =month-1
    if (tmp_month ===0){
      tmp_month =12
      setYear(year-1)
    }

    setMonth(tmp_month)
  }
  let nextMonthBtn =()=>{
    let tmp_month =month+1
    if (tmp_month ===13){
      tmp_month =1
      setYear(year+1)
    }

    setMonth(tmp_month)
  }
  let isActive =(row, col)=>{
    return ((1+(row*7+col)-firstDay())===currdate && currMonth===month && currYear===year)
  }

  return(
    <MenuList style={{minWidth: '0', border: '1px solid #e0e0e0', boxShadow: '1px 1px 2px rgba(0,0,0,0.1)', backgroundColor: 'white', borderRadius: '6px', width: '270px', padding: '0'}} className={['column',].join(' ')}>
      
      <div className={['col-auto', 'row', 'items-center', 'justify-between'].join(' ')} style={{height: '38px', fontSize: '14.4px', fontWeight: '500', color: 'white', backgroundColor: '#0e4e96', borderTopRightRadius: '6px', borderTopLeftRadius: '6px',  paddingRight: '5px', paddingLeft: '5px',}}>
          <button className={['col-auto', 'row', 'justify-center', 'items-center', style['arrow-btn']].join(' ')} style={{width: '32px', height: '80%'}} onClick={prevMonthBtn}><img alt="" src={calendar_arrow_svg}/></button>
          <div className={['col-auto',].join(' ')}>
            {year} 年 {month} 月
          </div>
          <button className={['col-auto', 'row', 'justify-center', 'items-center', style['arrow-btn']].join(' ')} style={{width: '32px', height: '80%', transform: 'rotate(180deg)',}} onClick={nextMonthBtn}><img alt="" src={calendar_arrow_svg}/></button>
        </div>

        <div className={['col-auto', 'column',].join(' ')} style={{fontSize: '14.4px', paddingLeft: '16px', paddingRight: '16px', paddingBottom: '8px'}}>
          <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{fontSize: '14.4px', height: '34px'}}>
            {[...Array(7).keys()].map(week=>{
              return(
                <div key={week} className={'col'} style={{textAlign: 'center', fontSize: '11.52px', color: '#568386'}}>{week_label[week]}</div>
              )
            })}
          </div>
          {
            estWeeks().map(row=>{
              return(
                <div key={row} className={['col-auto', 'row', 'items-center'].join(' ')} style={{fontSize: '11.52px', height: '34px'}}>
                {[...Array(7).keys()].map(col=>{
                  let cd =(row*7+col)-firstDay()
                    if (cd<0 || cd>=totalDays()){
                      return (<div key={row*7+col} className={'col'}></div>)
                    }else{
                      return (<button key={col} style={{position: 'relative', height: '100%'}} className={['col', 'row', 'items-center', 'justify-center', style['year-btn-container'], style[isActive(row, col)?'active':''] ].join(' ')} onClick={()=>{
                        setCurrDate(1+(row*7+col)-firstDay())
                        setCurrMonth(month)
                        setCurrYear(year)
                        // setEnableState(true)
                        onClose()

                        let pad =(n)=>{return n<10 ? '0'+n : n}
                        let compose_date_str =year+'-'+pad(month)+'-'+pad(1+(row*7+col)-firstDay())
                        handleDayChange(compose_date_str)
                      }}>
                        <div className={[style['year-btn'],].join(' ')} style={{position: 'absolute', left: 'calc(50% - 16px)', top: 'calc(50% - 16px)', width: '32px', height: '32px', }}></div>
                        <div className={[style['year-btn-label'], 'col-auto',].join(' ')} style={{zIndex: 1,}}>{1+(row*7+col)-firstDay()}</div>
                      
                      </button>)
                    }
                  
                })}
                </div>
              )
            })
          }
        </div>

    </MenuList>
  )
}

export default  MenuListDatePickerV2