import {Box, Button, useDisclosure} from "@chakra-ui/react";
import {ColumnDef} from "@tanstack/react-table";
import ApiDefinition from "api/ApiDefinition";
import NavButton from "common/NavButton";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import ChakraTable from "common/ChakraTable";
import NavLink from "common/NavLink";
import ImportModal from "common/ImportModal";
import {useFetchList, usePageControl} from "hooks";
import React, {useCallback, useEffect} from "react";
import PathDefinition from "routes/RoutePathDefinition";
import {ActivityData} from "./type";
import {enableMapping} from "./commonValues";
import {generatePath} from "react-router-dom";
import NumericText from "common/NumericText";

function ActivityList() {
  const disclosure = useDisclosure();
  const { fetchData, rows, total } = useFetchList<ActivityData>({ url: ApiDefinition.GET_ACTIVITY_LIST.path });
  const { pagination, pageCount, setPagination, setDataCount } = usePageControl();
  const loadData = useCallback(() => {
    fetchData({
      pageIndex: pagination.pageIndex,
      perPage: pagination.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex]);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex]);
  useEffect(() => {
    setDataCount(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);
  const columns: ColumnDef<ActivityData, any>[] = [
    { header: "活動名稱", accessorKey: "name", cell: (info) => info.getValue() },
    { header: "開課單位", accessorKey: "orgName", cell: (info) => info.getValue() },
    { header: "活動收據標題", accessorKey: "receiptName", cell: (info) => info.getValue() },
    {
      header: "狀態",
      accessorKey: "enabled",
      cell: (info) => {
        const value = info.getValue().toString() as keyof typeof enableMapping;
        return value in enableMapping ? enableMapping[value] : "";
      },
    },
    {
      header: "活動日期",
      accessorKey: "activityDate",
      cell: (info) => {
        const value = info.getValue() as string;
        return value.replaceAll("-", "/");
      },
    },
    {
      header: "人數限制",
      accessorKey: "headCountLimit",
      cell: (info) => (
        <>
          <NumericText value={info.getValue()} />人
        </>
      ),
    },
    {
      header: "認證時數",
      accessorKey: "mins",
      cell: (info) => {
        const value = info.getValue();
        return value ? (
          <>
            <NumericText value={value / 60} decimalScale={1} />
            小時
          </>
        ) : (
          "0.0小時"
        );
      },
    },
    { header: "活動金額", accessorKey: "amount", cell: (info) => <NumericText value={info.getValue()} /> },
    {
      header: "更多",
      accessorKey: "id",
      cell: (info) => {
        const value = info.getValue();
        const path = generatePath(PathDefinition.ACTIVITY_DETAIL.path, { id: value });
        return (
          <Box>
            <NavLink variant="tableCell" to={path}>
              查看詳細
            </NavLink>
          </Box>
        );
      },
    },
  ];
  return (
    <PageContainer>
      <PageHeader
        menuIconName="activity"
        title="全部活動列表"
        toolbar={
          <>
            <Button
              variant="customSolid"
              onClick={() => {
                disclosure.onOpen();
              }}
            >
              匯入活動
            </Button>
            <NavButton variant="customSolid" to={PathDefinition.ACTIVITY_CREATE.path}>
              建立活動
            </NavButton>
          </>
        }
      />
      <ChakraTable<ActivityData>
        variant="custom"
        data={rows}
        columns={columns}
        fullWidth
        TableOptions={{
          state: { pagination },
          pageCount,
          onPaginationChange: setPagination,
          manualPagination: true,
        }}
      />
      <ImportModal
        api={ApiDefinition.IMPORT_ACTIVITY_LIST}
        onFinish={() => {
          loadData();
        }}
        title="匯入活動"
        uploadDescription="上傳活動"
        {...disclosure}
      />
    </PageContainer>
  );
}

export default ActivityList;
