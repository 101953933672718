import {Flex, Text} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";

export interface UpdatedTimeBoxProps {
  value: dayjs.ConfigType;
}

function UpdatedTimeBox({ value }: UpdatedTimeBoxProps) {
  return (
    <Flex justifyContent="end" fontSize="18px">
      <Text className="dg-header">更新時間</Text>
      <Text>{value ? dayjs(value).format("YYYY.MM.DD HH:mm") : "-"}</Text>
    </Flex>
  );
}

export default UpdatedTimeBox;
