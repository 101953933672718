import {Radio, RadioProps} from "@chakra-ui/react";
import FRadioField, {FRadioFieldProps} from "common/form/FRadioField";
import {FastField, FieldProps} from "formik";
import React from "react";

export type FMRadioFieldProps = Omit<FRadioFieldProps, "name" | "errorMessage" | "renderItem"> & {
  name: string;
};

function FMRadioField({ name, helperText, ...props }: FMRadioFieldProps) {
  const renderItem = (itemProps: RadioProps | undefined, value: string, text: string) => (
    <FastField name={name} type="radio" value={value}>
      {({ field }: FieldProps) => {
        return (
          <Radio {...field} variant="custom" defaultChecked={field.checked}>
            {text}
          </Radio>
        );
      }}
    </FastField>
  );
  return (
    <FastField name={name}>
      {({ field, meta }: FieldProps) => {
        const error = meta.touched && meta.error ? meta.error : undefined;
        return <FRadioField {...props} defaultValue={field.value} renderItem={renderItem} errorMessage={error} />;
      }}
    </FastField>
  );
}

export default FMRadioField;
