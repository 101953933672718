import {Modal, ModalBody, ModalContent, ModalOverlay} from '@chakra-ui/react'

import style from './../css/student_detail.module.css'

import WarnCircle from './../../images/Rectangle 110.png'

let WarningDialogComponent =({disclosure, title, message, handler})=>{

  return(
    <Modal onClose={disclosure.onClose} isOpen={disclosure.isOpen} isCentered closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent className={['column'].join(' ')} style={{paddingLeft: '25px', paddingRight: '25px', paddingTop: '30px', paddingBottom: '30px', minWidth: '410px'}}>

        <ModalBody className={['col-auto', 'column', 'items-center'].join(' ')} style={{padding: '0px'}}>
          <img alt="" src={WarnCircle} style={{width: '120px', height: '120px'}}/>
          <div className={['col-auto',].join(' ')} style={{textAlign: 'center', marginTop: '30px', fontSize: '22.5px'}}>
            {title}
          </div>
          <div className={['col-auto'].join(' ')} style={{textAlign: 'center', marginTop: '8px', marginBottom: '30px', fontSize: '18px'}} dangerouslySetInnerHTML={{__html: message}}>
          </div>
          <div className={['col-auto', 'row'].join(' ')} style={{width: '100%'}}>
            <button className={['col', style['custom-button']].join(' ')} onClick={()=>{
              disclosure.onClose()
              handler(true)
            }} style={{marginRight: '8px'}} >確定</button>
            <button className={['col', style['custom-outline-button']].join(' ')} onClick={()=>{
              disclosure.onClose()
              handler(false)
            }} style={{marginLeft: '8px'}}>取消</button>
          </div>
          
        </ModalBody>

      </ModalContent>
    </Modal>
  )
}

export default WarningDialogComponent