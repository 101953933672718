import style from '../css/student_detail_panel.module.css'
import {useParams} from 'react-router-dom'

let ExamDetailPanelComponent =({memberdata})=>{

  const get_prop_impl = (object, path) => {
    if (Array.isArray(path)===false){
      return object[path]
    }
    if (path.length === 1){
      return object[path[0]];
    }else if (path.length === 0){
      //...
      return undefined
    }else {
       if (object[path[0]]) return get_prop_impl(object[path[0]], path.slice(1));
       else {
          object[path[0]] = {};
          return get_prop_impl(object[path[0]], path.slice(1));
       }
    }
  }

  const get_prop =(obj, path)=>{
    let ret =get_prop_impl(obj, path)
    if ((ret==='' || ret===null || ret===undefined || ret==='')){
      return '-'
    }
    return ret
  }

  const params =useParams()

  const layout_model=[
    {type: 'label', label: '考試名稱', dataid:[0, 'name']},
    {type: 'empty',},
    {type: 'label', label: '一般考生考試金額', dataid: [0, 'settings', 'signup', 'price']},
    {type: 'label', label: 'CFP/AFP證照考生考試金額', dataid: [0, 'settings', 'signup', 'special_price']},
    {type: 'label', label: '單科目複查金額', dataid: [0, 'settings', 'scorecheck', 'price']},
    { type: 'label', label: '學生身分考試金額', dataid: [0, 'settings', 'signup', 'student_price'] },
    { type: 'label', label: '再次報名金額', dataid: [0, 'settings', 'signup', 'reexam_price'] },
    { type: 'label_range', label: '報名日期', dataid1: [0, 'settings', 'signup', 'startAt'], dataid2: [0, 'settings', 'signup', 'endAt'] },
    { type: 'label', label: '准考證開放日期', dataid: [0, 'settings', 'ticket_release'] },
    { type: 'label', label: '考試日期', dataid: [0, 'settings', 'test_date'] },
    { type: 'label', label: '應考證明開放日期', dataid: [0, 'settings', 'admission_release'] },
    {type: 'label', label: '成績放榜日期', dataid: [0, 'settings', 'score_publish']},
    {type: 'label', label: '複查放榜日期', dataid: [0, 'settings', 'scorecheck_result']},
    {type: 'label_range', label: '複查申請日期', dataid1: [0, 'settings', 'scorecheck', 'startAt'], dataid2: [0, 'settings', 'scorecheck', 'endAt']},
    {type: 'label_range', label: '認證申請開放日期', dataid1: [0, 'settings', 'newcert', 'startAt'], dataid2: [0, 'settings', 'newcert', 'endAt']},
    {type: 'label', label: '認證申請金額', dataid: [0, 'settings', 'newcert', 'price']},
    {type: 'empty',},

  ]
  return (
    <div className={['colume', style['container']].join(' ')} style={{minWidth: '915px', marginTop: '18px', marginBottom: '26px', paddingTop: '30px', paddingLeft: '60px', paddingBottom: '40px', fontSize: '18px'}}>
      {
        layout_model.map((v,idx)=>{
          if (v.type==='label'){
            return(
              <div key={idx} className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
                <div className={['col-auto'].join(' ')} style={{width: '230px', textAlign: 'right', color: '#616161'}}>
                  {v.label}
                </div>
                <div className={['col'].join(' ')} style={{color: 'black', marginLeft: '32px'}}>
                  {get_prop(memberdata, v.dataid)}
                </div>
              </div>
            )
          }else
          if (v.type==='label_range'){
            return(
              <div key={idx} className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
                <div className={['col-auto'].join(' ')} style={{width: '230px', textAlign: 'right', color: '#616161'}}>
                  {v.label}
                </div>
                <div className={['col'].join(' ')} style={{color: 'black', marginLeft: '32px'}}>
                  {get_prop(memberdata, v.dataid1)+' - '+get_prop(memberdata, v.dataid2)}
                </div>
              </div>
            )
          }else
          if (v.type==='empty'){
            return(
              <div key={idx} className={['col-auto', 'row'].join(' ')}>&nbsp;</div>
            )
          }else
          if (v.type==='preview_img'){
            return(
              <div key={idx} className={['col-auto', 'row', 'justify-center', 'items-center'].join(' ')} style={{
                maxWidth: '866px',
                height: '350px',
                borderRadius: '8px',
                backgroundColor: '#f5f5f5',
                marginBottom: '20px',
                border: '1px solid #e0e0e0',
                backgroundImage: 'url('+(process.env.REACT_APP_IMAGE_BED+get_prop(memberdata, v.dataid))+')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }}>
                <div className={['col-auto'].join(' ')}>{v.label}</div>
              </div>
            )
          }else
          if (v.type==='preview_label'){
            return(
              <div key={idx} className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
                <div className={['col-auto'].join(' ')} style={{textAlign: 'right', color: '#616161'}}>
                  {v.label}
                </div>
                <div className={['col'].join(' ')} style={{color: 'black', marginLeft: '32px'}}>
                  {get_prop(memberdata, v.dataid)}
                </div>
              </div>
            )
          }else
          if (v.type==='preview_label_range'){
            return(
              <div key={idx} className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
                <div className={['col-auto'].join(' ')} style={{textAlign: 'right', color: '#616161'}}>
                  {v.label}
                </div>
                <div className={['col'].join(' ')} style={{color: 'black', marginLeft: '32px'}}>
                  {get_prop(memberdata, v.dataid1)+' - '+get_prop(memberdata, v.dataid2)}
                </div>
              </div>
            )
          }

        })
      }

      <div className={['col-auto', 'row', 'no-wrap', 'justify-end'].join(' ')} style={{marginTop: '64px', marginRight: '64px'}}>
        <div className={['col-auto'].join(' ')} style={{marginRight: '24px', color: '#616161'}}>更新時間</div>
        <div className={['col-auto'].join(' ')}>{memberdata[0]['updatedAt']===null?'-':new Date(memberdata[0]['updatedAt']).toLocaleString('zh-TW')}</div>
      </div>

    </div>
  )
}

export default ExamDetailPanelComponent
