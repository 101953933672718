import {FTextField} from "common/form";
import {FTextFieldProps} from "common/form/FTextField";
import {FastField, FieldProps} from "formik";
import React from "react";

export type FMTextFieldProps = Omit<FTextFieldProps, "name" | "errorMessage"> & {
  name: string;
};

function FMTextField({ name, type, ...props }: FMTextFieldProps) {
  return (
    <FastField name={name} type={type}>
      {({ field, form, meta }: FieldProps) => {
        const error = meta.touched && meta.error ? meta.error : undefined;
        return <FTextField {...props} type={type} errorMessage={error} {...field} />;
      }}
    </FastField>
  );
}

export default FMTextField;
