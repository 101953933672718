import {Button, ButtonProps} from "@chakra-ui/react";
import React from "react";
import {To, useNavigate} from "react-router-dom";

export type NavButtonProps = Omit<ButtonProps, "onClick"> & {
  to: To;
};

function NavButton({ to, ...props }: NavButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate(to);
      }}
      {...props}
    />
  );
}

export default NavButton;
