import {useFormikContext} from "formik";
import React from "react";

const develop = process.env.REACT_APP_ENV === "development";

function DebugTool() {
  const { values } = useFormikContext();
  if (!develop) {
    return <></>;
  }
  return <p>{JSON.stringify(values)}</p>;
}

export default DebugTool;
