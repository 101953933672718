import {Grid} from "@chakra-ui/react";
import CommonModal, {CommonModalProps} from "common/CommonModal";
import DtlGridItem from "common/DtlGridItem";
import React, {useEffect, useMemo} from "react";
import {SubscriptionRow, TransactionDetails} from "./type";
import {useFetchData} from "hooks";
import ApiDefinition from "api/ApiDefinition";
import NumericText from "common/NumericText";

export type DemoTableDetailModalProps = Omit<CommonModalProps, "isOpen"> & {
  data: SubscriptionRow | null;
};

function TransactionDetailModal({ data, ...props }: DemoTableDetailModalProps) {
  const transactionDataLoader = useFetchData<TransactionDetails>({
    url: `${ApiDefinition.GET_PAYMENT_DETAIL.path}${data?.transactionId || "null"}`,
  });
  const hasTransactionId = data?.transactionId && data.transactionId !== "tryout";
  useEffect(() => {
    if (hasTransactionId) {
      transactionDataLoader.fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTransactionId]);
  const transactionData = useMemo(() => {
    return hasTransactionId ? transactionDataLoader.data : null;
  }, [hasTransactionId, transactionDataLoader.data]);
  return (
    <CommonModal isOpen={!!data} size="xl" {...props}>
      {/* templateColumns="<標題欄位寬度 可調整> <值欄位寬度 固定1fr>" */}
      {data && (
        <Grid fontSize={18} templateColumns="150px 1fr" gap="2.5" w="100%">
          <DtlGridItem title="姓名">{data.chName}</DtlGridItem>
          <DtlGridItem title="付款狀態">{transactionData ? transactionData.statusText : "未付款"}</DtlGridItem>
          {transactionData && (
            <>
              <DtlGridItem title="訂閱方案">{transactionData.title}</DtlGridItem>
              <DtlGridItem title="金額">
                <NumericText value={transactionData.amount} />
              </DtlGridItem>
              <DtlGridItem title="廠商訂單編號">{transactionData.transactionId}</DtlGridItem>
            </>
          )}
        </Grid>
      )}
    </CommonModal>
  );
}

export default TransactionDetailModal;
