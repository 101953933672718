import {Input, InputGroup, InputProps, InputRightElement} from "@chakra-ui/react";
import {ChangeEvent, ReactNode, useEffect, useState} from "react";

export type BaseFormatInputProps = Omit<InputProps, "onBlur"> & {
  icon: ReactNode;
  regExp: RegExp;
};

function BaseFormatInput({ icon, regExp, onChange, value, ...otherProps }: BaseFormatInputProps) {
  const [mValue, setMValue] = useState<any>(value);
  const [event, setEvent] = useState<any>(null);
  const handleChange = (event: ChangeEvent<any>) => {
    setMValue(event.currentTarget.value);
    setEvent(event);
  };
  const handleBlur = () => {
    setMValue(value);
  };
  useEffect(() => {
    if (regExp.test(mValue) && mValue !== value) {
      onChange && onChange(event);
    }
  }, [regExp, mValue, event, value, onChange]);
  return (
    <InputGroup>
      <Input
        {...otherProps}
        variant="custom"
        value={mValue}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={10}
      />
      <InputRightElement color="secondary.600">{icon}</InputRightElement>
    </InputGroup>
  );
}

export default BaseFormatInput;
