import ApiDefinition from "api/ApiDefinition";
import {useFetchData} from "hooks";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import PathDefinition from "routes/RoutePathDefinition";
import {ActivityData} from "./../type";

function useFetchActivity() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { fetchData, loaded, data } = useFetchData<ActivityData>({
    url: `${ApiDefinition.GET_ACTIVITY_DETAIL.path}${id}`,
    onError: () => {
      navigate(PathDefinition.ACTIVITY.path);
    },
  });
  useEffect(() => {
    if (!Number.isNaN(Number(id))) {
      fetchData();
    } else {
      navigate(PathDefinition.ACTIVITY.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { id, fetchData, loaded, data };
}

export default useFetchActivity;
