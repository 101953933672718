import { Spinner } from "@chakra-ui/react";
import { useState, useEffect } from "react";

import { useAppDispatch } from "hooks";
import { ActionDefinition } from "store";

import style from "./css/login.module.css";

import { useNavigate } from "react-router-dom";
import RoutePath from "../routes/RoutePathDefinition";
import cookies from "../store/Cookies";

import api from "./../api/Api";

let Login = () => {
  const dispatch = useAppDispatch();
  let [accErr, setAccErr] = useState(false);
  let [pwdErr, setPwdErr] = useState(false);
  let [loginProcess, setLoginProcess] = useState(false);

  let [acc, setAcc] = useState("");
  let [pwd, setPwd] = useState("");

  let naviage = useNavigate();

  let handleSubmit = (e) => {
    //console.log('7 - handle form submit...')
    e.preventDefault();

    if (loginProcess === true) {
      return;
    }

    setAccErr(false);
    setPwdErr(false);

    let err = false;
    if (acc.length === 0) {
      setAccErr(true);
      err = true;
    }
    if (pwd.length === 0) {
      setPwdErr(true);
      err = true;
    }

    if (err) {
      return;
    }

    setLoginProcess(true);

    api
      .login(acc, pwd)
      .then((data) => {

        console.log(data);
        //...
        let acc_data = {
          token: "[TOKEN]",
          name: data.name,
          id: data.id,

          user: data.username,
        };
        dispatch({ type: ActionDefinition.LOGIN, value: acc_data });

        setLoginProcess(false);
        naviage(RoutePath.ROOT.path);
      })
      .catch((e) => {
        setLoginProcess(false);
        setAccErr(true);
        setPwdErr(true);
      });
  };

  const anotherLogin = (token) =>{
    api
      .anotherLogin(token)
      .then((data) => {

        console.log(data);
        //...
        let acc_data = {
          token: "[TOKEN]",
          name: data.name,
          id: data.id,

          user: data.username,
        };
        dispatch({ type: ActionDefinition.LOGIN, value: acc_data });

        setLoginProcess(false);
        naviage(RoutePath.ROOT.path);

      })
      .catch((e) => {
        setLoginProcess(false);
        setAccErr(true);
        setPwdErr(true);
      });
  }

  useEffect(() => {
    const currentUrl = window.location.href;
    // 獲取 %20 後面的文字
    const urlParts = currentUrl.split('%20');
    if (urlParts.length > 1) {
      const decodedText = decodeURIComponent(urlParts[1]);
      console.log(decodedText);
      anotherLogin(decodedText);
    } else {
      console.log('URL 中沒有 %20');
    }
  }, [])


  return (
    <>
      <div
        className={["row", "items-center", "justify-center"].join(" ")}
        style={{ width: "100%", height: "100%", backgroundColor: "#f0f3f7" }}
      >
        <form className={["column", "col-auto", style["form-container"]].join(" ")} onSubmit={handleSubmit}>
          <div className={["col-auto", style["form-title"]].join(" ")}>RFA退休理財規劃顧問 後台登入</div>
          <div className={["col-auto", "column"].join(" ")} style={{ marginTop: "20px" }}>
            <label className={["col-auto", style["controller-label"]].join(" ")}>帳號</label>
            <input
              className={["col-auto", style["input-box"], style[accErr ? "error" : ""]].join(" ")}
              onChange={(e) => {
                setAcc(e.target.value);
              }}
              value={acc}
              type={"text"}
              placeholder={"請輸入帳號"}
            ></input>
            {accErr ? <label className={["col-auto", style["caveat-label"]].join(" ")}>帳號不存在</label> : ""}
          </div>
          <div className={["col-auto", "column"].join(" ")} style={{ marginTop: "20px" }}>
            <label className={["col-auto", style["controller-label"]].join(" ")}>密碼</label>
            <input
              className={["col-auto", style["input-box"], style[pwdErr ? "error" : ""]].join(" ")}
              onChange={(e) => {
                setPwd(e.target.value);
              }}
              value={pwd}
              type={"password"}
              placeholder={"請輸入密碼"}
            ></input>
            {pwdErr ? <label className={["col-auto", style["caveat-label"]].join(" ")}>密碼錯誤</label> : ""}
          </div>

          <button
            className={[
              "col-auto",
              "row",
              "items-center",
              "justify-center",
              style["login-btn"],
              style[loginProcess ? "busy" : ""],
            ].join(" ")}
          >
            {loginProcess ? (
              <Spinner className={"col-auto"} style={{ marginRight: "10px", width: "var(--spinner-size)" }} />
            ) : (
              ""
            )}
            <label className={"col-auto"} style={{ pointerEvents: "none" }}>
              登入
            </label>
          </button>
        </form>
        {/* <button onClick={anotherLogin}>
          <label className={"col-auto"} style={{ pointerEvents: "none" }}>
            登入
          </label>
        </button> */}
      </div>
    </>
  );
};

export default Login;
