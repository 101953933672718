import {CalendarContainer, CalendarContainerProps} from "react-datepicker";
import {Card} from "@chakra-ui/react";
import clsx from "clsx";

function CustomCalendarContainer({ className, children }: CalendarContainerProps) {
  return (
    <Card variant="custom" rounded="md" overflow="hidden">
      <CalendarContainer className={clsx(className, "no-border")}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    </Card>
  );
}

export default CustomCalendarContainer;
