import {ActivityFormValue} from "./type";

export const enableMapping = {
  "1": "上架",
  "0": "下架",
};

export const signUpTypeMapping = {
  "1": "個人報名",
  "2": "團體報名",
};

export const sourceTypeMapping = {
  "1": "手動",
  "2": "匯入",
};

export const transactionStatusMapping = {
  "0": "-",
  "1": "已付款",
  "2": "待付款",
  "10100073": "待繳費",
  "10100058": "付款失敗",
  "10100248": "拒絕交易",
  "10100249": "交易失敗",
  "10100251": "卡片過期",
  "10100282": "3D授權未完成",
};

export const initialValues: ActivityFormValue = {
  name: "活動名稱",
  orgName: "開課單位",
  receiptName: "活動參加費",
  activityDate: "",
  startTime: "",
  endTime: "",
  headCountLimit: 100,
  startDate: "",
  endDate: "",
  place: "活動地點",
  amount: 100,
  mins: 180,
  activityContent: "",
  memo: "",
  enabled: "1",
};


export const initalEmailValues = {
  title: "主旨",
  content: "",
}