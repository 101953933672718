import style from './css/student_detail.module.css'

import people_dark_svg from '../svg-files/people-dark.svg'
import breadcrumb_arrow_svg from '../svg-files/breadcrumb-arrow.svg'

import {
    Menu,
    MenuButton,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useDisclosure,
    useMultiStyleConfig,
    useTab
} from '@chakra-ui/react'
import {forwardRef, useEffect, useState,} from 'react'

import PathDefinition from '../routes/RoutePathDefinition'
import {generatePath, matchRoutes, useLocation, useNavigate, useParams} from 'react-router-dom'

import StudentDetailPanelComponent from './components/StudentDetailPanelComponent'
import StudentDetailEditPanelComponent from './components/StudentDetailEditPanelComponent'
import StudentTestPanelComponent from './components/StudentTestPanelComponent'
import StudentTestEditPanelComponent from './components/StudentTestEditPanelComponent'
import StudentActivityPanelComponent from './components/StudentActivityPanelComponent'
import StudentOnlinePanelComponent from './components/StudentOnlinePanelComponent'
import StudentCertifiedPanelComponent from './components/StudentCertifiedPanelComponent'
import Api from '../api/Api'
import WarningDialogComponent from './components/WarningDialogComponent'

let StudentDetail =()=>{

  const disclosure =useDisclosure()

  const location =useLocation()
  const params =useParams()
  const [currTabIdx, setCurrTabIdx] =useState(0)
  const pathIdxMap=[
    PathDefinition.STUDENT_DETAIL.path,
    PathDefinition.STUDENT_EXAM.path,
    PathDefinition.STUDENT_ONLINE.path,
    PathDefinition.STUDENT_ACTIVITY.path,
    PathDefinition.STUDENT_CERT.path,
  ]
  const editablePathIdxMap=[
    PathDefinition.STUDENT_DETAIL_EDIT.path,
    PathDefinition.STUDENT_EXAM_EDIT.path,
  ]
  const pagingDetailedPathIdxMap=[
    PathDefinition.STUDENT_ONLINE_PAGE.path,
    PathDefinition.STUDENT_ACTIVITY_PAGE.path,
    PathDefinition.STUDENT_CERT_PAGE.path,
  ]
  const [currSelectedTestStage, setCurrSelectedTestStage] =useState(0)
  const [editMode, setEditmode] =useState(false)
  const navigate =useNavigate()

  const tab_name=[
    '學員資料',
    '考試報名資料',
    '數位持續教育',
    '實體持續教育',
    '認證管理',
  ]
  /* const test_stages=[
    {year: '110', stage:'1'},
    {year: '110', stage:'2'},
    {year: '111', stage:'1'},
    {year: '111', stage:'2'},
    {year: '111', stage:'3'},
  ] */

  useEffect(()=>{
    const find_match =matchRoutes(pathIdxMap.map(v=>{return {path: v}}), location)
    if (find_match!==null && find_match.length>0){
      console.warn('39 - update tab state...')
      const tab_idx =pathIdxMap.indexOf(find_match[0].route.path)
      setCurrTabIdx(tab_idx)
      setEditmode(false)
      if (tab_idx===1){
        setCurrSelectedTestStage(find_match[0].params.examid)
      }
      console.warn('58 - set tabidx to '+tab_idx+', set editmode to false')
      return
    }

    const find_match2 =matchRoutes(editablePathIdxMap.map(v=>{return {path: v}}), location)
    if (find_match2!==null && find_match2.length>0){
      console.warn('39 - update edit mode tab state...')
      const tab_idx =editablePathIdxMap.indexOf(find_match2[0].route.path)
      setCurrTabIdx(tab_idx)
      setEditmode(true)
      if (tab_idx===1){
        setCurrSelectedTestStage(find_match2[0].params.examid)
      }
      console.warn('71 - set tabidx to '+tab_idx+', set editmode to true')
      return
    }

    const find_match3 =matchRoutes(pagingDetailedPathIdxMap.map(v=>{return {path: v}}), location)
    if (find_match3!==null && find_match3.length>0){
      console.warn('83 - update paging mode tab state...')
      const tab_idx =pagingDetailedPathIdxMap.indexOf(find_match3[0].route.path)
      setCurrTabIdx(tab_idx+2)
      console.warn('86 - set tabidx to '+(tab_idx+2)+', set editmode to true')
      return
    }

    //unknown tab mode
    //...

  },[location.pathname])

  const CustomTab =forwardRef((props, ref)=>{
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref })
    const isSelected = !!tabProps['aria-selected']

    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps)

    return (
      <button __css={styles.tab} {...tabProps} className={[style['custom-tab'], style[isSelected?'selected':'']].join(' ')}>
        {tabProps.children}
      </button>
    )
  })
  const CustomDropdownTab =forwardRef((props, ref)=>{
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref })
    const isSelected = !!tabProps['aria-selected']

    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps)

    return (
      <Menu matchWidth>
        <MenuButton __css={styles.tab} {...tabProps}>
          <div className={['row', 'justify-center', 'items-center',].join(' ')}>
            <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>
            {/* 民國{props.year}年第{props.stage}梯次 */}
            最近一次考試資料</label>
            {/* <img className={['col-auto'].join(' ')} src={dropdown_arrow} style={{pointerEvents: 'none', width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/> */}
          </div>
        </MenuButton>
        {/* <MenuList style={{minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px',}} className={['column'].join(' ')}>
          {
            test_stages.map((v,idx)=>{
              return(
                <button key={idx} className={['col-auto', style['custom-menu-item']].join(' ')} onClick={()=>{
                  window.history.pushState(null, null, '#'+generatePath(pathIdxMap[1], {id: params.id, examid: idx}))
                  setCurrSelectedTestStage(idx)
                  setEditmode(false)
                }}>民國{v.year}年第{v.stage}梯次考試資料</button>
              )    
            })
          }
        </MenuList> */}
      </Menu>
    )
  })
  return (
    <>
      <div className={['column', style['page-container']].join(' ')} >
        <div className={['col-auto', 'row', 'no-wrap', 'justify-between', 'items-center', ].join(' ')}>
          <div className={['col-auto', 'row', 'no-wrap', 'items-center', ].join(' ')}>
            <img alt="" className={'col-auto'} src={people_dark_svg} style={{width: '27px', height: '27px'}}/>
            <div className={'col-auto'} style={{fontSize: '28.13px', marginLeft: '20.5px'}}>
              全部學員資料
            </div>
            <img alt="" src={breadcrumb_arrow_svg} style={{marginLeft: '26px', marginRight: '25px'}}/>
            {/* <div className={'col-auto'} style={{fontSize: '22.5px', color: '#959ca4'}}>
              {currTabIdx===1?'民國'+test_stages[currSelectedTestStage]?.year+'年第'+test_stages[currSelectedTestStage]?.stage+'梯次考試資料':tab_name[currTabIdx]}
            </div> */}
            {editMode&&(
              <>
                <img alt="" src={breadcrumb_arrow_svg} style={{marginLeft: '26px', marginRight: '25px'}}/>
                <div className={'col-auto'} style={{fontSize: '22.5px', color: '#959ca4'}}>
                  編輯
                </div>
              </>
            )}


          </div>
          {currTabIdx<1&&(
            <div className={['col-auto', 'row', 'no-wrap', 'items-center'].join(' ')}>
              
              <button className={['col-auto', style['custom-button']].join(' ')} onClick={()=>{
                console.warn('142 - toggle edit mode from '+editMode+' ---> '+!editMode)
                setEditmode(!editMode)
                editMode?
                navigate(generatePath(pathIdxMap[currTabIdx], {id: params.id, examid: currSelectedTestStage})):
                navigate(generatePath(editablePathIdxMap[currTabIdx], {id: params.id, examid: currSelectedTestStage}))

              }}>{editMode?'取消編輯':'編輯'}</button>
              <button className={['col-auto', style['custom-outline-button']].join(' ')} onClick={()=>{
                disclosure.onOpen()
              }} style={{marginLeft: '16px'}}>刪除</button>
            </div>
          )}
        </div>

        <Tabs index={currTabIdx} className={['col-auto'].join(' ')} style={{marginTop: '25px'}} onChange={v=>{
          console.warn('45 - tab idx changed to '+v)
          setCurrTabIdx(v)
          setEditmode(false)
          let testid ={}
          if (v===1){
            testid ={
              examid: currSelectedTestStage
            }
          }
          window.history.pushState(null, null, '#'+generatePath(pathIdxMap[v], {id: params.id, ...testid}))
        }}>
          <TabList style={{borderColor: '#b7cae0'}}>
            <CustomTab>{tab_name[0]}</CustomTab>
            {/* <CustomDropdownTab className={[style['custom-tab'], style[currTabIdx===1?'selected':'']].join(' ')} year={test_stages[currSelectedTestStage]?.year} stage={test_stages[currSelectedTestStage]?.stage}/> */}
            <CustomTab>{tab_name[1]}</CustomTab>
            <CustomTab>{tab_name[2]}</CustomTab>
            <CustomTab>{tab_name[3]}</CustomTab>
            <CustomTab>{tab_name[4]}</CustomTab>
          </TabList>

          <TabPanels>
            <TabPanel style={{padding: '0px'}}>
              {editMode?(
                <StudentDetailEditPanelComponent 
                  cancel={()=>{navigate(generatePath(pathIdxMap[currTabIdx], {id: params.id, examid: currSelectedTestStage}))}} 
                  save={(data, completed_callback)=>{

                  // //strip null/undefined data
                  // let keys =Object.keys(data)
                  // for(let i=0;i<keys.length;++i){
                  //   if (data[keys[i]]===null || data[keys[i]]===undefined){
                  //     delete data[keys[i]]
                  //   }
                  // }

                  //console.log(data)
                  Api.update_profile_detail(data).then((res)=>{
                    //console.log(res)
                    completed_callback(true)
                  }).catch((e)=>{
                    completed_callback(false, e)
                  }).finally(()=>{
                    navigate(generatePath(pathIdxMap[currTabIdx], {id: params.id, examid: currSelectedTestStage}))
                  })

                }}/>
              ):(
                <StudentDetailPanelComponent />
              )}
            </TabPanel>
            <TabPanel style={{padding: '0px'}}>
              {editMode?(
                <StudentTestEditPanelComponent />
              ):(
                <StudentTestPanelComponent />
              )}
            </TabPanel>
            <TabPanel style={{ padding: '0px' }}>
              <StudentOnlinePanelComponent />
            </TabPanel>
            <TabPanel style={{padding: '0px'}}>
              <StudentActivityPanelComponent />
            </TabPanel>
            <TabPanel style={{padding: '0px'}}>
              <StudentCertifiedPanelComponent />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <WarningDialogComponent title={''} 
          message={'確定要刪除這筆資料?'} 
          disclosure={disclosure} 
          handler={(cb)=>{
            console.warn(cb)
          }}/>
      </div>
    </>
  )
}

export default StudentDetail