const colors = {
  primary: {
    50: "#E7EDF5",
    100: "#B7CAE0",
    200: "#87A7CB",
    300: "#5683B6",
    400: "#2660A1",
    500: "#0E4E96",
    600: "#0D4687",
    700: "#0A3769",
    800: "#07274B",
    900: "#04172D",
  },
  secondary: {
    50: "#FAFAFA",
    100: "#F5F5F5",
    200: "#EEEEEE",
    300: "#E0E0E0",
    400: "#BDBDBD",
    500: "#9E9E9E",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
  blueGrey: {
    50: "#EAEBED",
    100: "#BFC4C8",
    200: "#959CA4",
    300: "#6A7480",
    400: "#3F4D5B",
    500: "#2A3949",
    600: "#263342",
    700: "#1D2833",
    800: "#151D25",
    900: "#0D1116",
    950: "#040607",
  },
};

export default colors;
