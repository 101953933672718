import {Radio, RadioGroup, VStack} from "@chakra-ui/react";
import ImportModal, {ImportModalProps} from "common/ImportModal";
import React, {useState} from "react";
import {signUpTypeMapping} from "../commonValues";

export type SignUpImportModalProps = Omit<ImportModalProps, "additionView">;

function SignUpImportModal({ params, ...props }: SignUpImportModalProps) {
  const [signUpTypeValue, setSignUpTypeValue] = useState("1");
  return (
    <ImportModal
      {...props}
      params={{ ...params, signupType: signUpTypeValue }}
      additionView={
        <RadioGroup value={signUpTypeValue} onChange={setSignUpTypeValue} w="100%">
          <VStack spacing={3} align="start">
            {Object.keys(signUpTypeMapping).map((value, index) => {
              const text = signUpTypeMapping[value as keyof typeof signUpTypeMapping];
              let desc = "";
              if(text==="個人報名"){
                desc = "(系統開立收據)";
              }
              if(text==="團體報名"){
                desc = "(系統不開收據，由人工開立)";
              }
              return (
                <Radio key={index} variant="custom" value={value}>
                  {text} {desc}
                </Radio>
              );
            })}
          </VStack>
        </RadioGroup>
      }
    />
  );
}

export default SignUpImportModal;
