import {FTimePicker} from "common/form";
import {FTimePickerProps} from "common/form/FTimePicker";
import dayjs from "dayjs";
import {FastField, FieldProps, useFormikContext} from "formik";
import React from "react";

export type FMTimePickerProps = Omit<FTimePickerProps, "name" | "errorMessage" | "onChange"> & {
  name: string;
  outputFormat?: string;
};

export const parseDateForPicker = (value: any) => {
  const dayjsInput = value ? dayjs(value) : null;
  return dayjsInput && dayjsInput.isValid() ? dayjsInput.toDate() : null;
};

function FMTimePicker({
  name,
  outputFormat = "YYYY-MM-DD HH:mm",
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  ...props
}: FMTimePickerProps) {
  const { setFieldValue } = useFormikContext();
  const handleChange = (date: Date) => {
    setFieldValue(name, date);
  };
  return (
    <FastField name={name}>
      {({ field, form, meta }: FieldProps) => {
        const error = meta.touched && meta.error ? meta.error : undefined;
        return (
          <FTimePicker {...props} errorMessage={error} {...field} onChange={handleChange} selected={field.value} />
        );
      }}
    </FastField>
  );
}

export default FMTimePicker;
