import baseURL from "api/baseURL";
import axios from "axios";
import {useCallback, useState} from "react";
import useLoading from "./useLoading";

export interface FetchListConfig {
  url: string;
}

export interface DataList<RowData> {
  total: number;
  perPage: number;
  pageIndex: number;
  rows: RowData[];
}

function useFetchList<RowData>({ url }: FetchListConfig) {
  const { showLoading } = useLoading();
  const [rows, setRows] = useState<RowData[]>([]);
  const [total, setTotal] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const fetchData = useCallback(
    (params?: any) => {
      showLoading();
      axios
        .get(url, { params, withCredentials: true, baseURL, headers: { "Content-Type": "application/json" } })
        .then((resp) => {
          const data = resp.data;
          if (data) {
            setRows(data.rows);
            setTotal(data.total);
            setLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          showLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  return { fetchData, rows, total, loaded };
}

export default useFetchList;
