import {Box, Flex, HStack, Text} from "@chakra-ui/react";
import ValueCard, {ValueCardProps} from "common/ValueCard";
import React, {ReactNode} from "react";

export type DefaultUnitData = Record<string, number | string>;

export type DashBoardUnitColumn<UnitData = DefaultUnitData> = Omit<ValueCardProps, "value"> & {
  accessKey: keyof UnitData;
};

export interface DashBoardUnitProps<UnitData = DefaultUnitData> {
  title: string;
  data: UnitData;
  columns: DashBoardUnitColumn<UnitData>[];
  toolbar?: ReactNode;
}

function DashBoardUnit<UnitData = DefaultUnitData>({ title, data, columns, toolbar }: DashBoardUnitProps<UnitData>) {
  return (
    <Box mb={5}>
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        <HStack spacing={5} alignItems="center">
          <Box>
            <Text fontSize={22.5} fontWeight="bold">{title}</Text>
          </Box>
        </HStack>
        <HStack spacing={4} alignItems="center">
          {toolbar}
        </HStack>
      </Flex>
      <HStack spacing={4}>
        {columns.map((column, index) => {
          const { accessKey, ...other } = column;
          const value = data[accessKey];
          return <ValueCard key={index} value={value as any} {...other} />;
        })}
      </HStack>
    </Box>
  );
}

export default DashBoardUnit;
