import {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

import {useAccount, useAppDispatch} from "hooks";
import {ActionDefinition} from "store";

import RoutePath from "../routes/RoutePathDefinition";
import RootFrame from "./components/RootFrame";
import api from "../api/Api";

let Root = () => {
  const [cookiesResuming, setCookiesResuming] = useState(true);
  //const [userLoaded, setUserLoaded] = useState(false);
  const account = useAccount();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    //console.log('15 - resume account info...')
    api
      .login_status()
      .then((res) => {
        dispatch({
          type: ActionDefinition.RESUME,
          value: {
            token: "[TOKEN]",
            name: res.name,
            id: res.id,
          },
        });
      })
      .catch((e) => {
        //fallback to login page ??
        //...
      })
      .finally(() => {
        setCookiesResuming(false);
        //setUserLoaded(true);
      });
  }, []);

  //a simple route guard
  useEffect(() => {
    if (cookiesResuming) {
      return;
    }
    window.scrollTo(0, 0);
    //console.log('17 - check auth for path ('+location.pathname+')...')
    if (location.pathname !== RoutePath.LOGIN.path && account?.token === "") {
      //console.log("45 - redirect from " + location.pathname + " ---> " + RoutePath.LOGIN.path);
      navigate(RoutePath.LOGIN.path, { replace: true });
    }
    if (account?.token !== "") {
      if (location.pathname === RoutePath.LOGIN.path || location.pathname === RoutePath.ROOT.path) {
        //console.log('51 - redirect from '+location.pathname+' ---> '+RoutePath.DASHBOARD.path)
        navigate(RoutePath.DASHBOARD.path, { replace: true });
      }
    }
  }, [cookiesResuming, location]);

  return (
    <>
      {!cookiesResuming && (
        <RootFrame>
          <Outlet />
        </RootFrame>
      )}
    </>
  );
};

export default Root;
