import style from './css/student.module.css'

import people_dark_svg from '../svg-files/people-dark.svg'
import next_page_arrow from '../svg-files/next-page-arrow.svg'
import modal_cross from '../svg-files/modal-cross.svg'
import document_normal from '../svg-files/document-normal.svg'
import document_grey from '../svg-files/document-grey.svg'
import green_check from '../svg-files/green-check.svg'
import red_cross from '../svg-files/red-cross.svg'

import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure, useToast} from '@chakra-ui/react'
import {useEffect, useState} from 'react'
import {generatePath, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom"
import RoutePath from '../routes/RoutePathDefinition'
import PathDefinition from '../routes/RoutePathDefinition'
import {stringify} from 'query-string'

import api from '../api/Api'
import LoadingComponent from './components/LoadingComponent'

const table_head=[
  '測驗名稱', '考試金額', '考試日期', '開放報名日期', '結束報名日期', '複查日期', '複查金額', '更多',
]

const page_rows =20
const max_pagination_btn_count =10

let Exam =({props})=>{

  const [totaldatacount, setTotalDataCount] =useState(0)
  const [currpageno, setCurrpageno] =useState(0)
  const [currpaginationno, setCurrpaginationno] =useState(0)
  const memberModalDisclosure =useDisclosure()
  const scoreModalDisclosure =useDisclosure()
  const toast = useToast()
  const navigate =useNavigate()
  const params =useParams()
  const query =useSearchParams()
  const location =useLocation()

  const [tabledata, settabledata] =useState([])
  const [tablebusy, setTablebusy] =useState(true)

  let update_table =(currpageno,)=>{
    return new Promise((resolve, reject)=>{
      setTablebusy(true)
      api.get_exam_list(page_rows, currpageno).then((res)=>{
        //console.log(res)
        let tmp_tabledata =[];

        let col_name_mapper=[
          'id', 'enabled', 'name', 'signup_price', 'test_date', 'startAt', 'endAt', 
          'scorecheck_date', 'scorecheck_price', 
        ]
        for(let i=0; i<res.rows.length; ++i){
          tmp_tabledata.push(
            col_name_mapper.map(v=>{
              return res.rows[i][v]===null?'-':res.rows[i][v]
            })
          ) 
        }
  
        settabledata(tmp_tabledata)
        setTotalDataCount(res.total)
  
      }).catch((e)=>{
        //...
      }).finally(()=>{
        setTablebusy(false)
        resolve()

      })
    })

  }

  useEffect(()=>{
    //pageno
    let tmp_pageno =1
    //console.log('44 - params.pageno : '+params?.pageno)
    if (params?.pageno !==undefined && params?.pageno !==''){
      tmp_pageno =params?.pageno
    }
    setCurrpaginationno(parseInt(parseInt(tmp_pageno-1)/max_pagination_btn_count));
    setCurrpageno(parseInt(tmp_pageno-1));
    update_table(tmp_pageno-1, );
    console.log(`44 - params.pageno: ${params?.pageno},currpageno: ${currpageno},tmp_pageno: ${tmp_pageno}`)

  },[location.pathname, location.search])
  
  let start_idx =currpageno*page_rows
  let end_idx =Math.min(start_idx+page_rows, totaldatacount)
  let page_count =Math.ceil(totaldatacount/page_rows)

  let pagination_start_idx =currpaginationno*max_pagination_btn_count
  let pagination_end_idx =Math.min(pagination_start_idx+max_pagination_btn_count, page_count)
  let pagination_count =Math.ceil(page_count/max_pagination_btn_count)

  return (
    <>
      <div className={['column', style['page-container']].join(' ')} >
        <div className={['col-auto', 'row', 'no-wrap', 'justify-between', 'items-center', ].join(' ')}>
          <div className={['col-auto', 'row', 'no-wrap', 'items-center', ].join(' ')}>
            <img alt="" className={'col-auto'} src={people_dark_svg} style={{width: '27px', height: '27px'}}/>
            <div className={'col-auto'} style={{fontSize: '28.13px', marginLeft: '20.5px'}}>
              考試列表
            </div>
          </div>
        </div>

        <div className={['col-auto', 'row', 'no-wrap', 'justify-end', 'items-center', ].join(' ')} style={{marginTop: '49px'}}>

          <div className={['col-auto', 'row', 'no-wrap', 'items-center'].join(' ')}>
            <button className={['col-auto', style['custom-button']].join(' ')} onClick={()=>{
              navigate(PathDefinition.EXAM_CREATE.path)
            }}>建立考試</button>

          </div>
        </div>

        <div className={['col-auto',]} style={{marginTop: '18px',  }}>
          <table className={style['custom-table']} style={{width: '100%', fontSize: '18px', fontWeight: '400',}}>
            <thead>
            <tr style={{height: '50px', color: 'white', }}>
              {table_head.map((v,idx)=>{
                return (
                  <th style={{textAlign: 'left', paddingLeft: idx===0?'10px':'0', }} key={idx}>{v}</th>
                )
              })}
            </tr>
            </thead>
            <tbody>
            {tabledata.length>0&&(tabledata.map((v,idx)=>{
              console.log(v, idx);
              const startIndex = 2; //skip 0:id, 1:enabled
              return (
                <tr key={idx} className={style['custom-table-row-hover']} style={{height: '50px', backgroundColor: idx%2==1?'#e7edf5':'white'}}>  
                {
                    v.slice(startIndex).map((subv, subidx)=>{
                    console.log('row',subv, subidx);
                    return (<td style={{ textAlign: 'left', paddingLeft: subidx === 0 ? '10px' : '0', borderBottom: '1px solid black' }} key={subidx}>
                      {(v[1] === 1 && subidx === 0) ? '[✔︎]' : ''}{subv}</td>)
                  }) 
                }<td style={{fontSize: '14.4px', borderBottom: '1px solid black'}}><button style={{color: '#00a3ff'}} onClick={()=>{navigate(generatePath(RoutePath.EXAM_DETAIL.path, {id: v[0]}))}}>查看詳細</button></td></tr>
              )
            }))}
            </tbody>
          </table>
          {tablebusy&&(
            <LoadingComponent />
          )}
        </div>

        {tabledata.length>0 &&(
          <div className={['col-auto', 'row', 'items-center', 'justify-center'].join(' ')} style={{marginTop: '52px', marginBottom: '64px'}}>
            {
              currpaginationno>0&&(
                <button className={['row', 'justify-center', 'items-center', style['page-btn']].join(' ')} onClick={()=>{setCurrpaginationno(currpaginationno-1)}}>
                  <img alt="" src={next_page_arrow} style={{width: '4.79px', height: '8.31px', transform: 'rotate(180deg)'}}/>
                </button>
              )
            }
            {
              [...Array(Math.max(0, pagination_end_idx-pagination_start_idx)).keys()].map(v=>{
                return (
                  <button key={v+currpaginationno*max_pagination_btn_count} className={[style['page-btn'], style[(v+currpaginationno*max_pagination_btn_count)===currpageno?'active':'']].join(' ')} 
                  onClick={()=>{
                    setCurrpageno(v+currpaginationno*max_pagination_btn_count)
                    // window.history.pushState(null, null, '#'+generatePath(RoutePath.EXAM_PAGE.path, {pageno:1+v+currpaginationno*max_pagination_btn_count}))
                    navigate(generatePath(RoutePath.EXAM_PAGE.path, {pageno: 1+v+currpaginationno*max_pagination_btn_count})+'?'+stringify({...Object.fromEntries([...query[0]]),}))
                  }}>{v+1+currpaginationno*max_pagination_btn_count}</button>
                )
              })
            }
            {
              currpaginationno<(pagination_count-1)&&(
                <button className={['row', 'justify-center', 'items-center', style['page-btn']].join(' ')} onClick={()=>{setCurrpaginationno(currpaginationno+1)}}>
                  <img alt="" src={next_page_arrow} style={{width: '4.79px', height: '8.31px'}}/>
                </button>
              )
            }
          </div>
        )}
      </div>

      <Modal onClose={memberModalDisclosure.onClose} isOpen={memberModalDisclosure.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent className={['column'].join(' ')} style={{paddingLeft: '30px', paddingRight: '30px', minWidth: '630px'}}>
          <ModalHeader className={['col-auto', 'row', 'justify-between', 'items-center'].join(' ')} style={{width: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
            <div className={['col', ].join(' ')} style={{fontSize: '28.13px', fontWeight: '400',}}>名單匯入</div>
            <button className={['col-auto'].join(' ')} onClick={()=>{
              console.log(`名單匯入`);
              memberModalDisclosure.onClose()
            }}>
              <img alt="" src={modal_cross} style={{width: '18.67px', height: '18.67px'}}/>
            </button>
          </ModalHeader>
          <div className={['col-auto'].join(' ')} style={{borderTop: '1px solid #bdbdbd'}}/>
          <ModalBody className={['col-auto', 'column'].join(' ')} style={{paddingLeft: '0px', paddingRight: '0px', marginTop: '20px'}}>
            <div className={['col-auto', 'column'].join(' ')}>
              <div className={['col-auto'].join(' ')} style={{fontSize: '18px', }}>Step1. 下載excel範本</div>
              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginTop: '14px'}}>
                <div className={['col-auto'].join(' ')}>
                  <img alt="" src={document_normal} style={{width: '16px', height: '20px', marginRight: '12px'}}/>
                </div>
                <div className={['col-auto'].join(' ')} style={{width: '153px'}}>excel_sample.xlsx</div>
                <button className={['col-auto', style['custom-outline-button']].join(' ')} style={{height: '32px', fontSize: '14.4px'}}>檔案下載</button>
              </div>
            </div>

            <div className={['col-auto', 'column'].join(' ')} style={{marginTop: '40px'}}>
              <div className={['col-auto'].join(' ')} style={{fontSize: '18px', }}>Step2. 上傳考生名單</div>
              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginTop: '14px'}}>
                <div className={['col-auto'].join(' ')}>
                  <img alt="" src={document_grey} style={{width: '16px', height: '20px', marginRight: '12px'}}/>
                </div>
                <div className={['col-auto'].join(' ')} style={{width: '153px', color: '#757575'}}>未選擇任何檔案</div>
                <button className={['col-auto', style['custom-outline-button']].join(' ')} style={{height: '32px', fontSize: '14.4px'}}>選擇檔案</button>
              </div>
            </div>

            <div className={['col-auto', 'row'].join(" ")} style={{marginTop: '90px', marginBottom: '32px'}}>
              <button className={['col-auto', style['custom-button']].join(' ')} style={{height: '40px', fontSize: '18px', marginLeft: '0'}} onClick={()=>{
                console.warn('222 - shot toast()')
                toast({
                  containerStyle:{
                    maxWidth: 'unset',
                    minWidth: 'usnet',
                    marginBottom: '20px',
                  },
                  render: () => (
                    <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{paddingLeft: '16px', paddingRight: '16px', backgroundColor: 'white', height: '49px', borderRadius: '12px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'}}>
                      <img alt="" className={['col-auto'].join(' ')} src={green_check} style={{width: '20px', height: '20px', marginRight: '7px'}}/>
                      <label className={['col-auto'].join(' ')} style={{fontSize: '16px', color: 'black'}}>上傳檔案成功</label>
                    </div>
                  ),
                })

                memberModalDisclosure.onClose()
              }}>上傳資料</button>
            </div>
            
          </ModalBody>

        </ModalContent>
      </Modal>

      <Modal onClose={scoreModalDisclosure.onClose} isOpen={scoreModalDisclosure.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent className={['column'].join(' ')} style={{paddingLeft: '30px', paddingRight: '30px', minWidth: '630px'}}>
          <ModalHeader className={['col-auto', 'row', 'justify-between', 'items-center'].join(' ')} style={{width: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
            <div className={['col', ].join(' ')} style={{fontSize: '28.13px', fontWeight: '400',}}>成績匯入</div>
            <button className={['col-auto'].join(' ')} onClick={()=>{
              console.log(`成績匯入`);
              scoreModalDisclosure.onClose()
              }}>
              <img alt="" src={modal_cross} style={{width: '18.67px', height: '18.67px'}}/>
            </button>
          </ModalHeader>
          <div className={['col-auto'].join(' ')} style={{borderTop: '1px solid #bdbdbd'}}/>
          <ModalBody className={['col-auto', 'column'].join(' ')} style={{paddingLeft: '0px', paddingRight: '0px', marginTop: '20px'}}>
            <div className={['col-auto', 'column'].join(' ')}>
              <div className={['col-auto'].join(' ')} style={{fontSize: '18px', }}>Step1. 下載excel範本</div>
              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginTop: '14px'}}>
                <div className={['col-auto'].join(' ')}>
                  <img alt="" src={document_normal} style={{width: '16px', height: '20px', marginRight: '12px'}}/>
                </div>
                <div className={['col-auto'].join(' ')} style={{width: '153px'}}>excel_sample.xlsx</div>
                <button className={['col-auto', style['custom-outline-button']].join(' ')} style={{height: '32px', fontSize: '14.4px'}}>檔案下載</button>
              </div>
            </div>

            <div className={['col-auto', 'column'].join(' ')} style={{marginTop: '40px'}}>
              <div className={['col-auto'].join(' ')} style={{fontSize: '18px', }}>Step2. 上傳考生成續</div>
              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginTop: '14px'}}>
                <div className={['col-auto'].join(' ')}>
                  <img alt="" src={document_grey} style={{width: '16px', height: '20px', marginRight: '12px'}}/>
                </div>
                <div className={['col-auto'].join(' ')} style={{width: '153px', color: '#757575'}}>未選擇任何檔案</div>
                <button className={['col-auto', style['custom-outline-button']].join(' ')} style={{height: '32px', fontSize: '14.4px'}}>選擇檔案</button>
              </div>
            </div>

            <div className={['col-auto', 'row'].join(" ")} style={{marginTop: '90px', marginBottom: '32px'}}>
              <button className={['col-auto', style['custom-button']].join(' ')} style={{height: '40px', fontSize: '18px', marginLeft: '0'}} onClick={()=>{
                //TODO post import api
                console.warn('222 - shot toast()')
                toast({
                  containerStyle:{
                    maxWidth: 'unset',
                    minWidth: 'usnet',
                    marginBottom: '20px',
                  },
                  render: () => (
                    <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{paddingLeft: '16px', paddingRight: '16px', backgroundColor: 'white', height: '49px', borderRadius: '12px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'}}>
                      <img alt="" className={['col-auto'].join(' ')} src={red_cross} style={{width: '20px', height: '20px', marginRight: '7px'}}/>
                      <label className={['col-auto'].join(' ')} style={{fontSize: '16px', color: 'black'}}>檔案格式錯誤</label>
                    </div>
                  ),
                })

                // memberModalDisclosure.onClose()
              }}>上傳資料</button>
            </div>
            
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}

export default Exam