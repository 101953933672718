import {Button, useDisclosure} from "@chakra-ui/react";
import api from '../../api/Api'
import ApiDefinition from "api/ApiDefinition";
import ImportModal from "common/ImportModal";
import PageContainer from "common/PageContainer";
import {useRef} from "react";
import ActivityDetailHeader from "./detail/ActivityDetailHeader";
import SignUpImportModal from "./detail/SignUpImportModal";
import SignUpList, {SignUpListRefProps} from "./detail/SignUpList";
import useFetchActivity from "./detail/useFetchActivity";
import {useCustomToast, useLoading} from "hooks";

function ActivitySignUp() {
  const toast = useCustomToast();
  const { showLoading } = useLoading();
  const signUpListRef = useRef<SignUpListRefProps>(null);
  const { id, loaded, data } = useFetchActivity();
  const disclosureSignUp = useDisclosure();
  const disclosureMins = useDisclosure();

  const handleSignUpImport = () => {
    const formData = new FormData();
    formData.append("activityId", id || "");
    return formData;
  };
  const handleMinsImport = () => {
    const formData = new FormData();
    formData.append("activityId", id || "");

    return formData;
  };

  if (!loaded) {
    return <PageContainer>載入中...</PageContainer>;
  }
  return (
    <PageContainer>
      <ActivityDetailHeader
        subTitle={data?.name}
        toolbar={
          <>
            <Button variant="customSolid"onClick={() => {
                console.log(`activity list export: ${id}`);
                showLoading();
                api.get_report_activity({ id: id }).then((res) => {
                  console.log(`get_report_exam_signup:`,res);
                  window.location.href = res;
                }).catch((e) => {
                  console.log(e);
                  let msg = "錯誤:";
                  if ("response" in e && e.response.data) {
                    msg += e.response.data;
                  }
                  if ("message" in e) {
                    msg += ` (${e.message})`;
                  }
                  toast("error", e.message);
                }).finally(() => {
                  showLoading(false);
                })
              }}>匯出名單</Button>
            <Button
              variant="customSolid"
              onClick={() => {
                disclosureSignUp.onOpen();
              }}
            >
              匯入名單
            </Button>
            <Button
              variant="customSolid"
              onClick={() => {
                disclosureMins.onOpen();
              }}
            >
              匯入時數
            </Button>
          </>
        }
      />
      {id && <SignUpList id={id} ref={signUpListRef} />}
      <SignUpImportModal
        api={ApiDefinition.IMPORT_ACTIVITY_SIGNUP}
        params={{ activityId: id }}
        exampleFilePath="/files/11.xlsx"
        exampleFileName="活動報名名單匯入.xlsx"
        onFinish={() => {
          signUpListRef.current?.reload();
        }}
        title="匯入名單"
        uploadDescription="上傳活動報名名單"
        onImport={handleSignUpImport}
        {...disclosureSignUp}
      />
      <ImportModal
        api={ApiDefinition.IMPORT_ACTIVITY_MINS}
        params={{ activityId: id }}
        exampleFilePath="/files/12.xlsx"
        exampleFileName="活動時數匯入.xlsx"
        onFinish={() => {
          signUpListRef.current?.reload();
        }}
        title="匯入時數"
        uploadDescription="上傳活動時數"
        onImport={handleMinsImport}
        {...disclosureMins}
      />
    </PageContainer>
  );
}

export default ActivitySignUp;
