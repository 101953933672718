import ApiDefinition from "api/ApiDefinition";
import MenuSelect, {MenuSelectProps} from "common/MenuSelect";
import {useFetchData} from "hooks";
import React, {useEffect, useMemo} from "react";

export interface ExamRow {
  id: number;
  name: string;
}

export type ExamSelectProps = Pick<MenuSelectProps, "onChange" | "value">;

function ExamSelect(props: ExamSelectProps) {
  const { fetchData, data } = useFetchData<ExamRow[]>({
    url: `${ApiDefinition.GET_EXAM_LIST_ALTER.path}`,
  });
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = useMemo(
    () =>
      data
        ? data
            .filter((row) => Number(row.id) !== 0)
            .reduce((previousValue, currentValue) => ({ ...previousValue, [currentValue.id]: currentValue.name }), {})
        : {},
    [data]
  );
  return <MenuSelect minW={150} variant="custom" options={options} nullValueText="考試屆次" {...props} />;
}

export default ExamSelect;
