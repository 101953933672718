import {Box, Button, Flex} from "@chakra-ui/react";
import {ColumnDef} from "@tanstack/react-table";
import React, {useState} from "react";
import {SubscriptionRow} from "./type";
import dayjs from "dayjs";
import ChakraTable, {ChakraTableProps} from "common/ChakraTable";
import TransactionDetailModal from "./TransactionDetailModal";

export type SubscriptionTableProps = Pick<ChakraTableProps<SubscriptionRow>, "data" | "TableOptions">;

function SubscriptionTable(props: SubscriptionTableProps) {
  const [dataToShow, setDataToShow] = useState<SubscriptionRow | null>(null);
  const handleCloseModal = () => {
    setDataToShow(null);
  };
  const columns: ColumnDef<SubscriptionRow, any>[] = [
    { header: "姓名", accessorKey: "chName", cell: (info) => info.getValue() },
    { header: "結業證號", accessorKey: "username", cell: (info) => info.getValue() },
    { header: "身分證字號", accessorKey: "idNum", cell: (info) => info.getValue() },
    { header: "手機號碼", accessorKey: "mobile", cell: (info) => info.getValue() },
    { header: "Email", accessorKey: "email", cell: (info) => info.getValue() },
    {
      header: "訂閱起始日",
      accessorKey: "payAt",
      cell: (info) => {
        const value = info.getValue();
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      header: "訂閱到期日",
      accessorKey: "expireAt",
      cell: (info) => {
        const value = info.getValue();
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      id: "transaction_data",
      header: () => <Box textAlign="center">付款資料</Box>,
      cell: (info) => {
        const data = info.row.original;
        const textToShow = data.transactionId && data.transactionId !== "tryout" ? "已付款" : "未付款";
        return (
          <Flex justifyContent="center">
            <Button
              variant="customSolid"
              onClick={() => {
                setDataToShow(data);
              }}
            >
              {textToShow}
            </Button>
          </Flex>
        );
      },
    },
  ];
  return (
    <>
      <ChakraTable<SubscriptionRow> {...props} variant="custom" columns={columns} fullWidth />
      <TransactionDetailModal title="付款資料" data={dataToShow} onClose={handleCloseModal} />
    </>
  );
}

export default SubscriptionTable;
