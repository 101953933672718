import {defineStyleConfig, extendTheme} from "@chakra-ui/react";
import colors from "./colors";
import Table from "./Table";
import Tabs from "./Tabs";

const theme = extendTheme({
  colors,
  components: {
    Table,
    Menu: {
      variants: {
        custom: {
          button: {
            border: "1px solid",
            borderColor: "primary.500",
            color: "primary.500",
            fontSize: 18,
            borderRadius: 4,
            padding: 2,
            _hover: {
              backgroundColor: "#E4EBF3",
            },
          },
          item: {
            borderRadius: 4,
            borderColor: "primary.500 !important",
            backgroundColor: "#F8FBFF",
            fontSize: 18,
            _hover: {
              backgroundColor: "primary.500",
              color: "white",
            },
          },
          list: {
            backgroundColor: "#F8FBFF",
            border: "1px solid",
            borderColor: "secondary.600",
            padding: 2,
          },
        },
      },
    },
    Button: {
      variants: {
        customSolid: {
          fontWeight: 400,
          bg: "primary.500",
          color: "white",
          fontSize: 18,
          borderRadius: 4,
          padding: 3,
          _hover: {
            filter: "brightness(1.2)",
          },
        },
        customOutline: {
          fontWeight: 400,
          border: "1px solid",
          borderColor: "primary.500",
          color: "primary.500",
          fontSize: 18,
          borderRadius: 4,
          padding: 3,
          _hover: {
            backgroundColor: "#E4EBF3",
          },
        },
        dph: {
          //DatePickHeader;
          bg: "primary.500",
          p: 0,
          m: 0,
          height: "32px",
          _hover: {
            border: "1px solid white",
          },
        },
      },
    },
    Card: {
      variants: {
        custom: {
          container: {
            border: "1px solid",
            borderColor: "secondary.300",
            boxShadow: "rgb(0 0 0 / 10%) 1px 1px 2px",
          },
        },
        uploader: {
          container: {
            backgroundColor: "secondary.100",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: "secondary.300",
            h: 350,
          },
        },
      },
    },
    FormLabel: {
      variants: {
        custom: {
          fontSize: 18,
        },
      },
    },
    Input: {
      variants: {
        custom: {
          field: {
            fontSize: 18,
            border: "1px solid",
            borderColor: "secondary.600",
          },
        },
      },
    },
    Radio: {
      variants: {
        custom: {
          control: {
            border: "2.5px solid",
            borderColor: "primary.500 !important",
            color: "primary.500 !important",
            bg: "white !important",
            _before: {
              width: "75% !important",
              height: "75% !important",
              top: "0.2px",
            },
          },
        },
      },
    },
    Select: {
      variants: {
        dph: {
          //DatePickHeader;
          field: {
            fontWeight: "bold",
            border: 0,
            bg: "primary.500",
            p: 0,
          },
          icon: {
            display: "none !important",
          },
        },
      },
    },
    Link: defineStyleConfig({
      variants: {
        tableCell: {
          color: "#00A3FF",
          fontSize: 16,
        },
      },
    }),
    Tabs,
    Alert: {
      variants: {
        customToast: {
          container: {
            backgroundColor: "white",
            paddingLeft: "16px !important",
            paddingRight: "16px !important",
            borderRadius: "12px",
            boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
          },
          title: {
            maxWidth: "unset",
          },
        },
      },
    },
  },
});

export default theme;
