import { Tab, TabList, Tabs, VStack, Flex, HStack } from "@chakra-ui/react";
import ApiDefinition from "api/ApiDefinition";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import SearchBox from "common/SearchBox";
import {useFetchList, usePageControl} from "hooks";
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import SubscriptionTable from "./SubscriptionTable";
import {SubscriptionRow} from "./type";
import SubscriptionDownloadTable from './SubscriptionDownloadTable';

function SubscriptionList() {
  const [searchText, setSearchText] = useState(""); //搜尋方塊
  const [tabIndex, setTabIndex] = useState(0); //頁籤
  const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };
  const { fetchData, rows, total } = useFetchList<SubscriptionRow>({ url: ApiDefinition.GET_SUBSCRIPTION_LIST.path });
  const { pagination, pageCount, setPagination, setDataCount } = usePageControl();
  const loadData = useCallback(() => {
    fetchData({
      pageIndex: pagination.pageIndex,
      perPage: pagination.pageSize,
      deleted: tabIndex,
      filter: searchText,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, tabIndex, searchText]);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, tabIndex]);
  useEffect(() => {
    setDataCount(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);
  const handleSearchButton = () => {
    loadData();
  };
  return (
    <PageContainer>
      <VStack spacing={3} align="start">
        <PageHeader menuIconName="calculator" title="訂閱清單" />
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <HStack alignItems="center">
            <SearchBox
              placeholder="輸入姓名搜尋"
              onSearchButton={handleSearchButton}
              onChange={handleSearchTextChange}
              value={searchText}
            />
          </HStack>
          <HStack alignItems="center">
            <SubscriptionDownloadTable />
          </HStack>
        </Flex>
        <Tabs
          colorScheme="primary"
          variant="customLine"
          w="100%"
          defaultIndex={tabIndex}
          onChange={handleTabsChange}
          mb={5}
        >
          <TabList>
            <Tab>現有訂閱</Tab>
            <Tab>已過期訂閱</Tab>
          </TabList>
        </Tabs>
        <SubscriptionTable
          data={rows}
          TableOptions={{
            state: { pagination },
            pageCount,
            onPaginationChange: setPagination,
            manualPagination: true,
          }}
        />
      </VStack>
    </PageContainer>
  );
}

export default SubscriptionList;
