import {
    HStack, Table, Button, VStack,
    Thead, Tbody, Tr, Th, Td,
    TableContainer, Input
} from "@chakra-ui/react";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import {useEffect, useState} from "react";
import {useCustomToast} from "../hooks";
import Api from "api/Api";

interface navType {
    id: number
    prefix: string
    year: number
    serialNumLength: number
    docType: string
    doc_type_name: string
    currSerialNum: number
    full_curr_serial_num: string
}

const columns = ['類型', '待配編號']

function generateSerialNumber(prefix: string, year: number, serial: number, serialLength: number): string {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() - 1911; // 轉換成中華民國年

    if (prefix == null || prefix === '') {
        prefix = '';
    }

    // 如果傳入的年度不等於當前的年度，則使用當前年度，並將流水號重設為 0001
    if (year !== currentYear) {
        year = currentYear;
        serial = 1;
    }

    const serialNumber = `${prefix}${year}${serial.toString().padStart(serialLength, '0')}`; // 使用 padStart 方法確保流水號是指定長度的數字

    return serialNumber;
}

function FormatRulesComponent() {

    const [nav, setFormatRule] = useState<navType[]>([])

    const toast = useCustomToast

    useEffect(() => {
        Api.get_format_rule().then((res) => {
            res.forEach((item: navType, index: number) => {
                switch (item.docType) {
                    case "renewcert": item.doc_type_name = "換證"; break;
                    case "receipt": item.doc_type_name = "收據"; break;
                    case "cert": item.doc_type_name = "新證"; break;
                    default: item.doc_type_name = item.docType;
                }

                item.full_curr_serial_num = generateSerialNumber(item.prefix, item.year, item.currSerialNum, item.serialNumLength);
            });
            setFormatRule(res)
        })
    }, [])

  return (
    <>
      <PageContainer>
        <VStack spacing={3} align="start">
          <PageHeader
            menuIconName="settings"
            title="編碼設定"
            toolbar={
              <HStack>
              </HStack>
            }
          />
        </VStack>
          <TableContainer>
              <Table variant='striped' colorScheme="gray" size='lg'>
                  <Thead backgroundColor='primary.500'>
                      <Tr>
                          {
                              columns.map((column, index) => {
                                  let width;
                                  if (index === 0) width = '30%';
                                  else if (index === 1) width = '70%';
                                  return <Th color='white' fontSize='lg' key={column} style={{ width: width }}>{column}</Th>
                              })
                          }
                      </Tr>
                  </Thead>
                  <Tbody>
                      {
                          nav.map((item, index) => (
                              <Tr key={item.id}>
                                  <Td>
                                      <Input
                                          defaultValue={item.doc_type_name}
                                          focusBorderColor="primary.500"
                                          borderColor="gray"
                                          htmlSize={1}
                                          isReadOnly
                                      />
                                  </Td>
                                  <Td>
                                      <Input
                                          defaultValue={item.full_curr_serial_num}
                                          focusBorderColor="primary.500"
                                          borderColor="gray"
                                          htmlSize={1}
                                          isReadOnly
                                      />
                                  </Td>
                              </Tr>
                          ))
                      }
                  </Tbody>
              </Table>
          </TableContainer>
      </PageContainer>

    </>
  )
}

export default FormatRulesComponent
