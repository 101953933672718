import {Button, HStack, Radio, RadioGroup, Table, Tbody, Td, Th, Thead, Tr, VStack} from "@chakra-ui/react";
import CommonModal, {CommonModalProps} from "common/CommonModal";
import {FMDateRangePicker, FMTextField} from "common/formik";
import dayjs from "dayjs";
import {Form, Formik, FormikHelpers, useFormikContext} from "formik";
import {useLoading} from "hooks";
import React, { useEffect, useRef, useState } from "react";
import api from '../../api/Api'
import useCustomToast from '../../hooks/useCustomToast';

export type ReportStatisticOneExamPassModalProps = Pick<CommonModalProps, "isOpen" | "onClose">;

interface QueryExamValues {
  examId: number;
}

interface QueryExamListSimple {
  id: number;
  name: string;
  testDate: string;
}


function ReportStatisticOneExamPassModal({ onClose, ...props }: ReportStatisticOneExamPassModalProps) {
  const toast = useCustomToast()
  const { showLoading } = useLoading();
  const downloadOneExamPass = (values: QueryExamValues) => {
    showLoading();
    api.get_report_statistics_one_exam_pass(values).then((res) => {
      window.location.href = res;
    }).catch((e) => {
      console.log(e);
      let message = "錯誤:";
      if ("response" in e && e.response.data) {
        message = e.response.data;
      }
      if ("message" in e) {
        message += ` (${e.message})`;
      }
      //toast("error", e.message);
    }).finally(() => {
      showLoading(false);
      //loading[1](false)
    })
    onClose();
    setSelectedRow(null);
  };

  const [selectedRow, setSelectedRow] = useState<number|null>(null);

  const handleRadioChange = (rowId:number) => {
    setSelectedRow(rowId);
  };

  const handleCancelButtonClick = () => {
    onClose();
    setSelectedRow(null);
  };

  const handleOkButtonClick = () => {
    if(!selectedRow){
      toast("error", "請先選擇考試場次");
    }else{
      downloadOneExamPass({examId: selectedRow})
    }
  };


  const [examData, setExamData] = useState<QueryExamListSimple[]>([]);
  
  
  useEffect(() => {
    api.get_exam_list_all_simple().then((res)=>{
      setExamData(res);
    })
  }, [props.isOpen]);


  return (
    <CommonModal title="選擇考試" size="xl" onClose={handleCancelButtonClick} scrollBehavior="inside" footer={
      <HStack w="100%" spacing={3}>
        <Button w="100%" variant="customOutline" onClick={handleCancelButtonClick}>
          取消
        </Button>
        <Button type="submit" w="100%" variant="customSolid" onClick={handleOkButtonClick}>
          確定
        </Button>
      </HStack>
    } {...props}>
      <VStack align="start" spacing={8}>
        <RadioGroup>
          <Table>
            <Thead>
              <Tr>
                <Th maxWidth="15%">選擇框</Th>
                <Th maxWidth="10%">Id</Th>
                <Th>測驗名稱</Th>
                <Th maxWidth="20%">考試日期</Th>
              </Tr>
            </Thead>
            <Tbody>
              {examData.map((item) => (
                <Tr key={item.id}>
                  <Td>
                    <Radio
                      isChecked={selectedRow === item.id}
                      onChange={() => handleRadioChange(item.id)}
                    />
                  </Td>
                  <Td>{item.id}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.testDate}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </RadioGroup>
      </VStack>
    </CommonModal>
  );
}

export default ReportStatisticOneExamPassModal;
