import ApiDefinition from "api/ApiDefinition";
import MenuSelect, {MenuSelectProps, MenuSelectValue} from "common/MenuSelect";
import {useFetchData} from "hooks";
import React, {useEffect, useMemo} from "react";

export interface CompanyRow {
  id: number;
  name: string;
  company_type: number;
}

export type CompanySelectProps = Pick<MenuSelectProps, "onChange" | "value"> & {
  companyTypeId: MenuSelectValue;
};

function CompanySelect({ companyTypeId, ...props }: CompanySelectProps) {
  const { fetchData, data } = useFetchData<CompanyRow[]>({
    url: `${ApiDefinition.GET_COMPANY.path}${companyTypeId}`,
  });
  const isTypeIdValid = companyTypeId && Number(companyTypeId) !== 0;
  useEffect(() => {
    if (isTypeIdValid) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTypeIdValid, companyTypeId]);

  const options = useMemo(
    () =>
      isTypeIdValid && data
        ? data
            .filter((row) => Number(row.id) !== 0)
            .reduce((previousValue, currentValue) => ({ ...previousValue, [currentValue.id]: currentValue.name }), {})
        : {},
    [isTypeIdValid, data]
  );
  return <MenuSelect minW={200} variant="custom" options={options} nullValueText="現職服務單位" {...props} />;
}

export default CompanySelect;
