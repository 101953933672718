// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

table.student_activity_custom-table__Ufq-7{
  border-collapse: separate;
  border-radius: 4px;
  border-spacing: 0;
  background-color: #0e4e96;
}

.student_activity_page-btn__ZBiuF{
  border: 1px solid #bdbdbd;
  font-size: 12px;
  font-weight: 400;
  color: #616161;

  width: 25px;
  height: 25px;

  margin-left: 8px;
  
}

.student_activity_page-btn__ZBiuF.student_activity_active__KFu3a{
  color: #0e4e96;
  border: 1px solid #0e4e96;
}

.student_activity_page-btn__ZBiuF:hover{
  background-color: #e4ebf3;
}`, "",{"version":3,"sources":["webpack://./src/pages/css/student_activity.module.css"],"names":[],"mappings":";;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,cAAc;;EAEd,WAAW;EACX,YAAY;;EAEZ,gBAAgB;;AAElB;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["\n\n:global(table).custom-table{\n  border-collapse: separate;\n  border-radius: 4px;\n  border-spacing: 0;\n  background-color: #0e4e96;\n}\n\n.page-btn{\n  border: 1px solid #bdbdbd;\n  font-size: 12px;\n  font-weight: 400;\n  color: #616161;\n\n  width: 25px;\n  height: 25px;\n\n  margin-left: 8px;\n  \n}\n\n.page-btn.active{\n  color: #0e4e96;\n  border: 1px solid #0e4e96;\n}\n\n.page-btn:hover{\n  background-color: #e4ebf3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-table": `student_activity_custom-table__Ufq-7`,
	"page-btn": `student_activity_page-btn__ZBiuF`,
	"active": `student_activity_active__KFu3a`
};
export default ___CSS_LOADER_EXPORT___;
