import react, {ChangeEvent, useMemo} from "react";
import dayjs from "dayjs";
import {Button, Flex, Select} from "@chakra-ui/react";
import {mdiMenuLeft, mdiMenuRight} from "@mdi/js";
import Icon from "@mdi/react";
import {ReactDatePickerCustomHeaderProps} from "react-datepicker";

function CustomDatePickerHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) {
  const dayjsInput = dayjs(date);
  const yearsOptions = useMemo(() => {
    return Array.from(Array(130).keys()).map((index) => {
      const year = 1920 + index;
      return (
        <option key={index} value={year} className="react-datepicker__header--option">
          {year} 年
        </option>
      );
    });
  }, []);
  const monthOptions = useMemo(() => {
    return Array.from(Array(12).keys()).map((index) => {
      const month = index + 1;
      return (
        <option key={index} value={month} className="react-datepicker__header--option">
          {month} 月
        </option>
      );
    });
  }, []);

  const handleYearChange = (event: ChangeEvent<HTMLSelectElement>) => {
    changeYear(Number(event.target.value));
  };
  const handleMonthChange = (event: ChangeEvent<HTMLSelectElement>) => {
    changeMonth(Number(event.target.value));
  };
  return (
    <Flex alignItems="center" justifyContent="space-between" bg="primary.500" color="white" p={1}>
      <Button variant="dph" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <Icon path={mdiMenuLeft} size={1} />
      </Button>
      <Flex fontWeight="bold">
        <Select
          variant="dph"
          size="sm"
          onChange={handleYearChange}
          value={dayjsInput.year()}
          icon={<react.Fragment />}
          style={{ width: 55 }}
        >
          {yearsOptions}
        </Select>
        <Select
          variant="dph"
          size="sm"
          onChange={handleMonthChange}
          value={dayjsInput.month() + 1}
          icon={<react.Fragment />}
        >
          {monthOptions}
        </Select>
      </Flex>
      <Button variant="dph" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <Icon path={mdiMenuRight} size={1} />
      </Button>
    </Flex>
  );
}

export default CustomDatePickerHeader;
