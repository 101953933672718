import React, {ReactNode} from "react";
import {
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    FormLabelProps,
    HStack,
    Radio,
    RadioGroup,
    RadioGroupProps,
    RadioProps,
} from "@chakra-ui/react";

export type RadioFieldOptions = number | string | undefined;

export type FRadioFieldProps = Omit<RadioGroupProps, "children"> & {
  data: Record<string, string>;
  formControlProps?: FormControlProps;
  radioProps?: RadioProps;
  label?: ReactNode;
  helperText?: string;
  errorMessage?: string;
  formLabelProps?: FormLabelProps;
  renderItem?: (itemProps: RadioProps | undefined, value: string, text: string, index: number) => ReactNode;
} & Pick<FormControlProps, "width">;

function FRadioField({
  data,
  formControlProps,
  radioProps,
  label,
  helperText,
  errorMessage,
  formLabelProps,
  renderItem,
  width,
  ...props
}: FRadioFieldProps) {
  return (
    <FormControl {...formControlProps} width={width}>
      <FormLabel variant="custom" {...formLabelProps}>
        {label}
      </FormLabel>
      <RadioGroup {...props}>
        <HStack spacing="24px">
          {Object.keys(data).map((value, index) => {
            const text = data[value];
            if (renderItem) {
              return <React.Fragment key={index}>{renderItem(radioProps, value, text, index)}</React.Fragment>;
            }
            return (
              <Radio key={index} value={value} variant="custom">
                {text}
              </Radio>
            );
          })}
        </HStack>
      </RadioGroup>
      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : <></>}
      {!errorMessage && helperText ? <FormHelperText>{helperText}</FormHelperText> : <></>}
    </FormControl>
  );
}

export default FRadioField;
