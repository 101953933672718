import {tabsAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const customLineVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    tablist: {
      borderBottom: "2px solid",
      borderColor: `${c}.100`,
    },
    tab: {
      color: `${c}.500`,
      fontSize: 18,
      marginBottom: "-2px",
      px: "25px",
      py: "8px",
      _hover: {
        bg: "#e4ebf3",
        borderBottom: "2px solid",
        borderColor: `${c}.100`,
      },
      _selected: {
        borderBottom: "2px solid",
        borderColor: `${c}.500`,
        _hover: {
          bg: "#e4ebf3",
        },
      },
    },
  };
});

const variants = {
  customLine: customLineVariant,
};

const Tab = defineMultiStyleConfig({
  variants,
});

export default Tab;
