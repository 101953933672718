import {Button, Card, VStack} from "@chakra-ui/react";
import ApiDefinition from "api/ApiDefinition";
import BannerUploader from "common/BannerUploader";
import {DebugTool, FMTextField} from "common/formik";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {useAPIRequest, useCustomToast, useFetchData} from "hooks";
import React, {useEffect, useState} from "react";
import {serialize} from "object-to-formdata";
import {getErrorMessage} from "common/errMsgHelper";

interface BannerFormValues {
  //表單格式
  title: string;
  link: string;
  banner?: string;
}

const defaultValues: BannerFormValues = {
  title: "RFA 測驗考區公告",
  link: "https://rfa.pension.org.tw/",
};

function Banner() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { processRequest } = useAPIRequest();
  const toast = useCustomToast();
  const handleSelectFile = (file: File) => {
    setSelectedFile(file);
  };
  const bannerDataLoader = useFetchData<BannerFormValues>({
    url: `${ApiDefinition.GET_BANNER_DATA.path}`,
  });
  const handleSubmit = (values: BannerFormValues, formikHelpers: FormikHelpers<BannerFormValues>) => {
    const { banner, ...mValues } = values; //拿掉banner字串
    const formData = serialize(mValues); //JSON轉formData
    if (selectedFile) {
      //置入上傳檔案
      formData.append("banner", selectedFile);
    }
    processRequest(ApiDefinition.UPDATE_BANNER_DATA, formData)
      .then((res) => {
        toast("success", "Banner編輯成功");
        bannerDataLoader.fetchData();
      })
      .catch((err) => {
        const message = getErrorMessage(err, "Banner編輯失敗");
        toast("error", message);
        console.error(err);
      });
  };
  useEffect(() => {
    bannerDataLoader.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageContainer>
      <PageHeader menuIconName="settings" title="Banner設定" />
      <Card variant="custom" px={20} py={10}>
        {bannerDataLoader.loaded ? (
          <Formik<BannerFormValues>
            initialValues={bannerDataLoader.data || defaultValues} //預設資料放這
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form>
                <VStack align="start" spacing={8}>
                  <Field name="banner" type="text">
                    {({ field }: FieldProps) => {
                      const value = field.value;
                      return (
                        <BannerUploader
                          title="Banner 1"
                          helperText="圖片建議尺寸：1000*1000px"
                          selectedFile={selectedFile}
                          onSelectFile={handleSelectFile}
                          accept="image/png, image/jpeg, image/gif, image/webp"
                          initialImage={value}
                        />
                      );
                    }}
                  </Field>
                  <FMTextField id="f-name" name="title" label="Banner標題" />
                  <FMTextField id="f-name" name="link" label="Banner連結" width="100%" />
                  <DebugTool />
                  <Button type="submit" w="100%" variant="customSolid">
                    建立
                  </Button>
                </VStack>
              </Form>
            )}
          </Formik>
        ) : (
          "載入中..."
        )}
      </Card>
    </PageContainer>
  );
}

export default Banner;
