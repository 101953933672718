import { Avatar } from '@chakra-ui/react'
import { useState, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import RoutePath from '../../routes/RoutePathDefinition'

import style from './../css/rootframe.module.css'

import { useAccount, useAppDispatch } from "hooks";
import { ActionDefinition } from "store";
import Loading from 'common/Loading';

import cookies from '../../store/Cookies'

import people_svg from '../../svg-files/people.svg'
import arrow_collapsed_svg from '../../svg-files/arrow-collapsed.svg'
import arrow_expanded_svg from '../../svg-files/arrow-expanded.svg'
import management_svg from '../../svg-files/management.svg'
import clipboard_svg from '../../svg-files/clipboard.svg'
import activity_svg from '../../svg-files/activity.svg'
import settings_svg from '../../svg-files/settings.svg'
import calculator_svg from '../../svg-files/calculator.svg'

import api from '../../api/Api'

const drawmenu = [
  {
    icon: people_svg, label: '學員資料管理', submenu: [
      { label: '全部學員資料', path: RoutePath.STUDENTS.path },
    ]
  },
  {
    icon: management_svg, label: '考試管理', submenu: [
      { label: '建立考試', path: RoutePath.EXAM_CREATE.path },
      { label: '考試列表', path: RoutePath.EXAM.path },
      { label: '考試統計報表', path: RoutePath.EXAM_STATISTIC_REPORT.path },  
    ]
  },
  {
    icon: activity_svg, label: '活動管理', submenu: [
      { label: '全部活動列表', path: RoutePath.ACTIVITY.path },
      { label: '建立活動', path: RoutePath.ACTIVITY_CREATE.path },
      // { label: '跳轉管理後台', path: RoutePath.ACTIVITY_PAGE.path },
    ]
  },
  {
    icon: clipboard_svg, label: '證書管理', submenu: [
      { label: '證書管理', path: RoutePath.EXCHANGEMANAGE.path },
      { label: '換證金額調整', path: RoutePath.EXCHANGEPRICING.path },
    ]
  },
  {
    icon: settings_svg, label: '設定', submenu: [
      { label: 'Email 設定', path: RoutePath.EMAILSETUP.path },
      { label: 'Email 發送', path: RoutePath.EMAILSEND.path },
      { label: '編碼規則', path: RoutePath.FORMATRULES.path },
      { label: '前台頁面', path: RoutePath.FRONTENDHOME.path },
      { label: 'RFA專區', path: RoutePath.RFAZONE.path },
      { label: 'Banner', path: RoutePath.BANNER.path },
      { label: '前台彈跳視窗', path: RoutePath.FUNCTION_MODULE.path },
    ]
  },
  {
    icon: calculator_svg, label: '計算機', submenu: [
      { label: '訂閱清單', path: RoutePath.SUBSCRIPTIONLIST.path },
      { label: '金額設定', path: RoutePath.PRICINGSETUP.path },
    ]
  },
  { icon: activity_svg, label: '簽到簽退系統', submenu: [] }
]

let RootFrame = ({ children }) => {
  const account = useAccount();
  const dispatch = useAppDispatch();
  const navigage = useNavigate()
  const menuClosure = useState([true, true, true, true, true, true,])
  const location = useLocation()
  const iframeRef = useRef(null);

  let handleSignout = () => {
    api.logout()

    dispatch({ type: ActionDefinition.LOGOUT, })
    cookies.delCookies('rfa_auth')
    navigage(RoutePath.LOGIN.path)
  }

  let handleNavigate = (path) => {
    if (location.pathname === path) {
      return
    }
    console.warn('54 - redirect url from ' + location.pathname + ' ---> ' + path)
    navigage(path)
  }

  const onMyFrameLoad = (token) => {
    if (token === undefined) { return }
    // console.log('onMyFrameLoad');
    // const userId2 = `${token}`;
    // const data = { userId2 };
    // const currentUrl = window.location.href.split('#')[0]

    // if (currentUrl === 'http://localhost:3300/'){
    //   // localhost
    //   window.location.assign(`http://localhost:8085/signIn?token=${token}`);
    // } else if (currentUrl === 'http://frontend-manager.rfa-api.tw/') {
    //   // 公司環境
    //   window.location.assign(`http://rfa-verification-manager.rfa-api.tw/signIn?token=${token}`);
    // } else if (currentUrl === 'https://rfa.newbyt.es/') {
    //   // AWS 測試 (還沒有)
    //   window.location.assign('http://localhost:8085/signIn');
    // } else if (currentUrl === 'https://rfa.pension.org.tw/') {
    //   // AWS 正式環境 (還沒有)
    //   window.location.assign('http://localhost:8085/signIn');
    // } 

    // 獲取 env直接跳

    // console.log(process.env.REACT_APP_SIGNTRACK_URL);
    
    window.location.assign(process.env.REACT_APP_SIGNTRACK_URL + `?token=${token}`);

    // console.log();

  };

  const handleMenuClick = (label, idx) => {
    if (label === '簽到簽退系統') {
      // window.open('https://www.google.com', '_blank'); // 直接跳轉到 Google
      api.post_verification_admin().then((res) => {
        if (res) {
          // console.log(res.token);
          onMyFrameLoad(res.token)
        }
      }).catch((err) => {
        console.log(err);
      });
    } else {
      let new_state = Array.from(menuClosure[0])
      new_state[idx] = !new_state[idx]
      menuClosure[1](new_state)
    }
  }


  return (
    <>
      <div className={style['container']}>
        <div className={'row'} style={{ minHeight: '100vh' }}>
          {account?.token !== '' && (
            <div className={['column', style['drawer']].join(' ')} style={{ color: 'white', zIndex: '1' }}>
              <button onClick={() => { handleNavigate(RoutePath.DASHBOARD.path) }} className={['col-auto', 'row', 'justify-center', 'items-center', style['drawer-btn'], style[location.pathname === RoutePath.DASHBOARD.path ? 'active' : '']].join(' ')} style={{ borderBottom: '1px solid white', height: '64px', fontSize: '22.5px', fontWeight: '500', }}>
                <div className={['col-auto'].join(' ')}>RFA 後台管理系統</div>
              </button>

              {
                drawmenu.map((v, idx) => {
                  let disableHover = {}
                  // for(let i=0;i<v.submenu.length;++i){
                  //   if (location.pathname.indexOf(v.submenu[i].path)===0){
                  //     disableHover ={pointerEvents: 'none'}
                  //     break
                  //   }
                  // }
                  return (
                    <div key={idx} className={['col-auto', 'column'].join(' ')}>
                      <button className={['col-auto', 'row', 'justify-between', 'items-center', style['drawer-btn'],].join(' ')} style={
                        { height: '54px', fontSize: '18px', fontWeight: '400', paddingLeft: '23.5px', paddingRight: '23.5px', ...disableHover }
                      }
                        onClick={() => handleMenuClick(v.label, idx)}>
                        <div className={['col-auto', 'row', 'items-center'].join(' ')}>
                          <img alt="" className={'col-auto'} src={v.icon} style={{ width: '21px', height: '21px', marginRight: '19.5px' }} />
                          <label className={'col-auto'} style={{ pointerEvents: 'none' }}>{v.label}</label>
                        </div>
                        {drawmenu[idx].submenu.length > 0 && (
                          <img alt="" className={['col-auto'].join(' ')} src={menuClosure[0][idx] ? arrow_expanded_svg : arrow_collapsed_svg} style={menuClosure[0][idx] ? { width: '14px', height: '8.64px' } : { width: '8.64px', height: '14px' }} />
                        )}
                      </button>
                      {menuClosure[0][idx] && (
                        v.submenu?.map((subv, subidx) => {
                          return (
                            <button onClick={() => { handleNavigate(subv.path) }} key={subidx} className={['col-auto', 'row', 'items-center', 'justify-center', style['drawer-sub-btn'], style[location.pathname.indexOf(subv.path) === 0 ? 'active' : '']].join(' ')} style={{ fontSize: '18px', fontWeight: '400', height: '46px' }}>{subv.label}</button>
                          )
                        })
                      )}
                    </div>
                  )
                })
              }

            </div>
          )}
          <div className={['col', 'column'].join(' ')}>
            <div className={['col-auto', 'row', 'no-wrap', 'items-center', (account?.token !== '') && 'justify-end', style['topbar']].join(' ')}>
              {account?.token === '' ? (
                <div className={['col-auto', style['topbar-title']].join(' ')}>RFA 後台管理系統</div>
              ) : (
                <>
                  <Avatar name={account?.name} style={{ width: '28px', height: '28px', marginRight: '12px' }} />
                  <div className={['col-auto'].join(' ')} style={{ marginRight: '33px' }}>{account?.name}</div>
                  <button className={['col-auto', 'row'].join(' ')} style={{ marginRight: '78px' }} onClick={handleSignout}>
                    <div className={['col-auto'].join(' ')}>登出</div>
                  </button>
                </>
              )}
            </div>
            <div className={'col'} style={{ width: '100%' }}>
              {children}
            </div>
          </div>
        </div>
        {/* <iframe
          style={{ display: 'none' }}
          ref={iframeRef}
          src="http://localhost:8085/signIn"
          title="iframe"
          onLoad={() => onMyFrameLoad()}
        /> */}
        <Loading />
      </div>
    </>
  )
}

export default RootFrame
