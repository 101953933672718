import {Box} from "@chakra-ui/react";

import React from "react";

export interface CustomerDayContentsProps {
  dayOfMonth: number;
}

function CustomerDayContents({ dayOfMonth }: CustomerDayContentsProps) {
  return (
    <Box position="relative" w="100%" h="100%">
      <Box
        position="absolute"
        zIndex={0}
        h="100%"
        w="calc(50% + 1px)"
        top="0"
        left="-1px"
        className="react-datepicker__day--bg0-start"
      ></Box>
      <Box
        position="absolute"
        zIndex={0}
        h="100%"
        w="calc(50% + 1px)"
        top="0"
        right="-1px"
        className="react-datepicker__day--bg0-end"
      ></Box>
      <Box
        position="absolute"
        zIndex={1}
        h="100%"
        w="calc(50% + 1px)"
        top="0"
        left="-1px"
        className="react-datepicker__day--bg1-start"
      ></Box>
      <Box
        position="absolute"
        zIndex={1}
        h="100%"
        w="calc(50% + 1px)"
        top="0"
        right="-1px"
        className="react-datepicker__day--bg1-end"
      ></Box>
      <Box
        position="absolute"
        textAlign="center"
        w="100%"
        h="100%"
        zIndex={2}
        className="react-datepicker__day--contents"
      >
        {dayOfMonth}
      </Box>
    </Box>
  );
}

export default CustomerDayContents;
