import ApiDefinition from "api/ApiDefinition";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Stack
} from '@chakra-ui/react'
import { Button, useDisclosure } from "@chakra-ui/react";
import { useFetchData } from "hooks";
import React, { useEffect, useMemo } from "react";


// 定義 下拉選單內容的 interface
export interface SummaryRow {
  id: number;
  name: string;
  table_type: string;
}


function SummaryTable() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button variant="customSolid" onClick={onOpen} size='md'>匯出報表</Button>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>欄位資料</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CheckboxGroup colorScheme='primary' defaultValue={['naruto', 'kakashi']}>
              <Stack spacing={[1, 3]} direction={['column']}>
                <Checkbox size='lg' value='naruto'>Naruto</Checkbox>
                <Checkbox size='lg' value='sasuke'>Sasuke</Checkbox>
                <Checkbox size='lg' value='kakashi'>Kakashi</Checkbox>
                <Checkbox size='lg' value='sho'>sho</Checkbox>
                <Checkbox size='lg' value='sakura'>sakura</Checkbox>
                <Checkbox size='lg' value='jun'>jun</Checkbox>
                <Checkbox size='lg' value='ohno'>ohno</Checkbox>
              </Stack>
            </CheckboxGroup>

          </ModalBody>

          <ModalFooter>
            {/* <Button mr={3} onClick={onClose} variant="customSolid">
              關閉
            </Button> */}
            <Button variant="customSolid">匯出報表</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SummaryTable