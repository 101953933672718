import React from "react";
import ReactPaginate from "react-paginate";
import clsx from "clsx";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    RowData,
    TableOptions,
    useReactTable,
} from "@tanstack/react-table";
import {
    Box,
    Table,
    TableBodyProps,
    TableHeadProps,
    TableProps,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    VStack,
} from "@chakra-ui/react";

export type ChakraTableProps<TData extends RowData> = Pick<TableOptions<TData>, "data" | "columns"> &
  TableProps & {
    TableOptions?: Omit<TableOptions<TData>, "data" | "columns" | "getCoreRowModel">;
    paging?: boolean;
    pageSize?: number;
    theadProps?: TableHeadProps;
    tbodyProps?: TableBodyProps;
    fullWidth?: boolean;
    widthOverflow?: boolean;
  };

const paginateClassName = clsx("col-auto", "row", "items-center", "justify-center");
const pageBtnClassName = clsx("row", "items-center", "justify-center", "page-btn");

function ChakraTable<TData extends RowData>({
  data,
  columns,
  TableOptions,
  paging = true,
  pageSize = 20,
  theadProps,
  tbodyProps,
  fullWidth,
  widthOverflow = false,
  ...props
}: ChakraTableProps<TData>) {
  const table = useReactTable<TData>({
    data,
    columns,
    ...TableOptions,
    initialState: {
      pagination: paging
        ? {
            pageSize,
          }
        : undefined,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: paging ? getPaginationRowModel() : undefined,
  });
  return (
    <VStack w={fullWidth ? "100%" : undefined} spacing={5}>
      <Box
        rounded="md"
        overflow="hidden"
        minW={fullWidth ? "100%" : undefined}
        w={fullWidth ? 0 : undefined}
        overflowX="scroll"
        border="1px solid"
        borderColor="blueGrey.500"
        className={clsx(widthOverflow ? "table-overflow-wrapper" : "")}
      >
        <Table d="inline-block" className="custom-table" {...props}>
          <Thead {...theadProps}>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <Tr key={index}>
                {headerGroup.headers.map((header, index) => {
                  const meta: any = header.column.columnDef.meta;
                  return (
                    <Th key={index} colSpan={header.colSpan} isNumeric={meta?.isNumeric} minW={header.getSize()}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...tbodyProps}>
            {table.getRowModel().rows.map((row, index) => (
              <Tr key={index}>
                {row.getVisibleCells().map((cell, index) => {
                  const meta: any = cell.column.columnDef.meta;
                  return (
                    <Td key={index} isNumeric={meta?.isNumeric}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {paging && (
        <Box mt={10}>
          <ReactPaginate
            breakLabel="…"
            nextLabel=">"
            onPageChange={({ selected }) => {
              table.setPageIndex(selected);
            }}
            pageRangeDisplayed={5}
            pageCount={table.getPageCount()}
            previousLabel="<"
            className={paginateClassName}
            pageClassName={pageBtnClassName}
            previousClassName={pageBtnClassName}
            nextClassName={pageBtnClassName}
            breakClassName={pageBtnClassName}
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLinkClassName="page-link"
            activeClassName="page-btn-active"
            disabledClassName="page-btn-disable"
          />
        </Box>
      )}
    </VStack>
  );
}

export default ChakraTable;
