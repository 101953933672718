import style from './../css/menu_list_date_picker_v2.module.css'
import {useEffect, useState} from 'react'
import {Menu, MenuButton, MenuList} from '@chakra-ui/react'

import calendar_arrow_svg from './../../svg-files/calendar-arrow.svg'
import dropdown_arrow_svg from './../../svg-files/dropdown_arrow_grey.svg'

let MenuListDateTimeRangePicker =({isOpen, onClose, dateState, handleDayChange})=>{

  let date =new Date()
  const [year, setYear] =useState(date.getFullYear())
  const [month, setMonth] =useState(date.getMonth()+1)
  
  const [currYear, setCurrYear] =useState(date.getFullYear())
  const [currMonth, setCurrMonth] =useState(date.getMonth()+1)
  const [currdate, setCurrDate] =useState(date.getDate())
  const [currTime, setCurrTime] =useState(0)

  let dd =new Date()
  dd.setDate(dd.getDate()+1)
  const [dstYear, setDstYear] =useState(dd.getFullYear())
  const [dstMonth, setDstMonth] =useState(dd.getMonth()+1)
  const [dstDate, setDstDate] =useState(dd.getDate()+1)
  const [dstTime, setDstTime] =useState(0)

  const [selectMode, setSelectMode] =useState(0) //0:src, 1:dst

  useEffect(()=>{
    if (isOpen===true){
      //console.log('17 - update dateStr : '+dateState)

      let expire_regex =/^(\d{4})\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\s(0[1-9]|[1-2][0-9]):\d\d\s-\s(\d{4})\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\s(0[1-9]|[1-2][0-9]):\d\d$/
      if (dateState!==undefined && dateState!==null && expire_regex.test(dateState)===true){
        let date_arr =expire_regex.exec(dateState)
        setYear(parseInt(date_arr[1]))
        setMonth(parseInt(date_arr[2]))

        setCurrYear(parseInt(date_arr[1]))
        setCurrMonth(parseInt(date_arr[2]))
        setCurrDate(parseInt(date_arr[3]))
        setCurrTime(parseInt(date_arr[4]))

        setDstYear(parseInt(date_arr[5]))
        setDstMonth(parseInt(date_arr[6]))
        setDstDate(parseInt(date_arr[7]))
        setDstTime(parseInt(date_arr[8]))

      }else{
        setYear(date.getFullYear())
        setMonth(date.getMonth()+1)

        setCurrYear(date.getFullYear())
        setCurrMonth(date.getMonth()+1)
        setCurrDate(date.getDate())

        setDstYear(dd.getFullYear())
        setDstMonth(dd.getMonth()+1)
        setDstDate(dd.getDate())
      }
    }
  },[isOpen])

  useEffect(()=>{
    //console.log('56 - update date...')
    let compose_date_str =
      currYear+'/'+pad(currMonth)+'/'+pad(currdate)+' '+pad(currTime)+':00 - '+
      dstYear+'/'+pad(dstMonth)+'/'+pad(dstDate)+' '+pad(dstTime)+':00'

    handleDayChange(compose_date_str)

  }, [currYear, currMonth, currdate, currTime, dstYear, dstMonth, dstDate, dstTime])

  const week_label=[
    'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
  ]

  let firstDay =()=>{
    return new Date(year, month-1, 1).getDay()
  }
  let totalDays =()=>{
    return new Date(year, month, 0).getDate()
  }
  let estWeeks =()=>{
    let days =totalDays()
    let first_week =firstDay()

    let tt =first_week+days
    return [...Array(Math.ceil(tt/7)).keys()]
  }
  let prevMonthBtn =()=>{
    let tmp_month =month-1
    if (tmp_month ===0){
      tmp_month =12
      setYear(year-1)
    }

    setMonth(tmp_month)
  }
  let nextMonthBtn =()=>{
    let tmp_month =month+1
    if (tmp_month ===13){
      tmp_month =1
      setYear(year+1)
    }

    setMonth(tmp_month)
  }
  let isStartDate =(row, col)=>{
    if (year ===currYear && month ===currMonth && (1+(row*7+col)-firstDay())===currdate){
      return true
    }
    return false
  }
  let isEndtDate =(row, col)=>{
    if (year ===dstYear && month ===dstMonth && (1+(row*7+col)-firstDay())===dstDate){
      return true
    }
    return false
  }
  let isPartDate =(row, col)=>{
    if (year >=currYear && year <=dstYear){
      if (month >currMonth && month <dstMonth){
        return true
      }else
      if (month ===currMonth && month !==dstMonth){
        if ((1+(row*7+col)-firstDay())> currdate){
          return true
        }
      }else
      if (month ===dstMonth && month !==currMonth){
        if ((1+(row*7+col)-firstDay())< dstDate){
          return true
        }
      }else{
        if (month ===dstMonth && month ===currMonth){
          if ((1+(row*7+col)-firstDay())> currdate && (1+(row*7+col)-firstDay())< dstDate){
            return true
          }
        }
      }
    }

    return false
  }
  let isActive =(row, col)=>{
    return ((1+(row*7+col)-firstDay())===currdate && currMonth===month && currYear===year)
  }
  let pad =(n)=>{return n<10 ? '0'+n : n}

  return(
    <MenuList style={{minWidth: '0', border: '1px solid #e0e0e0', boxShadow: '1px 1px 2px rgba(0,0,0,0.1)', backgroundColor: 'white', borderRadius: '6px', width: '270px', padding: '0'}} className={['column',].join(' ')}>
      
        <div className={['col-auto', 'row', 'items-center', 'justify-between'].join(' ')} style={{height: '38px', fontSize: '14.4px', fontWeight: '500', color: 'white', backgroundColor: '#0e4e96', borderTopRightRadius: '6px', borderTopLeftRadius: '6px',  paddingRight: '5px', paddingLeft: '5px',}}>
          <button className={['col-auto', 'row', 'justify-center', 'items-center', style['arrow-btn']].join(' ')} style={{width: '32px', height: '80%'}} onClick={prevMonthBtn}><img alt="" src={calendar_arrow_svg}/></button>
          <div className={['col-auto',].join(' ')}>
            {year} 年 {month} 月
          </div>
          <button className={['col-auto', 'row', 'justify-center', 'items-center', style['arrow-btn']].join(' ')} style={{width: '32px', height: '80%', transform: 'rotate(180deg)',}} onClick={nextMonthBtn}><img alt="" src={calendar_arrow_svg}/></button>
        </div>

        <div className={['col-auto', 'column',].join(' ')} style={{fontSize: '14.4px', paddingLeft: '16px', paddingRight: '16px', paddingBottom: '8px'}}>
          <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{fontSize: '14.4px', height: '34px'}}>
            {[...Array(7).keys()].map(week=>{
              return(
                <div key={week} className={'col'} style={{textAlign: 'center', fontSize: '11.52px', color: '#568386'}}>{week_label[week]}</div>
              )
            })}
          </div>
          {
            estWeeks().map(row=>{
              return(
                <div key={row} className={['col-auto', 'row', 'items-center'].join(' ')} style={{fontSize: '11.52px', height: '34px'}}>
                {[...Array(7).keys()].map(col=>{
                  let cd =(row*7+col)-firstDay()
                    if (cd<0 || cd>=totalDays()){
                      return (<div key={row*7+col} className={'col'}></div>)
                    }else{
                      return (<button key={col} style={{position: 'relative', height: '100%'}} className={['col', 'row', 'items-center', 'justify-center', 
                        style['year-btn-container'], 
                        style[isEndtDate(row, col)?'dst-date':''], 
                        style[isStartDate(row, col)?'src-date':''], 
                        style[isPartDate(row, col)?'part-date':''], 
                      ].join(' ')} onClick={()=>{
                        let compose_date_str =''
                        if (selectMode===0){
                          setCurrDate(1+(row*7+col)-firstDay())
                          setCurrMonth(month)
                          setCurrYear(year)

                          setSelectMode(1)

                          let dirty =false
                          if (dstYear<year || dstMonth <month){
                            dirty =true
                          }
                          if (dstMonth===month && dstDate<1+(row*7+col)-firstDay()){
                            dirty =true
                          }
                          if (dirty===true){
                            setDstDate(1+(row*7+col)-firstDay())
                            setDstMonth(month)
                            setDstYear(year) 
                          }
                          
                        }else{
                          setDstDate(1+(row*7+col)-firstDay())
                          setDstMonth(month)
                          setDstYear(year)

                          setSelectMode(0)

                          let dirty =false
                          if (currYear>year || currMonth >month){
                            dirty =true
                          }
                          if (currMonth===month && currdate>1+(row*7+col)-firstDay()){
                            dirty =true
                          }
                          if (dirty===true){
                            setCurrDate(1+(row*7+col)-firstDay())
                            setCurrMonth(month)
                            setCurrYear(year)
                          }
                        }

                      }}>
                        <div className={[style['year-btn-bg'],].join(' ')} style={{position: 'absolute', left: 0, top: 'calc(50% - 16px)', width: '100%', height: '32px', }}></div>
                        <div className={[style['year-btn-startdate'],].join(' ')} style={{position: 'absolute', left: 'calc(50% - 16px)', top: 'calc(50% - 16px)', width: '32px', height: '32px', }}></div>

                        <div className={[style[selectMode===0?'year-btn1':'year-btn2'],].join(' ')} style={{position: 'absolute', left: 'calc(50% - 16px)', top: 'calc(50% - 16px)', width: '32px', height: '32px', }}></div>
                        <div className={[style['year-btn-label'], style[selectMode===0?'src-mode':''], 'col-auto',].join(' ')} style={{zIndex: 1, }}>{1+(row*7+col)-firstDay()}</div>
                      
                      </button>)
                    }
                  
                })}
                </div>
              )
            })
          }
        </div>

        <div className={['col-auto', 'row', 'justify-between'].join(' ')} style={{paddingLeft: '10px', paddingRight: '20px', backgroundColor: '#f8f8ff', height: '73px', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px'}}>
          <div className={['col-auto'].join(' ')} style={{fontSize: '14.4px', alignSelf: 'end', marginBottom: '14px'}}>從</div>
          <div className={['col-auto', 'column', 'justify-center', 'items-center'].join(' ')} style={{width: '96px'}}>
            <div className={['col-auto']} style={{fontSize: '11.52px', color: '#0e4e96'}}>{currYear}年{currMonth}月{currdate}日</div>
            <Menu matchWidth>
              {({ isOpen, onClose }) => (
                <>
                <MenuButton className={[style['dropdown-time-btn']].join(' ')} style={{paddingLeft: '10px', paddingRight: '15px', height: '28px', width: '100%', border: '1px solid #757575', borderRadius: '3px', marginTop: '6px'}}>
                  <div className={['col-auto', 'row', 'items-center', 'justify-between', ].join(' ')}>
                    <label className={'col'} style={{fontSize: '11.52px', textAlign: 'left', pointerEvents: 'none'}}>{pad(currTime)}:00</label>
                    <img alt="" className={'col-auto'}src={dropdown_arrow_svg} style={{pointerEvents: 'none'}}/>
                  </div>
                </MenuButton>
                <MenuList style={{minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px', overflowY: 'auto', maxHeight: '400px'}} className={['column', 'no-wrap'].join(' ')}>
                  {
                    [...Array(24).keys()].map((v,idx)=>{
                      return(
                        <button onClick={()=>{
                          setCurrTime(idx)
                          onClose()
                        }} key={idx} className={['col-auto', style['dropwdown-opt-btn'], style[currTime===idx?'active':'']].join(' ')} style={{fontSize: '11.52px', height: '28px', textAlign: 'left', paddingLeft: '10px'}}>{pad(v)}:00</button>
                      )
                    })
                  }
                </MenuList>
                </>
              )}
            </Menu>
          </div>
          <div className={['col-auto'].join(' ')} style={{fontSize: '14.4px', alignSelf: 'end', marginBottom: '14px'}}>到</div>
          <div className={['col-auto', 'column', 'justify-center', 'items-center'].join(' ')} style={{width: '96px'}}>
            <div className={['col-auto']} style={{fontSize: '11.52px', color: '#0e4e96'}}>{dstYear}年{dstMonth}月{dstDate}日</div>
            <Menu matchWidth>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton className={[style['dropdown-time-btn']].join(' ')} style={{paddingLeft: '10px', paddingRight: '15px', height: '28px', width: '100%', border: '1px solid #757575', borderRadius: '3px', marginTop: '6px'}}>
                    <div className={['col-auto', 'row', 'items-center', 'justify-between', ].join(' ')}>
                      <label className={'col'} style={{fontSize: '11.52px', textAlign: 'left', pointerEvents: 'none'}}>{pad(dstTime)}:00</label>
                      <img alt="" className={'col-auto'}src={dropdown_arrow_svg} style={{pointerEvents: 'none'}}/>
                    </div>
                  </MenuButton>
                  <MenuList style={{minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px', overflowY: 'auto', maxHeight: '400px'}} className={['column', 'no-wrap'].join(' ')}>
                    {
                      [...Array(24).keys()].map((v,idx)=>{
                        return(
                          <button onClick={()=>{
                            setDstTime(idx)
                            onClose()
                          }} key={idx} className={['col-auto', style['dropwdown-opt-btn'], style[dstTime===idx?'active':'']].join(' ')} style={{fontSize: '11.52px', height: '28px', textAlign: 'left', paddingLeft: '10px'}}>{pad(v)}:00</button>
                        )
                      })
                    }
                  </MenuList>
                </>
              )}
            </Menu>
          </div>

        </div>

    </MenuList>
  )
}

export default  MenuListDateTimeRangePicker