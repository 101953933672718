import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import RoutePath from "./RoutePathDefinition";

import Root from "../pages/Root";
import LoginComponent from "../pages/Login";
import DashboardComponent from "../pagesV2/dashboard/Dashboard";
import StudentsComponent from "../pages/Students";
import StudentDetailComponent from "../pages/StudentDetail";
import ExamComponent from "../pages/Exam";
import ExamCreateComponent from "../pages/ExamCreate";
import ExamDetailComponent from "../pages/ExamDetail";
import {
  ActivityCreateComponent,
  ActivityDetailComponent,
  ActivityEditComponent,
  ActivityListComponent,
  ActivitySignUpComponent,
} from "../pagesV2/activity";
import ExamStatisticReportComponent from "../pagesV2/exam/ExamStatisticReport"
import EmailSetupComponent from "../pagesV2/setting/EmailSetup";
import FormatRulesComponent from "../pages/FormatRules";
import UserCertificatesList from "../pagesV2/training/UserCertificatesList";
import FrontendHomeComponent from "../pagesV2/setting/FrontendHome";
import SubscriptionListComponent from "../pagesV2/calculator/SubscriptionList";
import PricingSetupComponent from "../pages/PricingSetup";
import Banner from "../pagesV2/banner/Banner";
import FunctionModule from "pagesV2/setting/FunctionModule";
import ExchangePricingComponent from "pages/ExchangePricing";
import RFAZoneComponent from "../pagesV2/setting/RFAZone";
import EmailSendComponent from '../pagesV2/setting/EmailSend';

const RouteRoot = () => {
  return (
    <>
      <React.StrictMode>
        <Routes>
          <Route path={RoutePath.ROOT.path} element={<Root />}>
            <Route path={RoutePath.LOGIN.path} element={<LoginComponent />} />
            <Route path={RoutePath.DASHBOARD.path} element={<DashboardComponent />} />

            <Route path={RoutePath.STUDENTS.path} element={<StudentsComponent />} />
            <Route path={RoutePath.STUDENTS_PAGE.path} element={<StudentsComponent />} />

            <Route path={RoutePath.STUDENT_DETAIL.path} element={<StudentDetailComponent />} />
            <Route path={RoutePath.STUDENT_DETAIL_EDIT.path} element={<StudentDetailComponent />} />
            <Route path={RoutePath.STUDENT_EXAM.path} element={<StudentDetailComponent />} />
            <Route path={RoutePath.STUDENT_EXAM_EDIT.path} element={<StudentDetailComponent />} />
            <Route path={RoutePath.STUDENT_ACTIVITY.path} element={<StudentDetailComponent />} />
            <Route path={RoutePath.STUDENT_ACTIVITY_PAGE.path} element={<StudentDetailComponent />} />
            <Route path={RoutePath.STUDENT_CERT.path} element={<StudentDetailComponent />} />
            <Route path={RoutePath.STUDENT_CERT_PAGE.path} element={<StudentDetailComponent />} />

            <Route path={RoutePath.EXAM.path} element={<ExamComponent />} />
            <Route path={RoutePath.EXAM_PAGE.path} element={<ExamComponent />} />

            <Route path={RoutePath.EXAM_CREATE.path} element={<ExamCreateComponent />} />
            <Route path={RoutePath.EXAM_DETAIL.path} element={<ExamDetailComponent />} />
            <Route path={RoutePath.EXAM_DETAIL_EDIT.path} element={<ExamDetailComponent />} />
            <Route path={RoutePath.EXAM_CANDIDATE.path} element={<ExamDetailComponent />} />
            <Route path={RoutePath.EXAM_CANDIDATE_PAGE.path} element={<ExamDetailComponent />} />
            <Route path={RoutePath.EXAM_STATISTIC_REPORT.path} element={<ExamStatisticReportComponent />} />
            
            <Route path={RoutePath.ACTIVITY.path} element={<ActivityListComponent />} />
            <Route path={RoutePath.ACTIVITY_CREATE.path} element={<ActivityCreateComponent />} />
            <Route path={RoutePath.ACTIVITY_DETAIL.path} element={<ActivityDetailComponent />} />
            <Route path={RoutePath.ACTIVITY_DETAIL_EDIT.path} element={<ActivityEditComponent />} />
            <Route path={RoutePath.ACTIVITY_SIGNUP.path} element={<ActivitySignUpComponent />} />

            {/* 郵件修改 V */}
            <Route path={RoutePath.EMAILSETUP.path} element={<EmailSetupComponent />} />
            {/* 郵件寄送 */}
            <Route path={RoutePath.EMAILSEND.path} element={<EmailSendComponent />} />
            {/* 編碼規則 V */}
            <Route path={RoutePath.FORMATRULES.path} element={<FormatRulesComponent />} />
            {/* 換證管理 */}
            <Route path={RoutePath.EXCHANGEMANAGE.path} element={<UserCertificatesList />} />
            {/* 換證金額調整 */}
            <Route path={RoutePath.EXCHANGEPRICING.path} element={<ExchangePricingComponent />} />
            {/* 前台首頁 V */}
            <Route path={RoutePath.FRONTENDHOME.path} element={<FrontendHomeComponent />} />
            {/* RFA專區 */}
            <Route path={RoutePath.RFAZONE.path} element={<RFAZoneComponent />} />
            {/* 訂閱清單 */}
            <Route path={RoutePath.SUBSCRIPTIONLIST.path} element={<SubscriptionListComponent />} />
            {/* 訂閱計算機金額設定*/}
            <Route path={RoutePath.PRICINGSETUP.path} element={<PricingSetupComponent />} />
            {/* Banner */}
            <Route path={RoutePath.BANNER.path} element={<Banner />} />
            {/* 功能模組 */}
            <Route path={RoutePath.FUNCTION_MODULE.path} element={<FunctionModule />} />
            {/* 404 頁面 */}
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Route>
        </Routes>
      </React.StrictMode>
    </>
  );
};

export default RouteRoot;
