import React, {ReactNode} from "react";
import {Field, FieldProps} from "formik";
import FMDatePicker, {FMDatePickerProps, parseDateForPicker} from "./FMDatePicker";

export type FMDateRangePickerProps = Omit<
  FMDatePickerProps,
  "id" | "label" | "name" | "placeHolder" | "startDate" | "endDate" | "selectsStart" | "selectsEnd"
> & {
  idPrefix?: string;
  startDateName: string;
  startDateLabel?: string;
  startDatePlaceHolder?: string;
  endDateName: string;
  endDateLabel?: string;
  endDatePlaceHolder?: string;
  render?: (startDatePicker: ReactNode, endDatePicker: ReactNode) => ReactNode;
};

function FMDateRangePicker({
  idPrefix,
  startDateName,
  startDateLabel,
  startDatePlaceHolder,
  endDateName,
  endDateLabel,
  endDatePlaceHolder,
  render,
  ...props
}: FMDateRangePickerProps) {
  const startDateId = idPrefix ? "f-start-date" : `${idPrefix}-start`;
  const endDateId = idPrefix ? "f-end-date" : `${idPrefix}-start`;
  const renderStartPicker = () => (
    <Field name={endDateName}>
      {({ field }: FieldProps) => {
        const endValue = parseDateForPicker(field.value);
        return (
          <FMDatePicker
            {...props}
            id={startDateId}
            name={startDateName}
            label={startDateLabel}
            placeholderText={startDatePlaceHolder}
            selectsStart
            endDate={endValue}
          />
        );
      }}
    </Field>
  );
  const renderEndPicker = () => (
    <Field name={startDateName}>
      {({ field }: FieldProps) => {
        const startValue = parseDateForPicker(field.value);
        return (
          <FMDatePicker
            {...props}
            id={endDateId}
            name={endDateName}
            label={endDateLabel}
            placeholderText={endDatePlaceHolder}
            selectsEnd
            minDate={startValue}
            startDate={startValue}
          />
        );
      }}
    </Field>
  );
  return (
    <>
      {render ? (
        render(renderStartPicker(), renderEndPicker())
      ) : (
        <>
          {renderStartPicker()}
          {renderEndPicker()}
        </>
      )}
    </>
  );
}

export default FMDateRangePicker;
