import {Card, CardBody, HStack, Text, VStack} from "@chakra-ui/react";
import React from "react";

export interface ValueCardProps {
  title: string;
  value: number | string;
  unit?: string;
}

function ValueCard({ title, value, unit }: ValueCardProps) {
  let dataToShow = value;
  if(value === null || value === undefined){
    dataToShow = "-";
  }
  return (
    <Card w={200} variant="custom">
      <CardBody>
        <VStack>
          <Text fontSize={14} color="blueGrey.300">
            {title}
          </Text>
          <HStack spacing={2} alignItems="end">
            <Text fontSize={36} fontWeight={400}>
              {dataToShow}
            </Text>
            {unit && (
              <Text fontSize={18} pb={2}>
                {unit}
              </Text>
            )}
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
}

export default ValueCard;
