import {Button, HStack, VStack} from "@chakra-ui/react";
import CommonModal, {CommonModalProps} from "common/CommonModal";
import {FMDateRangePicker} from "common/formik";
import dayjs from "dayjs";
import {Form, Formik, FormikHelpers} from "formik";
import {useLoading} from "hooks";
import React from "react";
import api from '../../api/Api'

export type CertChangeModalProps = Pick<CommonModalProps, "isOpen" | "onClose">;

interface DateRangeFormValues {
  startDate: string;
  endDate: string;
}

export const initialValues: DateRangeFormValues = {
  startDate: dayjs().format("YYYY/MM/DD"),
  endDate: dayjs().format("YYYY/MM/DD"),
};

function CertChangeModal({ onClose, ...props }: CertChangeModalProps) {
  const { showLoading } = useLoading();
  const handleSubmit = (values: DateRangeFormValues, formikHelpers: FormikHelpers<DateRangeFormValues>) => {
    //表單送出之後的處理 API串街程式碼 送出格是參見DateRangeFormValues
    console.log("送出去的資料", values);
    //loading[1](true);
    showLoading();
    api.get_report_renew({ start: values.startDate , end: values.endDate }).then((res) => {
      console.log(`get_report_renew:`,res);
      //download(res, `報名清單.xls` );
      //download("下載的檔案位置", "檔案名稱");
      window.location.href = res;
    }).catch((e) => {
      console.log(e);
      let message = "錯誤:";
      if ("response" in e && e.response.data) {
        message = e.response.data;
      }
      if ("message" in e) {
        message += ` (${e.message})`;
      }
      //toast("error", e.message);
    }).finally(() => {
      showLoading(false);
      //loading[1](false)
    })
    
    onClose();
  };
  return (
    <CommonModal title="換證日期" size="md" onClose={onClose} {...props}>
      <Formik<DateRangeFormValues> initialValues={initialValues} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <VStack align="start" spacing={8}>
              <FMDateRangePicker
                startDateName="startDate"
                startDateLabel="開始日期"
                startDatePlaceHolder="yyyy/mm/dd"
                endDateName="endDate"
                endDateLabel="結束日期"
                endDatePlaceHolder="yyyy/mm/dd"
              />
              <HStack w="100%" spacing={3}>
                <Button w="100%" variant="customOutline" onClick={onClose}>
                  取消
                </Button>
                <Button type="submit" w="100%" variant="customSolid">
                  確定
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </CommonModal>
  );
}

export default CertChangeModal;
