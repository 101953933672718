
const PathDefinition={
  ROOT:                   {path: '/',                                   },
  LOGIN:                  {path: '/login',                              },
  DASHBOARD:              {path: '/dashboard',                          },

  //學員資料管理
  STUDENTS:               {path: '/students',                           },  //全部學員資料
  STUDENTS_PAGE:          {path: '/students/page/:pageno',              },  //全部學員資料
  STUDENT_DETAIL:         {path: '/students/:id/detail',                },  //學員詳細資料
  STUDENT_DETAIL_EDIT:    {path: '/students/:id/detail/edit',           },  //學員詳細資料
  STUDENT_EXAM:           {path: '/students/:id/exam/:examid',          },  //考試資料
  STUDENT_EXAM_EDIT:      {path: '/students/:id/exam/:examid/edit',     },  //考試資料
  STUDENT_ACTIVITY:       {path: '/students/:id/activity',              },  //實體持續教育
  STUDENT_ACTIVITY_PAGE:  {path: '/students/:id/activity/page/:pageno', },  //實體實體教育
  STUDENT_ONLINE:         {path: '/students/:id/online',                },  //數位持續教育
  STUDENT_ONLINE_PAGE:    {path: '/students/:id/online/page/:pageno',   },  //數位持續教育
  STUDENT_CERT:           {path: '/students/:id/cert',                  },  //認證管理
  STUDENT_CERT_PAGE:      {path: '/students/:id/cert/page/:pageno',     },  //認證管理

  EXAM:                   {path: '/exam/list',                            },
  EXAM_PAGE:              {path: '/exam/page/:pageno',                    },
  EXAM_CREATE:            {path: '/exam/create',                          },
  EXAM_DETAIL:            {path: '/exam/list/:id/detail',                 },
  EXAM_DETAIL_EDIT:       {path: '/exam/list/:id/detail/edit',            },
  EXAM_CANDIDATE:         {path: '/exam/list/:id/candidate',              },
  EXAM_CANDIDATE_PAGE:    {path: '/exam/list/:id/candidate/page/:pageno', },
  EXAM_SCORECHECK:        {path: '/exam/list/:id/scorecheck',              },
  EXAM_SCORECHECK_PAGE:   {path: '/exam/list/:id/scorecheck/page/:pageno', },
  EXAM_CERTIFICATE:       {path: '/exam/list/:id/certificate',             },
  EXAM_CERTIFICATE_PAGE: { path: '/exam/list/:id/certificate/page/:pageno',},
  EXAM_STATISTIC_REPORT: { path: '/exam/statistic/report',},

  //活動
  ACTIVITY:               {path: '/activity/list',                      },
  ACTIVITY_CREATE:        {path: '/activity/create',                    },
  ACTIVITY_DETAIL:        {path: '/activity/list/:id/detail',           },
  ACTIVITY_DETAIL_EDIT:   {path: '/activity/list/:id/detail/edit',      },
  ACTIVITY_SIGNUP:        {path: '/activity/list/:id/sign-up',          },
  ACTIVITY_PAGE:          {path: '/activity/page',                      }, //跳轉至簽到系統

  //設定
  EMAILSETUP:             {path: '/email-setup',                        },  //Email 設定
  EMAILSEND:              {path: '/email-send',                         },  //Email 發送
  FORMATRULES:            {path: '/format-rules',                       },  //編碼規則
  EXCHANGEPRICING:        {path: '/exchange-pricing',                   },  //換證金額
  EXCHANGEMANAGE:         {path: '/exchange-manage',                    },  //換證管理
  FRONTENDHOME:           {path: '/frontend-home',                      },  //前台首頁
  RFAZONE:                {path: '/rfa-zone',                           },  //RFA專區設定
  BANNER:                 {path: '/banner',                             },  //BANNER設定
  FUNCTION_MODULE:        {path: '/function-module',                    },  //前台彈跳視窗

  SUBSCRIPTIONLIST:       {path: '/subscription-list',                  },  //訂閱清單
  PRICINGSETUP:           {path: '/pricing-setup',                      },  //金額設定

}

export default PathDefinition
