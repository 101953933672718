import {FEditor} from "common/form";
import {FEditorProps} from "common/form/FEditor";
import {FastField, FieldProps, useFormikContext} from "formik";
import React from "react";

export type FMEditorProps = Omit<FEditorProps, "value" | "onChange" | "name" | "errorMessage"> & {
  name: string;
};

function FMEditor({ name, ...props }: FMEditorProps) {
  const { setFieldValue } = useFormikContext();
  const handleChange = (value: string) => {
    setFieldValue(name, value);
  };
  return (
    <FastField name={name}>
      {({ field, form, meta }: FieldProps) => {
        const error = meta.touched && meta.error ? meta.error : undefined;
        const { value } = field;
        return <FEditor {...props} value={value} onChange={handleChange} errorMessage={error} />;
      }}
    </FastField>
  );
}

export default FMEditor;
