import {mdiCalendarClock} from "@mdi/js";
import Icon from "@mdi/react";
import BaseFormatInput, {BaseFormatInputProps} from "./BaseFormatInput";

export type DateTimeInputProps = Omit<BaseFormatInputProps, "icon" | "regExp" | "maxLength">;

function DateTimeInput(props: DateTimeInputProps) {
  return (
    <BaseFormatInput
      {...props}
      icon={<Icon path={mdiCalendarClock} size={1} />}
      regExp={/^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g}
      maxLength={16}
    />
  );
}

export default DateTimeInput;
