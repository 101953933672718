import {Button, HStack, Text, VStack} from "@chakra-ui/react";
import {
    DebugTool,
    FMDatePicker,
    FMDateRangePicker,
    FMEditor,
    FMInputGroup,
    FMNumberPicker,
    FMTextField,
    FMTimePicker,
} from "common/formik";
import FMRadioField from "common/formik/FMRadioField";
import dayjs from "dayjs";
import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {enableMapping, initialValues as defaultInitialValues} from "./commonValues";
import {ActivityFormValue} from "./type";

export interface ActivityFormProps {
  initialValues?: ActivityFormValue;
  onSubmit: (values: ActivityFormValue, formikHelpers: FormikHelpers<ActivityFormValue>) => void;
  onCancel: () => void;
  isEdit?: boolean;
}

function ActivityForm({ onSubmit, onCancel, initialValues = defaultInitialValues, isEdit = false }: ActivityFormProps) {
  const handleSubmit = (values: ActivityFormValue, formikHelpers: FormikHelpers<ActivityFormValue>) => {
    const mValues = {
      ...values,
      startTime: dayjs(values.startTime).format("HH:mm"),
      endTime: dayjs(values.endTime).format("HH:mm"),
    };
    onSubmit(mValues, formikHelpers);
  };
  return (
    <Formik<ActivityFormValue> initialValues={initialValues} onSubmit={handleSubmit}>
      {(props) => (
        <Form>
          <VStack align="start" spacing={8}>
            <FMTextField id="f-name" name="name" label="活動名稱" />
            <FMTextField id="f-orgName" name="orgName" label="開課單位" />
            <FMTextField id="f-receiptName" name="receiptName" label="活動收據標題" isDisabled={isEdit} />
            <FMRadioField data={enableMapping} name="enabled" label="狀態" />
            <FMDatePicker name="activityDate" label="活動日期" />
            <FMTimePicker name="startTime" label="活動開始時間" />
            <FMTimePicker name="endTime" label="活動結束時間" />
            <FMTextField type="number" id="f-headCountLimit" name="headCountLimit" label="人數限制" />
            <FMDateRangePicker
              startDateName="startDate"
              startDateLabel="開放報名日期"
              startDatePlaceHolder="yyyy/mm/dd"
              endDateName="endDate"
              endDateLabel="結束報名日期"
              endDatePlaceHolder="yyyy/mm/dd"
            />
            <FMTextField id="f-place" name="place" label="活動地點" />
            <FMNumberPicker name="mins" label="認證時間設定(分鐘)" unitLabel="分鐘" isDisabled={isEdit} />
            <FMInputGroup
              id="f-amount"
              name="amount"
              label="活動金額"
              rightElement={<Text>元</Text>}
              isDisabled={isEdit}
            />
            <FMEditor label="活動內容說明" name="activityContent" placeholder="請輸入文字" />
            <FMEditor label="備註說明" name="memo" placeholder="請輸入文字" />
            <DebugTool />
            <HStack w="100%" spacing={3}>
              <Button
                w="100%"
                variant="customOutline"
                onClick={() => {
                  onCancel();
                }}
              >
                取消
              </Button>
              <Button type="submit" w="100%" variant="customSolid">
                確認編輯
              </Button>
            </HStack>
          </VStack>
        </Form>
      )}
    </Formik>
  );
}

export default ActivityForm;
