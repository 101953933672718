import {ActionDefinition} from "store";
import {useAppDispatch} from "./storeHooks";

function useLoading() {
  const dispatch = useAppDispatch();
  const showLoading = (show = true) => {
    if (show) {
      dispatch({ type: ActionDefinition.LOADING_SHOW });
    } else {
      dispatch({ type: ActionDefinition.LOADING_DISMISS });
    }
  };

  const clearLoading = () => {
    dispatch({ type: ActionDefinition.LOADING_CLEAR });
  };
  return { showLoading, clearLoading };
}

export default useLoading;
