import {Box, Grid} from "@chakra-ui/react";
import DtlGridBlank from "common/DtlGridBlank";
import DtlGridItem from "common/DtlGridItem";
import NumericText from "common/NumericText";
import {toMinguoDate} from "common/dateHelper";
import React from "react";
import {enableMapping} from "./../commonValues";
import {ActivityData} from "../type";

export interface ActivityDetailGridProps {
  data: ActivityData;
}

function ActivityDetailGrid({ data }: ActivityDetailGridProps) {
  const activityDate = `${toMinguoDate(data.activityDate)} ${data.startTime}-${data.endTime}`;
  const enabledString = enableMapping[data.enabled.toString() as keyof typeof enableMapping];
  return (
    <Grid fontSize={18} templateColumns="180px 1fr" gap="2.5" w="100%">
      <DtlGridItem title="活動名稱">{data.name}</DtlGridItem>
      <DtlGridBlank />
      <DtlGridItem title="開課單位">{data.orgName}</DtlGridItem>
      <DtlGridItem title="活動收據標題">{data.receiptName}</DtlGridItem>
      <DtlGridItem title="狀態">{enabledString}</DtlGridItem>
      <DtlGridItem title="活動日期">{activityDate}</DtlGridItem>
      <DtlGridItem title="人數限制">{data.headCountLimit ?? "不限"}</DtlGridItem>
      <DtlGridItem title="開放報名日期">{toMinguoDate(data.startDate)}</DtlGridItem>
      <DtlGridItem title="結束報名日期">{toMinguoDate(data.endDate)}</DtlGridItem>
      <DtlGridItem title="活動地點">{data.place}</DtlGridItem>
      <DtlGridItem title="認證時間設定(分鐘)">
        <NumericText value={data.mins} />
        分鐘
      </DtlGridItem>
      <DtlGridItem title="活動金額">{data.amount ? <NumericText value={data.amount} /> : "免費"}</DtlGridItem>
      <DtlGridBlank />
      <DtlGridItem title="活動內容說明" className="ql-snow">
        <Box w="100%" className="ql-editor" p={0} dangerouslySetInnerHTML={{ __html: data.activityContent }} />
      </DtlGridItem>
      <DtlGridBlank />
      <DtlGridItem title="備註說明" className="ql-snow">
        <Box w="100%" className="ql-editor" p={0} dangerouslySetInnerHTML={{ __html: data.memo }} />
      </DtlGridItem>
    </Grid>
  );
}

export default ActivityDetailGrid;
