import {Button, HStack, useDisclosure, VStack} from "@chakra-ui/react";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import CertChangeModal from "./CertChangeModal";
import CertChangeSuccModal from "./CertChangeSuccModal";

function UserCertificatesList() {
  const certChangeDisclosure = useDisclosure();
  const certChangeSuccDisclosure = useDisclosure();

  return (
    <PageContainer>
      <VStack spacing={3} align="start">
        <PageHeader
          menuIconName="training"
          title="證書管理"
          toolbar={
            <HStack>
              <Button variant="customSolid" onClick={certChangeDisclosure.onOpen}>
                換證清單(申請)
              </Button>
              <Button variant="customSolid" onClick={certChangeSuccDisclosure.onOpen}>
                換證清單(換證-已發證號)
              </Button>
            </HStack>
          }
        />
        {/* 搜尋列、表格放這裡 */}
      </VStack>
      <CertChangeModal {...certChangeDisclosure} />
      <CertChangeSuccModal {...certChangeSuccDisclosure} />
    </PageContainer>
  );
}

export default UserCertificatesList;
