import React, { useEffect, useState, useRef, ChangeEvent } from 'react'
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import {
  Button,
  SimpleGrid,
  Card,
  CardHeader,
  Heading,
  CardBody,
  CardFooter,
  Image as ChakraImage,
  Input,
  Checkbox
} from '@chakra-ui/react'
// import axios from 'axios';
import img from './img.png'
import Api from "api/Api";
import { useCustomToast } from "hooks";
import { getErrorMessage } from "common/errMsgHelper";

interface AdvertiseType {
  "id": string,
  "title": string,
  "link": string,
  "adImgUrl": string,
  "enable": string
}

// interface updata_AdvertiseType {
//   "id": string,
//   "title": string,
//   "link": string,
//   "file": File
// }

const FunctionModule = () => {

  const toast = useCustomToast();
  // 掛在畫面上的圖片url
  const [advertise, setAdvertise] = useState<AdvertiseType[]>([]);
  // const [advertiseData, setAdvertisData] = useState<AxiosData | null>(null);
  const [selectedOneFile, setSelectedOneFile] = useState<File | null>(null);
  const [selectedTwoFile, setSelectedTwoFile] = useState<File | null>(null);
  const [selectedThreeFile, setSelectedThreeFile] = useState<File | null>(null);

  const [previewOne, setPreviewOne] = useState('');
  const [previewTwo, setPreviewTwo] = useState('');
  const [previewThree, setPreviewThree] = useState('');

  const inputOneRef = useRef<HTMLInputElement>(null);
  const inputTwoRef = useRef<HTMLInputElement>(null);
  const inputThreeRef = useRef<HTMLInputElement>(null);

  // 上傳用

  // const [updataAdvertiseOne, setUpdataAdvertiseOne] = useState<updata_AdvertiseType[]>([]);

  const handleUploadFileButton = (id: number) => {
    if (id === 0) {
      inputOneRef.current?.click();
    } else if (id === 1) {
      inputTwoRef.current?.click();
    } else if (id === 2) {
      inputThreeRef.current?.click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number): void => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width === 800 && img.height === 450) {
          // 圖片尺寸符合要求，設置狀態並顯示預覽
          if (id === 0) {
            setSelectedOneFile(file);
          } else if (id === 1) {
            setSelectedTwoFile(file);
          } else if (id === 2) {
            setSelectedThreeFile(file);
          }
        } else {
          // 圖片尺寸不符合要求，顯示錯誤提示
          toast("error", "圖片尺寸必須為 800x450px");
        }
      };
      img.onerror = () => {
        toast("error", "無法讀取圖片，請嘗試其他圖片");
      };
      if (e.target?.result) {
        img.src = e.target.result.toString();
      }
    };
    reader.onerror = () => {
      toast("error", "文件讀取錯誤");
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (item: AdvertiseType, id: string, value: string) => {
    setAdvertise(advertise.map(i => i.id === item.id ? { ...i, title: item.title, enable: item.enable, link: value } : i))
  }

  const handleAdvertisementEnableChange = (item: AdvertiseType, id: string, value: boolean) => {
    setAdvertise(advertise.map(i => i.id === item.id ? { ...i, title: item.title, link: item.link, enable: value?'true':'false'} : i))
  }

  // id:  固定用 0, 1, 2(目前只有3個廣告圖片)
  // title: 標題
  // link: 點下連結之後跳轉的位置
  // file:檔案
  // enable: 是否啟用該廣告

  const edit_info = (item: AdvertiseType, id: string) => {
    let file ;
    if (id === '0') {
      file = selectedOneFile
    } else if (id === '1') {
      file = selectedTwoFile
    } else if (id === '2') {
      file = selectedThreeFile
    }
    Api.update_advertisement(item.id, item.title, item.link, item.enable, file).then((res)=>{
      console.log(res);
      toast("success", "修改成功");
    }).catch((err) => {
      const message = getErrorMessage(err, "修改失敗");
      toast("error", message);
    });
  }


  useEffect(() => {
    Api.get_advertisement().then((res) => {
      setAdvertise(res)
      res.forEach((item: AdvertiseType) => {
        if (item.id === '0') {
          setPreviewOne(item.adImgUrl)
        } else if (item.id === '1') {
          setPreviewTwo(item.adImgUrl)
        } else if (item.id === '2') {
          setPreviewThree(item.adImgUrl)
        }
      })
    })
  }, [])


  // 還不清楚要怎麼用
  useEffect(() => {
    if (selectedOneFile) {
      const objectUrl = URL.createObjectURL(selectedOneFile);
      setPreviewOne(objectUrl);
    } else {
      setPreviewOne(img);
    }
  }, [selectedOneFile]);

  useEffect(() => {
    if (selectedTwoFile) {
      const objectUrl = URL.createObjectURL(selectedTwoFile);
      setPreviewTwo(objectUrl);
    } else {
      setPreviewTwo(img);
    }
  }, [selectedTwoFile]);

  useEffect(() => {
    if (selectedThreeFile) {
      const objectUrl = URL.createObjectURL(selectedThreeFile);
      setPreviewThree(objectUrl);
    } else {
      setPreviewThree(img);
    }
  }, [selectedThreeFile]);

  return (
    <div>
      <PageContainer>
        <PageHeader menuIconName="settings" title="廣告圖片" />

        <SimpleGrid spacing={4} templateColumns='repeat(2,1fr)'>
          {
            advertise.map((item, index) => (
              <Card key={item.id}>
                <CardHeader>
                  {index === 0 && <Heading size='md'> 首頁 </Heading>}
                  {index === 1 && <Heading size='md'> 登入 </Heading>}
                  {index === 2 && <Heading size='md'> 任一功能畫面 </Heading>}
                </CardHeader>
                <CardBody style={{ paddingTop: '0' }}>
                  {/* <p>{item.title}</p> */}
                  {index === 0 && <ChakraImage src={previewOne} alt='廣告' w="100%" h="320px" objectFit={'contain'} />}
                  {index === 1 && <ChakraImage src={previewTwo} alt='廣告' w="100%" h="320px" objectFit={'contain'} />}
                  {index === 2 && <ChakraImage src={previewThree} alt='廣告' w="100%" h="320px" objectFit={'contain'} />}
                  <Input style={{ marginTop: '20px' }} defaultValue={item.link} onChange={(e) => handleInputChange(item, item.id, e.target.value)} />
                  <Checkbox style={{ marginTop: '20px' }} defaultChecked={item.enable === 'true'} onChange={(e) => handleAdvertisementEnableChange(item, item.id, e.target.checked)} size='lg'>啟用</Checkbox>
                </CardBody>
                <CardFooter style={{ gap: '12px', paddingTop: '0' }}>
                  <Button variant="customOutline" w="100%" onClick={() => handleUploadFileButton(index)}> 上傳圖片 </Button>
                  <Button variant="customSolid" w="100%" onClick={() => edit_info(item, item.id)}> 更新 </Button>
                </CardFooter>
                {index === 0 && <input style={{ display: 'none' }} type="file" onChange={(e) => handleChange(e, index)} accept='image/*' ref={inputOneRef} />}
                {index === 1 && <input style={{ display: 'none' }} type="file" onChange={(e) => handleChange(e, index)} accept='image/*' ref={inputTwoRef} />}
                {index === 2 && <input style={{ display: 'none' }} type="file" onChange={(e) => handleChange(e, index)} accept='image/*' ref={inputThreeRef} />}
              </Card>
            ))
          }
        </SimpleGrid>
      </PageContainer>
    </div>
  )
}

export default FunctionModule
