import {GridItem} from "@chakra-ui/react";
import React from "react";

function DtlGridBlank() {
  return (
    <>
      <GridItem />
      <GridItem />
    </>
  );
}

export default DtlGridBlank;
