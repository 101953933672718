import {Spinner} from '@chakra-ui/react'

let LoadingComponent =()=>{
  return(
    <div className={['column'].join(' ')} style={{position: 'fixed', left: 0, top: 0, width: '100%', height: '100%'}}>

            <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{position: 'fixed', left: '0', top: '0', width: '100%', height: '100%', minHeight: '400px'}}>
              <div className={['col-auto', 'row', 'items-center', 'justify-center'].join(' ')} style={{width: '250px', height: '100px', backgroundColor: '#000000aa', border: '1px solid white', color: 'white', borderRadius: '8px'}}>
                <Spinner className='col-auto' style={{height: '40px', width: '40px', marginRight: '15px'}}/>
                <div className='col-auto' style={{fontSize: '20px'}}>載入中...</div>
              </div>
            </div>

    </div>
  )
}

export default LoadingComponent