// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.col-auto.login_form-container__2IiM9{
  width: 413px;
  /* height: 390px; */

  background-color: white;

  border-radius: 10px;
  border: 1px solid #e0e0e0;

  border-radius: 10px;

  padding: 25px;
}

.col-auto.login_form-title__oGUUH{
  font-size: 22.5px;
  margin-top: 5px;

  padding-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
}

.login_controller-label__eBzUf{
  font-size: 18px;
}

.col-auto.login_input-box__B2E7d{
  height: 51px;
  color: black;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #757575;
  padding: 12px;

  margin-top: 8px;
}
.login_input-box__B2E7d.login_error__60N1Z{
  color: #f33223;
  border: 1px solid #f33223;
}
.login_input-box__B2E7d.login_error__60N1Z::placeholder{
  color: #f33223;
  opacity: 0.5;
}


.col-auto.login_login-btn__jNaEa{
  margin-top: 30px;
  background-color: #2a3949;
  color: white;

  font-size: 18px;
  height: 40px;

  border-radius: 4px;
}
.login_login-btn__jNaEa:hover{
  filter: brightness(1.5);
}
.login_login-btn__jNaEa.login_busy__cFlrQ{
  pointer-events: none;
}
.login_login-btn__jNaEa.login_busy__cFlrQ:hover{
  filter: unset;
}

.login_caveat-label__YFmbW{
  color: #f33223;
  font-size: 14.4px;
  margin-top: 8px;

}`, "",{"version":3,"sources":["webpack://./src/pages/css/login.module.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,mBAAmB;;EAEnB,uBAAuB;;EAEvB,mBAAmB;EACnB,yBAAyB;;EAEzB,mBAAmB;;EAEnB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,eAAe;;EAEf,oBAAoB;EACpB,gCAAgC;AAClC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;;EAEb,eAAe;AACjB;AACA;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,YAAY;AACd;;;AAGA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;;EAEZ,eAAe;EACf,YAAY;;EAEZ,kBAAkB;AACpB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,eAAe;;AAEjB","sourcesContent":["\n:global(.col-auto).form-container{\n  width: 413px;\n  /* height: 390px; */\n\n  background-color: white;\n\n  border-radius: 10px;\n  border: 1px solid #e0e0e0;\n\n  border-radius: 10px;\n\n  padding: 25px;\n}\n\n:global(.col-auto).form-title{\n  font-size: 22.5px;\n  margin-top: 5px;\n\n  padding-bottom: 10px;\n  border-bottom: 1px solid #bdbdbd;\n}\n\n.controller-label{\n  font-size: 18px;\n}\n\n:global(.col-auto).input-box{\n  height: 51px;\n  color: black;\n  font-size: 18px;\n  border-radius: 5px;\n  border: 1px solid #757575;\n  padding: 12px;\n\n  margin-top: 8px;\n}\n.input-box.error{\n  color: #f33223;\n  border: 1px solid #f33223;\n}\n.input-box.error::placeholder{\n  color: #f33223;\n  opacity: 0.5;\n}\n\n\n:global(.col-auto).login-btn{\n  margin-top: 30px;\n  background-color: #2a3949;\n  color: white;\n\n  font-size: 18px;\n  height: 40px;\n\n  border-radius: 4px;\n}\n.login-btn:hover{\n  filter: brightness(1.5);\n}\n.login-btn.busy{\n  pointer-events: none;\n}\n.login-btn.busy:hover{\n  filter: unset;\n}\n\n.caveat-label{\n  color: #f33223;\n  font-size: 14.4px;\n  margin-top: 8px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-container": `login_form-container__2IiM9`,
	"form-title": `login_form-title__oGUUH`,
	"controller-label": `login_controller-label__eBzUf`,
	"input-box": `login_input-box__B2E7d`,
	"error": `login_error__60N1Z`,
	"login-btn": `login_login-btn__jNaEa`,
	"busy": `login_busy__cFlrQ`,
	"caveat-label": `login_caveat-label__YFmbW`
};
export default ___CSS_LOADER_EXPORT___;
