import {Tab, TabList, Tabs, Text} from "@chakra-ui/react";
import PageHeader, {PageHeaderProps} from "common/PageHeader";
import React, {useMemo} from "react";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import PathDefinition from "routes/RoutePathDefinition";

export type ActivityDetailHeaderProps = Pick<PageHeaderProps, "toolbar"> & {
  subTitle?: string;
};

const TabPathMap = [PathDefinition.ACTIVITY_DETAIL.path, PathDefinition.ACTIVITY_SIGNUP.path];

function ActivityDetailHeader({ toolbar, subTitle }: ActivityDetailHeaderProps) {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { tabIndex, subPageTitles } = useMemo<{ tabIndex: number; subPageTitles: string[] }>(() => {
    let tabIndex = 0;
    let subPageTitles = ["活動詳情"];
    const pathname = location.pathname;
    if (pathname.includes("/sign-up")) {
      tabIndex = 1;
      subPageTitles = ["報名列表"];
    } else if (pathname.includes("/detail/edit")) {
      subPageTitles.push("編輯");
    }
    return { tabIndex, subPageTitles };
  }, [location.pathname]);
  const handleTabsChange = (index: number) => {
    navigate(generatePath(TabPathMap[index], { id }));
  };
  return (
    <>
      <PageHeader menuIconName="activity" title={["全部活動列表", ...subPageTitles]} toolbar={toolbar} />
      {subTitle && (
        <Text fontSize="22.5px" mb={3}>
          {subTitle}
        </Text>
      )}
      <Tabs colorScheme="primary" variant="customLine" defaultIndex={tabIndex} onChange={handleTabsChange} mb={5}>
        <TabList>
          <Tab>活動詳情</Tab>
          <Tab>報名列表</Tab>
        </TabList>
      </Tabs>
    </>
  );
}

export default ActivityDetailHeader;
