import style from '../css/student_detail_panel.module.css'

import radio_true from '../../svg-files/radio-true.svg'
import radio_false from '../../svg-files/radio-false.svg'
import dropdown_calendar from '../../svg-files/calendar-black.svg'
import dropdown_arrow from '../../svg-files/dropdown_arrow.svg'
import green_check from '../../svg-files/green-check.svg'
import red_cross from '../../svg-files/red-cross.svg'

import {Menu, MenuButton, MenuList, Spinner, useDisclosure, useToast} from '@chakra-ui/react'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import MenuListDatePicker from './MenuListDatePickerV2'
import MenuListCountyPicker from './MenuListCountyPicker'
import MenuListCityPicker from './MenuListCityPicker'

import twzipcode from 'twzipcode-data'

import api from '../../api/Api'
import LoadingComponent from './LoadingComponent'
import WarningDialogComponent from './WarningDialogComponent'

let StudentDetailEditPanelComponent =({cancel, save})=>{

  const toast = useToast()
  const disclosure =useDisclosure()
  const zipdata =twzipcode()

  const loading =useState(true)
  const params =useParams()
  const memberdata =useState({})
  const subscriptiondata =useState(undefined)
  const agreementdata =useState(undefined)

  const [busyUpdate, setBusyUpdate] =useState(false)

  let company_type =useState([])
  let company_id_map =useState({})

  let sub_menu_busy =useState(false)

  const map_zipcode_from_id ={}
  const map_zipcode_from_code ={}
  const map_zipcode_from_county={}
  for(let i=0;i<zipdata.zipcodes.length;++i){
    if (map_zipcode_from_id.hasOwnProperty(zipdata.zipcodes[i]['id'])===true){
      console.warn('43 - found duplicated zipcode id : '+zipdata.zipcodes[i]['id'])
    }
    map_zipcode_from_id[zipdata.zipcodes[i]['id']] =zipdata.zipcodes[i]

    if (map_zipcode_from_code.hasOwnProperty(zipdata.zipcodes[i]['zipcode'])===false){
      map_zipcode_from_code[zipdata.zipcodes[i]['zipcode']] =[]  
    }
    map_zipcode_from_code[zipdata.zipcodes[i]['zipcode']].push(zipdata.zipcodes[i])

    if (map_zipcode_from_county.hasOwnProperty(zipdata.zipcodes[i]['county'])===false){
      map_zipcode_from_county[zipdata.zipcodes[i]['county']] =[]
    }
    map_zipcode_from_county[zipdata.zipcodes[i]['county']].push(zipdata.zipcodes[i])

  }

  


  useEffect(()=>{
    if (params.hasOwnProperty('id')===true){
      loading[1](true)
      api.get_company_types().then((res)=>{
        company_type[1](res)

        let tmp_company_id_map ={}
        for(let i=0;i<res.length;++i){
          tmp_company_id_map[res[i]['id']] =res[i]['label']
        }
        company_id_map[1](tmp_company_id_map)

        return api.get_subscription_status(params.id)
      }).then((res)=>{
        subscriptiondata[1](res)

        return api.get_agreement_status(params.id)
      }).then((res)=>{
        agreementdata[1](res)
        
        return api.get_profile_detail(params.id)
      }).then((res)=>{

        memberdata[1](res)

        //update city options
        //...

        //update county options
        //...


      }).catch((e)=>{
        
      }).finally(()=>{
        loading[1](false)
      })
    }

    
  },[])

  let sub_company_type =useState([])

  useEffect(()=>{
    if (memberdata[0]['companyType']===undefined){
      return
    }

    if (memberdata[0]['companyType']==='99'){
      return
    }

    //console.log('69 - companyType changed to '+memberdata[0]['companyType'])
    sub_menu_busy[1](true)
    api.get_company_list(memberdata[0]['companyType']).then((res)=>{
      sub_company_type[1](res)

      let found_item =false
      for(let i=0;i<res.length;++i){
        if (res[i].name===memberdata[0]['company']){
          //console.log('87 - found sub item : '+res[i].name)
          found_item =true
          break
        }
      }
      if (found_item===false){
        handleChange(res[0]['name'], 'company')
      }

    }).finally(()=>{
      sub_menu_busy[1](false)
    })

  },[memberdata[0]['companyType']])

  let handleChange =(value, dataid)=>{
    let obj ={[dataid]: value}
    memberdata[1]({...memberdata[0], ...obj})
  }

  let handelNumberChange =(value, dataid)=>{
    let number_regex =/^\d+$/
    if (number_regex.test(value)===false && value !==''){
      return
    }

    handleChange(value, dataid)
  }

  const layout_model =[
    [
      {type: 'input', label: '姓名', dataid:'chName'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '結業證號', dataid:'classNum'}, {type: 'spacer'}, {type: 'input', label: '結業訓練機構', dataid:''},
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '中文姓名', dataid:'chName'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input', label: '英文姓', dataid:'SurName'}, {type: 'spacer'}, {type: 'input', label: '英文名', dataid:'GivenName'},
    ],
    [{type: 'empty'},],
    [
      /*{type: 'radio', label: '是否持CFP/AFP', radio_opts:[{'是':true}, {'否':false}], dataid: 'certNum'}, {type: 'spacer'}, */{type: 'input', label: 'CFP/AFP 編號', dataid:'certNum'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '身分證字號', dataid:'idNum'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'date', label: '出生年月日', dataid:'birthday'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [{type: 'empty'},],
    [
      {type: 'menu', label: '現職服務單位類別', menu_opts:()=>{return company_type[0]}, dataid: 'companyType'}, {type: 'spacer'}, {type: 'rwd-menu', label: '現職服務單位', menu_opts:()=>{return sub_company_type[0]}, parentdataid: 'companyType', dataid: 'company'},
    ],
    [
      {type: 'date', label: '本工作到職日', dataid:'workDate'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input_number', label: '每週工作時數', dataid:'workHoursPerWeek'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input_number', label: '總計年資', dataid:'totalYears'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'textarea', label: '簡述實質工作內容', dataid:'description'},
    ],
    [{type: 'empty'},],
    // [
    //   {type: 'input', label: '公司', dataid:'companyArea'}, {type: 'spacer'}, {type: 'empty'}
    // ],
    // [
    //   {type: 'input', label: '住家', dataid:'homeArea'}, {type: 'spacer'}, {type: 'empty'}
    // ],
    [
      {type: 'addr-input', label: '公司地址', dataid1:'companyAddress', dataid2: 'companyArea', dataid3: 'companyZipcode'},
    ],
    [
      {type: 'addr-input', label: '住家地址', dataid1:'homeAddress', dataid2: 'homeArea', dataid3: 'homeZipcode'},
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '收據抬頭', dataid:'vatTitle'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input', label: '統一編號', dataid:'vatNumber'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [{type: 'empty'},],
    [
      {type: 'input', label: '最高學歷', menu_opts:[], dataid: 'eduLevel'}, {type: 'spacer'}, {type: 'input', label: '學位取得年度', menu_opts:[], dataid: 'graduateYear'},
    ],
    [
      {type: 'input', label: '畢業學校', menu_opts:[], dataid: 'school'}, {type: 'spacer'}, {type: 'input', label: '科系', menu_opts:[], dataid: 'eduClass'},
    ],
    [{type: 'empty'},],
    [
      {type: 'input_number', label: '公司電話', menu_opts:[], dataid: 'workPhone'}, {type: 'spacer'}, {type: 'input_number', label: '住家電話', menu_opts:[], dataid: 'homePhone'},
    ],
    [
      {type: 'input_number', label: '行動電話', dataid:'mobile'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input', label: '電子郵件信箱', dataid:'email'}, {type: 'spacer'}, {type: 'empty'}
    ],
    [{type: 'empty'},],
    [{type: 'head', label: '個人資料對外授權',}],
    [{type: 'narrow-empty',}],
    [{type: 'radio_ext_arr', label: '持證人榜單', ext_arr:[
      [
        {ext: '姓名', width: '80px', marginLeft: '0px', radio_opts:[{'同意':1}, {'不同意':0}], dataid: 'publish_name'},
        {ext: '現職任職公司', width: '115px', marginLeft: '64px', radio_opts:[{'同意':1}, {'不同意':0}], dataid: 'publish_company'},
      ]
    ]}],
    [{type: 'radio_ext_arr', label: '通知任職公司', ext_arr:[
      [
        {ext: '姓名', width: '80px', marginLeft: '0px', radio_opts:[{'同意':1}, {'不同意':0}], dataid: 'notify_company'},
      ]
    ]}],
    [{type: 'radio_ext_arr', label: '協會官網', ext_arr:[
      [
        {ext: '姓名', width: '80px', marginLeft: '0px', radio_opts:[{'同意':1}, {'不同意':0}], dataid: 'display_name'},
        {ext: '現職任職公司', width: '115px', marginLeft: '64px', radio_opts:[{'同意':1}, {'不同意':0}], dataid: 'display_company'},
      ],[
        {ext: '公司電話', width: '80px', marginLeft: '0px', radio_opts:[{'同意':1}, {'不同意':0}], dataid: 'display_workphone'},
        {ext: '行動電話', width: '115px', marginLeft: '64px', radio_opts:[{'同意':1}, {'不同意':0}], dataid: 'display_mobile'},
      ],[
        {ext: '電子信箱', width: '80px', marginLeft: '0px', radio_opts:[{'同意':1}, {'不同意':0}], dataid: 'display_email'},
      ]
      
    ]}],
    [{type: 'empty',}],
    [{type: 'head', label: '計算機訂閱狀態',}],
    [{type: 'narrow-empty',}],
    // [{type: 'radio_ext_arr', label: '訂閱方案', ext_arr:[
    //   [
    //     {ext: '', width: '0px', marginLeft: '0px', radio_opts:[{'一年':1}, {'兩年':2}, {'三年':3}], dataid: ''},
    //   ]
    // ]}],
    // [{type: 'indent-label', label: '有效日期', data: ''}],

  ]
  return (
    <div className={['colume', style['container']].join(' ')} style={{
        marginTop: '18px', marginBottom: '26px', 
        paddingTop: '30px', paddingLeft: '60px', paddingRight: '60px', paddingBottom: '40px', 
        fontSize: '18px',
        position: 'relative',
      }}>
        <div className={['col-auto', 'column'].join(' ')} style={{maxWidth: '866px'}}>
          {
            layout_model.map((v,idx)=>{
              return (
                <div key={idx} className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
                  <div className={['col', 'row'].join(' ')}>
                    {
                      v.map((subv, subidx)=>{
                        if (subv.type==='input_number'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <input className={['col-auto']} onChange={(e)=>{handelNumberChange(e.target.value, subv.dataid)}} value={(memberdata[0][subv.dataid]===null||memberdata[0][subv.dataid]===undefined)?'':memberdata[0][subv.dataid]} type='text' style={{marginTop: '8px', height: '50px', borderRadius: '5px', border: '1px solid #757575', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                            </div>                        
                          )
                        }else
                        if (subv.type==='input'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <input className={['col-auto']} onChange={(e)=>{handleChange(e.target.value, subv.dataid)}} value={(memberdata[0][subv.dataid]===null||memberdata[0][subv.dataid]===undefined)?'':memberdata[0][subv.dataid]} type='text' style={{marginTop: '8px', height: '50px', borderRadius: '5px', border: '1px solid #757575', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                            </div>                        
                          )
                        }else
                        if (subv.type==='textarea'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <textarea className={['col-auto']} onChange={(e)=>{handleChange(e.target.value, subv.dataid)}} value={(memberdata[0][subv.dataid]===null||memberdata[0][subv.dataid]===undefined)?'':memberdata[0][subv.dataid]}  rows={2} style={{marginTop: '8px', borderRadius: '5px', border: '1px solid #757575', padding: '12px', fontSize: '18px'}}/>
                            </div>                        
                          )
                        }else
                        if (subv.type==='addr-input'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <div className={['row', 'items-center'].join(' ')} style={{marginTop: '8px'}}>
                                <input placeholder='郵遞區號' value={(memberdata[0][subv.dataid3]===undefined||memberdata[0][subv.dataid3]===null)?'':memberdata[0][subv.dataid3]} onChange={(e)=>{
                                  handleChange(e.target.value, subv.dataid3)

                                  //udpate area info
                                  let cc =map_zipcode_from_code[e.target.value]
                                  if (cc!==undefined && cc.length>0){
                                    let curr_selected_zipcodedata =map_zipcode_from_id[memberdata[0][subv.dataid2]]
                                    if (curr_selected_zipcodedata===undefined){
                                      //update zipcode
                                      let found_zipdata =cc[0]
                                      let obj ={[subv.dataid3]: found_zipdata.zipcode, [subv.dataid2]: found_zipdata.zipcode+found_zipdata.county+found_zipdata.city}
                                      memberdata[1]({...memberdata[0], ...obj})
                                      
                                      return
                                    }
                                    
                                    //update county options
                                    let found_available_city_list =[]
                                    for(let i=0;i<cc.length;++i){
                                      if (cc[i].county ===curr_selected_zipcodedata.county){
                                        found_available_city_list.push(cc[i])
                                      }
                                    }
                                    if (found_available_city_list.length===0){
                                      found_available_city_list.push(cc[0])
                                    }

                                    //update city options
                                    let found_zipcode_data =found_available_city_list[0]
                                    for(let i=0;i<found_available_city_list.length;++i){
                                      if (found_available_city_list[i].city ===curr_selected_zipcodedata.city){
                                        found_zipcode_data =found_available_city_list[i]
                                        break
                                      }
                                    }

                                    //update selected option
                                    let obj ={[subv.dataid3]: found_zipcode_data.zipcode, [subv.dataid2]: found_zipcode_data.zipcode+found_zipcode_data.county+found_zipcode_data.city}
                                    memberdata[1]({...memberdata[0], ...obj})


                                  }else{
                                    let obj ={[subv.dataid3]: e.target.value, [subv.dataid2]: undefined}
                                    memberdata[1]({...memberdata[0], ...obj})
                                  }
                                  

                                }} className={['col-auto']} type='number' style={{width: '120px', height: '50px', borderRadius: '5px', border: '1px solid #757575', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                                <Menu matchWidth>
                                  {({ isOpen, onClose }) => (
                                    <>
                                    <MenuButton className={['col', style['custom-outline-button']].join(' ')} style={{marginLeft: '24px', height: '50px'}}>
                                      <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')} style={{}}>
                                        <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none', color: 'black'}}>{(map_zipcode_from_id[memberdata[0][subv.dataid2]]===undefined||memberdata[0][subv.dataid2]===undefined)?'請選擇縣市':map_zipcode_from_id[memberdata[0][subv.dataid2]]?.county}</label>
                                        <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                                      </div>
                                    </MenuButton>
                                    <MenuListCountyPicker isOpen={isOpen} onClose={onClose} map_zipcode_from_code={map_zipcode_from_code} map_zipcode_from_id={map_zipcode_from_id} selected={memberdata[0][subv.dataid2]} update={(new_id)=>{
                                      //console.log('323 - update zipcode data : '+new_id)

                                      //update county
                                      let curr_selected_zipcodedata =map_zipcode_from_id[memberdata[0][subv.dataid2]]
                                      let candidate_list =map_zipcode_from_county[new_id]

                                      //update region list
                                      let found_zipdata =candidate_list[0]
                                      for(let i=0;i<candidate_list.length;++i){
                                        if (candidate_list[i].city ===curr_selected_zipcodedata?.city){
                                          found_zipdata =candidate_list[i]
                                          break
                                        }
                                      }

                                      //update zipcode
                                      let obj ={[subv.dataid3]: found_zipdata.zipcode, [subv.dataid2]: found_zipdata.zipcode+found_zipdata.county+found_zipdata.city}
                                      memberdata[1]({...memberdata[0], ...obj})

                                    }}/>
                                    </>
                                  )}
                                </Menu>
                                <Menu matchWidth>
                                  {({ isOpen, onClose }) => (
                                    <>
                                    <MenuButton className={['col', style['custom-outline-button']].join(' ')} style={{marginLeft: '24px', height: '50px'}}>
                                      <div className={['col-auto', 'row', 'justify-between', 'items-center', ].join(' ')} style={{}}>
                                        <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none', color: 'black'}}>{(memberdata[0][subv.dataid2]===undefined||map_zipcode_from_id[memberdata[0][subv.dataid2]]===undefined)?'請選擇區域':map_zipcode_from_id[memberdata[0][subv.dataid2]]?.city}</label>
                                        <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                                      </div>
                                    </MenuButton>
                                    <MenuListCityPicker isOpen={isOpen} onClose={onClose} map_zipcode_from_county={map_zipcode_from_county} map_zipcode_from_code={map_zipcode_from_code} map_zipcode_from_id={map_zipcode_from_id} selected={memberdata[0][subv.dataid2]} update={(new_id)=>{
                                      //console.log('339 - update zipcode data : '+new_id)

                                      //update zip code
                                      let curr_selected_zipcodedata =map_zipcode_from_id[memberdata[0][subv.dataid2]]
                                      let candidate_list =map_zipcode_from_county[curr_selected_zipcodedata.county]
                                      let found_zipdata =undefined
                                      for(let i=0;i<candidate_list.length;++i){
                                        if (candidate_list[i].city ===new_id){
                                          found_zipdata =candidate_list[i]
                                          break
                                        }
                                      }

                                      let obj ={[subv.dataid3]: found_zipdata.zipcode, [subv.dataid2]: found_zipdata.zipcode+found_zipdata.county+found_zipdata.city}
                                      memberdata[1]({...memberdata[0], ...obj})

                                    }}/>
                                    </>
                                  )}
                                </Menu>
                              </div>
                              <input placeholder='請輸入地址' value={(memberdata[0][subv.dataid1]===undefined||memberdata[0][subv.dataid1]===null)?'':memberdata[0][subv.dataid1]} onChange={(e)=>{
                                handleChange(e.target.value, subv.dataid1)
                                
                              }} className={['col-auto']} style={{height: '50px', borderRadius: '5px', border: '1px solid #757575', marginTop: '14px', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                            </div>
                          )
                        }else
                        if (subv.type==='spacer'){
                          return(
                            <div key={subidx} className={'col-auto'} style={{width: '40px'}}></div>
                          )
                        }else
                        if (subv.type==='narrow-empty'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '0px'}}></div>
                          )
                        }else
                        if (subv.type==='empty'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '26px'}}></div>
                          )
                        }else
                        if (subv.type==='radio'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{color: 'black', marginTop: '8px', marginLeft: '-20px', height: '50px'}}>
                              {
                                subv.radio_opts.map((radiov, radioidx)=>{
                                  return (
                                    <button key={radioidx} className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginLeft: '20px'}}>
                                      <img alt="" className={['col-auto'].join(' ')} src={ ((memberdata[0][subv.dataid]!==undefined && memberdata[0][subv.dataid]!==null && memberdata[0][subv.dataid].length>0)===radiov[Object.keys(radiov)[0]])?radio_true:radio_false} style={{width: '20px', height: '20px', marginRight: '8px'}}/>
                                      <div className={['col-auto'].join(' ')} >{Object.keys(radiov)[0]}</div>
                                    </button>
                                  )
                                })
                              }
                              </div>
                            </div>                        
                          )
                        }else
                        if (subv.type==='date'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '85px'}}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <Menu>
                                {({ isOpen, onClose })=>(
                                  <>
                                    <MenuButton className={['col-auto', style['custom-outline-button']].join(' ')} style={{height: '50px', marginTop: '8px'}}>
                                      <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')} >
                                        <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none', color: 'black'}}>{memberdata[0][subv.dataid]}</label>
                                        <img alt="" className={['col-auto'].join(' ')} src={dropdown_calendar} style={{width: '16.67px', height: '18.33px', marginLeft: '9.67px'}}/>
                                      </div>
                                    </MenuButton>
                                    <MenuListDatePicker isOpen={isOpen} onClose={onClose} dateState={memberdata[0][subv.dataid]} handleDayChange={(date_str)=>{
                                      handleChange(date_str, subv.dataid)

                                    }}/>
                                  
                                  </>
                                )}
                              </Menu>
                              
                            </div>
                          )
                        }else
                        if (subv.type==='menu'){
                          return(
                            <div key={subidx} className={['col', 'column'].join(' ')}>
                              <label className={['col-auto']}>{subv.label}</label>
                              <Menu matchWidth>
                                {({ isOpen, onClose }) => (
                                  <>
                                    <MenuButton className={['col-auto', style['custom-outline-button']].join(' ')} style={{marginTop: '8px',}}>
                                      <div className={['col-auto', 'row', 'justify-between', 'items-center', ].join(' ')} style={{height: '50px'}}>
                                        <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none', color: 'black'}}>{company_id_map[0][ memberdata[0][subv.dataid] ]===undefined?'選擇單位類別':company_id_map[0][ memberdata[0][subv.dataid] ]}</label>
                                        <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                                      </div>
                                    </MenuButton>
                                    <MenuList style={{minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px',}} className={['column'].join(' ')}>
                                      {
                                        subv.menu_opts().map((v,idx)=>{
                                          return(
                                            <button key={idx} className={['col-auto', 'row', 'items-center', style['custom-menu-item'], style[subv.menu_opts()[idx]['id']===memberdata[0][subv.dataid]?'active':'']].join(' ')} onClick={()=>{
                                              onClose()
                                              handleChange(subv.menu_opts()[idx]['id'], subv.dataid)
                                            }}>
                                              <label className={['col-auto']} style={{lineHeight: '100%'}}>{v.label}</label>
                                            </button>
                                          )
                                        })
                                      }

                                    </MenuList>
                                  </>
                                )}
                              </Menu>
                            </div>
                          )
                        }else
                        if (subv.type==='rwd-menu'){
                          return memberdata[0][subv.parentdataid]==='99'?(
                            (
                              <div key={subidx} className={['col', 'column'].join(' ')}>
                                <label className={['col-auto']}>{subv.label}</label>
                                <input className={['col-auto']} onChange={(e)=>{handleChange(e.target.value, subv.dataid)}} value={(memberdata[0][subv.dataid]===null||memberdata[0][subv.dataid]===undefined)?'':memberdata[0][subv.dataid]} type='text' style={{marginTop: '8px', height: '50px', borderRadius: '5px', border: '1px solid #757575', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                              </div>
                            )
                          ):(
                            (
                              <div key={subidx} className={['col', 'column'].join(' ')}>
                                <label className={['col-auto']}>{subv.label}</label>
                                <Menu matchWidth>
                                  {({ isOpen, onClose }) => (
                                    <>
                                      <MenuButton className={['col-auto', style['custom-outline-button']].join(' ')} style={{marginTop: '8px', width: '100%'}}>
                                        <div className={['col-auto', 'row', 'justify-between', 'items-center', 'no-wrap',].join(' ')} style={{height: '50px', minWidth: '0'}}>
                                          <label className={['col-auto', style['ellipsis']].join(' ')} style={{textAlign: 'left', pointerEvents: 'none', color: 'black', width: 'calc(100% - 8.33px - 13.83px)'}}>{sub_menu_busy[0]?'載入中...':memberdata[0][subv.dataid]}</label>
                                          <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                                        </div>
                                      </MenuButton>
                                      <MenuList style={{overflowY: 'auto', maxHeight: '400px', minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px',}} className={['column', 'no-wrap'].join(' ')}>
                                        {
                                          subv.menu_opts().map((v,idx)=>{
                                            return(
                                              <button key={idx} className={['col-auto', 'row', 'items-center', style['custom-menu-item'], style[subv.menu_opts()[idx]['name']===memberdata[0][subv.dataid]?'active':'']].join(' ')} onClick={()=>{
                                                onClose()
                                                handleChange(subv.menu_opts()[idx]['name'], subv.dataid)
                                              }} style={{height: 'unset'}}>
                                                <label className={['col-auto']} style={{height: 'unset'}}>{v.name}</label>
                                              </button>
                                            )
                                          })
                                        }

                                      </MenuList>
                                    </>
                                  )}
                                </Menu>
                              </div>
                            )
                          )
                        }else
                        if (subv.type==='head'){
                          return(
                            <div key={subidx} className={['col-auto', ].join(' ')} style={{color: '#212121', fontSize: '22.5px'}}>{subv.label}</div>
                          )
                        }else
                        if (subv.type==='indent-label'){
                          return(
                            <div key={subidx} className={['col-auto', 'row', 'no-wrap'].join(' ')} >
                              <div className={['col-auto'].join(' ')} style={{width: '159px', textAlign: 'right', }}>
                                {subv.label}
                              </div>
                              <label className={['col-auto']} style={{color: '#616161', marginLeft: '32px'}}>ABCDEF</label>
                            </div>                        
                          )
                        }else
                        if (subv.type==='radio_ext_arr'){
                          return(
                            <div key={subidx} className={['col-auto', 'row', 'no-wrap'].join(' ')} >
                              <div className={['col-auto'].join(' ')} style={{width: '159px', textAlign: 'right', }}>
                                {subv.label}
                              </div>
                              <div className={['col-auto', 'column'].join(' ')} style={{color: 'black', marginLeft: '32px'}}>
                              { 
                                subv.ext_arr.map((subsubv, subsubidx)=>{
                                  return (
                                    <div key={subsubidx} className={['col-auto', 'row'].join(' ')} style={{color: 'black', marginBottom: '14px'}}>
                                    {
                                      subsubv.map((rowv, rowidx)=>{
                                        return (
                                          <div key={rowidx} className={['col-auto', 'row'].join(' ')} style={{marginLeft: rowv.marginLeft}}>
                                            {rowv.ext!==''&&(
                                              <div className={['col-auto'].join(' ')} style={{width: rowv.width, color: '#616161', marginRight: '32px'}}>
                                                {rowv.ext}
                                              </div>
                                            )}
                                            <div className={['col-auto', 'row', 'no-wrap'].join(' ')} style={{color: '#616161', marginLeft: '-20px'}}>
                                            {
                                              rowv.radio_opts.map((rowsubv, rowsubidx)=>{
                                                return (
                                                  <div key={rowsubidx} className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginLeft: '20px'}}>
                                                    <img alt="" className={['col-auto'].join(' ')} src={((agreementdata[0]!==undefined && agreementdata[0][rowv.dataid]!==undefined && agreementdata[0][rowv.dataid]!==null && agreementdata[0][rowv.dataid]===1)===(rowsubv[Object.keys(rowsubv)[0]]===1))?radio_true:radio_false} style={{width: '20px', height: '20px', marginRight: '8px'}}/>
                                                    <div className={['col-auto'].join(' ')} >{Object.keys(rowsubv)[0]}</div>
                                                  </div>
                                                )
                                              })
                                            }
                                            </div>
                                          </div>

                                        )
                                          
                                      })
                                    }
                                    </div>
                                  )
                                })
                              }
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                </div>
              )
            })
          }

      <div className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
        <div className={['col-auto'].join(' ')} style={{width: '159px', textAlign: 'right', color: '#616161'}}>
          訂閱方案
        </div>                
        {
          subscriptiondata[0]===undefined?(
            <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginLeft: '32px'}}>-</div>
          ):(
            <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginLeft: '32px'}}>
              {subscriptiondata[0].title}
            </div>
          )
        }
      </div>
      <div className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
        <div className={['col-auto'].join(' ')} style={{width: '159px', textAlign: 'right', color: '#616161'}}>
        有效日期
        </div>                
        {
          subscriptiondata[0]===undefined?(
            <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginLeft: '32px'}}>-</div>
          ):(
            <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginLeft: '32px'}}>
              {subscriptiondata[0].expireAt}
            </div>
          )
        }
      </div>

          <div className={['col-auto', 'row'].join(' ')} style={{marginTop: '26px', marginBottom: '14px'}}>
            <button className={['col', style['custom-outline-button']].join(' ')} style={{padding: '0', border: '1px solid #0e4e96', borderRadius: '4px', height: '51px', color: '#0e4e96', fontSize: '18px'}} onClick={()=>{
              window.scrollTo(0, 0)
              cancel()
            }}>取消編輯</button>
            <div className={'col-auto'} style={{width: '20px'}}/>
            <button className={['col', 'row', 'items-center', 'justify-center', style['custom-button']].join(' ')} style={{backgroundColor: '#0e4e96', borderRadius: '4px', heidht: '40px', fontSize: '18px', color: 'white'}} onClick={()=>{
              if (busyUpdate===true){
                return
              }
              setBusyUpdate(true)
              disclosure.onOpen()
            }}>
              {busyUpdate?(<Spinner className={'col-auto'} style={{marginRight: '10px', width: 'var(--spinner-size)'}}/>):('')}
              <label className={'col-auto'} style={{pointerEvents: 'none'}}>儲存變更</label>
            </button>
          </div>
        </div>
      {loading[0]&&(
        <LoadingComponent />
      )}

      <WarningDialogComponent title={'請注意!'} message={'按下確定後，學員資料將被更新，<br/>請確認資訊是否正確無誤。'} disclosure={disclosure} handler={(cb)=>{
        console.warn(cb)
        if (cb===false){
          setBusyUpdate(false)
          return
        }
        save(memberdata[0], (success, res_data)=>{
          window.scrollTo(0, 0)
          setBusyUpdate(false)

          if (success===true){
            console.warn('222 - shot toast()')
            toast({
              containerStyle:{
                maxWidth: 'unset',
                minWidth: 'usnet',
                marginBottom: '20px',
              },
              render: () => (
                <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{paddingLeft: '16px', paddingRight: '16px', backgroundColor: 'white', height: '49px', borderRadius: '12px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'}}>
                  <img alt="" className={['col-auto'].join(' ')} src={green_check} style={{width: '20px', height: '20px', marginRight: '7px'}}/>
                  <label className={['col-auto'].join(' ')} style={{fontSize: '16px', color: 'black'}}>學員資料變更成功</label>
                </div>
              ),
            })
          }else{
            console.warn(res_data)
            console.warn('726 - shot toast()')
            toast({
              containerStyle:{
                maxWidth: 'unset',
                minWidth: 'usnet',
                marginBottom: '20px',
              },
              render: () => (
                <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{paddingLeft: '16px', paddingRight: '16px', backgroundColor: 'white', height: '49px', borderRadius: '12px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'}}>
                  <img alt="" className={['col-auto'].join(' ')} src={red_cross} style={{width: '20px', height: '20px', marginRight: '7px'}}/>
                  <label className={['col-auto'].join(' ')} style={{fontSize: '16px', color: 'black'}}>{res_data.response?.data}({res_data.message})</label>
                </div>
              ),
            })
          }

        })
      }}/>
    </div>
  )
}

export default StudentDetailEditPanelComponent