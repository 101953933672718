import {Button, Card, useDisclosure} from "@chakra-ui/react";
import ApiDefinition from "api/ApiDefinition";
import {getErrorMessage} from "common/errMsgHelper";
import NavButton from "common/NavButton";
import PageContainer from "common/PageContainer";
import UpdatedTimeBox from "common/UpdatedTimeBox";
import WarningDialog from "common/WarningDialog";
import {useAPIRequest, useCustomToast} from "hooks";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import PathDefinition from "routes/RoutePathDefinition";
import ActivityDetailGrid from "./detail/ActivityDetailGrid";
import ActivityDetailHeader from "./detail/ActivityDetailHeader";
import useFetchActivity from "./detail/useFetchActivity";

function ActivityDetail() {
  const { id } = useParams();
  const toast = useCustomToast();
  const disclosure = useDisclosure();
  const { loaded, data } = useFetchActivity();
  const navigate = useNavigate();
  const { processRequestWithId } = useAPIRequest();
  const handleConfirm = () => {
    processRequestWithId(ApiDefinition.DELETE_ACTIVITY, id)
      .then((res) => {
        toast("success", "刪除活動成功");
        navigate(generatePath(PathDefinition.ACTIVITY.path));
      })
      .catch((err) => {
        const message = getErrorMessage(err, "刪除活動失敗");
        toast("error", message);
        console.error(err);
      });
  };
  if (!loaded) {
    return <PageContainer>載入中...</PageContainer>;
  }
  return (
    <PageContainer>
      <ActivityDetailHeader
        subTitle={data?.name}
        toolbar={
          <>
            <NavButton variant="customSolid" to={PathDefinition.ACTIVITY.path}>
              上一頁
            </NavButton>
            <NavButton variant="customSolid" to={generatePath(PathDefinition.ACTIVITY_DETAIL_EDIT.path, { id })}>
              編輯
            </NavButton>
            <Button
              variant="customOutline"
              onClick={() => {
                disclosure.onOpen();
              }}
            >
              刪除
            </Button>
          </>
        }
      />
      <Card variant="custom" px={20} py={10}>
        {data && (
          <>
            <ActivityDetailGrid data={data} />
            <UpdatedTimeBox value={data.updatedAt} />
          </>
        )}
      </Card>
      <WarningDialog title="確定要刪除這筆活動嗎？" onConfirm={handleConfirm} {...disclosure} />
    </PageContainer>
  );
}

export default ActivityDetail;
