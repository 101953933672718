import {Button, HStack, useDisclosure, VStack} from "@chakra-ui/react";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import { useLoading } from "hooks";
import CertChangeModal from "pagesV2/training/CertChangeModal";
import CertChangeSuccModal from "pagesV2/training/CertChangeSuccModal";
import api from '../../api/Api'
import ReportStatisticOneExamPassModal from "./ReportStatisticOneExamPassModal";

function ExamStatisticReportComponent() {
  const { showLoading } = useLoading();
  const reportStatisticOneExamPassDisclosure = useDisclosure(); 
  
  const downloadEveryExam = () => {
    showLoading();
    api.get_report_statistics_every_exam().then((res) => {
      window.location.href = res;
    }).catch((e) => {
      console.log(e);
      let message = "錯誤:";
      if ("response" in e && e.response.data) {
        message = e.response.data;
      }
      if ("message" in e) {
        message += ` (${e.message})`;
      }
      //toast("error", e.message);
    }).finally(() => {
      showLoading(false);
      //loading[1](false)
    })
    return undefined
  }

  const downloadOwnCertificate = () => {
    showLoading();
    api.get_report_statistics_own_certificate().then((res) => {
      window.location.href = res;
    }).catch((e) => {
      console.log(e);
      let message = "錯誤:";
      if ("response" in e && e.response.data) {
        message = e.response.data;
      }
      if ("message" in e) {
        message += ` (${e.message})`;
      }
      //toast("error", e.message);
    }).finally(() => {
      showLoading(false);
      //loading[1](false)
    })
    return undefined
  }

  return (
    <PageContainer>
      <VStack spacing={3} align="start">
        <PageHeader
          menuIconName="exam"
          title="考試統計報表"
          toolbar={
            <HStack>
              <Button variant="customSolid" onClick={downloadEveryExam}>
                歷次考試統計
              </Button>
              <Button variant="customSolid" onClick={downloadOwnCertificate}>
                持證人數統計
              </Button>
              <Button variant="customSolid" onClick={reportStatisticOneExamPassDisclosure.onOpen}>
                通過率統計
              </Button>
            </HStack>
          }
        />
        {/* 搜尋列、表格放這裡 */}
      </VStack>
      <ReportStatisticOneExamPassModal {...reportStatisticOneExamPassDisclosure} />
    </PageContainer>
  );
}

export default ExamStatisticReportComponent;
