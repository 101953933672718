import DEF from "../ActionDefinition";

const initialState: AccountState = {
  token: "",
  name: "",
  id: "",
};

export default function reducer(state = initialState, action: LoadingAction) {
  switch (action.type) {
    case DEF.LOGIN:
      if (action.value) {
        const { id, token, name } = action.value;
        return { token, name, id };
      } else {
        return state;
      }

    case DEF.RESUME:
      if (action.value) {
        const { id, token, name } = action.value;
        return { token, name, id };
      } else {
        return state;
      }
    case DEF.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export interface AccountState {
  token: string;
  name: string;
  id: string;
}

export type LoadingActionType = "LOGIN" | "LOGOUT" | "RESUME";

export interface LoadingAction {
  type: LoadingActionType;
  value?: AccountState;
}
