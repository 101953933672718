import {FDatePicker} from "common/form";
import {FDatePickerProps} from "common/form/FDatePicker";
import dayjs from "dayjs";
import {Field, FieldProps, useFormikContext} from "formik";
import React from "react";

export type FMDatePickerProps = Omit<FDatePickerProps, "name" | "errorMessage" | "onChange"> & {
  name: string;
  outputFormat?: string;
};

export const parseDateForPicker = (value: any) => {
  const dayjsInput = value ? dayjs(value) : null;
  return dayjsInput && dayjsInput.isValid() ? dayjsInput.toDate() : null;
};

function FMDatePicker({
  name,
  outputFormat = "YYYY-MM-DD",
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  ...props
}: FMDatePickerProps) {
  const { setFieldValue } = useFormikContext();
  const handleChange = (date: Date) => {
    const dayjsInput = dayjs(date);
    if (dayjsInput.isValid()) {
      const mDate = dayjsInput.format(outputFormat);
      setFieldValue(name, mDate);
    }
  };
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { value, ...others } = field;
        const error = meta.touched && meta.error ? meta.error : undefined;
        const mValue = parseDateForPicker(field.value);
        return (
          <FDatePicker
            {...props}
            errorMessage={error}
            {...others}
            selected={mValue}
            onChange={handleChange}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            startDate={selectsStart ? mValue : startDate}
            endDate={selectsEnd ? mValue : endDate}
          />
        );
      }}
    </Field>
  );
}

export default FMDatePicker;
