import {
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    FormLabelProps,
    Input,
    InputProps,
} from "@chakra-ui/react";
import {ReactNode} from "react";

export type FTextFieldProps = InputProps & {
  formControlProps?: FormControlProps;
  label?: ReactNode;
  helperText?: string;
  errorMessage?: string;
  formLabelProps?: FormLabelProps;
} & Pick<FormControlProps, "width">;

function FTextField({
  formControlProps,
  label,
  helperText,
  errorMessage,
  formLabelProps,
  width = 400,
  ...props
}: FTextFieldProps) {
  return (
    <FormControl {...formControlProps} width={width}>
      <FormLabel variant="custom" {...formLabelProps}>
        {label}
      </FormLabel>
      <Input variant="custom" {...props} />
      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : <></>}
      {!errorMessage && helperText ? <FormHelperText>{helperText}</FormHelperText> : <></>}
    </FormControl>
  );
}

export default FTextField;
