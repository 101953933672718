import style from './../css/menu_list_date_picker.module.css'
import {MenuList,} from '@chakra-ui/react'
import {useState} from 'react'

let MenuListDatePicker =({onClose, yearState, monthState, enableState, handleDateChange})=>{
  const [enable_state, setEnableState] =enableState
  const [year, setYear] =yearState//useState(date.getFullYear())
  const [month, setMonth] =monthState//useState(date.getMonth()+1)
  const [mode, setMode] =useState(1) //0: day, 1: month, 2: year
  const [yearModeStartIdx, setYearModeStartIdx] =useState(2020)

  return(
    <MenuList style={{minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px', width: '240px', padding: '0'}} className={['column',].join(' ')}>
      
      {mode===2?(
        <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{height: '42px', fontSize: '14.4px', fontWeight: '500', color: 'white', backgroundColor: '#0e4e96', paddingLeft: '5px',}}>
          <button className={['col-auto', style['arrow-btn']].join(' ')} style={{height: '32px', width: '32px'}} onClick={()=>{
            setYearModeStartIdx(yearModeStartIdx-16)
          }}>←</button>
          <div className={['col-auto'].join(' ')} style={{marginLeft: '10px', marginRight: '10px'}}>{yearModeStartIdx} ~ {yearModeStartIdx+16}</div>
          <button className={['col-auto', style['arrow-btn']].join(' ')} style={{height: '32px', width: '32px'}} onClick={()=>{
            setYearModeStartIdx(yearModeStartIdx+16)
          }}>→</button>
        </div>
      ):(
        <div className={['col-auto', 'row', 'items-center', 'justify-between'].join(' ')} style={{height: '42px', fontSize: '14.4px', fontWeight: '500', color: 'white', backgroundColor: '#0e4e96', paddingLeft: '5px', paddingRight: '5px'}}>
          <div className={['col-auto']}>
            <button className={['col-auto', style['arrow-btn']].join(' ')} style={{height: '32px'}} onClick={()=>{
              setMode(2)
            }}>{year} 年</button>&nbsp;-&nbsp;{month}月
          </div>
          <button className={['col-auto', style['arrow-btn']].join(' ')} style={{height: '32px'}} onClick={()=>{
            onClose()
            setEnableState(false)
            handleDateChange(false)
          }}>清除</button>
        </div>
        
      )}
      {mode===2?(
        <div className={['col-auto', 'column',].join(' ')} style={{fontSize: '14.4px', padding: '5px',}}>
          {
            [...Array(4).keys()].map(row=>{
              return(
                <div key={row} className={['col-auto', 'row', 'items-center'].join(' ')} style={{fontSize: '14.4px', height: '32px'}}>
                {[...Array(4).keys()].map(col=>{
                  return (
                    <button key={col} className={['col', style['year-btn'], style[(yearModeStartIdx+(row*4+col))===year?'active':'']].join(' ')} onClick={()=>{
                      setYear(yearModeStartIdx+(row*4+col))
                      setMode(1)
                    }}>{yearModeStartIdx+(row*4+col)}</button>
                  )
                })}
                </div>
              )
            })
          }

        </div>
      ):(
        <div className={['col-auto', 'column',].join(' ')} style={{fontSize: '14.4px', padding: '5px',}}>
          {
            [...Array(3).keys()].map(row=>{
              return(
                <div key={row} className={['col-auto', 'row', 'items-center'].join(' ')} style={{fontSize: '14.4px', height: '32px'}}>
                {[...Array(4).keys()].map(col=>{
                  return (
                    <button key={col} className={['col', style['year-btn'], style[(1+(row*4+col))===month?'active':'']].join(' ')} onClick={()=>{
                      setMonth(1+(row*4+col))
                      setEnableState(true)
                      onClose()
                      handleDateChange(true, year, 1+(row*4+col))
                    }}>{1+(row*4+col)}月</button>
                  )
                })}
                </div>
              )
            })
          }
        </div>
      )}
    </MenuList>
  )
}

export default  MenuListDatePicker