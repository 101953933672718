import ApiDefinition from "api/ApiDefinition";
import MenuSelect, {MenuSelectProps} from "common/MenuSelect";
import {useFetchData} from "hooks";
import React, {useEffect, useMemo} from "react";

export interface CompanyTypeRow {
  id: string;
  label: string;
}

export type CompanyTypeSelectProps = Pick<MenuSelectProps, "onChange" | "value">;

function CompanyTypeSelect(props: CompanyTypeSelectProps) {
  const { fetchData, data } = useFetchData<CompanyTypeRow[]>({
    url: `${ApiDefinition.GET_COMPANY_TYPES.path}`,
  });
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = useMemo(
    () =>
      data
        ? data
            .filter((row) => Number(row.id) !== 0)
            .reduce(
              (previousValue, currentValue) => ({ ...previousValue, [Number(currentValue.id)]: currentValue.label }),
              {}
            )
        : {},
    [data]
  );
  return <MenuSelect minW={200} variant="custom" options={options} nullValueText="現職服務單位類別" {...props} />;
}

export default CompanyTypeSelect;
