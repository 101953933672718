import React from "react";
import {NumericFormat, NumericFormatProps} from "react-number-format";

export type NumericTextProps = Pick<
  NumericFormatProps,
  "value" | "prefix" | "suffix" | "decimalScale" | "fixedDecimalScale"
>;

function NumericText({ fixedDecimalScale = true, ...props }: NumericTextProps) {
  return (
    <NumericFormat {...props} displayType={"text"} thousandSeparator={true} fixedDecimalScale={fixedDecimalScale} />
  );
}

export default NumericText;
