import {
  VStack, Table, Button, Input,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer
} from "@chakra-ui/react";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import Api from "api/Api";
import { useEffect, useState } from 'react'
import { useCustomToast } from "hooks";
import { getErrorMessage } from "common/errMsgHelper";


interface navType {
  id: number
  text: string
  href: string
  isReadonly?: boolean
  isEditing?: boolean
}

const columns = ['標題', '網址', '']
const readonly = [true, true, true, true, true]

function FrontendHomeComponent() {
  const [nav, setNav] = useState<navType[]>([])

  const toast = useCustomToast();

  const edit_info = (item: navType) => {
    item.isEditing = true;
    setNav(nav.map(i => i.id === item.id ? { ...i, isEditing: true } : i));
  }

  const handleInputChange = (item: navType, field: 'text' | 'href', value: string) => {
    setNav(nav.map(i => i.id === item.id ? { ...i, [field]: value } : i));
  }

  const check = (item: navType) => {

    console.log(item);

    Api.update_nav(item.id, item.text, item.href).then((res) => {
      toast("success", "修改成功");
      setNav(nav.map(i => i.id === item.id ? { ...i, isEditing: false } : i));
    }).catch((err) => {
      const message = getErrorMessage(err, "修改失敗");
      toast("error", message);
    });
    item.isEditing = false;
  }


  useEffect(() => {
    Api.get_nav().then((res) => {
      res.links.forEach((item: navType, index: number) => {
        item.isReadonly = readonly[index]
        item.isEditing = false;
      });
      setNav(res.links)
    })
  }, [])

  // ExchangePricing 複製過來的

  return (
    <>
      <PageContainer>
        <VStack spacing={3} align="start">
          <PageHeader
            menuIconName="settings"
            title="前台首頁"
          />
        </VStack>
        <TableContainer>
          <Table variant='striped' colorScheme="gray" size='lg'>
            <Thead backgroundColor='primary.500'>
              <Tr>
                {
                  columns.map((column, index) => {
                    let width;
                    if (index === 0) width = '30%';
                    else if (index === 1) width = '60%';
                    else if (index === 2) width = '10%';
                    return <Th color='white' fontSize='lg' key={column} style={{ width: width }}>{column}</Th>
                  })
                }
              </Tr>
            </Thead>
            <Tbody>
              {
                nav.map((item, index) => (
                  <Tr key={item.id}>
                    <Td>
                      {item.isEditing ? (
                        <Input
                          defaultValue={item.text}
                          focusBorderColor="crimson"
                          isInvalid
                          errorBorderColor='crimson'
                          htmlSize={1}
                          onChange={(e) => handleInputChange(item, 'text', e.target.value)}
                        />
                      ) : (
                        <Input
                          defaultValue={item.text}
                          focusBorderColor="primary.500"
                          borderColor="gray"
                          htmlSize={1}
                          isReadOnly={item.isReadonly}
                        />
                      )}
                    </Td>
                    <Td>
                      {item.isEditing ? (
                        <Input
                          defaultValue={item.href}
                          focusBorderColor="crimson"
                          isInvalid
                          errorBorderColor='crimson'
                          htmlSize={1}
                          onChange={(e) => handleInputChange(item, 'href', e.target.value)}
                        />
                      ) : (
                        <Input
                          defaultValue={item.href}
                          focusBorderColor="primary.500"
                          borderColor="gray"
                          htmlSize={1}
                          isReadOnly={item.isReadonly}
                        />
                      )}
                    </Td>
                    <Td>
                      {item.isEditing ? (
                        <Button variant="customOutline" onClick={() => check(item)}>確認</Button>
                      ) : (
                        <Button variant="customSolid" onClick={() => edit_info(item)}>
                          修改
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))
              }
            </Tbody>
          </Table>
        </TableContainer>
      </PageContainer>
    </>
  )
}

export default FrontendHomeComponent
