import axios, { AxiosRequestConfig } from "axios";
import baseURL from "api/baseURL";
import useLoading from "./useLoading";
import { ApiDefinitionData } from "api/ApiDefinition";

const commonConfig: AxiosRequestConfig = {
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
  baseURL,
};

function useAPIRequest() {
  const { showLoading } = useLoading();

  const processRequestWithId = (def: ApiDefinitionData, id: any, data?: any, params?: any) => {
    return processRequest({ ...def, path: `${def.path}${id}` }, data, params);
  };

  const processRequest = (def: ApiDefinitionData, data?: any, params?: any) =>
    new Promise((resolve, reject) => {
      showLoading();
      const { path, method } = def;
      console.log(path,method);
      console.log(data,params);
      console.log(commonConfig);
      
      
      axios
        .request({
          ...commonConfig,
          params,
          url: path,
          method,
          data,
        })
        .then((res) => {    
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          showLoading(false);
        });
    });

  return { processRequest, processRequestWithId };
}

export default useAPIRequest;
