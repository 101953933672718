// ActivityForm 貼過來的
import { Button, HStack, VStack, Select, Flex, Spacer, Input } from "@chakra-ui/react";
import {
  FMTextField,
  FMEditor,
  DebugTool,
} from "common/formik";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useState, useEffect } from "react";
import { initalEmailValues as defaultInitialValues } from "../activity/commonValues";
import { ActivityEmailFormValue } from '../activity/type'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Api from "api/Api";
import { useAPIRequest, useCustomToast } from "hooks";
import ApiDefinition from "api/ApiDefinition";
import { getErrorMessage } from "common/errMsgHelper";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import PathDefinition from "routes/RoutePathDefinition";


export interface ActivityFormProps {
  initialValues?: ActivityEmailFormValue;
  onSubmit: (values: ActivityEmailFormValue, formikHelpers: FormikHelpers<ActivityEmailFormValue>) => void;
  onCancel: () => void;
  isEdit?: boolean;
}

export interface MyUploadAdapterType {
  loader: any; // 如果有具體的類型，這裡可以替換成具體的類型
  upload: () => Promise<{ default: string }>;
  abort: () => void;
}

function MyUploadAdapter(loader: any) {
  return {
    upload: () => {
      return loader.file.then((file: File) => new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('upload', file);
        fetch('YOUR_UPLOAD_ENDPOINT', {
          method: 'POST',
          body: formData,
        })
          .then(response => response.json())
          .then(result => {
            resolve({ default: result.url }); // 伺服器需要返回圖片的URL地址
          })
          .catch(reject);
      }));
    },
    abort: () => {
      // 這裡可以處理上傳中止的邏輯
    }
  };
}

export interface EmailType {
  id: number;
  title: string;
}


export interface EmailTemplateIdType {
  id: number;
  title: string;
  content: string;
}


function EmailSetupComponent({ onSubmit, onCancel, initialValues = defaultInitialValues, isEdit = false }: ActivityFormProps) {

  const [selectedOption, setSelectedOption] = useState('');
  const [editorData, setEditorData] = useState('<p>開始編輯吧!</p>');
  // const [subject, setSubject] = useState(''); // 主旨
  const [emailTypes, setEmailTypes] = useState<EmailType[]>([])
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplateIdType[]>([])
  const { processRequestWithId } = useAPIRequest();
  const toast = useCustomToast();
  const navigate = useNavigate();
  // const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSubject(event.target.value); // 更新主旨狀態
  // };

  const handleSubjectChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    // 更新對應的 emailTemplates 項目
    const updatedEmailTemplates = emailTemplates.map(template => {
      if (template.id === id) {
        return { ...template, title: event.target.value };
      }
      return template;
    });

    setEmailTemplates(updatedEmailTemplates);
  };


  const handleSubmit = () => {

    if (!emailTemplates[0]) {
      toast("error", "請選擇信件模板");
      return;
    }

    // 獲取 template_id
    const templateId = emailTemplates[0].id;

    const data = {
      "title": emailTemplates[0].title,
      "content": editorData
    }

    processRequestWithId(ApiDefinition.UPDATE_EMAIL, templateId, data)
      .then((res) => {
        toast("success", "修改成功");
        Api.get_email().then((res) => {
          setEmailTypes(res)
        })
      })
      .catch((err) => {
        const message = getErrorMessage(err, "修改失敗");
        toast("error", message);
        console.error(err);
      });
  };

  // Api.update_email(templateId, params).then((res) => {
  //   console.log(res)
  // })

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    // 假设value是直接的文本值而非HTML，或者您可以从HTML中提取文本
    setSelectedOption(value);
  };

  const handleButtonClick = () => {
    Api.get_email_template_id(selectedOption).then((res) => {
      setEmailTemplates(res)
      setEditorData(res[0].content)
    })
  };


  // const handleReady = (editor: any) => {
  //   console.log('Editor is ready to use!', editor);
  // };

  const handleChange = (event: any, editor: any) => {
    const data = editor.getData();
    setEditorData(data);

    // console.log({ event, editor, data });
  };

  const handleBlur = (event: any, editor: any) => {
    // console.log('Blur.', editor);
  };

  const handleFocus = (event: any, editor: any) => {
    // console.log('Focus.', editor);
  };

  const handleReady = (editor: any) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return MyUploadAdapter(loader);
    };
  };



  useEffect(() => {
    Api.get_email().then((res) => {
      // console.log(res);
      setEmailTypes(res)
    })
  }, [])


  return (
    <>
      <VStack align="start" mb={6} w="100%">
        <HStack width="100%">
          <Select placeholder='Select option' onChange={handleOptionChange} flex={3}>
            {
              emailTypes.map((item: EmailType, index: number) => {
                return <option key={index} value={item.id}>{item.title}</option>
              })
            }
          </Select>
          <Button variant="customSolid" flex={1} onClick={handleButtonClick}>選擇</Button>
        </HStack>
      </VStack>
      <VStack align="start" mb={6}>
        <HStack width="100%" alignItems="center">
          <div style={{ flexShrink: 0 }}>主旨：</div>
          {
            emailTemplates.length > 0 ?
              emailTemplates.map((item) => (
                <Input key={item.id} style={{ flexGrow: 1 }} value={item.title} onChange={(e) => handleSubjectChange(item.id, e)} />
              ))
              :
              <Input style={{ flexGrow: 1 }} />
          }
        </HStack>
      </VStack>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onReady={handleReady}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <HStack w="100%" spacing={3} mt={6}>
        <Button
          w="100%"
          variant="customOutline"
          onClick={() => {
            onCancel();
          }}
        >
          取消
        </Button>
        <Button onClick={handleSubmit} w="100%" variant="customSolid">
          確認修改
        </Button>
      </HStack>
    </>
  );
}

export default EmailSetupComponent