import {Box, Button, Card, Flex, HStack, Image, Text, VStack} from "@chakra-ui/react";
import React, {ChangeEvent, HTMLProps, useEffect, useRef, useState} from "react";

export type BannerUploaderProps = Pick<HTMLProps<HTMLInputElement>, "accept"> & {
  selectedFile?: File | null;
  onSelectFile: (file: File) => void;
  title: string;
  helperText?: string;
  initialImage?: string;
};

function BannerUploader({ accept, onSelectFile, title, helperText, selectedFile, initialImage }: BannerUploaderProps) {
  const [preview, setPreview] = useState(initialImage);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const mFiles = Array.from<File>(e.target.files || []);
    if (mFiles && mFiles.length > 0) {
      const file = mFiles[0];
      onSelectFile(file);
    }
  };
  const handleUploadFileButton = () => {
    inputRef.current?.click();
  };
  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    } else {
      setPreview(initialImage);
    }
  }, [initialImage, selectedFile]);
  return (
    <VStack w="100%" align="left">
      {preview ? (
        <Image src={preview} w="100%" borderRadius="8px" objectFit="cover" h={350} />
      ) : (
        <Card variant="uploader" w="100%">
          <HStack align="center" w="100%" h="100%">
            <VStack align="center" w="100%">
              <Text fontSize="22.5px" fontWeight={400}>
                {title}
              </Text>
              {helperText && (
                <Text fontSize="18px" color="secondary.600">
                  {helperText}
                </Text>
              )}
            </VStack>
          </HStack>
        </Card>
      )}
      <Text fontSize="18px" fontWeight="bold">
        {title}
      </Text>
      {helperText && (
        <Text ml={2} maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {helperText}
        </Text>
      )}
      <Flex alignItems="center" w="100%">
        <Button variant="customSolid" onClick={handleUploadFileButton} fontSize="15px" mr={3}>
          上傳圖片
        </Button>
        <Text fontSize="18px">{selectedFile && selectedFile.name}</Text>
      </Flex>
      <Box display="none">
        <input type="file" onChange={handleChange} accept={accept} ref={inputRef} />
      </Box>
    </VStack>
  );
}

export default BannerUploader;
