import {AlertStatus, useToast} from "@chakra-ui/react";
import ToastAlert from "common/ToastAlert";

function useCustomToast() {
  const toast = useToast({ containerStyle: { maxWidth: "unset", minWidth: "unset" } });
  const addCustomToast = (status: AlertStatus, title: string) => {
    toast({
      render: () => <ToastAlert status={status} title={title} />,
    });
  };
  return addCustomToast;
}

export default useCustomToast;
