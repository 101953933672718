/* eslint-disable no-unused-vars */
/**
 * 定義有哪些行為，統一觸發的類型
 */
enum Action {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  RESUME = "RESUME",
  LOADING_SHOW = "LOADING_SHOW",
  LOADING_DISMISS = "LOADING_DISMISS",
  LOADING_CLEAR = "LOADING_CLEAR",
  CLEAR_ALL = "CLEAR_ALL",
}
export default Action;
