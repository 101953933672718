import base_url from "api/baseURL";
import ApiDefinition from "./ApiDefinition";
import axios from "axios";
import cookies from "../store/Cookies";
// import {rejects} from "assert";

/**
 * 注意：
 * 此為舊API串接的方式，新的部分請改用hook (useAPIRequest, useFetchData, useFetchList) 取代\
 * 新增、修改、刪除 useAPIRequest
 * const { processRequest } = useFetchData();
 * processRequest(useFetchData.<你的API網址定義>, <表單內容(可有可無)>);
 * 取得列表 useFetchList
 * 取得單筆資料等 useFetchData
 */

export function login(user, pwd) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.LOGIN.path,
      method: ApiDefinition.LOGIN.method,
      headers: { "Content-Type": "application/json" },
      data: {
        username: user,
        password: pwd,
      },
    };
    axios
      .request(cfg)
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          reject();
          return;
        }

        let token = cookies.getCookies("rfa_auth");
        console.log(res.data, token);
        resolve({ ...res.data, token: token });
      })
      .catch((e) => {
        reject(e);
      });
  });
}


export function anotherLogin(token) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.LOGIN.path,
      method: ApiDefinition.LOGIN.method,
      headers: { 
        "Content-Type": "application/json", 
        "Authorization": token
      },
      data: {},
    };
    axios
      .request(cfg)
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          reject();
          return;
        }

        let token = cookies.getCookies("rfa_auth");
        console.log(res.data, token);
        resolve({ ...res.data, token: token });
      })
      .catch((e) => {
        reject(e);
      });
  });
}



export function logout() {
  let cfg = {
    withCredentials: true,
    baseURL: base_url,
    url: ApiDefinition.LOGOUT.path,
    method: ApiDefinition.LOGOUT.method,
  };
  return axios.request(cfg);
}

export function login_status() {
  return new Promise((resolve, reject) => {
    let cfg = {
      baseURL: base_url,
      url: ApiDefinition.LOGIN_STATUS.path,
      method: ApiDefinition.LOGIN_STATUS.method,
    };
    axios
      .request(cfg)
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          reject();
          return;
        }

        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_profile_list(page_size, page_no, criteria, pdu_from, pdu_to, m3, last_result, expire) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_MEMBER_LIST.path,
      method: ApiDefinition.GET_MEMBER_LIST.method,
      headers: { "Content-Type": "application/json" },
      data: {
        perPage: page_size,
        pageIndex: page_no,
        namelike: criteria,
        pduFrom: pdu_from,
        pduTo: pdu_to,
        m3: m3,
        lastResult: last_result,
        expireMonth: expire,
      },
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_profile_detail(id) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_MEMBER_DETAIL.path + id,
      method: ApiDefinition.GET_MEMBER_DETAIL.method,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function update_profile_detail(data) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.UPDATE_MEMBER_DETAIL.path,
      method: ApiDefinition.UPDATE_MEMBER_DETAIL.method,
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_company_types() {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_COMPANY_TYPES.path,
      method: ApiDefinition.GET_COMPANY_TYPES.method,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_company_list(type) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_COMPANY.path + type,
      method: ApiDefinition.GET_COMPANY.method,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_subscription_status(id) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_SUBSCRIPTION_STATUS.path + id,
      method: ApiDefinition.GET_SUBSCRIPTION_STATUS.method,
    };

    axios
      .request(cfg)
      .then((res) => {
        if (res.data.hasOwnProperty("0")) {
          resolve(res.data[0]);
        }
      })
      .catch((e) => {
        resolve(undefined);
      });
  });
}

export function get_agreement_status(id) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_AGREEMENT_STATUS.path + id,
      method: ApiDefinition.GET_AGREEMENT_STATUS.method,
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve(undefined);
      });
  });
}

export function get_exam_list(page_size, page_no, criteria) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_EXAM_LIST.path,
      method: ApiDefinition.GET_EXAM_LIST.method,
      params: {
        perPage: page_size,
        pageIndex: page_no,
        namelike: criteria,
      },
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve(undefined);
      });
  });
}

export function get_exam_list_all_simple() {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_EXAM_ALL_SIMPLE.path,
      method: ApiDefinition.GET_EXAM_ALL_SIMPLE.method,
      params: {
      },
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve(undefined);
      });
  });
}

export function get_exam_detail(id) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_EXAM_DETAIL.path + id,
      method: ApiDefinition.GET_EXAM_DETAIL.method,
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve(undefined);
      });
  });
}

export function update_exam_detail(data) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.UPDATE_EXAM_DETAIL.path,
      method: ApiDefinition.UPDATE_EXAM_DETAIL.method,
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function create_exam(data) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.CREATE_EXAM.path,
      method: ApiDefinition.CREATE_EXAM.method,
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function delete_exam(id) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.DELETE_EXAM.path + id,
      method: ApiDefinition.DELETE_EXAM.method,
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        resolve(e.response);
      });
  });
}

export function upload_image(image_name, image_data) {
  return new Promise((resolve, reject) => {
    let form_data = new FormData();
    form_data.append(image_name, image_data);

    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.UPLOAD_IMAGE.path,
      method: ApiDefinition.UPLOAD_IMAGE.method,
      headers: { "Content-Type": "multipart/form-data" },
      data: form_data,
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_signup_list(page_size, page_no, examId, criteria, payment_status) {
  //console.log(page_size, page_no, examId, criteria, payment_status);
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_SIGNUP_LIST.path,
      method: ApiDefinition.GET_SIGNUP_LIST.method,
      headers: { "Content-Type": "application/json" },
      params: {
        perPage: page_size,
        pageIndex: page_no,
        examId: examId,
        namelike: criteria,
        status: payment_status,
      },
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_scorecheck_list(page_size, page_no, examId, criteria, payment_status) {
  //console.log(page_size, page_no, examId, criteria, payment_status);
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_SCORECHECK_LIST.path,
      method: ApiDefinition.GET_SCORECHECK_LIST.method,
      headers: { "Content-Type": "application/json" },
      params: {
        perPage: page_size,
        pageIndex: page_no,
        examId: examId,
        namelike: criteria,
        status: payment_status,
      },
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_cert_list(page_size, page_no, examId, criteria, payment_status) {
  //console.log(page_size, page_no, examId, criteria, payment_status);
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: ApiDefinition.GET_CERT_LIST.path,
      method: ApiDefinition.GET_CERT_LIST.method,
      headers: { "Content-Type": "application/json" },
      params: {
        perPage: page_size,
        pageIndex: page_no,
        examId: examId,
        namelike: criteria,
        status: payment_status,
      },
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function create_activity(data) {
  const { path, method } = ApiDefinition.CREATE_ACTIVITY;
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: path,
      method,
      headers: { "Content-Type": "application/json" },
      data,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function update_activity_detail(data) {
  const { path, method } = ApiDefinition.UPDATE_ACTIVITY_DETAIL;
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: path,
      method,
      headers: { "Content-Type": "application/json" },
      data,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_report_exam_signup(data) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_SIGNUP.path}?exam=${data.exam}`,
      method: ApiDefinition.REPORT_SIGNUP.method,
      headers: { "Content-Type": "plain/text" },
      data: data,
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
export function get_report_exam_scorecheck(data) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_SCORECHECK.path}?exam=${data.exam}`,
      method: ApiDefinition.REPORT_SCORECHECK.method,
      headers: { "Content-Type": "plain/text" },
      data: data,
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_report_exam_newcert(data) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_NEWCERT.path}?exam=${data.exam}`,
      method: ApiDefinition.REPORT_NEWCERT.method,
      headers: { "Content-Type": "plain/text" },
      data: data,
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
export function get_report_profile() {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_PROFILE.path}`,
      method: ApiDefinition.REPORT_PROFILE.method,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
export function get_report_activity(params) {
  console.log('get_report_activity', params.id);
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_ACTIVITY.path}?id=${params.id}`,
      method: ApiDefinition.REPORT_ACTIVITY.method,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
export function get_report_renew(params) {
  console.log('get_report_renew');
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_RENEW.path}?start=${params.start}&end=${params.end}`,
      method: ApiDefinition.REPORT_RENEW.method,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_report_renewcert(params) {
  console.log('get_report_renewcert');
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_RENEWCERT.path}?start=${params.start}&end=${params.end}`,
      method: ApiDefinition.REPORT_RENEWCERT.method,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_report_statistics_every_exam(params) {
  console.log('get_report_statistics_every_exam');
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_STATISTICS_EVERY_EXAM.path}`,
      method: ApiDefinition.REPORT_STATISTICS_EVERY_EXAM.method,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
        .request(cfg)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
  });
}

export function get_report_statistics_own_certificate(params) {
  console.log('get_report_statistics_own_certificate');
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_STATISTICS_OWN_CERTIFICATE.path}`,
      method: ApiDefinition.REPORT_STATISTICS_OWN_CERTIFICATE.method,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
        .request(cfg)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
  });
}

export function get_report_statistics_one_exam_pass(params) {
  console.log('get_report_statistics_one_exam_pass');
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.REPORT_STATISTICS_ONE_EXAM_PASS.path}/${params.examId}`,
      method: ApiDefinition.REPORT_STATISTICS_ONE_EXAM_PASS.method,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
        .request(cfg)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
  });
}

export function get_student_exam_list(id) {
  console.log('get_student_exam_list');
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `/admin/profile/${id}/exam`,
      method: `get`,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_student_activity_list(id) {
  console.log('get_student_activity_list');
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `/admin/profile/${id}/activity`,
      method: `get`,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_student_online_list(id) {
  console.log('get_student_online_list');
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `/admin/profile/${id}/online`,
      method: `get`,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function get_student_cert_list(id) {
  console.log('get_student_cert_list', id);
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `/admin/profile/${id}/cert`,
      method: `get`,
      headers: { "Content-Type": "plain/text" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

// 查計算機訂閱方案

export function get_subscribefee() {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.GET_SUBSCRIBEFEE.path}`,
      method: `${ApiDefinition.GET_SUBSCRIBEFEE.method}`,
      headers: { "Content-Type": "plain/text" },
    };
    // console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  })
}

// 修改計算機訂閱方案的金額

// POST BODY (json) id 是 GET 查出的 id [{"id": 1,"price": "0"}]
export function edit_subscribefee(id, price) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.UPDATE_SUBSCRIBEFEE.path}`,
      method: `${ApiDefinition.UPDATE_SUBSCRIBEFEE.method}`,
      headers: { "Content-Type": "application/json" },
      body: {
        "id": id,
        "price": price,
      },
    };

    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}




// 查換證方案

export function get_renewfee() {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.GET_RENEWFEE.path}`,
      method: `${ApiDefinition.GET_RENEWFEE.method}`,
      headers: { "Content-Type": "application/json" },
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

//修改換證方案的金額  **用另外一個引用方式呼叫 因為這邊無法成功**

// GET nav

export function get_nav() {
  return new Promise((resolve, reject) => {
    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.GET_NAV.path}`,
      method: `${ApiDefinition.GET_NAV.method}`,
      headers: { "Content-Type": "plain/text" },
    };
    // console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  })
}

// 修改 nav
// export function
export function update_nav(id, text, href) {
  return new Promise((resolve, reject) => {
    var form_data = new FormData();
    form_data.append('id', id);
    form_data.append('text', text);
    form_data.append('href', href);


    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.UPDATE_NAV.path}`,
      method: `${ApiDefinition.UPDATE_NAV.method}`,
      headers: { "Content-Type": "multipart/form-data" },
      data: form_data,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      })
  })
}

export function update_rfa_card(cardId, href) {
  return new Promise((resolve, reject) => {
    var form_data = new FormData();
    form_data.append('cardId', cardId)
    form_data.append('href', href);

    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.UPDATE_RFA_CARD.path}`,
      method: `${ApiDefinition.UPDATE_RFA_CARD.method}`,
      headers: { "Content-Type": "multipart/form-data" },
      data: form_data,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      })
  })
}

export function get_rfa() {
  return new Promise((resolve, reject) => {
    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.GET_RFA.path}`,
      method: `${ApiDefinition.GET_RFA.method}`,
      headers: { "Content-Type": "plain/text" },
    };
    // console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  })
}

// GET format
export function get_format_rule() {
  return new Promise((resolve, reject) => {
    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.GET_FORMAT.path}`,
      method: `${ApiDefinition.GET_FORMAT.method}`,
      headers: { "Content-Type": "plain/text" }
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      });
  })
}

// GET advertisement

export function get_advertisement() {
  return new Promise((resolve, reject) => {
    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.GET_ADVERTISEMENT.path}`,
      method: `${ApiDefinition.GET_ADVERTISEMENT.method}`,
      headers: { "Content-Type": "plain/text" },
    };
    // console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  })
}

// POST advertisement

export function update_advertisement(id, title, link, enable, file) {
  return new Promise((resolve, reject) => {
    var form_data = new FormData();
    form_data.append('id', id);
    form_data.append('title', title);
    form_data.append('link', link);
    form_data.append('adImgFile', file);
    form_data.append('enable', enable);

    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.UPDATE_ADVERTISEMENT.path}`,
      method: `${ApiDefinition.UPDATE_ADVERTISEMENT.method}`,
      headers: { "Content-Type": "multipart/form-data" },
      data: form_data,
    };
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      })
  })
}

// EMAIL 
export function get_email() {
  return new Promise((resolve, reject) => {
    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.GET_EMAIL.path}`,
      method: `${ApiDefinition.GET_EMAIL.method}`,
      headers: { "Content-Type": "application/json" },
    };
    // console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  })
}

export function get_email_template_id(templateId) {
  return new Promise((resolve, reject) => {
    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.GET_EMAIL_TEMPLATE_ID.path}/${templateId}`,
      method: `${ApiDefinition.GET_EMAIL_TEMPLATE_ID.method}`,
      headers: { "Content-Type": "application/json" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  })
}


export function update_email(id, params) {
  const { title, content } = params
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.UPDATE_EMAIL.path}/${id}`,
      method: `${ApiDefinition.UPDATE_EMAIL.method}`,
      headers: { "Content-Type": "application/json" },
      body: {
        "content": content,
        "title": title
      }
    };

    console.log(cfg);

    axios
      .request(cfg)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}


export function get_email_sendlist() {
  return new Promise((resolve, reject) => {
    var cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.GET_EMAIL_SENDLIST.path}`,
      method: `${ApiDefinition.GET_EMAIL_SENDLIST.method}`,
      headers: { "Content-Type": "application/json" },
    };
    console.log(cfg);
    axios
      .request(cfg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  })
}

export function post_email_send(key) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.POST_EMAIL_SEND.path}`,
      method: `${ApiDefinition.POST_EMAIL_SEND.method}`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({
        "key": Number(key)
      })
    };

    console.log(cfg);

    axios
      .request(cfg)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}


export function post_email_send_test(key,email) {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.POST_EMAIL_SEND_TEST.path}`,
      method: `${ApiDefinition.POST_EMAIL_SEND_TEST.method}`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({
        "key": Number(key),
        "email":email
      })
    };

    console.log(cfg);

    axios
      .request(cfg)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

// VERIFICATION_ADMIN

export function post_verification_admin() {
  return new Promise((resolve, reject) => {
    let cfg = {
      withCredentials: true,
      baseURL: base_url,
      url: `${ApiDefinition.POST_VERIFICATION_ADMIN.path}`,
      method: `${ApiDefinition.POST_VERIFICATION_ADMIN.method}`,
      headers: { "Content-Type": "application/json" },
    };

    // console.log(cfg);

    axios
      .request(cfg)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get_student_cert_list,
  get_student_online_list,
  get_student_activity_list,
  get_student_exam_list,

  get_report_profile,
  get_report_activity,
  get_report_exam_signup,
  get_report_exam_scorecheck,
  get_report_exam_newcert,
  get_report_renew,
  get_report_renewcert,
  get_report_statistics_every_exam,
  get_report_statistics_own_certificate,
  get_report_statistics_one_exam_pass,

  login,
  logout,
  login_status,

  get_company_types,
  get_company_list,
  get_subscription_status,
  get_agreement_status,

  get_profile_list,
  get_profile_detail,
  update_profile_detail,

  //2022-07-29:
  get_exam_list,
  get_exam_detail,
  update_exam_detail,
  create_exam,
  delete_exam,
  get_exam_list_all_simple,

  //2022-08-11:
  upload_image,

  get_signup_list,
  get_scorecheck_list,
  get_cert_list,

  create_activity,

  // 2023-09-13
  get_subscribefee,
  edit_subscribefee,

  get_renewfee,

  // 2024/01/29
  get_nav,
  update_nav,
  get_advertisement,
  update_advertisement,

  // 2024/03/07
  get_rfa,
  update_rfa_card,

  // 2024/02/19
  get_format_rule,


  // 2024/03/14
  get_email,
  get_email_template_id,
  update_email,
  get_email_sendlist,
  post_email_send,
  post_email_send_test,

  // 2024/04/29
  post_verification_admin,
  anotherLogin

};
