import {mdiClockOutline} from "@mdi/js";
import Icon from "@mdi/react";
import BaseFormatInput, {BaseFormatInputProps} from "./BaseFormatInput";

export type TimeInputProps = Omit<BaseFormatInputProps, "icon" | "regExp" | "maxLength">;

function TimeInput(props: TimeInputProps) {
  return (
    <BaseFormatInput
      {...props}
      icon={<Icon path={mdiClockOutline} size={1} />}
      regExp={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g}
      maxLength={5}
    />
  );
}

export default TimeInput;
