import {FInputGroup} from "common/form";
import {FInputGroupProps} from "common/form/FInputGroup";
import {FastField, FieldProps} from "formik";
import React from "react";

export type FMInputGroupProps = Omit<FInputGroupProps, "name" | "errorMessage"> & {
  name: string;
};

function FMInputGroup({ name, type, ...props }: FMInputGroupProps) {
  return (
    <FastField name={name} type={type}>
      {({ field, form, meta }: FieldProps) => {
        const error = meta.touched && meta.error ? meta.error : undefined;
        return <FInputGroup {...props} type={type} errorMessage={error} {...field} />;
      }}
    </FastField>
  );
}

export default FMInputGroup;
