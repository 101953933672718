import {Button, HStack, Input, Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack} from "@chakra-ui/react";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import Api from "api/Api";
import {useEffect, useState} from 'react'
// import dayjs from "dayjs";
import {useAPIRequest, useCustomToast} from "hooks";
import ApiDefinition from "api/ApiDefinition";
import {getErrorMessage} from "common/errMsgHelper";
import { checkIsNumber } from "common/numberHelper";


interface renewfeeType {
  id: number
  title: string
  price: string
  createdAt: string
  updatedAt: string
  isReadonly: boolean
  isEditing: boolean
}

// const columns = ['狀態', '金額', '起始日', '修改日', '']
const columns = ['狀態', '金額', '']

const readonly = [true, true, true, true]

function ExchangePricingComponent() {
  const { processRequest } = useAPIRequest();
  const toast = useCustomToast();
  const [renewfee, setRenewfee] = useState<renewfeeType[]>([])
  // 修改後的值
  const [editedPrice, setEditedPrice] = useState<string|null>(null);

  useEffect(() => {
    Api.get_renewfee().then((res) => {
      res.forEach((item: renewfeeType, index: number) => {
        item.isReadonly = readonly[index]
        item.isEditing = false;
      });
      setRenewfee(res)
    })
  }, [])

  const edit_price = (item: renewfeeType) => {
    item.isEditing = true;
    setRenewfee(renewfee.map((i) => (i.id === item.id ? item : i)));
  }

  const check = (item: renewfeeType) => {
    setRenewfee(renewfee.map((i) => (i.id === item.id ? item : i)));
    
    const changedValue = (editedPrice===null)? item.price : editedPrice

    if (checkIsNumber(changedValue)){
      const modifiedPrice = changedValue

      processRequest(ApiDefinition.UPDATE_RENEWFEE, [{ "id": item.id, "price": modifiedPrice }])
        .then((res) => {
          toast("success", "修改成功");
        })
        .catch((err) => {
          const message = getErrorMessage(err, "修改失敗");
          toast("error", message);
        });

      item.isEditing = false;
    } else {
      toast("error", '請輸入正確金額');
    }
    
  }

  return (
    <>
      <PageContainer>
        <VStack spacing={3} align="start">
          <PageHeader
            menuIconName="training"
            title="換證金額調整"
            toolbar={
              <HStack>
              </HStack>
            }
          />
        </VStack>
        <TableContainer>
          <Table variant='striped' colorScheme="gray" size='lg'>
            <Thead backgroundColor='primary.500'>
              <Tr>
                {
                  columns.map((column) => <Th color='white' fontSize='lg' key={column}>{column}</Th>)
                }
              </Tr>
            </Thead>
            <Tbody>
              {
                renewfee.map((item) => (
                  <Tr key={item.id}>
                    <Td>{item.title}</Td>
                    <Td>{item.isEditing ? (
                      <Input
                        defaultValue={item.price}
                        focusBorderColor="crimson"
                        isInvalid
                        errorBorderColor='crimson'
                        htmlSize={1}
                        onChange={(e) => setEditedPrice(e.target.value)}
                      />
                    ) : (
                      <Input
                        defaultValue={item.price}
                        focusBorderColor="primary.500"
                        borderColor="gray"
                        htmlSize={1}
                        isReadOnly={item.isReadonly}
                      />
                    )}</Td>
                    {/* <Td>{dayjs(item.createdAt).format("YYYY/MM/DD")}</Td>
                    <Td>{dayjs(item.updatedAt).format("YYYY/MM/DD")}</Td> */}
                    <Td style={{ textAlign:'center' }}>{item.isEditing ? (
                      <Button variant="customOutline" onClick={() => check(item)}>確認</Button>
                    ) : (
                      <Button variant="customSolid" onClick={() => edit_price(item)}>
                        修改
                      </Button>
                    )}</Td>
                  </Tr>
                ))
              }
            </Tbody>
          </Table>
        </TableContainer>
      </PageContainer>
    </>
  )
}

export default ExchangePricingComponent