import style from '../css/student_detail_panel.module.css'

import dropdown_calendar from '../../svg-files/calendar-black.svg'
import green_check from '../../svg-files/green-check.svg'
import red_cross from '../../svg-files/red-cross.svg'

import {Menu, MenuButton, Spinner, Switch, useDisclosure, useToast} from '@chakra-ui/react'
import {useEffect, useState} from 'react'

import Api from '../../api/Api'

import MenuListDatePicker from './MenuListDatePickerV2'
import MenuListDateTimeRangePicker from "./MenuListDateTimeRangePicker"
import WarningDialogComponent from './WarningDialogComponent'

let ExamDetailEditPanelComponent =({memberdata, cancel, save})=>{

  const toast = useToast()
  const disclosure =useDisclosure()
  const [busyUpdate, setBusyUpdate] =useState(false)
  const tmp_memberdata =useState({})

  useEffect(()=>{
    tmp_memberdata[1]({...memberdata[0]})
  },[memberdata])

  const upload_btn_busy_status =useState({'0':false, '1':false, '2':false})

  const get_prop_impl = (object, path) => {
    if (Array.isArray(path)===false){
      return object[path]
    }
    if (path.length === 1){
      return object[path[0]];
    }else if (path.length === 0){
      //...
      return undefined
    }else {
       if (object[path[0]]) return get_prop_impl(object[path[0]], path.slice(1));
       else {
          object[path[0]] = {};
          return get_prop_impl(object[path[0]], path.slice(1));
       }
    }
  }

  const set_prop_impl =(obj, path, val)=>{
    let schema = obj;  // a moving reference to internal objects within obj
    let pList = path
    let len = pList.length;
    for(let i = 0; i < len-1; i++) {
        let elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }

    schema[pList[len-1]] = val;
  }

  const get_prop =(obj, path)=>{
    let ret =get_prop_impl(obj, path)
    if ((ret==='' || ret===null || ret===undefined || ret==='')){
      return '-'
    }
    return ret
  }

  const get_datetime_prop =(obj, path)=>{
    let date_str = get_prop(obj, path)

    let expire_regex =/^(\d{4})\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\s(0[1-9]|[1-2][0-9]):\d\d:\d\d/
    if (expire_regex.test(date_str)===true){
      let date_arr =expire_regex.exec(date_str)
      date_str =date_arr[1]+'/'+date_arr[2]+'/'+date_arr[3]+' '+date_arr[4]+':00'
    }

    return date_str
  }

  const get_date_prop =(obj, path)=>{
    let date_str = get_prop(obj, path)

    let expire_regex =/^(\d{4})\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])/
    if (expire_regex.test(date_str)===true){
      let date_arr =expire_regex.exec(date_str)
      date_str =date_arr[1]+'/'+date_arr[2]+'/'+date_arr[3]
    }

    return date_str
  }

  let handleChange =(value, dataid)=>{
    console.warn(value, dataid);
    let obj ={...tmp_memberdata[0]}
    console.warn('obj',obj);
    set_prop_impl(obj, dataid.slice(1), value)

    tmp_memberdata[1](obj);
    console.warn('tmp_memberdata', tmp_memberdata);
  }

  let handelNumberChange =(value, dataid)=>{
    let number_regex =/^\d+$/
    if (number_regex.test(value)===false && value !==''){
      return
    }

    handleChange(value, dataid)
  }

  let handleImageUpload =(subv, e)=>{
    console.warn(e.target.files)

    let file_data =e.target.files[0]
    let file_name =file_data.name
    if (file_data.type.indexOf('image')===-1){
      return
    }

    let new_input_status ={
      ...upload_btn_busy_status[0],
      [subv.input_id]:true
    }
    upload_btn_busy_status[1](new_input_status)

    Api.upload_image(file_name, file_data).then((res)=>{
      console.warn(res)

      handleChange(res, subv.dataid)

    }).catch((e)=>{
      console.error(e)
    }).finally(()=>{

      let new_input_status ={
        ...upload_btn_busy_status[0],
        [subv.input_id]:false
      }
      upload_btn_busy_status[1](new_input_status)

    })

  }

  const layout_model =[
    [
      { type: 'input', label: '考試名稱', dataid: [0, 'name'] }, { type: 'spacer' }, { type: 'input_switch', label: '啟用', dataid: [0, 'enabled'] },
    ],
    [
      {type: 'input_number', label: '一般考生考試金額', dataid:[0, 'settings', 'signup', 'price']}, {type: 'spacer'}, {type: 'empty'},
    ],
    [
      {type: 'input_number', label: 'CFP/AFP證照考生考試金額', dataid:[0, 'settings', 'signup', 'special_price']}, {type: 'spacer'}, {type: 'empty'},
    ],
    [
      {type: 'input_number', label: '單科目複查金額', dataid:[0, 'settings', 'scorecheck', 'price']}, {type: 'spacer'}, {type: 'empty'},
    ],
    [
      { type: 'input_number', label: '學生身分考試金額', dataid: [0, 'settings', 'signup', 'student_price'] }, { type: 'spacer' }, { type: 'empty' },
    ],
    [
      { type: 'input_number', label: '再次報名金額', dataid: [0, 'settings', 'signup', 'reexam_price'] }, { type: 'spacer' }, { type: 'empty' },
    ],
    [
      {type: 'daterange', label: '報名日期', dataid1:[0, 'settings', 'signup', 'startAt'], dataid2:[0, 'settings', 'signup', 'endAt']}, {type: 'spacer'}, {type: 'empty'},
    ],
    [
      { type: 'date', label: '准考證開放日期', dataid: [0, 'settings', 'ticket_release'] }, { type: 'spacer' }, { type: 'empty' }
    ],
    [
      { type: 'date', label: '考試日期', dataid: [0, 'settings', 'test_date'] }, { type: 'spacer' }, { type: 'empty' }
    ],
    [
      { type: 'date', label: '應考證明開放日期', dataid: [0, 'settings', 'admission_release'] }, { type: 'spacer' }, { type: 'empty' }
    ],
    [
      {type: 'date', label: '成績放榜日期', dataid:[0, 'settings', 'score_publish']}, {type: 'spacer'}, {type: 'empty'},
    ],
    [
      {type: 'date', label: '複查放榜日期', dataid:[0, 'settings', 'scorecheck_result']}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'daterange', label: '複查申請日期', dataid1:[0, 'settings', 'scorecheck', 'startAt'], dataid2:[0, 'settings', 'scorecheck', 'endAt']}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'daterange', label: '認證申請開放日期', dataid1:[0, 'settings', 'newcert', 'startAt'], dataid2:[0, 'settings', 'newcert', 'endAt']}, {type: 'spacer'}, {type: 'empty'}
    ],
    [
      {type: 'input_number', label: '認證申請金額', dataid:[0, 'settings', 'newcert', 'price']}, {type: 'spacer'}, {type: 'empty'},
    ]
  ]

  return (
    <div className={['colume', style['container']].join(' ')} style={{
        marginTop: '18px', marginBottom: '26px',
        paddingTop: '30px', paddingLeft: '60px', paddingRight: '60px', paddingBottom: '40px',
        fontSize: '18px',
        position: 'relative',
      }}>
        <div className={['col-auto', 'column'].join(' ')} style={{maxWidth: '866px'}}>
          {
              layout_model.map((v,idx)=>{
                return (
                  <div key={idx} className={['col-auto', 'row'].join(' ')} style={{marginBottom: '14px'}}>
                    <div className={['col', 'row'].join(' ')}>
                      {
                        v.map((subv, subidx)=>{
                          if (subv.type === 'input_switch') {
                            return (
                              <div key={subidx} className={['col', 'column'].join(' ')} style={{ height: '85px' }}>
                                <label className={['col-auto']}>{subv.label}</label>
                                <Switch className={['col-auto']}
                                  isChecked={get_prop(tmp_memberdata, subv.dataid)}
                                  onChange={(e) => {
                                    console.log(e, subv.dataid );
                                    handleChange(e.target.checked, subv.dataid)
                                  }}
                                  style={{ marginTop: '8px', height: '50px', borderRadius: '5px', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px' }}
                                  />
                              </div>
                            )
                          } else
                          if (subv.type==='input_number'){
                            return(
                              <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '85px'}}>
                                <label className={['col-auto']}>{subv.label}</label>
                                <input
                                  onChange={(e)=>{handelNumberChange(e.target.value, subv.dataid)}}
                                  value={get_prop(tmp_memberdata, subv.dataid)} className={['col-auto']} type='text' style={{marginTop: '8px', height: '50px', borderRadius: '5px', border: '1px solid #757575', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                              </div>
                            )
                          }else
                          if (subv.type==='input'){
                            return(
                              <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '85px'}}>
                                <label className={['col-auto']}>{subv.label}</label>
                                <input
                                  onChange={(e)=>{handleChange(e.target.value, subv.dataid)}}
                                  value={get_prop(tmp_memberdata, subv.dataid)} className={['col-auto']} type='text' style={{marginTop: '8px', height: '50px', borderRadius: '5px', border: '1px solid #757575', paddingLeft: '12px', paddingRight: '12px', fontSize: '18px'}}/>
                              </div>
                            )
                          }else
                          if (subv.type==='spacer'){
                            return(
                              <div key={subidx} className={'col-auto'} style={{width: '40px'}}></div>
                            )
                          }else
                          if (subv.type==='empty'){
                            return(
                              <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '26px'}}></div>
                            )
                          }else
                          if (subv.type==='date'){
                            return(
                              <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '85px'}}>
                                <label className={['col-auto']}>{subv.label}</label>
                                <Menu>
                                {({ isOpen, onClose }) => (
                                    <>
                                  <MenuButton className={style['custom-outline-button']} style={{height: '50px', marginTop: '8px'}}>
                                    <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')} >
                                      <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>{get_date_prop(tmp_memberdata, subv.dataid)}</label>
                                      <img alt="" src={dropdown_calendar} style={{width: '16.67px', height: '18.33px', marginLeft: '9.67px'}}/>
                                    </div>
                                  </MenuButton>
                                  <MenuListDatePicker isOpen={isOpen} onClose={onClose} dateState={get_date_prop(tmp_memberdata, subv.dataid)} handleDayChange={(new_date)=>{
                                    //console.log('245 - date updated to '+new_date)

                                    let expire_regex =/^(\d{4})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/
                                    if (expire_regex.test(new_date)===true){
                                      let date_arr =expire_regex.exec(new_date)
                                      let new_date_str =
                                        date_arr[1]+'/'+date_arr[2]+'/'+date_arr[3]

                                      handleChange(new_date_str, subv.dataid)
                                    }

                                  }}/>

                                    </>
                                  )}
                                </Menu>

                              </div>
                            )
                          }else
                          if (subv.type==='daterange'){
                            return(
                              <div key={subidx} className={['col', 'column'].join(' ')} style={{height: '85px'}}>
                                <label className={['col-auto']}>{subv.label}</label>
                                <Menu>
                                  {({ isOpen, onClose }) => (
                                    <>
                                      <MenuButton className={style['custom-outline-button']} style={{height: '50px', marginTop: '8px'}}>
                                        <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')} >
                                          <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>{get_datetime_prop(tmp_memberdata, subv.dataid1)+' - '+get_datetime_prop(tmp_memberdata, subv.dataid2)}</label>
                                          <img alt="" src={dropdown_calendar} style={{width: '16.67px', height: '18.33px', marginLeft: '9.67px'}}/>
                                        </div>
                                      </MenuButton>
                                      <MenuListDateTimeRangePicker isOpen={isOpen} onClose={onClose} dateState={get_datetime_prop(tmp_memberdata, subv.dataid1)+' - '+get_datetime_prop(tmp_memberdata, subv.dataid2)} handleDayChange={(new_date)=>{
                                        //console.log('190 - date updated to '+new_date)

                                        let expire_regex =/^(\d{4})\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\s(0[1-9]|[1-2][0-9]):\d\d\s-\s(\d{4})\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\s(0[1-9]|[1-2][0-9]):\d\d$/
                                        if (expire_regex.test(new_date)===true){
                                          let date_arr =expire_regex.exec(new_date)
                                          let new_date_str1 =
                                            date_arr[1]+'/'+date_arr[2]+'/'+date_arr[3]+' '+date_arr[4]+':00:00'
                                          handleChange(new_date_str1, subv.dataid1)
                                          let new_date_str2 =
                                            date_arr[5]+'/'+date_arr[6]+'/'+date_arr[7]+' '+date_arr[8]+':00:00'
                                          handleChange(new_date_str2, subv.dataid2)
                                        }

                                      }}/>
                                    </>
                                  )}
                                </Menu>

                              </div>
                            )
                          }else
                          if (subv.type==='banner'){
                            return(
                              <div key={subidx} className={['col', 'column', 'justify-center', 'items-center'].join(' ')} style={{
                                height: '350px',
                                width: '100%',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '8px',
                                border: '1px solid #e0e0e0',
                                backgroundImage: 'url('+(process.env.REACT_APP_IMAGE_BED+get_prop(tmp_memberdata, subv.dataid))+')',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                              }}>
                                <div className={['col-auto'].join(' ')} style={{fontSize: '22.5px'}}>{subv.label}</div>
                                <div className={['col-auto'].join(' ')} style={{fontSize: '18px', color: '#757575', marginBottom:'30px'}}>圖片建議尺寸：1000*1000px</div>
                                <label htmlFor={'input_file_'+subv.input_id} className={['col-auto', 'row', 'items-center', style['custom-button']].join(' ')} style={{cursor:'pointer'}}>
                                  {upload_btn_busy_status[0][subv.input_id]?(<Spinner className={'col-auto'} style={{marginRight: '10px', width: 'var(--spinner-size)'}}/>):('')}
                                  上傳圖片
                                </label>
                                <input disabled={upload_btn_busy_status[0][subv.input_id]} id={'input_file_'+subv.input_id} type="file" style={{display:'none'}} onChange={(e)=>{
                                  handleImageUpload(subv, e)
                                }}/>
                              </div>
                            )
                          }


                        })
                      }
                    </div>
                  </div>
                )
              })
          }

          <div className={['col-auto', 'row'].join(' ')} style={{marginTop: '26px', marginBottom: '14px'}}>
            <button className={['col', style['custom-outline-button']].join(' ')} style={{padding: '0', border: '1px solid #0e4e96', borderRadius: '4px', height: '51px', color: '#0e4e96', fontSize: '18px'}} onClick={()=>{cancel()}}>取消編輯</button>
            <div className={'col-auto'} style={{width: '20px'}}/>
            <button className={['col', 'row', 'items-center', 'justify-center', style['custom-button']].join(' ')} style={{backgroundColor: '#0e4e96', borderRadius: '4px', heidht: '40px', fontSize: '18px', color: 'white'}} onClick={()=>{
              if (busyUpdate===true){
                return
              }
              setBusyUpdate(true)
              disclosure.onOpen()

            }}>
              {busyUpdate?(<Spinner className={'col-auto'} style={{marginRight: '10px', width: 'var(--spinner-size)'}}/>):('')}
              <label className={'col-auto'} style={{pointerEvents: 'none'}}>儲存變更</label>
            </button>
          </div>
        </div>

      <WarningDialogComponent title={'請注意!'} message={'按下確定後，考試資訊將被更新，<br/>請確認資訊是否正確無誤。'} disclosure={disclosure} handler={(cb)=>{
        console.warn(cb)
        if (cb===false){
          setBusyUpdate(false)
          return
        }
        //console.log(tmp_memberdata[0])
        save(tmp_memberdata[0], (success, res_data)=>{
          setBusyUpdate(false)

          if (success===true){
            console.warn('222 - shot toast()')
            toast({
              containerStyle:{
                maxWidth: 'unset',
                minWidth: 'usnet',
                marginBottom: '20px',
              },
              render: () => (
                <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{paddingLeft: '16px', paddingRight: '16px', backgroundColor: 'white', height: '49px', borderRadius: '12px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'}}>
                  <img alt="" className={['col-auto'].join(' ')} src={green_check} style={{width: '20px', height: '20px', marginRight: '7px'}}/>
                  <label className={['col-auto'].join(' ')} style={{fontSize: '16px', color: 'black'}}>考試資訊變更成功</label>
                </div>
              ),
            })
          }else{
            console.warn(res_data)
            console.warn('726 - shot toast()')
            toast({
              containerStyle:{
                maxWidth: 'unset',
                minWidth: 'usnet',
                marginBottom: '20px',
              },
              render: () => (
                <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{paddingLeft: '16px', paddingRight: '16px', backgroundColor: 'white', height: '49px', borderRadius: '12px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'}}>
                  <img alt="" className={['col-auto'].join(' ')} src={red_cross} style={{width: '20px', height: '20px', marginRight: '7px'}}/>
                  <label className={['col-auto'].join(' ')} style={{fontSize: '16px', color: 'black'}}>{res_data.response?.data}({res_data.message})</label>
                </div>
              ),
            })
          }

        })
      }}/>
    </div>
  )
}

export default ExamDetailEditPanelComponent
