import {Flex, IconButton, Text} from "@chakra-ui/react";
import {mdiMinusCircleOutline, mdiPlusCircleOutline} from "@mdi/js";
import Icon from "@mdi/react";
import React, {ChangeEvent, FocusEvent, useEffect, useState} from "react";
import FInputGroup, {FInputGroupProps} from "./FInputGroup";

export type FNumberPickerProps = Omit<
  FInputGroupProps,
  "leftElement" | "rightElement" | "onChange" | "onBlur" | "value"
> & {
  max?: number;
  min?: number;
  value?: number;
  onChange?: (value: number) => void;
  onBlur?: (value: number) => void;
  unitLabel?: string;
};

function FNumberPicker({
  max = 99999,
  min = 1,
  value,
  onChange,
  onBlur,
  unitLabel,
  isDisabled,
  ...props
}: FNumberPickerProps) {
  const checkValue = (value: number | undefined) => {
    if (value === undefined || Number.isNaN(value) || value < min) {
      return min;
    } else if (value > max) {
      return max;
    }
    return value;
  };
  const [currentValue, setCurrentValue] = useState<number>(checkValue(value));
  const handleButtonUp = () => {
    if (currentValue < max) {
      onChange && onChange(currentValue + 1);
      setCurrentValue(currentValue + 1);
    }
  };
  const handleButtonDown = () => {
    if (currentValue > min) {
      onChange && onChange(currentValue - 1);
      setCurrentValue(currentValue - 1);
    }
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const mValue = event.currentTarget.value;
    onChange && onChange(checkValue(Number(mValue)));
    setCurrentValue(checkValue(Number(mValue)));
  };
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const mValue = event.currentTarget.value;
    onBlur && onBlur(checkValue(Number(mValue)));
    setCurrentValue(checkValue(Number(mValue)));
  };
  useEffect(() => {
    if (checkValue(value) !== currentValue) {
      setCurrentValue(checkValue(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <FInputGroup
      {...props}
      type="tel"
      value={currentValue}
      max={max}
      min={min}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={isDisabled}
      rightElementProps={{ width: "initial" }}
      rightElement={
        <Flex alignItems="center" whiteSpace="nowrap">
          {unitLabel && <Text mr={1}>{unitLabel}</Text>}
          <IconButton
            variant="outline"
            colorScheme="primary"
            aria-label="Count Up"
            fontSize="20px"
            onClick={handleButtonUp}
            disabled={isDisabled}
            icon={<Icon path={mdiPlusCircleOutline} size={1} />}
            style={{ border: 0 }}
          />
          <IconButton
            variant="outline"
            colorScheme="primary"
            aria-label="Count Down"
            fontSize="20px"
            onClick={handleButtonDown}
            disabled={isDisabled}
            icon={<Icon path={mdiMinusCircleOutline} size={1} />}
            style={{ border: 0 }}
          />
        </Flex>
      }
    />
  );
}

export default FNumberPicker;
