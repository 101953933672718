import {Alert, AlertIcon, AlertStatus, AlertTitle, Box, UseToastOptions} from "@chakra-ui/react";

type ToastAlertProps = Pick<UseToastOptions, "title" | "status">;

const colorSchemeMap: Record<AlertStatus, string> = {
  info: "blue",
  warning: "yellow",
  success: "green",
  error: "red",
  loading: "blue",
};

function ToastAlert({ title, status }: ToastAlertProps) {
  const iconColor = status ? `${colorSchemeMap[status]}.400` : undefined;
  return (
    <Box>
      <Alert size="md" status={status} variant="customToast">
        {<AlertIcon color={iconColor} />}
        <AlertTitle>{title}</AlertTitle>
      </Alert>
    </Box>
  );
}

export default ToastAlert;
