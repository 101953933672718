// ActivityForm 貼過來的
import { Select, VStack, HStack, Button, Input } from "@chakra-ui/react";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import React, { useState, useEffect } from "react";
import Api from "api/Api";
import ApiDefinition from "api/ApiDefinition";
import { useAPIRequest, useCustomToast } from "hooks";
import { getErrorMessage } from "common/errMsgHelper";

export interface SendListType {
  key: number
  value: string
}

function EmailSendComponent() {
  const [sendList, setSendList] = useState<SendListType[]>([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [email, setEmail] = useState('');
  const toast = useCustomToast();
  // const { processRequest } = useAPIRequest();
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    // 假设value是直接的文本值而非HTML，或者您可以从HTML中提取文本
    setSelectedOption(value);
  };

  const handleSubjectChange = (value: string) => {
    setEmail(value);
  }

  const handleButtonClickOne = () => {

    if (email === '') {
      toast("error", "請填入測試信收件人信箱");
      return false;
    }


    Api.post_email_send_test(selectedOption, email)
      .then((res) => {
        toast("success", "寄送成功");
        setEmail('')
      }).catch((err) => {
        const message = getErrorMessage(err, "寄送失敗");
        toast("error", message);
        console.error(err);
      })
  }


  const validateSendingConditions = () => {
    if (selectedOption === '') {
      toast("error", "請選擇寄送項目");
      return false;
    }

    if (email !== '') {
      toast("error", "請確保測試信收件人信箱為空");
      return false;
    }

    return true;
  }



  const handleButtonClick = () => {
    
    if (!validateSendingConditions()) {
      return;
    }

    Api.post_email_send(selectedOption)
      .then((res) => {
        console.log(res);
        toast("success", "寄送成功");
      }).catch((err) => {
        const message = getErrorMessage(err, "寄送失敗");
        toast("error", message);
        console.error(err);
      })
  }



  useEffect(() => {
    Api.get_email_sendlist().then((res) => {
      console.log(res);
      setSendList(res)
    }).catch((err) => { })
  }, [])


  return (
    <>
      <PageContainer>
        <VStack spacing={3} align="start">
          <PageHeader
            menuIconName="settings"
            title="Email 發送"
          />
        </VStack>
        <p style={{ marginRight: '10px', fontSize: '20px', marginBottom: '10px' }}>請選擇寄送項目</p>
        <VStack align="start" mb={6} w="100%">
          <HStack width="100%">
            <Select placeholder='Select option' onChange={handleOptionChange} flex={3}>
              {
                sendList.map((item: SendListType, index: number) => {
                  return <option key={index} value={item.key}>{item.value}</option>
                })
              }
            </Select>
            <Button variant="customSolid" flex={1} onClick={handleButtonClick}>寄發正式信(全)</Button>
          </HStack>
        </VStack>
        <p style={{ marginRight: '10px', fontSize: '20px', marginBottom: '10px' }}>請輸入測試信收件人信箱</p>
        <VStack align="start" mb={6} w="100%">
          <HStack width="100%">
            <Input flex={3} value={email} onChange={(e) => handleSubjectChange(e.target.value)} />
            <Button flex={1} variant="customSolid" onClick={handleButtonClickOne}>寄發測試信(單)</Button>
          </HStack>
        </VStack>
      </PageContainer>
    </>
  )
}


export default EmailSendComponent