import ApiDefinition from "api/ApiDefinition";
import {MenuSelectValue} from "common/MenuSelect";
import {useFetchData} from "hooks";
import React, {useEffect, useMemo, useState} from "react";
import DashBoardUnit, {DashBoardUnitColumn} from "./DashBoardUnit";
import CompanySelect from "./select/CompanySelect";
import CompanyTypeSelect from "./select/CompanyTypeSelect";
import ExamSelect from "./select/ExamSelect";

export interface SingleExamStatRow {
  field1?: number;
  field2?: number;
  field3?: number;
  field4?: number;
  field5?: number;
}

function SingleExamStatBlock() {
  const [currentExamId, setCurrentExamId] = useState<MenuSelectValue>(undefined);
  const [currentCompanyId, setCurrentCompanyId] = useState<MenuSelectValue>(undefined);
  const [currentCompanyTypeId, setCurrentCompanyTypeId] = useState<MenuSelectValue>(undefined);
  const [currentCompanyName, setCurrentCompanyName] = useState<string | undefined>(undefined);
  const handleCompanyChange = (value: MenuSelectValue, option: Record<number | string, string>) => {
    setCurrentCompanyId(value);
    setCurrentCompanyName(value ? option[value] : undefined);
  };
  const handleCompanyTypeChange = (value: MenuSelectValue) => {
    setCurrentCompanyTypeId(value);
    handleCompanyChange(undefined, {});
  };
  const handleExamChange = (value: MenuSelectValue) => {
    setCurrentExamId(value);
  };
  const { fetchData, data } = useFetchData<number[]>({
    url: `${ApiDefinition.GET_SINGLE_EXAM_STAT.path}${currentExamId}`,
  });
  //載入資料
  useEffect(() => {
    fetchData({ type: currentCompanyTypeId, name: currentCompanyName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyTypeId, currentCompanyName, currentExamId]);

  const mData = useMemo(
    () =>
      data
        ? data.reduce(
            (previousValue, currentValue, currentIndex) => ({
              ...previousValue,
              [`field${currentIndex + 1}`]: currentValue,
            }),
            {}
          )
        : {},
    [data]
  );
  const columns: DashBoardUnitColumn<SingleExamStatRow>[] = [
    {
      accessKey: "field1",
      title: "測驗報名人數",
      unit: "人",
    },
    {
      accessKey: "field2",
      title: "考試通過人數",
      unit: "人",
    },
    {
      accessKey: "field3",
      title: "通過率",
      unit: "%",
    },
    {
      accessKey: "field4",
      title: "持證人人數",
      unit: "人",
    },
    {
      accessKey: "field5",
      title: "認證率",
      unit: "%",
    },
  ];
  return (
    <DashBoardUnit
      title="單次 RFA考試統計"
      columns={columns}
      data={mData} //資料從這邊傳入
      toolbar={
        //右上角選單
        <>
          <ExamSelect onChange={handleExamChange} value={currentExamId} />
          <CompanyTypeSelect onChange={handleCompanyTypeChange} value={currentCompanyTypeId} />
          <CompanySelect companyTypeId={currentCompanyTypeId} onChange={handleCompanyChange} value={currentCompanyId} />
        </>
      }
    />
  );
}

export default SingleExamStatBlock;
