import {Box, BoxProps} from "@chakra-ui/react";
import React from "react";

function PageContainer(props: Omit<BoxProps, "sx">) {
  return (
    <Box
      px={8}
      py={5}
      w="100%"
      h="100%"
      sx={{
        backgroundColor: "white",
        //minWidth: 950,
      }}
      {...props}
    />
  );
}

export default PageContainer;
