import {Box, Button, Flex} from "@chakra-ui/react";
import {ColumnDef} from "@tanstack/react-table";
import ApiDefinition from "api/ApiDefinition";
import ChakraTable from "common/ChakraTable";
import NumericText from "common/NumericText";
import dayjs from "dayjs";
import {useFetchList, usePageControl} from "hooks";
import React, {forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {signUpTypeMapping, sourceTypeMapping} from "../commonValues";
import {ActivitySignUpData} from "../type";
import SignUpDetailModal from "./SignUpDetailModal";

export interface SignUpListRefProps {
  reload: () => void;
}

export interface SignUpListDefProps {
  id: string;
}

function SignUpListDef({ id }: SignUpListDefProps, ref: Ref<SignUpListRefProps>) {
  const [dataToShow, setDataToShow] = useState<ActivitySignUpData | null>(null);
  const handleCloseModal = () => {
    setDataToShow(null);
  };
  const { fetchData, rows, total } = useFetchList<ActivitySignUpData>({
    url: ApiDefinition.GET_ACTIVITY_SIGNUP_LIST.path,
  });
  const { pagination, pageCount, setPagination, setDataCount } = usePageControl();
  const loadData = useCallback(() => {
    fetchData({
      pageIndex: pagination.pageIndex,
      perPage: pagination.pageSize,
      activityId: id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pagination.pageIndex]);
  useImperativeHandle(
    ref,
    () => ({
      reload: loadData,
    }),
    [loadData]
  );
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pagination.pageIndex]);
  useEffect(() => {
    setDataCount(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);
  const columns: ColumnDef<ActivitySignUpData, any>[] = [
    {
      id: "f_class_num",
      header: "結業証號",
      cell: (info) => {
        const value = info.row.original.user?.classNum;
        return value ?? "-";
      },
    },
    {
      id: "f_mobile",
      header: "手機號碼",
      cell: (info) => {
        const value = info.row.original.user?.mobile;
        return value ?? "-";
      },
    },
    {
      id: "f_email",
      header: "Email",
      cell: (info) => {
        const value = info.row.original.user?.email;
        return value ?? "-";
      },
    },
    {
      id: "f_serial_number",
      header: "證書證號",
      cell: (info) => {
        const value = info.row.original.userCertificate?.serialNumber;
        return value ?? "-";
      },
    },
    {
      header: "報名時間",
      accessorKey: "createdAt",
      cell: (info) => {
        const value = info.getValue();
        return value ? dayjs(value).format("YYYY/MM/DD") : "-";
      },
    },
    {
      header: "認證時數",
      accessorKey: "mins",
      cell: (info) => {
        const value = info.getValue();
        return value ? (
          <>
            <NumericText value={value / 60} decimalScale={1} />
            小時
          </>
        ) : (
          "0.0小時"
        );
      },
    },
    {
      header: "類別",
      accessorKey: "sourceType",
      cell: (info) => {
        const value = info.getValue();
        return sourceTypeMapping[value.toString() as keyof typeof sourceTypeMapping];
      },
    },
    {
      id: "f_signup_type",
      header: () => <Box textAlign="center">報名類別</Box>,
      accessorKey: "signupType",
      cell: (info) => {
        const value = info.getValue();
        const row = info.row.original;
        const text = signUpTypeMapping[value.toString() as keyof typeof signUpTypeMapping];
        return (
          <Flex justifyContent="center">
            <Button
              variant="customSolid"
              onClick={() => {
                setDataToShow(row);
              }}
            >
              {text}
            </Button>
          </Flex>
        );
      },
    },
  ];
  return (
    <>
      <ChakraTable<ActivitySignUpData>
        variant="custom"
        data={rows}
        columns={columns}
        fullWidth
        TableOptions={{
          state: { pagination },
          pageCount,
          onPaginationChange: setPagination,
          manualPagination: true,
        }}
      />
      <SignUpDetailModal data={dataToShow} onClose={handleCloseModal} />
    </>
  );
}

const SignUpList = forwardRef(SignUpListDef);
export default SignUpList;
