import {Radio, RadioGroup, VStack} from "@chakra-ui/react";
import ImportModal, {ImportModalProps} from "common/ImportModal";
import React, {useState} from "react";
import {importTypeMapping} from "./commonValues";

export type ExamImportModalProps = Omit<ImportModalProps, "additionView">;

function ExamImportModal({ params, ...props }: ExamImportModalProps) {
  const [importTypeValue, setImportValue] = useState("1");
  console.log('import params', params);
  if(params.importType === 4 || params.importType === 5 ){
    //應考證明 & 成績匯入 不顯示個人和團體報名選項
    return(
    <ImportModal
      {...props}
      params={{ ...params, importType: importTypeValue }}
    />
  );
  }
  return (
    <ImportModal
      {...props}
      params={{ ...params, importType: importTypeValue }}
      additionView={
        <RadioGroup value={importTypeValue} onChange={setImportValue} w="100%">
          <VStack spacing={3} align="start">
            {Object.keys(importTypeMapping).map((value, index) => {
              const text = importTypeMapping[value as keyof typeof importTypeMapping];
              return (
                <Radio key={index} variant="custom" value={value}>
                  {text}
                </Radio>
              );
            })}
          </VStack>
        </RadioGroup>
      }
    />
  );
}

export default ExamImportModal;
