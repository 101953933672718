import {PaginationState} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";

export interface PageControlConfig {
  pageSize: number;
}

function usePageControl(config: PageControlConfig = { pageSize: 20 }) {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: config.pageSize,
  });
  const [dataCount, setDataCount] = useState<number>(0);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const pageCount = useMemo(() => {
    if (pageSize > 0) {
      return Math.ceil(dataCount / pageSize);
    }
    return -1;
  }, [pageSize, dataCount]);
  useEffect(() => {
    if (pageIndex > pageCount - 1 && pageIndex > 0) {
      setPagination({
        pageIndex: 0,
        pageSize,
      });
    }
  }, [pageCount, pageIndex, pageSize]);
  return { pagination, pageCount, setPagination, setDataCount };
}

export default usePageControl;
