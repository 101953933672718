import style from './../css/exam_candidate_panel.module.css'

import {useEffect, useState} from 'react'

import RoutePath from '../../routes/RoutePathDefinition'
import {generatePath, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom"
import {stringify} from 'query-string'

import {Menu, MenuButton, MenuList, Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure} from '@chakra-ui/react'

import dropdown_arrow from '../../svg-files/dropdown_arrow.svg'
import next_page_arrow from '../../svg-files/next-page-arrow.svg'
import modal_cross from '../../svg-files/modal-cross.svg'


import LoadingComponent from './LoadingComponent'
import api from '../../api/Api'

const page_rows =20
const max_pagination_btn_count =10

let ExamCandidatePanelComponent =()=>{

  const paymentDisclosure = useDisclosure()
  const [paymentParam, setPaymentParam] = useState(['', '', '']);

  const ticketDisclosure = useDisclosure()
  const [ticketParam, setTicketParam] = useState(['', '', '']);

  const detendTimeDisclosure = useDisclosure()
  const [detendTimeParam, setDetendTimeParam] = useState(['','','']);

  const [criteria, setCriteria] = useState('')
  const [purchaseState, setPurchaseState] = useState('')
  const [totaldatacount, setTotalDataCount] =useState(0)
  const [currpageno, setCurrpageno] =useState(0)
  const [currpaginationno, setCurrpaginationno] =useState(0)

  const navigate =useNavigate()
  const params =useParams()
  const query =useSearchParams()
  const location =useLocation()

  const [tabledata, settabledata] =useState([])
  const [tablebusy, setTablebusy] =useState(true)

  let update_table = (currpageno, examId, criteria, status)=>{
    return new Promise((resolve, reject)=>{
      setTablebusy(true)
      //console.log(page_rows, currpageno, examId, criteria, status)
      api.get_signup_list(page_rows, currpageno, examId, criteria, status).then((res) => {
        console.log(res)

        let tmp_tabledata =[];
        let col_name_mapper = [
          'chName', 'classNum', 'mobile', 'applyType', 'testResult', 'applyGroup', 'applyArea', 'status','allowDelay'
        ]
  
        for (let i = 0; i < res.rows.length; ++i) {
          tmp_tabledata.push(
            // eslint-disable-next-line no-loop-func
            col_name_mapper.map(col_name => {
              //console.log(v, res.rows[i][v]);
              if (col_name === undefined) {
                return '-'
              }
              if (col_name === "status") {
                if (res.rows[i][col_name]==="1"){
                  return '完成'
                }
                if (res.rows[i][col_name] ==="-1") {
                  return '取消'
                }
                if (res.rows[i][col_name] === "0") {
                  return '未付'
                }
                if (res.rows[i][col_name] === null) {
                  return '-'
                }
              }
              
              return res.rows[i][col_name] === null ? '-' : res.rows[i][col_name]
            })
          )
        }

        settabledata(tmp_tabledata)
        setTotalDataCount(res.total)
  
        setTablebusy(false)
        resolve()

      }).catch((e) => {
        //console.log(e);
      }).finally(() => {
        setTablebusy(false)
        resolve()
      })

    })

  }

  const purchase_list =[
    '付款狀態', '已付款', '未付款', '取消'
  ]

  const purchase_list_meta ={
    '付款狀態': ['', 0],
    '已付款': ['1', 1],
    '未付款': ['0', 2], 
    '取消': ['-1', 3],
  }

  useEffect(()=>{
    //pageno
    let tmp_pageno =1
    //console.log('44 - current page no : '+params?.pageno)
    if (params?.pageno !==undefined && params?.pageno !==''){
      tmp_pageno =params?.pageno
    }
    setCurrpaginationno(parseInt(parseInt(tmp_pageno-1)/max_pagination_btn_count))
    setCurrpageno(parseInt(tmp_pageno-1))

    //criteria
    let tmp_criteria =''
    if (query[0].get('query')!==null){
      tmp_criteria =query[0].get('query')
    }
    setCriteria(tmp_criteria)

    //pdu
    let payment_status = '';
    let examId = params.id;

    //console.log(`query[0].get('payment_status'):`, query[0].get('payment_status'));
    
    //console.log(`purchase_list_meta.hasOwnProperty(query[0].get('payment_status'))[0]:`, purchase_list_meta.hasOwnProperty(query[0].get('payment_status'))[0]);

    if (query[0].get('payment_status') !== null && 
      purchase_list_meta.hasOwnProperty(query[0].get('payment_status'))){
      
      payment_status = purchase_list_meta[query[0].get('payment_status')][0];
      //console.log(`purchase_list_meta[query[0].get('payment_status'): ${payment_status}`);
    }
    setPurchaseState(payment_status)

    update_table(tmp_pageno - 1, examId, tmp_criteria, payment_status,)

  },[location.pathname, location.search])

  const table_head=[
    '姓名', '結業證號', '手機號碼', '身份別', '考試結果', '報名類別', '應考資料', '付款狀態', '延期考試'
  ]
  const head_min_width=[
    '150px', '150px', '150px', '110px', '110px', '110px', '110px', '110px', '110px'
  ]

  let start_idx =currpageno*page_rows
  let end_idx =Math.min(start_idx+page_rows, totaldatacount)
  let page_count =Math.ceil(totaldatacount/page_rows)

  let pagination_start_idx =currpaginationno*max_pagination_btn_count
  let pagination_end_idx =Math.min(pagination_start_idx+max_pagination_btn_count, page_count)
  let pagination_count =Math.ceil(page_count/max_pagination_btn_count)

  let pdu_list =['111年第一次', '111年第二次', '111年第三次']
  let [selectedPdu, setSelectedPdu] = useState(0)
  return(
    <>

      <div className={['col-auto', 'row', 'no-wrap', 'justify-between', 'items-center', ].join(' ')} style={{marginTop: '18px'}}>
        <form className={['col-auto', 'row', style['custom-search-box-container']].join(' ')} onSubmit={(e)=>{
          e.preventDefault()
          //console.log('147 - update table from the criteria : '+criteria)

          setCurrpageno(0)
          navigate(generatePath(RoutePath.EXAM_CANDIDATE_PAGE.path, {id: params.id, pageno: 1})+'?'+stringify({...Object.fromEntries([...query[0]]), ...{query: criteria}}))

        }}>
          <input className={['col-auto', style['custom-search-box']].join(' ')} type='text' placeholder='輸入姓名搜尋' value={criteria} onChange={e=>setCriteria(e.target.value)}/>
          <button className={[style['custom-search-box-magnifier']].join(' ')} type="submit"/>
        </form>

        <div className={['col-auto', 'row', 'no-wrap', 'items-center'].join(' ')}>

          <Menu matchWidth>
            {({ isOpen, onClose }) => (
              <>
              <MenuButton style={{marginLeft: '20px', minWidth: '130px'}} className={style['custom-outline-button']}>
                <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')}>
                  <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>{purchase_list[purchaseState]}</label>
                  <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                </div>
              </MenuButton>
              <MenuList style={{minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px',}} className={['column'].join(' ')}>
                {
                  purchase_list.map((v,idx)=>{
                    {/* 
                    console.log(`purchase_list_meta[v] :`, [purchase_list_meta[v], idx]);
                    if (purchase_list_meta[v]==="1"){
                      
                    } 
                    */}
                    return(
                      <button key={idx} className={['col-auto', 'row', 'items-center', style['custom-menu-item'], style[purchase_list_meta[v][0]===purchaseState?'active':'']].join(' ')} 
                      onClick={()=>{
                        
                        setPurchaseState(purchase_list_meta[v][0]);
                        //console.log(`v :`, v);
                        //console.log(`purchaseState :`, purchaseState);
                        onClose()
                        
                        let new_url =generatePath(
                          RoutePath.EXAM_CANDIDATE_PAGE.path, 
                          { id: params.id, pageno: 1 }) + '?' +
                            stringify({ 
                            ...Object.fromEntries([...query[0]]), 
                            ...{ 'payment_status': purchase_list_meta[v][0] } 
                          });

                        //console.log("new_url", new_url);
                        navigate(new_url);
                      }}>
                        <label className={['col-auto']} style={{lineHeight: '100%'}}>{v}</label>
                      </button>
                    )
                  })
                }
              </MenuList>
              </>
            )}
          </Menu>

        </div>
      </div>

      <div className={['col-auto', 'row'].join(' ')} style={{position: 'relative', marginTop: '18px', width: '100%', }}>
        <table className={style['custom-table']} style={{paddingBottom: '16px', display: 'block', width: '100%', fontSize: '18px', fontWeight: '400', overflowX: 'scroll'}}>
          <thead style={{backgroundColor: '#0e4e96'}}>
          <tr style={{height: '50px', color: 'white', }}>
            {table_head.map((v,idx)=>{
              return (
                <th style={{textAlign: 'left', paddingLeft: idx===0?'10px':'0', minWidth: head_min_width[idx]}} key={idx}>{v}</th>
              )
            })}
          </tr>
          </thead>
          <tbody>
          {tabledata.length>0&&(tabledata.map((v,idx)=>{
            console.log(`rows[${idx}]`,v);
            return (
              <tr key={idx} className={style['custom-table-row-hover']} style={{height: '50px', backgroundColor: idx%2===1?'#e7edf5':'white'}}>
              { v.map((subv, subidx) => {

                if (subidx < 6) {
                  return (<td style={{ paddingRight: '5px', textAlign: 'left', paddingLeft: subidx === 0 ? '10px' : '0', borderBottom: '1px solid black' }} key={subidx}>{subv}</td>)
                }
                if (subidx === 6) {
                  return (<td style={{ paddingRight: '5px', textAlign: 'left', paddingLeft: '0', borderBottom: '1px solid black' }} key={subidx}>
                    <button style={{ color: '#00a3ff' }} onClick={() => {
                      console.log(`應考資料`, [params.id, v]);
                      setTicketParam([params.id, v[0], v[1]]);
                      ticketDisclosure.onOpen();
                    }}>{v[6]}</button></td>
                  )
                }
                if (subidx === 7) {
                  return (<td style={{ paddingRight: '5px', textAlign: 'left', paddingLeft: '0', borderBottom: '1px solid black' }} key={subidx}>
                    <button style={{ color: '#00a3ff' }} onClick={() => {
                      console.log(`付款狀態`, [params.id, v[0], v[1]]);
                      setPaymentParam([params.id, v[0], v[1]]);
                      paymentDisclosure.onOpen();
                    }}>{v[7]}</button></td>
                  )
                }
                if (subidx === 8) {
                  return (<td style={{ paddingRight: '5px', textAlign: 'left', paddingLeft: '0', borderBottom: '1px solid black' }} key={subidx}>
                    <button style={{ color: '#00a3ff' }} onClick={() => {
                      console.log(`延期考試`, [params.id, v[0], v[1]]);
                      setDetendTimeParam([params.id, v[0], v[1]]);
                      detendTimeDisclosure.onOpen();
                    }}>延期</button></td>
                  )
                }
                  
                }) 
              }
              </tr>
            )
          }))}
          </tbody>
        </table>
        {tablebusy&&(
          <LoadingComponent />
        )}
      </div>

      {tabledata.length>0 &&(
        <div className={['col-auto', 'row', 'items-center', 'justify-center'].join(' ')} style={{marginTop: '52px', marginBottom: '64px'}}>
          {
            currpaginationno>0&&(
              <button className={['row', 'justify-center', 'items-center', style['page-btn']].join(' ')} onClick={()=>{setCurrpaginationno(currpaginationno-1)}}>
                <img alt="" src={next_page_arrow} style={{width: '4.79px', height: '8.31px', transform: 'rotate(180deg)'}}/>
              </button>
            )
          }
          {
            [...Array(Math.max(0, pagination_end_idx-pagination_start_idx)).keys()].map(v=>{
              return (
                <button key={v+currpaginationno*max_pagination_btn_count} className={[style['page-btn'], style[(v+currpaginationno*max_pagination_btn_count)===currpageno?'active':'']].join(' ')} 
                onClick={()=>{
                  window.scrollTo(0, 0)
                  setCurrpageno(v+currpaginationno*max_pagination_btn_count)
                  // window.history.pushState(null, null, '#'+generatePath(RoutePath.STUDENTS_PAGE.path, {pageno:1+v+currpaginationno*max_pagination_btn_count}))
                  navigate(generatePath(RoutePath.EXAM_CANDIDATE_PAGE.path, {id: params.id, pageno: 1+v+currpaginationno*max_pagination_btn_count})+'?'+stringify({...Object.fromEntries([...query[0]]),}))
                }}>{v+1+currpaginationno*max_pagination_btn_count}</button>
              )
            })
          }
          {
            currpaginationno<(pagination_count-1)&&(
              <button className={['row', 'justify-center', 'items-center', style['page-btn']].join(' ')} onClick={()=>{setCurrpaginationno(currpaginationno+1)}}>
                <img alt="" src={next_page_arrow} style={{width: '4.79px', height: '8.31px'}}/>
              </button>
            )
          }
        </div>
      )}
      {/* 付款資訊對話框 */}
      <Modal onClose={paymentDisclosure.onClose} isOpen={paymentDisclosure.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent className={['column'].join(' ')} style={{ paddingLeft: '30px', paddingRight: '30px', paddingTop: '20px', paddingBottom: '30px', minWidth: '410px' }}>

          <ModalBody className={['col-auto', 'column', 'items-center'].join(' ')} style={{ padding: '0px' }}>
            <div className={['col-auto', 'row', 'items-center', 'justify-between'].join(' ')} style={{ width: '100%', borderBottom: '1px solid #bdbdbd', paddingBottom: '8px' }}>
              <div className={['col-auto']} style={{ fontSize: '28.13px' }}>付款資訊</div>
              <button className={['col-auto'].join(' ')} onClick={() => {
                console.log(`close ticket`);
                paymentDisclosure.onClose()
              }}>
                <img alt="" src={modal_cross} style={{ width: '18.67px', height: '18.67px' }} />
              </button>
            </div>
            <div className={['col-auto']} style={{ fontSize: '28.13px' }}>考生-{paymentParam[1]} {paymentParam[2]}</div>
            <button className={['col-auto', style['custom-button']].join(' ')} style={{ width: '100%', marginTop: '30px', marginBottom: '20px' }} onClick={() => {
              console.log(`確認延期`, paymentParam);
              paymentDisclosure.onClose()
            }}>確定</button>
            <label style={{ color: '#757575', fontSize: '18px' }}>上次更新時間 2022/05/16 16:20</label>

          </ModalBody>

        </ModalContent>
      </Modal>
      {/* 准考證對話框 */}
      <Modal onClose={ticketDisclosure.onClose} isOpen={ticketDisclosure.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent className={['column'].join(' ')} style={{ paddingLeft: '30px', paddingRight: '30px', paddingTop: '20px', paddingBottom: '30px', minWidth: '410px' }}>

          <ModalBody className={['col-auto', 'column', 'items-center'].join(' ')} style={{ padding: '0px' }}>
            <div className={['col-auto', 'row', 'items-center', 'justify-between'].join(' ')} style={{ width: '100%', borderBottom: '1px solid #bdbdbd', paddingBottom: '8px' }}>
              <div className={['col-auto']} style={{ fontSize: '28.13px' }}>准考證</div>
              <button className={['col-auto'].join(' ')} onClick={() => {
                console.log(`close ticket`);
                ticketDisclosure.onClose()
              }}>
                <img alt="" src={modal_cross} style={{ width: '18.67px', height: '18.67px' }} />
              </button>
            </div>
            <div className={['col-auto']} style={{ fontSize: '28.13px' }}>考生-{ticketParam[1]} {ticketParam[2]}</div>
            <button className={['col-auto', style['custom-button']].join(' ')} style={{ width: '100%', marginTop: '30px', marginBottom: '20px' }} onClick={() => {
              console.log(`准考證對話框`, ticketParam);
              ticketDisclosure.onClose()
            }}>確定</button>
            <label style={{ color: '#757575', fontSize: '18px' }}>上次更新時間 2022/05/16 16:20</label>

          </ModalBody>

        </ModalContent>
      </Modal>
      {/* 延期考試對話框 */}
      <Modal onClose={detendTimeDisclosure.onClose} isOpen={detendTimeDisclosure.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent className={['column'].join(' ')} style={{paddingLeft: '30px', paddingRight: '30px', paddingTop: '20px', paddingBottom: '30px', minWidth: '410px'}}>

          <ModalBody className={['col-auto', 'column', 'items-center'].join(' ')} style={{padding: '0px'}}>
            <div className={['col-auto', 'row', 'items-center', 'justify-between'].join(' ')} style={{width: '100%', borderBottom: '1px solid #bdbdbd', paddingBottom: '8px'}}>
              <div className={['col-auto']} style={{ fontSize: '28.13px' }}>延期考試</div>
              <button className={['col-auto'].join(' ')} onClick={()=>{
                console.log(`取消延期`);
                detendTimeDisclosure.onClose()
                }}>
                <img alt="" src={modal_cross} style={{width: '18.67px', height: '18.67px'}}/>
              </button>
            </div>
            <div className={['col-auto']} style={{ fontSize: '28.13px' }}>考生-{detendTimeParam[1]} {detendTimeParam[2]}</div>
            <button className={['col-auto', style['custom-button']].join(' ')} style={{width: '100%', marginTop: '30px', marginBottom: '20px'}} onClick={()=>{
              console.log(`延期考試對話框`,detendTimeParam);
              detendTimeDisclosure.onClose()
            }}>確定</button>
            <label style={{color: '#757575', fontSize: '18px'}}>上次更新時間 2022/05/16 16:20</label>
            
          </ModalBody>

        </ModalContent>
      </Modal>

    </>
  )
}

export default ExamCandidatePanelComponent