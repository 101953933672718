import {Card} from "@chakra-ui/react";
import ApiDefinition from "api/ApiDefinition";
import {getErrorMessage} from "common/errMsgHelper";
import PageContainer from "common/PageContainer";
import WarningDialog from "common/WarningDialog";
import dayjs from "dayjs";
import {useAPIRequest, useConfirmDisclosure, useCustomToast} from "hooks";
import {useMemo} from "react";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import PathDefinition from "routes/RoutePathDefinition";
import ActivityForm from "./ActivityForm";
import ActivityDetailHeader from "./detail/ActivityDetailHeader";
import useFetchActivity from "./detail/useFetchActivity";
import {ActivityFormValue} from "./type";

function ActivityEdit() {
  const toast = useCustomToast();
  const { id } = useParams();
  const navigate = useNavigate();
  const { processRequest } = useAPIRequest();
  const { loaded, data } = useFetchActivity();
  const handleConfirm = (formData: ActivityFormValue) => {
    processRequest(ApiDefinition.UPDATE_ACTIVITY_DETAIL, { ...formData, activityId: id })
      .then((res) => {
        toast("success", "活動資訊編輯成功");
        navigate(generatePath(PathDefinition.ACTIVITY_DETAIL.path, { id }));
      })
      .catch((err) => {
        const message = getErrorMessage(err, "活動資訊編輯失敗");
        toast("error", message);
        console.error(err);
      });
  };
  const initialValues = useMemo(() => {
    if (data) {
      return {
        ...data,
        startTime: dayjs(`2000-01-01 ${data.startTime}:00`).toDate(),
        endTime: dayjs(`2000-01-01 ${data.endTime}:00`).toDate(),
        enabled: data.enabled.toString(),
      };
    }
    return undefined;
  }, [data]);
  const handleCancel = () => {
    navigate(generatePath(PathDefinition.ACTIVITY_DETAIL.path, { id }));
  };
  const { handleSubmit, dialogProps } = useConfirmDisclosure<ActivityFormValue>({
    onConfirm: handleConfirm,
  });
  if (!loaded) {
    return <PageContainer>載入中...</PageContainer>;
  }
  return (
    <PageContainer>
      <ActivityDetailHeader />
      <Card variant="custom" px={20} py={10}>
        <ActivityForm onSubmit={handleSubmit} initialValues={initialValues} onCancel={handleCancel} isEdit />
      </Card>
      <WarningDialog
        title="請注意！"
        message={
          <>
            按下確定後，活動資訊將被更新，
            <br />
            請確認資訊是否正確無誤。
          </>
        }
        {...dialogProps}
      />
    </PageContainer>
  );
}

export default ActivityEdit;
