import {IconButton, Input, InputGroup, InputProps, InputRightElement} from "@chakra-ui/react";
import {mdiMagnify} from "@mdi/js";
import Icon from "@mdi/react";
import React, {MouseEventHandler} from "react";

export type SearchBoxProps = InputProps & {
  onSearchButton?: MouseEventHandler | undefined;
};

function SearchBox({ onSearchButton, isDisabled, ...props }: SearchBoxProps) {
  return (
    <InputGroup width={200}>
      <Input {...props} borderColor="primary.500" _placeholder={{ color: "primary.500" }} isDisabled={isDisabled} />
      <InputRightElement color="primary.500">
        <IconButton
          variant="outline"
          colorScheme="primary"
          aria-label="Count Down"
          fontSize="20px"
          onClick={onSearchButton}
          disabled={isDisabled}
          icon={<Icon path={mdiMagnify} size={1} />}
          style={{ border: 0 }}
        />
      </InputRightElement>
    </InputGroup>
  );
}

export default SearchBox;
