// ActivityForm 貼過來的
import { VStack, Card } from "@chakra-ui/react";
import ApiDefinition from "api/ApiDefinition";
import { getErrorMessage } from "common/errMsgHelper";
import PageContainer from "common/PageContainer";
import PageHeader from "common/PageHeader";
import WarningDialog from "common/WarningDialog";
import { useAPIRequest, useConfirmDisclosure, useCustomToast } from "hooks";
import React from "react";
// import { generatePath, useNavigate } from "react-router-dom";
import EmailForm from '../setting/EmailForm'
import { ActivityEmailFormValue } from '../activity/type'


function EmailSetupComponent() {
  const toast = useCustomToast();
  // const navigate = useNavigate();
  const { processRequest } = useAPIRequest();
  // ActivityEmailFormValue 有先設定 title & content
  const handleConfirm = (formData: ActivityEmailFormValue) => {
    // 要改發 email 的 api 
    console.log("formData", formData);

    // processRequest(ApiDefinition.CREATE_ACTIVITY, formData)
    //   .then((res) => {
    //     toast("success", "郵件發送成功");
    //     // 本來是要轉跳頁面
    //     // navigate(generatePath(PathDefinition.ACTIVITY.path));
    //   })
    //   .catch((err) => {
    //     const message = getErrorMessage(err, "郵件發送失敗");
    //     toast("error", message);
    //     console.error(err);
    //   });
  };
  const { handleSubmit, dialogProps } = useConfirmDisclosure<ActivityEmailFormValue>({
    onConfirm: handleConfirm,
  });

  const handleCancel = () => {
    // 原本是要轉跳頁面 
    // navigate(PathDefinition.ACTIVITY.path);
  };


  return (
    <>
      <PageContainer>
        <VStack spacing={3} align="start">
          <PageHeader
            menuIconName="settings"
            title="Email 設定"
          />
        </VStack>
        <Card variant="custom" px={8} py={8}>
          <EmailForm onSubmit={handleSubmit} onCancel={handleCancel} />
        </Card>
        {/* <WarningDialog
          title="請注意！"
          message={
            <>
              按下確定後，將發送郵件，
              <br />
              請確認資訊是否正確無誤。
            </>
          }
          {...dialogProps}
        /> */}
      </PageContainer>
    </>

  );
}

export default EmailSetupComponent