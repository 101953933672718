import style from './css/student.module.css'

import people_dark_svg from '../svg-files/people-dark.svg'
import dropdown_arrow from '../svg-files/dropdown_arrow.svg'
import dropdown_calendar from '../svg-files/calendar.svg'
import next_page_arrow from '../svg-files/next-page-arrow.svg'
import modal_cross from '../svg-files/modal-cross.svg'
import document_normal from '../svg-files/document-normal.svg'
import document_grey from '../svg-files/document-grey.svg'
import green_check from '../svg-files/green-check.svg'
import red_cross from '../svg-files/red-cross.svg'

import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast
} from '@chakra-ui/react'
import {useEffect, useState} from 'react'
import {generatePath, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom"
import RoutePath from '../routes/RoutePathDefinition'

import MenuListDatePicker from './components/MenuListMonthPicker.js'
import {stringify} from 'query-string'

import api from '../api/Api'
import LoadingComponent from './components/LoadingComponent'

import ImportModal from "common/ImportModal";
import ApiDefinition from "api/ApiDefinition";

const table_head=[
  '姓名', '結業證號', '身份證字號', 'M3紀錄', '考試結果', '證書證號', '教育時數', '計算機訂閱到期日', '更多',
]

const pdu_list=[
  '教育時數', '0', '1-10', '11-20', '21-29', '30',
]
const pdu_list_meta={
  '0': [0, 0, 1],
  '1-10':[1,10, 2],
  '11-20':[11,20, 3],
  '21-29':[21,29, 4],
  '30':[30,30, 5],
}

const m3_record=[
  'M3申請紀錄', '已申請', '未申請',
]
const m3_meta={
  '已申請':1,
  '未申請':2,
}

const exam_result=[
  '考試結果', '已通過', '未通過', '無',
]
const lastresult_hm =['無', '未通過', '通過']
const lastresult_meta={
  '無':[-1, 3], 
  '未通過':[0, 2],
  '已通過':[1, 1],
}

const page_rows =20
const max_pagination_btn_count =10

let StudentsComponent =({props})=>{

  const disclosure = useDisclosure();
  /* const { fetchData, rows, total } = useFetchList({ url: ApiDefinition.GET_MEMBER_LIST.path });
  const { pagination, pageCount, setPagination, setDataCount } = usePageControl();
  const loadData = useCallback(() => {
    fetchData({
      pageIndex: pagination.pageIndex,
      perPage: pagination.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex]); */

  const [criteria, setCriteria] = useState('')
  const [selectedPdu, setSelectedPdu] =useState(0)
  const [selectedM3Record, setSelectedM3Record] =useState(0)
  const [selectedExam, setSelectedExam] =useState(0)

  let date =new Date()
  const enable_state =useState(false)
  const year_state =useState(date.getFullYear())
  const month_state =useState(date.getMonth()+1)
  const expire_date =useState(undefined)

  const [totaldatacount, setTotalDataCount] =useState(0)
  const [currpageno, setCurrpageno] =useState(0)
  const [currpaginationno, setCurrpaginationno] =useState(0)
  const memberModalDisclosure =useDisclosure()
  const scoreModalDisclosure =useDisclosure()
  const toast = useToast()
  const navigate =useNavigate()
  const params =useParams()
  const query =useSearchParams()
  const location =useLocation()

  const [tabledata, settabledata] =useState([])
  const [tablebusy, setTablebusy] =useState(true)

  let update_table = (currpageno, criteria, pdu_from, pdu_to, m3, exam, expire)=>{
    return new Promise((resolve, reject)=>{
      setTablebusy(true)
      api.get_profile_list(page_rows, currpageno, criteria, pdu_from, pdu_to, m3 , exam, expire).then((res)=>{
        //console.log(res)
        let tmp_tabledata =[]
  
        // lastResult 1: 通過 0:未通過 -1:無
        // ['uid0',   '陳貞妙',   'RFAPM1101271',  'E123456789',  '未申請',   '已通過',            'RFAC-TESET-0', '100',      '2022-12-29',],
        let col_name_mapper=[
            'userId', 'chName', 'classNum', 'idNum', 'm3', 'lastTestResult', 'serialNumber', 'pdu', 'expireAt',
        ]
        for(let i=0;i<res.rows.length;++i){
          tmp_tabledata.push(
            col_name_mapper.map((v)=>{
              if (v===undefined){
                return '-'
              }
              if (v === 'm3') {
                return res.rows[i][v] === 0 ? '-' : '已申請'
              }
              if (v==='lastTestResult'){
                return res.rows[i][v]===null?'-':lastresult_hm[res.rows[i][v]+1]
              }
              return res.rows[i][v]===null?'-':res.rows[i][v]
            })
          );
        }
  
        settabledata(tmp_tabledata)
        setTotalDataCount(res.total)
  
      }).catch((e)=>{  
        //process 403 issue
        //...
        
      }).finally(()=>{
        setTablebusy(false)
        resolve()
      })
    })

  }

  useEffect(()=>{
    //pageno
    let tmp_pageno =1
    //console.log('44 - current page no : '+params?.pageno)
    if (params?.pageno !==undefined && params?.pageno !==''){
      tmp_pageno =params?.pageno
      console.log(`tmp_pageno`, tmp_pageno)
    }
    setCurrpaginationno(parseInt(parseInt(tmp_pageno-1)/max_pagination_btn_count))
    setCurrpageno(parseInt(tmp_pageno-1))

    //criteria
    let tmp_criteria =''
    if (query[0].get('query')!==null){
      tmp_criteria =query[0].get('query')
      console.log(`tmp_criteria`, tmp_criteria)
    }
    setCriteria(tmp_criteria)

    //pdu
    let tmp_pdu_from =undefined
    let tmp_pdu_to =undefined
    let tmp_pdu =[0,0,0]
    if (query[0].get('pdu')!==null && pdu_list_meta.hasOwnProperty(query[0].get('pdu'))){
      tmp_pdu =pdu_list_meta[query[0].get('pdu')]
      tmp_pdu_from =tmp_pdu[0]
      tmp_pdu_to =tmp_pdu[1]
      console.log(`tmp_pdu`, tmp_pdu)
    }
    setSelectedPdu(tmp_pdu[2])

    //m3
    let tmp_m3 =0
    if (query[0].get('m3')!==null && m3_meta.hasOwnProperty(query[0].get('m3'))){
      tmp_m3 =m3_meta[query[0].get('m3')];
      console.log(`tmp_m3`, tmp_m3)
    }
    setSelectedM3Record(tmp_m3)

    //exam
    let tmp_exam =[undefined, 0]
    // let lastresult_hm =['無', '未通過', '通過']
    if (query[0].get('exam')!==null && lastresult_meta.hasOwnProperty(query[0].get('exam'))){
      tmp_exam =lastresult_meta[query[0].get('exam')]
    }
    setSelectedExam(tmp_exam[1])

    //expire
    let tmp_expire_year =date.getFullYear()
    let tmp_expire_month =date.getMonth()+1
    let tmp_expire_enable=false
    let expire_regex =/^\d{4}-([1-9]|1[0-2])$/
    if (query[0].get('expire')!==null && expire_regex.test(query[0].get('expire'))===true){
       let cc =query[0].get('expire').split('-')
       tmp_expire_year =cc[0]
       tmp_expire_month =cc[1]

       tmp_expire_enable =true
    }
    year_state[1](parseInt(tmp_expire_year))
    month_state[1](parseInt(tmp_expire_month))
    enable_state[1](tmp_expire_enable)

    let pad =(n)=>{return n<10 ? '0'+n : n}

    update_table(tmp_pageno - 1, tmp_criteria, tmp_pdu_from, tmp_pdu_to, tmp_m3, tmp_exam[0], tmp_expire_enable?(tmp_expire_year+'-'+pad(tmp_expire_month)):undefined)

  },[location.pathname, location.search])
  
  let start_idx =currpageno*page_rows
  let end_idx =Math.min(start_idx+page_rows, totaldatacount)
  let page_count =Math.ceil(totaldatacount/page_rows)

  let pagination_start_idx =currpaginationno*max_pagination_btn_count
  let pagination_end_idx =Math.min(pagination_start_idx+max_pagination_btn_count, page_count)
  let pagination_count =Math.ceil(page_count/max_pagination_btn_count)

  return (
    <>
      <div className={['column', style['page-container']].join(' ')} >
        <ImportModal
          api={ApiDefinition.IMPORT_PROFILE}
          params={{ queryId: 1 }}
          exampleFilePath="/files/1.xlsx"
          exampleFileName="名單匯入.xlsx"
          onFinish={() => {
            console.log('end of import')
            //TODO: reload page
          }}
          title="名單匯入"
          uploadDescription="上傳名單"
          onImport={()=>{
              const formData = new FormData();
              formData.append("postId", 1 || "");
              return formData;
          }}
          {...disclosure}
        />
        <div className={['col-auto', 'row', 'no-wrap', 'justify-between', 'items-center', ].join(' ')}>
          <div className={['col-auto', 'row', 'no-wrap', 'items-center', ].join(' ')}>
            <img alt="" className={'col-auto'} src={people_dark_svg} style={{width: '27px', height: '27px'}}/>
            <div className={'col-auto'} style={{fontSize: '28.13px', marginLeft: '20.5px'}}>
              全部學員資料
            </div>
          </div>
          <div className={['col-auto', 'row', 'no-wrap', 'items-center'].join(' ')}>
            <button className={['col-auto', style['custom-button']].join(' ')} onClick={() => { 
              setTablebusy(true);
              console.log(`export profile list`);
              //importType.current = 4
              api.get_report_profile({ }).then((res) => {
                console.log(`get_report_profile:`, res);
                window.location.href = res;
              }).catch((e) => {
                console.log(e);
                let message = "錯誤:";
                if ("response" in e && e.response.data) {
                  message = e.response.data;
                }
                if ("message" in e) {
                  message += ` (${e.message})`;
                }
                toast("error", e.message);
              }).finally(() => {
                setTablebusy(false)
              })
              }}>名單匯出</button>
            {/* <button className={['col-auto', style['custom-button']].join(' ')} onClick={()=>{
              console.log('名單匯入,click');
              memberModalDisclosure.onOpen();
              }}>名單匯入</button> */}
            <Button className={['col-auto', style['custom-button']].join(' ')}
              variant="customSolid"
              onClick={() => {
                disclosure.onOpen();
              }}>
              名單匯入
            </Button>
          </div>
        </div>

        <div className={['col-auto', 'row', 'no-wrap', 'justify-between', 'items-center', ].join(' ')} style={{marginTop: '49px'}}>
          <form className={['col-auto', 'row', style['custom-search-box-container']].join(' ')} onSubmit={(e)=>{
            e.preventDefault()
            //console.log('147 - update table from the criteria : '+criteria)

            setCurrpageno(0)
            navigate(generatePath(RoutePath.STUDENTS_PAGE.path, {pageno: 1})+'?'+stringify({...Object.fromEntries([...query[0]]), ...{query: criteria}}))

          }}>
            <input className={['col-auto', style['custom-search-box']].join(' ')} type='text' placeholder='輸入姓名搜尋' value={criteria} onChange={e=>setCriteria(e.target.value)}/>
            <button className={[style['custom-search-box-magnifier']].join(' ')} type="submit"/>
          </form>

          <div className={['col-auto', 'row', 'no-wrap', 'items-center'].join(' ')}>
            <Menu matchWidth>
              {({ isOpen, onClose }) => (
                <>
                <MenuButton style={{marginLeft: '20px', minWidth: '130px'}} className={style['custom-outline-button']}>
                  <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')}>
                    <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>{pdu_list[selectedPdu]}</label>
                    <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                  </div>
                </MenuButton>
                <MenuList style={{minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px',}} className={['column'].join(' ')}>
                  {
                    pdu_list.map((v,idx)=>{
                      return(
                        <button key={idx} className={['col-auto', 'row', 'items-center', style['custom-menu-item'], style[idx===selectedPdu?'active':'']].join(' ')} onClick={()=>{
                          setSelectedPdu(idx)
                          onClose()

                          let tmp_v =''
                          if (idx>0){
                            tmp_v =v
                          }

                          navigate(generatePath(RoutePath.STUDENTS_PAGE.path, {pageno: 1})+'?'+stringify({...Object.fromEntries([...query[0]]), ...{pdu: tmp_v}}))
                        }}>
                          <label className={['col-auto']} style={{lineHeight: '100%'}}>{v}</label>
                        </button>
                      )
                    })
                  }
                </MenuList>
                </>
              )}
            </Menu>

            <Menu matchWidth>
              {({ isOpen, onClose }) => (
                <>
                <MenuButton style={{marginLeft: '20px', minWidth: '160px'}} className={style['custom-outline-button']}>
                  <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')}>
                    <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>{m3_record[selectedM3Record]}</label>
                    <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                  </div>
                </MenuButton>
                <MenuList style={{minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px',}} className={['column'].join(' ')}>
                  {
                    m3_record.map((v,idx)=>{
                      return(
                        <button key={idx} className={['col-auto', 'row', 'items-center', style['custom-menu-item'], style[idx===selectedM3Record?'active':'']].join(' ')} onClick={()=>{
                          setSelectedM3Record(idx)
                          onClose()

                          let tmp_v =''
                          if (idx>0){
                            tmp_v =v
                          }

                          navigate(generatePath(RoutePath.STUDENTS_PAGE.path, {pageno: 1})+'?'+stringify({...Object.fromEntries([...query[0]]), ...{m3: tmp_v}}))
                        }}>
                          <label className={['col-auto']} style={{lineHeight: '100%'}}>{v}</label>
                        </button>
                      )
                    })
                  }
                </MenuList>
                </>
              )}
            </Menu>

            <Menu matchWidth>
              {({ isOpen, onClose }) => (
                <>
              <MenuButton style={{marginLeft: '20px', minWidth: '130px'}} className={style['custom-outline-button']}>
                <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')}>
                  <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>{exam_result[selectedExam]}</label>
                  <img alt="" src={dropdown_arrow} style={{width: '8.33px', height: '4.17px', marginLeft: '13.83px'}}/>
                </div>
              </MenuButton>
              <MenuList style={{minWidth: '0', border: '1px solid #757575', backgroundColor: '#f8f8ff', borderRadius: '5px',}} className={['column'].join(' ')}>
                {
                  exam_result.map((v,idx)=>{
                    return(
                      <button key={idx} className={['col-auto', 'row', 'items-center', style['custom-menu-item'], style[idx===selectedExam?'active':'']].join(' ')} onClick={()=>{
                        setSelectedExam(idx)
                        onClose()

                        let tmp_v =''
                        if (idx>0){
                          tmp_v =v
                        }

                        navigate(generatePath(RoutePath.STUDENTS_PAGE.path, {pageno: 1})+'?'+stringify({...Object.fromEntries([...query[0]]), ...{exam: tmp_v}}))
                      }}>
                        <label className={['col-auto']} style={{lineHeight: '100%'}}>{v}</label>
                      </button>
                    )
                  })
                }
              </MenuList>
                </>
              )}
            </Menu>

            <Menu>
              {({ isOpen, onClose }) => (
                <>
              <MenuButton style={{marginLeft: '20px', minWidth: '150px'}} className={style['custom-outline-button']}>
                <div className={['col-auto', 'row', 'justify-between', 'items-center',].join(' ')}>
                  <label className={['col-auto'].join(' ')} style={{pointerEvents: 'none'}}>{enable_state[0]?(year_state[0]+'-'+month_state[0]):'計算機到期'}</label>
                  <img alt="" src={dropdown_calendar} style={{width: '16.67px', height: '18.33px', marginLeft: '9.67px'}}/>
                </div>
              </MenuButton>
              <MenuListDatePicker onClose={onClose} yearState={year_state} monthState={month_state} enableState={enable_state} handleDateChange={(enable, year, month,)=>{
                let tmp_v =''
                if (enable===true){
                  tmp_v =year+'-'+month
                }

                navigate(generatePath(RoutePath.STUDENTS_PAGE.path, {pageno: 1})+'?'+stringify({...Object.fromEntries([...query[0]]), ...{expire: tmp_v}}))
              }}/>
                </>
              )}
            </Menu>

          </div>
        </div>

        <div className={['col-auto',]} style={{marginTop: '18px',  }}>
          <table className={style['custom-table']} style={{width: '100%', fontSize: '18px', fontWeight: '400',}}>
            <thead>
            <tr style={{height: '50px', color: 'white', }}>
              {table_head.map((v,idx)=>{
                return (
                  <th style={{textAlign: 'left', paddingLeft: idx===0?'10px':'0', }} key={idx}>{v}</th>
                )
              })}
            </tr>
            </thead>
            <tbody>
            {tabledata.length>0&&(tabledata.map((v,idx)=>{
              return (
                <tr key={idx} className={style['custom-table-row-hover']} style={{height: '50px', backgroundColor: idx%2==1?'#e7edf5':'white'}}>{
                  v.slice(1).map((subv, subidx)=>{
                    return (<td style={{textAlign: 'left', paddingLeft: subidx===0?'10px':'0', borderBottom: '1px solid black'}} key={subidx}>{subv}</td>)
                  }) 
                }<td style={{fontSize: '14.4px', borderBottom: '1px solid black'}}><button style={{color: '#00a3ff'}} onClick={()=>{navigate(generatePath(RoutePath.STUDENT_DETAIL.path, {id: v[0]}))}}>查看詳細</button></td></tr>
              )
            }))}
            </tbody>
          </table>
          {tablebusy&&(
            <LoadingComponent />
          )}
        </div>

        {tabledata.length>0 &&(
          <div className={['col-auto', 'row', 'items-center', 'justify-center'].join(' ')} style={{marginTop: '52px', marginBottom: '64px'}}>
            {
              currpaginationno>0&&(
                <button className={['row', 'justify-center', 'items-center', style['page-btn']].join(' ')} onClick={()=>{setCurrpaginationno(currpaginationno-1)}}>
                  <img alt="" src={next_page_arrow} style={{width: '4.79px', height: '8.31px', transform: 'rotate(180deg)'}}/>
                </button>
              )
            }
            {
              [...Array(Math.max(0, pagination_end_idx-pagination_start_idx)).keys()].map(v=>{
                return (
                  <button key={v+currpaginationno*max_pagination_btn_count} className={[style['page-btn'], style[(v+currpaginationno*max_pagination_btn_count)===currpageno?'active':'']].join(' ')} 
                  onClick={()=>{
                    window.scrollTo(0, 0)
                    setCurrpageno(v+currpaginationno*max_pagination_btn_count)
                    // window.history.pushState(null, null, '#'+generatePath(RoutePath.STUDENTS_PAGE.path, {pageno:1+v+currpaginationno*max_pagination_btn_count}))
                    navigate(generatePath(RoutePath.STUDENTS_PAGE.path, {pageno: 1+v+currpaginationno*max_pagination_btn_count})+'?'+stringify({...Object.fromEntries([...query[0]]),}))
                  }}>{v+1+currpaginationno*max_pagination_btn_count}</button>
                )
              })
            }
            {
              currpaginationno<(pagination_count-1)&&(
                <button className={['row', 'justify-center', 'items-center', style['page-btn']].join(' ')} onClick={()=>{setCurrpaginationno(currpaginationno+1)}}>
                  <img alt="" src={next_page_arrow} style={{width: '4.79px', height: '8.31px'}}/>
                </button>
              )
            }
          </div>
        )}
      </div>

      <Modal onClose={memberModalDisclosure.onClose} isOpen={memberModalDisclosure.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent className={['column'].join(' ')} style={{paddingLeft: '30px', paddingRight: '30px', minWidth: '630px'}}>
          <ModalHeader className={['col-auto', 'row', 'justify-between', 'items-center'].join(' ')} style={{width: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
            <div className={['col', ].join(' ')} style={{fontSize: '28.13px', fontWeight: '400',}}>名單匯入</div>
            <button className={['col-auto'].join(' ')} onClick={()=>{
              memberModalDisclosure.onClose()
              }}>
              <img alt="" src={modal_cross} style={{width: '18.67px', height: '18.67px'}}/>
            </button>
          </ModalHeader>
          <div className={['col-auto'].join(' ')} style={{borderTop: '1px solid #bdbdbd'}}/>
          <ModalBody className={['col-auto', 'column'].join(' ')} style={{paddingLeft: '0px', paddingRight: '0px', marginTop: '20px'}}>
            <div className={['col-auto', 'column'].join(' ')}>
              <div className={['col-auto'].join(' ')} style={{fontSize: '18px', }}>Step1. 下載excel範本</div>
              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginTop: '14px'}}>
                <div className={['col-auto'].join(' ')}>
                  <img alt="" src={document_normal} style={{width: '16px', height: '20px', marginRight: '12px'}}/>
                </div>
                <div className={['col-auto'].join(' ')} style={{width: '153px'}}>excel_sample.xlsx</div>
                <button className={['col-auto', style['custom-outline-button']].join(' ')} style={{height: '32px', fontSize: '14.4px'}} onClick={()=>{
                  console.log(`sample DL 1.學員清單匯入`);
                  //window.location.href = '/files/import1.xlsx';
                }}>檔案下載</button>
              </div>
            </div>

            <div className={['col-auto', 'column'].join(' ')} style={{marginTop: '40px'}}>
              <div className={['col-auto'].join(' ')} style={{fontSize: '18px', }}>Step2. 上傳考生名單</div>
              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginTop: '14px'}}>
                <div className={['col-auto'].join(' ')}>
                  <img alt="" src={document_grey} style={{width: '16px', height: '20px', marginRight: '12px'}}/>
                </div>
                <div className={['col-auto'].join(' ')} style={{width: '153px', color: '#757575'}}>未選擇任何檔案</div>
                <button className={['col-auto', style['custom-outline-button']].join(' ')} style={{height: '32px', fontSize: '14.4px'}}>選擇檔案</button>
              </div>
            </div>

            <div className={['col-auto', 'row'].join(" ")} style={{marginTop: '90px', marginBottom: '32px'}}>
              <button className={['col-auto', style['custom-button']].join(' ')} style={{height: '40px', fontSize: '18px', marginLeft: '0'}} onClick={()=>{
                console.warn('222 - shot toast()')
                toast({
                  containerStyle:{
                    maxWidth: 'unset',
                    minWidth: 'usnet',
                    marginBottom: '20px',
                  },
                  render: () => (
                    <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{paddingLeft: '16px', paddingRight: '16px', backgroundColor: 'white', height: '49px', borderRadius: '12px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'}}>
                      <img alt="" className={['col-auto'].join(' ')} src={green_check} style={{width: '20px', height: '20px', marginRight: '7px'}}/>
                      <label className={['col-auto'].join(' ')} style={{fontSize: '16px', color: 'black'}}>上傳檔案成功</label>
                    </div>
                  ),
                })

                memberModalDisclosure.onClose()
              }}>上傳資料</button>
            </div>
            
          </ModalBody>

        </ModalContent>
      </Modal>

      <Modal onClose={scoreModalDisclosure.onClose} isOpen={scoreModalDisclosure.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent className={['column'].join(' ')} style={{paddingLeft: '30px', paddingRight: '30px', minWidth: '630px'}}>
          <ModalHeader className={['col-auto', 'row', 'justify-between', 'items-center'].join(' ')} style={{width: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
            <div className={['col', ].join(' ')} style={{fontSize: '28.13px', fontWeight: '400',}}>成績匯入</div>
            <button className={['col-auto'].join(' ')} onClick={()=>{scoreModalDisclosure.onClose()}}>
              <img alt="" src={modal_cross} style={{width: '18.67px', height: '18.67px'}}/>
            </button>
          </ModalHeader>
          <div className={['col-auto'].join(' ')} style={{borderTop: '1px solid #bdbdbd'}}/>
          <ModalBody className={['col-auto', 'column'].join(' ')} style={{paddingLeft: '0px', paddingRight: '0px', marginTop: '20px'}}>
            <div className={['col-auto', 'column'].join(' ')}>
              <div className={['col-auto'].join(' ')} style={{fontSize: '18px', }}>Step1. 下載excel範本</div>
              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginTop: '14px'}}>
                <div className={['col-auto'].join(' ')}>
                  <img alt="" src={document_normal} style={{width: '16px', height: '20px', marginRight: '12px'}}/>
                </div>
                <div className={['col-auto'].join(' ')} style={{width: '153px'}}>excel_sample.xlsx</div>
                <button className={['col-auto', style['custom-outline-button']].join(' ')} style={{ height: '32px', fontSize: '14.4px' }} onClick={() => {
                  console.log(`sample DL 5.考試成績匯入`);
                  //window.location.href = '/files/import1.xlsx';
                }}>檔案下載</button>
              </div>
            </div>

            <div className={['col-auto', 'column'].join(' ')} style={{marginTop: '40px'}}>
              <div className={['col-auto'].join(' ')} style={{fontSize: '18px', }}>Step2. 上傳考生成續</div>
              <div className={['col-auto', 'row', 'items-center'].join(' ')} style={{marginTop: '14px'}}>
                <div className={['col-auto'].join(' ')}>
                  <img alt="" src={document_grey} style={{width: '16px', height: '20px', marginRight: '12px'}}/>
                </div>
                <div className={['col-auto'].join(' ')} style={{width: '153px', color: '#757575'}}>未選擇任何檔案</div>
                <button className={['col-auto', style['custom-outline-button']].join(' ')} style={{height: '32px', fontSize: '14.4px'}}>選擇檔案</button>
              </div>
            </div>

            <div className={['col-auto', 'row'].join(" ")} style={{marginTop: '90px', marginBottom: '32px'}}>
              <button className={['col-auto', style['custom-button']].join(' ')} style={{height: '40px', fontSize: '18px', marginLeft: '0'}} onClick={()=>{
                console.warn('222 - shot toast()')
                toast({
                  containerStyle:{
                    maxWidth: 'unset',
                    minWidth: 'usnet',
                    marginBottom: '20px',
                  },
                  render: () => (
                    <div className={['row', 'items-center', 'justify-center'].join(' ')} style={{paddingLeft: '16px', paddingRight: '16px', backgroundColor: 'white', height: '49px', borderRadius: '12px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'}}>
                      <img alt="" className={['col-auto'].join(' ')} src={red_cross} style={{width: '20px', height: '20px', marginRight: '7px'}}/>
                      <label className={['col-auto'].join(' ')} style={{fontSize: '16px', color: 'black'}}>檔案格式錯誤</label>
                    </div>
                  ),
                })

                // memberModalDisclosure.onClose()
              }}>上傳資料</button>
            </div>
            
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}

export default StudentsComponent