import {Box, Flex, Menu, MenuButton, MenuItem, MenuItemProps, MenuList, MenuProps, Text} from "@chakra-ui/react";
import {mdiMenuDown} from "@mdi/js";
import Icon from "@mdi/react";
import React, {useMemo} from "react";

export type MenuSelectValue = number | string | undefined;

export type MenuSelectProps = {
  nullValueText?: string;
  options: Record<number | string, string>;
  onChange: (value: MenuSelectValue, options: Record<number | string, string>) => void;
  value?: MenuSelectValue;
  disabled?: boolean;
  width?: number;
  minW?: number;
  maxH?: number;
} & Pick<MenuProps, "variant">;

const calcTextWidth = (value: number) => value * 17 + 30;

function MenuSelect({
  nullValueText = "",
  options,
  onChange,
  value,
  disabled,
  variant,
  width,
  minW,
  maxH = 400,
}: MenuSelectProps) {
  const labelToShow = useMemo(() => {
    return value ? options[value] : nullValueText;
  }, [nullValueText, options, value]);
  const handleSelect = (value: number | string | undefined) => {
    onChange(value, options);
  };
  const activeProps: MenuItemProps = {
    border: "1px solid",
  };
  const mWidth = useMemo(() => {
    if (width) {
      return width;
    }
    const maxTextLength = Object.keys(options).reduce(
      (previousValue, currentValue) => Math.max(previousValue, options[currentValue].length),
      0
    );
    const nullValueTextLength = nullValueText.length;
    return Math.max(calcTextWidth(maxTextLength), calcTextWidth(nullValueTextLength), minW || 0);
  }, [width, options, nullValueText.length, minW]);
  return (
    /* <Menu> Component should be wrapped with <Box> to get rid of warning.
     * @see https://github.com/chakra-ui/chakra-ui/issues/3440
     */
    <Box>
      <Menu matchWidth variant={variant}>
        <MenuButton disabled={disabled} w={mWidth} minW={minW}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{labelToShow}</Text>
            <Icon path={mdiMenuDown} size={1} />
          </Flex>
        </MenuButton>
        <MenuList minW={mWidth} maxH={maxH} overflowY="scroll">
          {nullValueText ? (
            <MenuItem
              onClick={() => {
                handleSelect(undefined);
              }}
              {...(!value ? activeProps : {})}
            >
              {nullValueText}
            </MenuItem>
          ) : (
            <></>
          )}
          {Object.keys(options).map((key, index) => {
            const isActive = key === value;
            console.log("render item", new Date());
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  handleSelect(key);
                }}
                {...(isActive ? activeProps : {})}
              >
                {options[key]}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
}
export default MenuSelect;
