// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.student_detail_panel_container__LlHj5{
  border: 1px solid #E0E0E0;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  
}

.student_detail_panel_custom-outline-button__9M66w{
  height: 43px;
  border: 1px solid #757575;

  padding-left: 16px;
  padding-right: 16px;

  border-radius: 4px;

  color: #757575;

  font-size: 18px;

  
}

.student_detail_panel_custom-outline-button__9M66w:hover{
  background-color: #e4ebf3;
}

.col-auto.student_detail_panel_custom-button__r\\+hoh{
  background-color: #0e4e96;
  color: white;

  height: 40px;

  padding-left: 16px;
  padding-right: 16px;

  border-radius: 4px;

  margin-left: 20px;
}

.student_detail_panel_custom-button__r\\+hoh:hover{
  filter: brightness(1.2);
}

.col-auto.student_detail_panel_custom-menu-item__gB1IX{
  height: 39px;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  margin-left: 6px;
  margin-right: 6px;
  padding: 7px;

  border-radius: 3px;
}

.student_detail_panel_custom-menu-item__gB1IX:hover{
  background-color: #0E4E96;
  color: white;
}

.student_detail_panel_custom-menu-item__gB1IX.student_detail_panel_active__N1tDw{
  border: 1px solid #0e4e96;
  margin: -1px;
  margin-left: 5px;
  margin-right: 5px;
}

.student_detail_panel_custom-menu-item__gB1IX.student_detail_panel_active__N1tDw:hover{
  background-color: #0E4E96;
  color: white;
}

.student_detail_panel_ellipsis__NcnfE {
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/pages/css/student_detail_panel.module.css"],"names":[],"mappings":";AACA;EACE,yBAAyB;EACzB,0CAA0C;EAC1C,mBAAmB;;;AAGrB;;AAEA;EACE,YAAY;EACZ,yBAAyB;;EAEzB,kBAAkB;EAClB,mBAAmB;;EAEnB,kBAAkB;;EAElB,cAAc;;EAEd,eAAe;;;AAGjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;;EAEZ,YAAY;;EAEZ,kBAAkB;EAClB,mBAAmB;;EAEnB,kBAAkB;;EAElB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;;EAEZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":["\n.container{\n  border: 1px solid #E0E0E0;\n  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n\n  \n}\n\n.custom-outline-button{\n  height: 43px;\n  border: 1px solid #757575;\n\n  padding-left: 16px;\n  padding-right: 16px;\n\n  border-radius: 4px;\n\n  color: #757575;\n\n  font-size: 18px;\n\n  \n}\n\n.custom-outline-button:hover{\n  background-color: #e4ebf3;\n}\n\n:global(.col-auto).custom-button{\n  background-color: #0e4e96;\n  color: white;\n\n  height: 40px;\n\n  padding-left: 16px;\n  padding-right: 16px;\n\n  border-radius: 4px;\n\n  margin-left: 20px;\n}\n\n.custom-button:hover{\n  filter: brightness(1.2);\n}\n\n:global(.col-auto).custom-menu-item{\n  height: 39px;\n  text-align: left;\n  font-size: 18px;\n  font-weight: 400;\n  margin-left: 6px;\n  margin-right: 6px;\n  padding: 7px;\n\n  border-radius: 3px;\n}\n\n.custom-menu-item:hover{\n  background-color: #0E4E96;\n  color: white;\n}\n\n.custom-menu-item.active{\n  border: 1px solid #0e4e96;\n  margin: -1px;\n  margin-left: 5px;\n  margin-right: 5px;\n}\n\n.custom-menu-item.active:hover{\n  background-color: #0E4E96;\n  color: white;\n}\n\n.ellipsis {\n  overflow:hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `student_detail_panel_container__LlHj5`,
	"custom-outline-button": `student_detail_panel_custom-outline-button__9M66w`,
	"custom-button": `student_detail_panel_custom-button__r+hoh`,
	"custom-menu-item": `student_detail_panel_custom-menu-item__gB1IX`,
	"active": `student_detail_panel_active__N1tDw`,
	"ellipsis": `student_detail_panel_ellipsis__NcnfE`
};
export default ___CSS_LOADER_EXPORT___;
