import axios from "axios";
import fileDownload from "js-file-download";

export const download = (path: string, filename: string, params?: any) => {
  axios.get(path, { responseType: "blob", params }).then((response) => {
    fileDownload(response.data, filename);
  });
};

export const appendFiles = (formData: FormData, files: File[], encodeFileName = true): void => {
  if (files.length > 0) {
    files.forEach((file: any) => {
      let mFileName = file.name;
      if (encodeFileName) {
        mFileName = encodeURI(mFileName);
      }
      formData.append("files[]", file, mFileName); //required
    });
  }
};
