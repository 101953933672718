import dayjs from "dayjs";

export function toMinguoDate(date: dayjs.ConfigType, withTime = false) {
  const djDate = dayjs(date);
  const y = djDate.year() - 1911;
  const timeFormat = withTime ? " HH:mm" : "";
  return `${y}年${djDate.format("M月D日" + timeFormat)}`;
}

export function toSlashDate(value: Date | string) {
  if (typeof value === "string") {
    return value.replaceAll("-", "/");
  } else {
    return dayjs(value).format("YYYY/MM/DD");
  }
}
