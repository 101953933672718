import ApiDefinition from "api/ApiDefinition";
import {useFetchData} from "hooks";
import React, {useEffect, useMemo} from "react";
import DashBoardUnit, {DashBoardUnitColumn} from "./DashBoardUnit";

export interface CalculatorStatRow {
  field1: number;
  field2: number;
  field3: number;
  field4: number;
}

function CalculatorStatBlock() {
  const { fetchData, data } = useFetchData<number[]>({
    url: `${ApiDefinition.GET_SUBSCRIPTION_STAT.path}`,
  });
  //載入資料
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mData = useMemo(
    () =>
      data
        ? data.reduce(
            (previousValue, currentValue, currentIndex) => ({
              ...previousValue,
              [`field${currentIndex + 1}`]: currentValue,
            }),
            {}
          )
        : {},
    [data]
  );
  const columns: DashBoardUnitColumn<CalculatorStatRow>[] = [
    {
      accessKey: "field1",
      title: "訂閱總人數",
      unit: "人",
    },
    {
      accessKey: "field2",
      title: "一年期訂閱人數",
      unit: "人",
    },
    {
      accessKey: "field3",
      title: "二年期訂閱人數",
      unit: "人",
    },
    {
      accessKey: "field4",
      title: "三年期訂閱人數",
      unit: "人",
    },
  ];
  return (
    <DashBoardUnit
      title="計算機訂閱統計"
      columns={columns}
      data={mData as CalculatorStatRow} //資料從這邊傳入
    />
  );
}

export default CalculatorStatBlock;
