import {
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    FormLabelProps,
} from "@chakra-ui/react";
import {ReactNode} from "react";
import ReactQuill, {ReactQuillProps} from "react-quill";

export type FEditorProps = ReactQuillProps & {
  formControlProps?: FormControlProps;
  label?: ReactNode;
  helperText?: string;
  errorMessage?: string;
  formLabelProps?: FormLabelProps;
} & Pick<FormControlProps, "width">;

function FEditor({
  formControlProps,
  label,
  helperText,
  errorMessage,
  formLabelProps,
  width = "100%",
  ...props
}: FEditorProps) {
  const modules = props.readOnly
    ? { toolbar: [] }
    : {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link"],
          ["clean"],
        ],
      };
  return (
    <FormControl {...formControlProps} width={width}>
      <FormLabel variant="custom" {...formLabelProps}>
        {label}
      </FormLabel>
      <ReactQuill modules={modules} theme="snow" {...props} />
      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : <></>}
      {!errorMessage && helperText ? <FormHelperText>{helperText}</FormHelperText> : <></>}
    </FormControl>
  );
}

export default FEditor;
