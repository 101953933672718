import {FNumberPicker} from "common/form";
import {FNumberPickerProps} from "common/form/FNumberPicker";
import {FastField, FieldProps, useFormikContext} from "formik";
import React from "react";

export type FMNumberPickerProps = Omit<FNumberPickerProps, "name" | "errorMessage"> & {
  name: string;
};

function FMNumberPicker({ name, type, ...props }: FMNumberPickerProps) {
  const { setFieldValue } = useFormikContext();
  const handleChange = (value: number) => {
    setFieldValue(name, value);
  };
  return (
    <FastField name={name} type={type}>
      {({ field, form, meta }: FieldProps) => {
        const error = meta.touched && meta.error ? meta.error : undefined;
        const { value, name } = field;
        return (
          <FNumberPicker
            {...props}
            type={type}
            errorMessage={error}
            value={value}
            name={name}
            onChange={handleChange}
          />
        );
      }}
    </FastField>
  );
}

export default FMNumberPicker;
