/**
 * 注意：
 * 此為舊API串接的方式，新的部分請改用hook (useAPIRequest, useFetchData, useFetchList) 取代\
 * 新增、修改、刪除 useAPIRequest
 * const { processRequest } = useAPIRequest();
 * processRequest(ApiDefinition.<你的API網址定義>, <表單內容(可有可無)>);
 * 取得列表 useFetchList
 * 取得單筆資料等 useFetchData
 */
import style from './../css/student_activity.module.css'
import next_page_arrow from '../../svg-files/next-page-arrow.svg'
import api from '../../api/Api'
import {useEffect, useState} from 'react'
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom"

import RoutePath from '../../routes/RoutePathDefinition'
import LoadingComponent from './LoadingComponent'

const table_head=[
  '活動名稱', '開課單位', '上課日期', '認證時數', '匯入證書', '匯入時間',
]

const mock_tabledata=[
  ['uid0', '----', '----', '202?-??-??', '1.5', 'RFAC???????', '202?-??-??',],
]

const page_rows =20
const max_pagination_btn_count =10

let StudentActivityPanelComponent =()=>{
  const loading = useState(true)
  const [currpageno, setCurrpageno] = useState(0)
  const [currpaginationno, setCurrpaginationno] = useState(0)
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  const [tabledata, settabledata] = useState([])

  useEffect(() => {
    let tmp_pageno = 1
    //console.log('44 - current page no : '+params?.pageno)
    if (params?.pageno !== undefined && params?.pageno !== '') {
      tmp_pageno = params?.pageno
    }
    setCurrpaginationno(parseInt(parseInt(tmp_pageno - 1) / max_pagination_btn_count))
    setCurrpageno(parseInt(tmp_pageno - 1))

    setTimeout(() => {

      if (!Number.isNaN(Number(id))) {
        loading[1](true)
        api.get_student_activity_list(id).then((res) => {
          loading[1](false)
          console.log('get_student_activity_list', res)
          if (res.total > 0) {
            let tmp_tabledata = []
            for (let i = 0; i < res.rows.length; ++i) {
              mock_tabledata[0][0] = res.rows[i].id;
              mock_tabledata[0][1] = res.rows[i].name;
              mock_tabledata[0][2] = res.rows[i].orgName;
              mock_tabledata[0][3] = res.rows[i].StartDate;
              mock_tabledata[0][4] = res.rows[i].hours;
              mock_tabledata[0][5] = res.rows[i].serialNumber;
              mock_tabledata[0][6] = res.rows[i].completedDate;
              //tmp_tabledata.push(Array.from(res.rows[i]))
              tmp_tabledata.push(Array.from(mock_tabledata[0]));
            }
            settabledata(tmp_tabledata)
            console.log(tabledata)
          } else {

          }

        }).catch((e) => {

        }).finally(() => {
          loading[1](false)
        })
      }
    }, 500)



  }, [location.pathname])

  let start_idx =currpageno*page_rows
  let end_idx =Math.min(start_idx+page_rows, tabledata.length)
  let page_count =Math.ceil(tabledata.length/page_rows)

  let pagination_start_idx =currpaginationno*max_pagination_btn_count
  let pagination_end_idx =Math.min(pagination_start_idx+max_pagination_btn_count, page_count)
  let pagination_count =Math.ceil(page_count/max_pagination_btn_count)

  return (
    <div className={['colume',].join(' ')} style={{
      marginTop: '18px', marginBottom: '26px', 
    }}>
      <div className={['col-auto',]} >
        <table className={style['custom-table']} style={{width: '100%', fontSize: '18px', fontWeight: '400', }}>
          <thead>
          <tr style={{height: '50px', color: 'white', }}>
            {table_head.map((v,idx)=>{
              return (
                <th style={{textAlign: 'left', paddingLeft: idx===0?'10px':'0', }} key={idx}>{v}</th>
              )
            })}
          </tr>
          </thead>
          <tbody>
          {tabledata.length>0&&(tabledata.slice(start_idx, end_idx).map((v,idx)=>{
            return (
              <tr key={idx} className={style['custom-table-row-hover']} style={{height: '50px', backgroundColor: idx%2==1?'#e7edf5':'white'}}>{
                v.slice(1).map((subv, subidx)=>{
                  return (<td style={{textAlign: 'left', paddingLeft: subidx===0?'10px':'0', borderBottom: '1px solid black'}} key={subidx}>{subv}</td>)
                }) 
              }</tr>
            )
          }))}
          </tbody>
        </table>
        {loading[0]&&(
          <LoadingComponent />
        )}

        {tabledata.length>0 &&(
          <div className={['col-auto', 'row', 'items-center', 'justify-center'].join(' ')} style={{marginTop: '52px', marginBottom: '64px'}}>
            {
              currpaginationno>0&&(
                <button className={['row', 'justify-center', 'items-center', style['page-btn']].join(' ')} onClick={()=>{setCurrpaginationno(currpaginationno-1)}}>
                  <img alt="" src={next_page_arrow} style={{width: '4.79px', height: '8.31px', transform: 'rotate(180deg)'}}/>
                </button>
              )
            }
            {
              [...Array(Math.max(0, pagination_end_idx-pagination_start_idx)).keys()].map(v=>{
                return (
                  <button key={v+currpaginationno*max_pagination_btn_count} className={[style['page-btn'], style[(v+currpaginationno*max_pagination_btn_count)===currpageno?'active':'']].join(' ')} 
                  onClick={()=>{
                    setCurrpageno(v+currpaginationno*max_pagination_btn_count)
                    window.history.pushState(null, null, '#'+generatePath(RoutePath.STUDENT_ACTIVITY_PAGE.path, {id: params.id, pageno:1+v+currpaginationno*max_pagination_btn_count}))
                  }}>{v+1+currpaginationno*max_pagination_btn_count}</button>
                )
              })
            }
            {
              currpaginationno<(pagination_count-1)&&(
                <button className={['row', 'justify-center', 'items-center', style['page-btn']].join(' ')} onClick={()=>{setCurrpaginationno(currpaginationno+1)}}>
                  <img alt="" src={next_page_arrow} style={{width: '4.79px', height: '8.31px'}}/>
                </button>
              )
            }
          </div>
        )}
      </div>
    </div>
  )
}

export default StudentActivityPanelComponent